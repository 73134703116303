class DefaultValues {
    static updated = false
    static holiday: number
    static tax1: number
    static tax2: number
    static tax3: number
    static ov1: number
    static ov2: number
    static ov3: number
    static start_working_time: string
    static end_working_time: string

    static updateValues(values: any) {
        DefaultValues.holiday = values.holiday
        DefaultValues.tax1 = values.tax1
        DefaultValues.tax2 = values.tax2
        DefaultValues.tax3 = values.tax3
        DefaultValues.ov1 = values.ov1
        DefaultValues.ov2 = values.ov2
        DefaultValues.ov3 = values.ov3
        DefaultValues.start_working_time = values.start_working_time
        DefaultValues.end_working_time = values.end_working_time
        DefaultValues.updated = true
    }
}

export default DefaultValues
