import {Dropdown} from "react-native-element-dropdown";
import {colors} from "../colors";
import React, {useState} from "react";
import {StyleSheet, Text, View} from "react-native";
import {fontStyles} from "../fontStyles";

export default function StyledDropDown(
    {
        style,
        items,
        placeHolder,
        onChange,
        labelField = "label",
        valueField = "label",
        value,
        search = false,
        topLabel = true,
    }) {
    let height = style && style.height ? style.height : 40
    const [showTitle, setShowTitle] = useState(value != null)
    return (
        <View style={{
            flex: 1,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: colors.lightYellow,
            marginHorizontal: 5,
            height: height,
            maxHeight: height,
            minHeight: height,
            ...style,
        }}>
            <Dropdown
                style={{
                    flex: 1,
                    marginHorizontal: 15,
                    height: height,
                    minHeight: height,
                    maxHeight: height,
                }}
                search={search}
                inputSearchStyle={{outlineStyle: 'none'}}
                data={items} labelField={labelField} valueField={valueField}
                placeholder={placeHolder}
                placeholderStyle={{color: colors.mediumGray, ...fontStyles.lato700_14}}
                itemTextStyle={{color: colors.darkGray, ...fontStyles.lato700_14}}
                selectedTextStyle={{color: colors.darkGray, ...fontStyles.lato700_14}}
                onChange={(item) => {
                    setShowTitle(item != null && item[labelField] != null && item[valueField] != null)
                    if (onChange) onChange(item)
                }}
                value={value}
            />
            {
                topLabel === true && showTitle && <Text style={{
                    ...fontStyles.lato400_12,
                    backgroundColor: "white",
                    marginLeft: 15,
                    paddingHorizontal: 3,
                    alignSelf: "flex-start",
                    color: colors.mediumGray,
                    zIndex: 1,
                    lineHeight: 15,
                    marginTop: -height - 15 / 1.5
                }}>{placeHolder}</Text>
            }
        </View>
    )
}
