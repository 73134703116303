import {useEffect, useRef, useState} from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import {colors} from "../colors";
import {View} from "react-native";
import DatePicker from "react-native-modern-datepicker";
import {addMonths, format, getMonth, getYear} from "date-fns";
import {da} from "date-fns/locale";


function ModalDatePicker({
                             open,
                             onConfirm,
                             onCancel,
                             currentDate,
                             startYear = getYear(new Date()) - 9,
                             endYear = getYear(new Date()),
                             mode
                         }) {
    const date = useRef(currentDate ? currentDate : new Date());
    useEffect(() => {
        date.current = (currentDate ? currentDate : new Date())
    });
    return (
        <AwesomeAlert
            show={open}
            closeOnTouchOutside={false}
            showConfirmButton={true}
            confirmText={"OK"}
            confirmButtonStyle={{width: 100, alignItems: "center", justifyContent: "center"}}
            confirmButtonColor={colors.lightYellow}
            onConfirmPressed={() => {
                if (onConfirm) {
                    if (mode && mode === "monthYear") {
                        onConfirm(new Date(getYear(date.current), getMonth(date.current), 1))
                    } else {
                        onConfirm(date.current)
                    }
                }
            }}
            showCancelButton={true}
            cancelText={"Cancel"}
            cancelButtonStyle={{width: 100, alignItems: "center", justifyContent: "center"}}
            cancelButtonColor={colors.lightYellow}
            onCancelPressed={() => {
                if (onCancel) onCancel()
            }}
            customView={
                <View style={{
                    width: 400,
                    height: 400,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 22
                }}>
                    <DatePicker
                        mode={mode ?? "calendar"}
                        selectorEndingYear={endYear}
                        selectorStartingYear={startYear}
                        current={format(date.current, "yyyy/MM/dd")}
                        selected={format(date.current, "yyyy/MM/dd")}
                        onSelectedChange={(d) => {
                            let parts = d.toString().split('/');
                            date.current = new Date(parts[0], parts[1] - 1, parts[2])
                            console.log(date.current)
                        }}
                        onMonthYearChange={(d) => {
                            let parts = d.toString().split(' ');
                            console.log(parts)
                            date.current = new Date(parts[0], parts[1] - 1, 1)
                            console.log(date.current)
                        }}
                    />
                </View>}>
        </AwesomeAlert>
    )
}

export default ModalDatePicker
