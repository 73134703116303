import FadeView from "../../FadeView";
import Space from "../../Space";
import React, {useEffect, useRef, useState} from "react";
import {Image, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colors} from "../../../colors";
import {fontStyles} from "../../../fontStyles";
import CustomButton from "../../employee_screens/components/CustomButton";
import {addYears, format, getDate, getMonth, getYear} from "date-fns";
import IntentMessenger from "../../../misc/IntentMessenger";
import Helpers from "../../../misc/Helpers";


export const selectedEmployeeTag = "selected employee";

export default function
    HomeContent({
                    visible,
                    animationDuration,
                    onChangeDestination,
                    useAnimation,
                    searchQuery,
                    onSearchQueryChange,
                    employees,
                    selectedType,
                    onSelectedTypeChange,
                    onUserReportClick,
                    onUserTimeTableClick,
                    onChangeStatus,
                    onEditClick
                }) {

    const [selectedRowIndex, setSelectedRowIndex] = useState(-1)
    useEffect(() => {
        console.log(selectedRowIndex)
    }, [selectedRowIndex]);

    return (
        <FadeView style={{flex: 1}} visible={visible} duration={animationDuration} useAnimation={useAnimation}>
            {/*<BudgetBanner/>*/}
            {/*<Space height={30}/>*/}
            <EmployeeFilters
                selectedType={selectedType}
                searchQuery={searchQuery}
                onSearchButtonPressed={(query) => {
                    onSearchQueryChange(query)
                }}
                onTypeFilterChanged={(index) => {
                    onSelectedTypeChange(index)
                }}
                onSearchQueryChanged={(s) => {
                    onSearchQueryChange(s)
                }}
                onChangeDestination={onChangeDestination}
            />
            <Space height={20}/>
            <EmployeesTable employees={employees} onUserReportClick={onUserReportClick}
                            selectedRowIndex={selectedRowIndex}
                            onUserTimeTableClick={onUserTimeTableClick} onChangeStatus={onChangeStatus}
                            onEditClick={onEditClick} onRowSelected={(index) => setSelectedRowIndex(index)}/>
        </FadeView>);
}

function EmployeeFilters(
    {
        onTypeFilterChanged,
        selectedType,
        onSearchButtonPressed,
        searchQuery,
        onSearchQueryChanged,
        onChangeDestination
    }) {
    function FilterByType({selectedType, onTypeFilterChanged}) {

        function TypeSelector({text, index}) {
            return (
                <TouchableOpacity
                    style={{
                        flex: 1,
                        backgroundColor: selectedType === index ? colors.navyBlue : "transparent",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 20,
                    }}
                    onPress={() => {
                        onTypeFilterChanged(index)
                    }}
                >
                    <Text
                        style={{...fontStyles.lato400_14, color: selectedType === index ? "white" : null}}
                    >{text}</Text>
                </TouchableOpacity>
            )
        }

        const selectorData = [
            "All Employees",
            "Current Employees",
            "Past Employees",
        ]

        return (
            <View style={{
                flexDirection: "row",
                flex: 1,
                maxHeight: 40,
                backgroundColor: colors.lightYellow,
                borderRadius: 20,
            }}>
                {selectorData.map((value, index, array) => {
                    return <TypeSelector key={index} text={value} index={index}/>
                })}
            </View>
        )
    }

    function FilterByName({searchQuery, onSearchQueryChanged}) {
        const inputFieldRef = useRef(null);
        setTimeout(() => {
            inputFieldRef.current?.focus()
        }, 10)

        return (
            <View
                style={{
                    flex: 1,
                    flexDirection: "row",
                    borderRadius: 20,
                    borderWidth: 1,
                    borderColor: colors.navyBlue,
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextInput
                    ref={inputFieldRef}
                    style={{
                        ...fontStyles.lato700_14,
                        flex: 1,
                        marginHorizontal: 20,
                        outlineStyle: 'none'
                    }}
                    value={searchQuery}
                    placeholder={"Search employee (ID or Name)"}
                    placeholderTextColor={colors.lightGray}
                    onChangeText={(t) => {
                        onSearchQueryChanged(t)
                    }}
                />
                <TouchableOpacity
                    style={{
                        width: 20,
                        height: 20,
                        marginHorizontal: 20
                    }}
                    onPress={() => {
                        onSearchButtonPressed(searchQuery)
                    }}
                >
                    <Image
                        style={{
                            width: 20,
                            height: 20,
                        }}
                        source={require("../../../assets/icons/magnifier.png")}
                    />
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <View style={{
            flexDirection: "row",
            height: 40,
        }}>
            <FilterByType selectedType={selectedType} onTypeFilterChanged={onTypeFilterChanged}/>
            <Space width={15}/>
            <FilterByName searchQuery={searchQuery} onSearchQueryChanged={onSearchQueryChanged}/>
            <Space width={15}/>
            <CustomButton text={"Add Employee"} style={{maxWidth: 200}} onPress={onChangeDestination} fontSize={16}/>
        </View>
    )
}

function EmployeesTable({
                            employees = [],
                            onRowSelected,
                            selectedRowIndex,
                            onUserReportClick,
                            onUserTimeTableClick,
                            onChangeStatus,
                            onEditClick
                        }) {
    function RowDivider() {
        return (
            <View
                style={{
                    borderColor: colors.lighterGray,
                    borderWidth: 1,
                }}/>
        )
    }

    function TableRow({values = [], onRowSelected, onChangeStatus, onEditClick, isSelected, isHeader = false}) {
        const styles = StyleSheet.create({
            headerRowStyle: {...fontStyles.lato700_16},
            normalRowStyle: {...fontStyles.lato400_14}
        })
        const style = isHeader ? styles.headerRowStyle : styles.normalRowStyle;
        const statusColor = values[7] === "Current" ? "green" : (values[7] === "Past" ? "red" : "black")
        const parsedBirthDate = Date.parse(values[3])
        const now = new Date()
        let age = values[3]
        if (!isNaN(parsedBirthDate)) {
            const yearDiff = getYear(now) - getYear(parsedBirthDate)
            const monthDiff = getMonth(now) - getMonth(parsedBirthDate)
            const dateDiff = getDate(now) - getDate(parsedBirthDate)
            age = format(new Date(yearDiff, monthDiff, dateDiff), "yy")
        }
        let gender = values[4]
        if (!isHeader && values[4] !== null && values[4] !== "") {
            if (+values[4] === 0) {
                gender = "Male"
            } else if (+values[4] === 1) {
                gender = "Female"
            } else if (+values[4] === 2) {
                gender = "Other"
            }
        }

        function ExtraDataView({title, value}) {
            return <View style={{flexDirection: "row", flex: 1}}>
                <Text style={fontStyles.lato700_12}>{title}:</Text>
                <Space width={5}/>
                <Text style={fontStyles.lato400_12}>{value ?? "Not Assigned"}</Text>
            </View>
        }

        function TableContents() {
            return <>
                <View
                    style={{
                        flexDirection: "row",
                        height: 45,
                        alignItems: "center",
                        backgroundColor: "white"
                    }}
                >
                    <Text style={[style, {flex: 380 - 220}]}>{values[0]}</Text>
                    <Text style={[style, {flex: 930 - 380}]}>{values[1]}</Text>
                    <Text style={[style, {flex: 1330 - 930}]}>{values[2]}</Text>
                    <Text style={[style, {flex: 1550 - 1330}]}>{age}</Text>
                    <Text style={[style, {flex: 1755 - 1550}]}>{gender}</Text>
                    <Text style={[style, {flex: 2010 - 1755}]}>{Helpers.formatNumberWithSpace(values[5])}</Text>
                    <Text style={[style, {flex: 2270 - 2010}]}>{values[6]}</Text>
                    {isHeader ?
                        <Text style={{...style, flex: 2450 - 2270, fontWeight: "700"}}>{values[7]}</Text> :
                        <TouchableOpacity style={{flex: 2450 - 2270}} onPress={() => onChangeStatus(values[0])}>
                            <Text style={{...style, color: statusColor, flex: 1, fontWeight: "700"}}>{values[7]}</Text>
                        </TouchableOpacity>
                    }
                    {
                        values.length >= 9 && values[8] !== "" ?
                            <Text style={[style, {flex: 2580 - 2450}]}>{values[8]}</Text> :
                            <View style={{flex: 2580 - 2450}}>
                                <TouchableOpacity
                                    style={{flex: 1, marginHorizontal: 10, alignItems: "center"}}
                                    onPress={() => {
                                        // Messenger.setMessage(Messenger.receivers.ReportsTab, values[0])
                                        console.log(values[0])
                                        IntentMessenger.setMessage(selectedEmployeeTag, values[0])
                                        onUserReportClick()
                                    }}
                                >
                                    <Image
                                        style={{width: 25, height: 25}}
                                        source={require("../../../assets/icons/business_report.png")}
                                    />
                                </TouchableOpacity>
                            </View>
                    }
                    {
                        values.length >= 10 && values[9] !== "" ?
                            <Text style={[style, {flex: 2755 - 2580}]}>{values[9]}</Text> :
                            <View style={{flex: 2755 - 2580, alignItems: "center", justifyContent: "center"}}>
                                <TouchableOpacity
                                    style={{flex: 1, marginHorizontal: 10, alignItems: "center"}}
                                    onPress={() => {
                                        // Messenger.setMessage(Messenger.receivers.PostingTab, `selectedId: ${values[0]}`)
                                        console.log(values[0])
                                        IntentMessenger.setMessage(selectedEmployeeTag, values[0])
                                        onUserTimeTableClick()
                                    }}
                                >
                                    <Image
                                        style={{width: 25, height: 25}}
                                        source={require("../../../assets/icons/calendar_clock.png")}
                                    />
                                </TouchableOpacity>
                            </View>
                    }
                    {
                        values.length >= 11 && values[10] !== "" ?
                            <Text style={[style, {flex: 2810 - 2755,}]}>{values[10]}</Text> :
                            <View style={{flex: 2810 - 2755, alignItems: "center", justifyContent: "center"}}>
                                <TouchableOpacity style={{flex: 1, marginHorizontal: 10, alignItems: "center"}}
                                                  onPress={() => {
                                                      onEditClick(values[0])
                                                  }}>
                                    <Image
                                        style={{width: 25, height: 25}}
                                        source={require("../../../assets/icons/pen.png")}
                                    />
                                </TouchableOpacity>
                            </View>
                    }
                </View>
                {
                    isSelected &&
                    <View style={{height: 45}}>
                        <View style={{
                            flexDirection: "row",
                            height: 20,
                            alignItems: "center",
                            backgroundColor: "white"
                        }}>
                            <ExtraDataView title={"SSN"} value={values[16]}/>
                            <ExtraDataView title={"Clearing #"} value={values[17]}/>
                            <ExtraDataView title={"Account #"} value={values[18]}/>
                        </View>
                        <View style={{
                            flexDirection: "row",
                            height: 20,
                            alignItems: "center",
                            backgroundColor: "white"
                        }}>
                            <ExtraDataView title={"Contact #"} value={values[19]}/>
                            <ExtraDataView title={"Closest Family"} value={values[20]}/>
                            <ExtraDataView title={"Closest Family Contact #"} value={values[21]}/>
                        </View>

                    </View>
                }
                <RowDivider/>
            </>
        }

        return (
            onRowSelected ?
                <TouchableOpacity onPress={onRowSelected}>
                    <TableContents/>
                </TouchableOpacity> :
                <View>
                    <TableContents/>
                </View>
        )
    }

    return (
        <View>
            <RowDivider/>
            <TableRow
                values={["ID", "Employee", "Position", "Age", "Gender", "Salary", "Salary Type", "Status", "Report", "Time Table", "Edit"]}
                isHeader={true}
            />
            {
                employees.map((value, index) => {
                    return (
                        <TableRow
                            key={index}
                            values={value} onChangeStatus={onChangeStatus}
                            onEditClick={onEditClick} isSelected={selectedRowIndex === index}
                            onRowSelected={() => {
                                if (selectedRowIndex === index) {
                                    onRowSelected(-1)
                                } else {
                                    onRowSelected(index)
                                }
                            }}
                        />
                    )
                })
            }
        </View>
    )
}
