import {Image, SafeAreaView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {DrawerContentScrollView} from "@react-navigation/drawer";
import {colors} from "../colors";
import HttpRequests from "../http_requests/HttpRequests";
import ProfileData from "../misc/ProfileData";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {fontStyles} from "../fontStyles";
import Space from "./Space";


function CustomDrawerContent(props) {
    const {state, descriptors, navigation} = props
    const styles = StyleSheet.create({
        itemContainer: {
            alignItems: "center",
            width: 90,
            flex: 1,
            minHeight: 60,
            flexDirection: "column",
            justifyContent: "space-around"
        },
        itemTitle: {
            fontFamily: "Lato",
            fontWeight: "700",
            fontSize: 8
        },
        scrollViewContentStyle: {flex: 1, flexDirection: 'column', justifyContent: 'space-between'},
        logoutButton: {height: 60, alignItems: "center", justifyContent: "space-around", marginBottom: 40},
    })

    const isEmployee = state.routes.length === 3

    return (
        <DrawerContentScrollView contentContainerStyle={styles.scrollViewContentStyle} {...props}>
            <SafeAreaView style={{marginTop: 20}} forceInset={{top: 'always', horizontal: 'never'}}>
                <Space height={40}/>
                {
                    !isEmployee &&
                    <>
                        <Text style={{
                            alignSelf: "center", ...fontStyles.lato700_12,
                            color: colors.navyBlue
                        }}>Registering</Text>
                        <View style={{height: 3, width: '100%', backgroundColor: colors.navyBlue, marginTop: 2}}/>
                        <Space height={8}/>
                    </>
                }
                {
                    state.routes.map((route, index) => {
                        const isFocused = index === state.index
                        const {options} = descriptors[route.key]
                        const onPressed = () => {
                            const event = navigation.emit({
                                type: 'tabPressed',
                                target: route.key
                            })
                            if (!isFocused && !event.defaultPrevented) {
                                navigation.navigate(route.name)
                            }
                        }

                        const containerBackgroundColor = isFocused ? colors.navyBlue : "transparent"
                        const textColor = isFocused ? "white" : colors.navyBlue
                        const drawerItem = options.item

                        return (
                            <>
                                <TouchableOpacity
                                    key={index}
                                    onPress={onPressed}
                                    accessibilityRole="button"
                                >
                                    <View style={[styles.itemContainer, {backgroundColor: containerBackgroundColor}]}>
                                        {(drawerItem.selectedSrc && drawerItem.deselectedSrc) ?
                                            <Image style={{width: 35, height: 35}}
                                                   source={isFocused ? drawerItem.selectedSrc : drawerItem.deselectedSrc}/> :
                                            null}
                                        <Text style={[styles.itemTitle, {color: textColor}]}>{drawerItem.label}</Text>
                                    </View>
                                </TouchableOpacity>
                                {
                                    !isEmployee && index === 3 &&
                                    <>
                                        <Space height={30}/>
                                        <Text style={{
                                            alignSelf: "center", ...fontStyles.lato700_12,
                                            color: colors.navyBlue
                                        }}>Reporting</Text>
                                        <View
                                            style={{
                                                height: 3,
                                                width: '100%',
                                                backgroundColor: colors.navyBlue,
                                                marginTop: 2
                                            }}/>
                                        <Space height={8}/>
                                    </>
                                }
                            </>
                        )
                    })
                }
            </SafeAreaView>
            <TouchableOpacity style={styles.logoutButton} onPress={() => {
                HttpRequests.logout(ProfileData.refreshToken).then((result) => {
                    if (result.success) {
                        navigation.navigate("LoginScreen")
                        ProfileData.reset()
                        AsyncStorage.clear()
                    }
                })
            }}>
                <View style={styles.item}>
                    <Image style={{width: 35, height: 35}} source={require("./../assets/icons/logout.png")}/>
                    <Text style={[styles.itemTitle, {color: colors.navyBlue}]}>Logout</Text>
                </View>
            </TouchableOpacity>

        </DrawerContentScrollView>
    )
}

export default CustomDrawerContent
