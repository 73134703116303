import {format, getDaysInMonth, getDaysInYear, getMonth, getYear} from "date-fns";

export default class Helpers {

    static rangeTypes = {year: "year", month: "month", none: null}

    constructor() {

    }

    static getMinutesOfTime(time) {
        return +time.split(":")[0] * 60 + time.split(":")[1] * 1
    }

    static getTimeOfMinutes(minutes) {
        const hour = Math.floor(minutes / 60).toString().padStart(2, "0")
        const minute = (minutes % 60).toString().padStart(2, "0")
        return `${hour}:${minute}`
    }

    static getDate(dateStr) {
        return new Date(Date.parse(dateStr))
    }

    static formatDate(date) {
        return format(date, "yyyy-MM-dd")
    }

    static getRangeType(startDate, endDate) {
        const startYear = getYear(startDate)
        const startMonth = getMonth(startDate)
        const endYear = getYear(endDate)
        const endMonth = getMonth(endDate)
        const daysDiff = (endDate - startDate) / 1000 / 3600 / 24
        let rangeType = Helpers.rangeTypes.none
        if (startYear === endYear && startMonth === endMonth && daysDiff === getDaysInMonth(startDate) - 1) {
            rangeType = Helpers.rangeTypes.month
        } else if (startYear === endYear && daysDiff === getDaysInYear(startYear) - 1) {
            rangeType = Helpers.rangeTypes.year
        }
        return rangeType
    }

    static getMonthName(monthIndex) {
        switch (monthIndex) {
            case 1:
                return "Jan"
            case 2:
                return "Feb"
            case 3:
                return "Mar"
            case 4:
                return "Apr"
            case 5:
                return "May"
            case 6:
                return "Jun"
            case 7:
                return "Jul"
            case 8:
                return "Aug"
            case 9:
                return "Sep"
            case 10:
                return "Oct"
            case 11:
                return "Nov"
            case 12:
                return "Dec"
        }
        return ""
    }

    static getDayName(dayIndex) {
        switch (dayIndex) {
            case 1:
                return "Sunday"
            case 2:
                return "Monday"
            case 3:
                return "Tuesday"
            case 4:
                return "Wednesday"
            case 5:
                return "Thursday"
            case 6:
                return "Friday"
            case 7:
                return "Saturday"
        }
        return ""
    }

    static getMonthIndex(monthName) {
        switch (monthName.toLowerCase()) {
            case "jan":
                return 1
            case "feb":
                return 2
            case "mar":
                return 3
            case "apr":
                return 4
            case "may":
                return 5
            case "jun":
                return 6
            case "jul":
                return 7
            case "aug":
                return 8
            case "sep":
                return 9
            case "oct":
                return 10
            case "nov":
                return 11
            case "dec":
                return 12
        }
        return -1
    }

    static formatNumberWithSpace(num) {
        if (num === "") {
            return num
        } else {
            const noSpaceText = `${num}`.replaceAll(" ", "")
            const number = +noSpaceText
            if (!isNaN(number)) {
                const nf = Intl.NumberFormat()
                return nf.format(number).replaceAll(",", " ")
            }
        }
        return null
    }

    static formatNumberInMK(num) {
        if (num >= 1000000) {
            return `${Math.round(num / 10000) / 100}M`
        } else if (num > 1000) {
            return `${Math.round(num / 10) / 100}K`
        } else {
            return `${num}`
        }
    }
}
