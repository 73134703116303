import {useEffect, useRef, useState} from "react";
import {Text} from "react-native";

function Timer({totalTime, onTimeUp}) {
    let styles = {
        timer: {
            fontFamily: "Lato",
            fontSize: 50,
            fontWeight: "300",
            alignSelf: "center",
            marginTop: 22,
        },
    }


    const remainingTimeRef = useRef(totalTime)
    const [remainingTime, setRemainingTime] = useState(remainingTimeRef.current)
    useEffect(() => {
        remainingTimeRef.current = remainingTime
        console.log(remainingTimeRef)
    }, [remainingTime]);
    setTimeout(() => {
        if (remainingTime > 0) {
            setRemainingTime(remainingTime - 1)
        } else {
            if (onTimeUp) onTimeUp()
        }
    }, 1000)

    return <Text
        style={styles.timer}
        onLayout={() => {
        }}>{Math.floor(remainingTime / 60).toString().padStart(2, '0') + ":" + (remainingTime % 60).toString().padStart(2, '0')}</Text>
}

export default Timer
