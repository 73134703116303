import {Text, TextProps, TextStyle, View, ViewStyle} from "react-native";
import PropTypes from "prop-types";
import {DividerLineHorizontal} from "./DividerLine";
import Space from "./Space";
import Helpers from "../misc/Helpers";

type ColumnType = { type: "flex" | "fixed" }

type TableProps = {
    // table props
    columnType: "flex" | "fixed",
    values: Array<Array<any>>,
    columnWidthValues: Array<number>,
    containerStyle: ViewStyle,

    // header props
    headers: Array<any>,
    headerColor: string,
    headerHeight: number,
    headerTextStyle: TextStyle,

    // row props
    rowHeight: number,
    rowTextStyle: TextStyle,
    rowColors: Array<string>,

    //divider
    dividerColor: string
}

export default function NormalTable(
    {
        columnType,
        values,
        columnWidthValues,
        containerStyle,
        headers,
        headerColor,
        headerHeight,
        headerTextStyle,
        rowHeight,
        rowTextStyle,
        rowColors,
        dividerColor
    }: TableProps) {
    const isFlex = columnType === "flex";

    return (
        <View style={containerStyle}>
            {/*header*/}
            <View style={{
                flex: 1,
                flexDirection: "row",
                maxHeight: headerHeight,
                minHeight: headerHeight,
                backgroundColor: headerColor
            }}>
                {
                    headers.map((headerValue, index) => {
                        return (
                            <View style={{
                                width: isFlex ? undefined : columnWidthValues[index],
                                height: isFlex ? undefined : headerHeight,
                                flex: isFlex ? columnWidthValues[index] : undefined,
                                minHeight: isFlex ? headerHeight : undefined
                            }}>
                                <Text
                                    style={{...headerTextStyle, lineHeight: headerHeight, alignSelf: "center"}}>{headerValue}</Text>
                            </View>
                        )
                    })
                }
            </View>

            {/*rows*/}
            {
                values.map((value, index) => {
                    if (value.some(v => v !== null && v !== undefined)) {
                        return (
                            <>
                                <View style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    maxHeight: rowHeight,
                                    minHeight: rowHeight,
                                    backgroundColor: rowColors[index % rowColors.length]
                                }}>
                                    {
                                        value.map((item, index) => {
                                            return (
                                                <View style={{
                                                    width: isFlex ? undefined : columnWidthValues[index],
                                                    height: isFlex ? undefined : rowHeight,
                                                    flex: isFlex ? columnWidthValues[index] : undefined,
                                                    minHeight: isFlex ? rowHeight : undefined
                                                }}>
                                                    <Text
                                                        style={{...rowTextStyle, lineHeight: rowHeight, alignSelf: "center"}}>
                                                        {isNaN(+item) ? item : Helpers.formatNumberWithSpace(item)}
                                                    </Text>
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                                {
                                    dividerColor && <DividerLineHorizontal color={dividerColor}/>
                                }
                            </>
                        )
                    } else {
                        return (
                            <>
                                <View style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    maxHeight: rowHeight,
                                    minHeight: rowHeight,
                                    backgroundColor: rowColors[index % rowColors.length]
                                }}>
                                    <Space flex={1}/>
                                    <Text style={{...rowTextStyle, lineHeight: rowHeight, alignSelf: "center"}}>
                                        Not submitted by employee
                                    </Text>
                                    <Space flex={1}/>
                                </View>
                                {
                                    dividerColor && <DividerLineHorizontal color={dividerColor}/>
                                }
                            </>
                        )
                    }
                })
            }
        </View>
    )
}
