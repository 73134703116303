import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import ScreenHeader from "../../ScreenHeader";
import TitleView from "../../employee_screens/components/TitleView";
import CustomButton from "../../employee_screens/components/CustomButton";
import {colors} from "../../../colors";
import Footer from "../../Footer";
import {fontStyles} from "../../../fontStyles";
import StyledDropDown from "../../StyledDropDown";
import {addDays, format, getYear, isMonday, nextMonday} from "date-fns";
import {useEffect, useState} from "react";
import Helpers from "../../../misc/Helpers";
import Space from "../../Space";
import LoadingMessage from "../../DefaultMessages";
import HttpRequests from "../../../http_requests/HttpRequests";
import AwesomeAlert from "react-native-awesome-alerts";
import SwedishHolidays from "../../../misc/swedish-holidays";
import FileSaver, { saveAs } from 'file-saver';

export default function NewTimingReport({navigation}) {
    const years = Array.from({length: 10}, (x, i) => {
        return {label: getYear(new Date()) + i}
    })
    const [selectedYear, setSelectedYear] = useState(years[0].label)

    const weeks = Array.from({length: 53}, (v, k) => {
        return {label: `Week ${k + 1}`}
    })
    const [selectedWeek, setSelectedWeek] = useState(weeks[0].label)

    const [tableValues, setTableValues] =
        useState<[{
            name: string,
            startTime: string,
            endTime: string,
            status: string,
            position: string,
            date: Date
        }]>(null)
    useEffect(() => {
        console.log(tableValues)
    }, [tableValues]);

    useEffect(() => {
        updateWeekEmployees(selectedYear, selectedWeek)
    }, []);

    const [showLoadingMessage, setShowLoadingMessage] = useState(true)

    const [showDayDetails, setShowDayDetails] = useState(false)
    const [selectedPostDate, setSelectedPostDate] = useState<Date>()
    const [selectedPostInfo, setSelectedPostInfo] =
        useState<{
            name: string,
            startTime: string,
            endTime: string,
            status: string,
            position: string,
            date: Date
        }[]>(null)
    useEffect(() => {
        console.log(selectedPostInfo)
    }, [selectedPostInfo]);
    const [workingTimeInfo, setWorkingTimeInfo] =
        useState<{ startTime: string, endTime: string }>(null)

    const [weekDays, setWeekDays] = useState<Date[]>(null)

    // functions
    function updateWeekEmployees(selectedYear: number, selectedWeek: string) {
        const firstDayOfYear = new Date(selectedYear, 0, 1);
        let firstMondayOfYear = isMonday(firstDayOfYear) ? firstDayOfYear : nextMonday(firstDayOfYear)
        if (!isMonday(firstDayOfYear)) firstMondayOfYear = addDays(firstMondayOfYear, -7)

        const weekNumber = Number(selectedWeek.replace("Week ", ""));
        const weekStartDate = addDays(firstMondayOfYear, (weekNumber - 1) * 7)
        const weekEndDate = addDays(firstMondayOfYear, (weekNumber) * 7 - 1)

        setWeekDays(Array.from({length: 7}, (v, k) => {
            return addDays(weekStartDate, k)
        }))

        setShowLoadingMessage(true)
        HttpRequests.getTimeTableDataReport(weekStartDate, weekEndDate).then(response => {
            setShowLoadingMessage(false)
            console.log(response)
            if (response.success) {
                setTableValues(response.extras.map((value) => {
                    return {
                        name: value.name,
                        startTime: value.startTime,
                        endTime: value.endTime,
                        status: value.workStatusTitle,
                        date: Helpers.getDate(value.date),
                        position: value.position
                    }
                }))
                setWorkingTimeInfo({startTime: response.startTime, endTime: response.endTime})
            } else {
                setTableValues(null)
            }
        })
    }

    // functional components
    function WeekDayView({date, startTime, endTime, dayInfo}) {
        const color =
            SwedishHolidays.isHoliday(date) ? colors.lighterOrange :
                findEmptyRanges(startTime, endTime, dayInfo).length > 0 ? colors.normalOrange :
                    "white"
        return (
            <View style={{
                flex: 1,
                borderRadius: 40,
                borderWidth: 1,
                borderColor: colors.navyBlue,
                backgroundColor: color,
                maxHeight: 40,
                minHeight: 40,
            }}>
                <Text style={{
                    ...fontStyles.lato700_14,
                    lineHeight: 40,
                    alignSelf: "center",
                    color: colors.navyBlue,
                }}>{format(date, "EEE - MMM dd yyyy")}</Text>
            </View>
        )
    }

    function EmployeeRow({employeeName, start, end}) {
        return (
            <View style={{
                flex: 1,
                maxHeight: 50,
                minHeight: 50,
                borderStyle: "dotted",
                borderBottomWidth: 1,
                borderBottomColor: colors.navyBlue
            }}>
                <Text style={fontStyles.lato700_14}>{employeeName}</Text>
                <Text style={fontStyles.lato700_14}>{`${start} - ${end}`}</Text>
            </View>
        )
    }

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation} children={undefined}/>
            <TitleView titleText={"Employees Timing Report"} style={undefined}/>
            {/*filters*/}
            <View style={{flex: 1, flexDirection: "row", maxHeight: 40}}>
                <View style={{flex: 1, flexDirection: "row"}}>
                    <Text style={{...fontStyles.lato700_14, lineHeight: 40}}>Choose Year</Text>
                    <StyledDropDown
                        style={{maxWidth: 180}} items={years} placeHolder={"Year"} value={selectedYear}
                        onChange={(value) => {
                            setSelectedYear(value.label)
                            updateWeekEmployees(value.label, selectedWeek)
                        }}/>
                    <StyledDropDown
                        style={{maxWidth: 180}} items={weeks} placeHolder={"Week"} value={selectedWeek}
                        onChange={(value) => {
                            setSelectedWeek(value.label)
                            updateWeekEmployees(selectedYear, value.label)
                        }}/>
                </View>
                <View style={{
                    flexDirection: "row",
                    width: 300,
                    height: 40,
                    backgroundColor: colors.lightYellow,
                    borderRadius: 40,
                    paddingHorizontal: 20
                }}>
                    <CustomButton
                        flex={1} text={"<"} style={{maxWidth: 20}} fontSize={40} color={"transparent"}
                        disabledColor={"transparent"}
                        onPress={
                            weeks.map(value => value.label).indexOf(selectedWeek) === 0 ? null : () => {
                                const weekIndex = weeks.map(value => value.label).indexOf(selectedWeek)
                                setSelectedWeek(weeks[weekIndex - 1].label)
                                updateWeekEmployees(selectedYear, weeks[weekIndex - 1].label)
                            }
                        }
                    />
                    <Space flex={1}/>
                    <Text style={{
                        ...fontStyles.lato700_20,
                        lineHeight: 40,
                        color: colors.navyBlue
                    }}>{selectedWeek}</Text>
                    <Space flex={1}/>
                    <CustomButton
                        flex={1} text={">"} style={{maxWidth: 20}} fontSize={40} color={"transparent"}
                        disabledColor={"transparent"}
                        onPress={
                            weeks.map(value => value.label).indexOf(selectedWeek) === weeks.length - 1 ? null : () => {
                                const weekIndex = weeks.map(value => value.label).indexOf(selectedWeek)
                                setSelectedWeek(weeks[weekIndex + 1].label)
                                updateWeekEmployees(selectedYear, weeks[weekIndex + 1].label)
                            }
                        }
                    />
                </View>
                <Space flex={1}/>
            </View>
            <Space height={30}/>

            {/*result table*/}
            <ScrollView style={{flex: 1}}>
                <View style={{flex: 1, flexDirection: "row", maxHeight: 40, gap: 10}}>
                    {
                        weekDays && weekDays.map((day, index) => {
                            return (
                                <View key={index} style={{flex: 1, gap: 10}}>
                                    <TouchableOpacity
                                        onPress={event => {
                                            setSelectedPostDate(day)
                                            if (tableValues !== null) {
                                                console.log(tableValues.filter(element => {
                                                    return Helpers.formatDate(element.date) === Helpers.formatDate(day)
                                                }))
                                                setSelectedPostInfo(tableValues.filter(element => {
                                                    return Helpers.formatDate(element.date) === Helpers.formatDate(day)
                                                }))
                                            } else {
                                                setSelectedPostInfo([])
                                            }
                                            setShowDayDetails(true)
                                        }}
                                    >
                                        <WeekDayView
                                            date={day}
                                            startTime={workingTimeInfo === null ? "" : workingTimeInfo.startTime}
                                            endTime={workingTimeInfo === null ? "" : workingTimeInfo.endTime}
                                            dayInfo={
                                                tableValues !== null ?
                                                    tableValues.filter(element => {
                                                        return Helpers.formatDate(element.date) === Helpers.formatDate(day)
                                                    }) :
                                                    []
                                            }/>
                                    </TouchableOpacity>
                                    {
                                        tableValues && tableValues.filter(element => {
                                            return Helpers.formatDate(element.date) === Helpers.formatDate(day)
                                        }).map(item => {
                                            return <EmployeeRow key={index} employeeName={item.name} start={item.startTime}
                                                                end={item.endTime}/>
                                        })
                                    }
                                </View>
                            )
                        })
                    }
                </View>
            </ScrollView>
            <Space height={20}/>

            {/*export button*/}
            <View style={{flex: 1, maxHeight: 40, flexDirection: "row-reverse",}}>
                <CustomButton
                    flex={1} text={"Export PDF"} style={{maxWidth: 220}} fontSize={16} color={colors.lightYellow}
                    onPress={() => {
                        const firstDayOfYear = new Date(selectedYear, 0, 1);
                        let firstMondayOfYear = isMonday(firstDayOfYear) ? firstDayOfYear : nextMonday(firstDayOfYear)
                        if (!isMonday(firstDayOfYear)) firstMondayOfYear = addDays(firstMondayOfYear, -7)

                        const weekNumber = Number(selectedWeek.replace("Week ", ""));
                        const weekStartDate = addDays(firstMondayOfYear, (weekNumber - 1) * 7)
                        const weekEndDate = addDays(firstMondayOfYear, (weekNumber) * 7 - 1)
                        const weekStartDateStr = Helpers.formatDate(weekStartDate)
                        const weekEndDateStr = Helpers.formatDate(weekEndDate)

                        HttpRequests.getTimingReportPdf(weekStartDateStr, weekEndDateStr, weekNumber, selectedYear).then(value => {
                            console.log(value)
                        })
                    }}/>
            </View>
            <Footer/>
            <LoadingMessage showLoadingMessage={showLoadingMessage}/>
            {showDayDetails && <AwesomeAlert
                contentContainerStyle={{
                    borderRadius: 20,
                }}
                closeOnTouchOutside={false}
                show={showDayDetails}
                customView={
                    <DayDetails
                        date={selectedPostDate} startTime={workingTimeInfo?.startTime}
                        endTime={workingTimeInfo?.endTime}
                        postingInfo={selectedPostInfo}
                        onCloseClick={() => {
                            setShowDayDetails(false);
                        }}
                    />
                }
            />}
        </View>
    )
}

function findEmptyRanges(
    startTime: string,
    endTime: string,
    postingInfo: { startTime: string, endTime: string, name: string, date: Date }[]
) {
    let emptyRanges: { start: number, end: number }[] = [{
        start: Helpers.getMinutesOfTime(startTime),
        end: Helpers.getMinutesOfTime(endTime)
    }]
    for (let postingInfoIndex = 0; postingInfoIndex < postingInfo.length; postingInfoIndex++) {
        const postingInfoItem = postingInfo[postingInfoIndex];
        const filledRangeStart = Helpers.getMinutesOfTime(postingInfoItem["startTime"])
        const filledRangeEnd = Helpers.getMinutesOfTime(postingInfoItem["endTime"])
        // different cases are:
        // 1. the posting info item has a range that contains the empty range:
        //      empty range should be removed
        // 2. the posting info starts inside an empty range but ends outside of it:
        //      empty range should finish at the start of posting info range
        // 3. the posting info starts outside an empty range but ends inside of it:
        //      empty range should start at the end of posting info range
        // 4. the posting info starts and ends inside an empty range:
        //      empty range should be sliced into 2 ranges, one of them from empty range start to
        //      posting info start and the other from posting info end to empty range end
        for (let i = 0; i < emptyRanges.length; i++) {
            const emptyRangeStart = emptyRanges[i].start
            const emptyRangeEnd = emptyRanges[i].end
            if (filledRangeStart <= emptyRangeStart && filledRangeEnd >= emptyRangeEnd) {
                emptyRanges.splice(i, 1)
                i--
            } else if (filledRangeStart >= emptyRangeStart && filledRangeStart < emptyRangeEnd) {
                if (filledRangeEnd >= emptyRangeEnd) {
                    emptyRanges[i] = {start: emptyRangeStart, end: filledRangeStart}
                } else {
                    emptyRanges[i] = {start: emptyRangeStart, end: filledRangeStart}
                    emptyRanges = [...emptyRanges, {start: filledRangeEnd, end: emptyRangeEnd}]
                }
            } else if (filledRangeEnd > emptyRangeStart && filledRangeEnd < emptyRangeEnd) {
                if (filledRangeStart <= emptyRangeStart) {
                    emptyRanges[i] = {start: filledRangeEnd, end: emptyRangeEnd}
                } else {
                    // already handled
                }
            }
        }
    }
    return emptyRanges;
}

function DayDetails({date, startTime, endTime, postingInfo, onCloseClick}) {
    const [noteText, setNoteText] =
        useState("")

    useEffect(() => {
        updateNote(startTime, endTime)
    }, []);

    // functions
    function updateNote(startTime: string, endTime: string) {
        if (startTime && endTime) {
            let emptyRanges = findEmptyRanges(startTime, endTime, postingInfo);
            const times = emptyRanges.sort((a, b) => a.start - b.start).map(value => {
                const start = Helpers.getTimeOfMinutes(value.start)
                const end = Helpers.getTimeOfMinutes(value.end)
                return `${start} to ${end}`
            }).join(" - ")
            setNoteText(times)
        } else {
            setNoteText("Could not find start time and end time info.")
        }
    }

    return (
        <View style={{width: 530}}>
            {/*close button*/}
            <TouchableOpacity
                onPress={_ => {
                    onCloseClick()
                }}
            >
                <Image style={{width: 50, height: 50, alignSelf: "center", marginTop: -100}}
                       source={require("../../../assets/icons/exit.png")}/>
            </TouchableOpacity>
            {/*date*/}
            <Text
                style={{...fontStyles.lato700_20, alignSelf: "center"}}>{format(date, "dd LLL yyyy - EEEE")}</Text>
            <Space height={10}/>
            {/*note*/}
            {
                noteText.length !== 0 &&
                <View style={{
                    width: 480,
                    paddingVertical: 15,
                    backgroundColor: colors.lightGray,
                    borderRadius: 20,
                    alignItems: "center",
                    alignSelf: "center"
                }}>
                    <Text style={fontStyles.lato700_14}>Attention!</Text>
                    <Text style={fontStyles.lato400_14}>You have not set any staff for working times:</Text>
                    <Text style={fontStyles.lato400_14}>{noteText}</Text>
                </View>
            }
            {
                noteText.length !== 0 && <Space height={10}/>
            }


            {/*employee list*/}
            <View style={{gap: 10}}>
                {
                    // { startTime: string, endTime: string, name: string, date: Date, status: string, position: string }
                    postingInfo.sort(
                        (a: { startTime: string }, b: { startTime: string }) => {
                            return Helpers.getMinutesOfTime(a["startTime"]) - Helpers.getMinutesOfTime(b["startTime"])
                        }
                    ).map((info: {
                        startTime: string,
                        endTime: string,
                        name: string,
                        status: string,
                        position: string,
                        date: Date
                    }, index: number) => {
                        return (
                            <View key={index} style={{flexDirection: "row", height: 30}}>
                                <Text style={{
                                    ...fontStyles.lato700_14,
                                    lineHeight: 30
                                }}>{`${index + 1} - ${info.name} - ${info.position}`}</Text>
                                <Space flex={1}/>
                                <View
                                    style={{
                                        width: 100,
                                        height: 30,
                                        backgroundColor: colors.lightGreen,
                                        borderRadius: 20
                                    }}>
                                    <Text style={{
                                        ...fontStyles.lato700_14,
                                        lineHeight: 30,
                                        alignSelf: "center"
                                    }}>{info.status}</Text>
                                </View>
                                <Space width={10}/>
                                <Text style={{
                                    ...fontStyles.lato700_14,
                                    lineHeight: 30
                                }}>{`${info.startTime} to ${info.endTime}`}</Text>
                            </View>
                        )
                    })
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
    fillingRow: {
        flex: 1, flexDirection: "row"
    },
    fillingColumn: {
        flex: 1, flexDirection: "column"
    },
    centerMainAxis: {
        alignItems: "center"
    },
    centerCrossAxis: {
        justifyContent: "center"
    },
    center: {
        justifyContent: "center", alignItems: "center"
    }
})
