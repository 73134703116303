import {StyleSheet} from "react-native";

export const fontStyles = StyleSheet.create({
    lato700_40: {
        fontFamily: "LatoRegular",
        fontSize: 40,
        fontWeight: "700",
    },
    lato700_30: {
        fontFamily: "LatoRegular",
        fontSize: 30,
        fontWeight: "700",
    },
    lato700_24: {
        fontFamily: "LatoRegular",
        fontSize: 24,
        fontWeight: "700",
    },
    lato700_22: {
        fontFamily: "LatoRegular",
        fontSize: 22,
        fontWeight: "700",
    },
    lato700_20: {
        fontFamily: "LatoRegular",
        fontSize: 20,
        fontWeight: "700",
    },
    lato700_18: {
        fontFamily: "LatoRegular",
        fontWeight: "700",
        fontSize: 18,
    },
    lato700_16: {
        fontFamily: "LatoRegular",
        fontSize: 16,
        fontWeight: "700",
    },
    lato700_15: {
        fontFamily: "LatoRegular",
        fontSize: 15,
        fontWeight: "700",
    },
    lato700_14: {
        fontFamily: "LatoRegular",
        fontSize: 14,
        fontWeight: "700",
    },
    lato700_12: {
        fontFamily: "LatoRegular",
        fontSize: 12,
        fontWeight: "700",
    },
    lato700_11: {
        fontFamily: "LatoRegular",
        fontSize: 11,
        fontWeight: "700",
    },
    lato700_10: {
        fontFamily: "LatoRegular",
        fontSize: 10,
        fontWeight: "700",
    },

    lato400_20: {
        fontFamily: "LatoRegular",
        fontSize: 20,
        fontWeight: "400",
    },
    lato400_18: {
        fontFamily: "LatoRegular",
        fontSize: 18,
        fontWeight: "400",
    },
    lato400_16: {
        fontFamily: "LatoRegular",
        fontSize: 16,
        fontWeight: "400",
    },
    lato400_14: {
        fontFamily: "LatoRegular",
        fontSize: 14,
        fontWeight: "400",
    },
    lato400_12: {
        fontFamily: "LatoRegular",
        fontWeight: "400",
        fontSize: 12
    },
    lato400_11: {
        fontFamily: "LatoRegular",
        fontSize: 11,
        fontWeight: "400",
    },

})
