import HttpRequests from "../http_requests/HttpRequests";

export default class ProfileData {
    static AccountTypes = {
        Admin: 0,
        Staff: 1,
        Unknown: 2
    }

    static SalaryTypes = {
        Monthly: 0,
        Hourly: 1
    }

    static Genders = {
        Male: 0,
        Female: 1,
        Other: 2
    }

    static ContractStatus = {
        Current: 0,
        Past: 1
    }

    static email = ""
    static password = ""

    static accessToken = ""
    static refreshToken = ""
    static userId = ""
    static accountType = ProfileData.AccountTypes.Unknown
    static name = ""
    static position = ""
    static contractDate = ""
    static salary = 0
    static salaryType = ProfileData.SalaryTypes.Monthly
    static contractFile = ""
    static jobDesc = ""
    static socialNumber = ""
    static cleaningNumber = ""
    static age = ""
    static contactNumber = ""
    static gender = ProfileData.Genders.Male
    static familyMemberName = ""
    static familyMemberContact = ""
    static contractStatus = ProfileData.ContractStatus.Current
    static monthlyHours = 0
    static accountNum = ""

    static setData(
        {
            accessToken = null,
            refreshToken = null,
            userId = null,
            accountType = this.AccountTypes.Unknown,
            name = null,
            position = null,
            contractDate = null,
            salary = null,
            salaryType = null,
            contractFile = null,
            jobDescription = null,
            socialNumber = null,
            cleaningNumber = null,
            age = null,
            contactNumber = null,
            gender = null,
            familyMemberName = null,
            familyMemberContact = null,
            email = null,
            password = null,
            monthlyHours = null,
            accountNum = null
        }
    ) {
        if (accessToken != null) this.accessToken = accessToken
        if (refreshToken != null) this.refreshToken = refreshToken
        if (userId != null) this.userId = userId
        if (accountType !== this.AccountTypes.Unknown) this.accountType = accountType
        if (name != null) this.name = name
        if (position != null) this.position = position
        if (contractDate != null) this.contractDate = contractDate
        if (salary != null) this.salary = salary
        if (salaryType != null) this.salaryType = salaryType
        if (contractFile != null) this.contractFile = contractFile
        if (jobDescription != null) this.jobDesc = jobDescription
        if (socialNumber != null) this.socialNumber = socialNumber
        if (cleaningNumber != null) this.cleaningNumber = cleaningNumber
        if (age != null) this.age = age
        if (contactNumber != null) this.contactNumber = contactNumber
        if (gender != null) this.gender = gender
        if (familyMemberName != null) this.familyMemberName = familyMemberName
        if (familyMemberContact != null) this.familyMemberContact = familyMemberContact
        if (email != null) this.email = email
        if (password != null) this.password = password
        if (monthlyHours != null) this.monthlyHours = monthlyHours
        if (accountNum != null) this.accountNum = accountNum
    }

    static reset() {
        this.accessToken = ""
        this.refreshToken = ""
        this.userId = ""
        this.accountType = ProfileData.AccountTypes.Unknown
        this.name = ""
        this.position = ""
        this.contractDate = ""
        this.salary = 0
        this.salaryType = ProfileData.SalaryTypes.Monthly
        this.contractFile = ""
        this.jobDesc = ""
        this.socialNumber = ""
        this.cleaningNumber = ""
        this.age = ""
        this.contactNumber = ""
        this.gender = ProfileData.Genders.Male
        this.familyMemberName = ""
        this.familyMemberContact = ""
        this.contractStatus = ProfileData.ContractStatus.Current
        this.email = ""
        this.password = ""
        this.accountNum = ""
    }
}
