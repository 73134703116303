import {Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colors} from "../../colors";
import Space from "../Space";
import Table from "../Table";
import Footer from "../Footer";
import ScreenHeader from "../ScreenHeader";
import HttpRequests from "../../http_requests/HttpRequests";
import {useEffect, useRef, useState} from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import DefaultValues from "../../misc/DefaultValues";
import {fontStyles} from "../../fontStyles";
import StyledEditText from "../employee_screens/components/StyledEditText";
import StyledDropDown from "../StyledDropDown";
import {getYear} from "date-fns";
import CustomButton from "../employee_screens/components/CustomButton";
import YearlyHours from "../../misc/YearlyHours";
import Helpers from "../../misc/Helpers";
import TitleView from "../employee_screens/components/TitleView";

function DefaultValuesTab({navigation}) {

    const [showLoadingMessage, setShowLoadingMessage] = useState(false)
    const [defaultValues, setDefaultValues] = useState(Array(9).fill(0))
    const [newDefaultValues, setNewDefaultValues] = useState([...defaultValues])
    const [monthValues, setMonthValues] = useState(Array(12).fill(0))
    const newMonthValues = useRef([...monthValues]);

    // const [newMonthValues, setNewMonthValues] = useState([...monthValues])

    function updateDefaultValues() {
        setDefaultValues([
            DefaultValues.start_working_time,
            DefaultValues.tax1,
            DefaultValues.ov1,
            DefaultValues.end_working_time,
            DefaultValues.tax2,
            DefaultValues.ov2,
            DefaultValues.holiday,
            DefaultValues.tax3,
            DefaultValues.ov3,
        ])
        setNewDefaultValues([
            DefaultValues.start_working_time,
            DefaultValues.tax1,
            DefaultValues.ov1,
            DefaultValues.end_working_time,
            DefaultValues.tax2,
            DefaultValues.ov2,
            DefaultValues.holiday,
            DefaultValues.tax3,
            DefaultValues.ov3,
        ])
    }

    function updateMonthValues(year) {
        let updatedMonthValues = []
        console.log(year)
        if (YearlyHours.getMonthlyValues(year) != null) {
            console.log(YearlyHours.getMonthlyValues(year))
            for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
                const monthName = Helpers.getMonthName(monthIndex + 1).toLowerCase()
                const monthValue = YearlyHours.getMonthlyValues(year)[monthName]
                console.log(`${monthName}: ${monthValue}`)
                updatedMonthValues = [...updatedMonthValues, monthValue]
            }
        } else {
            updatedMonthValues = Array(12).fill(0)
        }
        setMonthValues(updatedMonthValues)
        newMonthValues.current = [...updatedMonthValues]
    }

    useEffect(() => {
        // todo: update monthly values
        if (DefaultValues.updated) {
            updateDefaultValues()
        } else {
            setShowLoadingMessage(true)
            HttpRequests.getDefaultValues().then((defaultsResult) => {
                if (defaultsResult.success || defaultsResult.message.includes("No Data Available")) {
                    HttpRequests.getMonthlyHours().then((monthlyHoursResult) => {
                        setShowLoadingMessage(false)
                        console.log(defaultsResult)
                        console.log(monthlyHoursResult)
                        if (monthlyHoursResult.success || monthlyHoursResult.message.includes("No Data Available")) {
                            // update default values
                            const defaultsExtras = defaultsResult.extras
                            if (defaultsExtras != null) {
                                DefaultValues.updateValues(defaultsExtras)
                                updateDefaultValues()
                            }

                            // update monthly hours
                            const monthlyExtras = monthlyHoursResult.extras;
                            if (monthlyExtras != null) {
                                let years = []
                                for (let i = 0; i < monthlyExtras.length; i++) {
                                    const year = monthlyExtras[i].year;
                                    if (!years.includes(year)) {
                                        years.push(year)
                                    }
                                }

                                for (let i = 0; i < years.length; i++) {
                                    const year = years[i]
                                    console.log(year)
                                    const yearItems = monthlyExtras.filter(value => {
                                        return value.year === year
                                    })
                                    const monthItems = yearItems.map(value => {
                                        const monthName = Helpers.getMonthName(value.month).toLowerCase()
                                        const workingHours = value.workingHours
                                        const result = {}
                                        result[monthName] = workingHours
                                        return result
                                    }).reduce((previousValue, currentValue) => {
                                        return {...previousValue, ...currentValue}
                                    })
                                    YearlyHours.updateValues(year, monthItems)
                                }
                            }
                            updateMonthValues(getYear(new Date()))
                        } else {
                            setShowLoadingMessage(false)
                        }
                    })
                } else {
                    setShowLoadingMessage(false)
                }
            })
        }
    }, []);

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation}/>
            <TitleView titleText={"Default Values"}/>
            <TaxCards
                monthlyHours={monthValues}
                cardValues={defaultValues}
                onDefaultValueUpdated={(text, index) => {
                    const n = newDefaultValues
                    console.log(index)
                    if (index !== 0 && index !== 3) {
                        if (!isNaN(+text)) {
                            n[index] = +text
                        } else {
                            n[index] = defaultValues[index]
                        }
                    } else {
                        if (text !== "" && !isNaN(+text)) {
                            n[index] = text + ":00"
                        } else if (text.split(":").length === 2 && !isNaN(+text.replace(":", ""))) {
                            const hour = text.split(":")[0]
                            const minute = text.split(":")[1]
                            const hourOk = hour.length > 0 && !isNaN(+hour) && +hour < 24
                            const minuteOk = !isNaN(+minute) && +minute < 60
                            if (hourOk && minuteOk) {
                                n[index] = `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
                            } else {
                                n[index] = defaultValues[index]
                            }
                        } else {
                            n[index] = defaultValues[index]
                        }
                    }
                    console.log(n)
                    setNewDefaultValues(n)
                }}
                onMonthlyHourUpdated={(text, index) => {
                    if (!isNaN(+text)) {
                        newMonthValues.current[index] = +text
                    } else {
                        newMonthValues.current[index] = monthValues[index]
                    }
                    console.log(newMonthValues.current)
                }}
                onSubmitDefaults={() => {
                    if (!defaultValues.every((value, index) => {
                        return value === newDefaultValues[index]
                    })) {
                        console.log(newDefaultValues)
                        setShowLoadingMessage(true)
                        HttpRequests.setDefaultValues(newDefaultValues).then((result) => {
                            setShowLoadingMessage(false)
                            if (result.success) {
                                DefaultValues.updateValues({
                                    start_working_time: newDefaultValues[0],
                                    end_working_time: newDefaultValues[1],
                                    holiday: newDefaultValues[2],
                                    tax1: newDefaultValues[3],
                                    tax2: newDefaultValues[4],
                                    tax3: newDefaultValues[5],
                                    ov1: newDefaultValues[6],
                                    ov2: newDefaultValues[7],
                                    ov3: newDefaultValues[8]
                                })
                                setDefaultValues([...newDefaultValues])
                            } else {
                                // handle error
                            }
                        })
                    }
                }}
                onSubmitMonthlyHours={(selectedYear) => {
                    if (!newMonthValues.current.every((value, index) => value === monthValues[index])) {
                        setShowLoadingMessage(true)
                        const dataToSend = newMonthValues.current.map((item, index) => {
                            return {year: selectedYear, month: index + 1, working_hours: item}
                        }).filter(value => value.working_hours !== 0)
                        HttpRequests.updateMonthlyHours(dataToSend).then(result => {
                            setShowLoadingMessage(false)
                            console.log(result)
                            if (result.success) {
                                setMonthValues([...newMonthValues.current])
                            } else {
                                // handle error
                            }
                        })
                    }
                }}
                onSelectedYearChange={(year) => {
                    updateMonthValues(year)
                }}
            />
            <Footer/>
            <AwesomeAlert
                show={showLoadingMessage}
                message={"Loading, please wait..."}
                title={"Loading"}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
            />
        </View>)
}

function TaxCards({
                      cardValues,
                      monthlyHours,
                      onDefaultValueUpdated,
                      onMonthlyHourUpdated,
                      onSubmitDefaults,
                      onSubmitMonthlyHours,
                      onSelectedYearChange
                  }) {
    const workingItems = ["Start Working Time", "End Working Time", "Holiday",]
    const taxItems = ["Tax | 0-65 years", "Tax | 66-84 years", "Tax | Above 85 years"]
    const overTimeItems = ["Overtime 1", "Overtime 2", "Overtime 3",]
    const rows = workingItems.map((value, index) => {
        return [value, taxItems[index], overTimeItems[index]]
    }).flat()
    const spring = ["January", "February", "March",]
    const summer = ["April", "May", "June",]
    const autumn = ["July", "August", "September",]
    const winter = ["October", "November", "December",]
    const months = [...spring, ...summer, ...autumn, ...winter]
    const years = Array(12).fill(getYear((new Date()))).map((value, index) => {
        return {label: value + index}
    })
    const [selectedYear, setSelectedYear] = useState(years[0].label)
    useEffect(() => {
        console.log(selectedYear)
        onSelectedYearChange(selectedYear.label)
    }, [selectedYear]);

    function ValueCard({title = "", currentValue = "", cardIndex, onTextChange}) {
        return (
            <View style={{
                width: 280,
                height: 70,
                marginBottom: 30,
                marginHorizontal: 10,
                justifyContent: "space-between"
            }}>
                <View
                    style={{
                        width: 280,
                        marginRight: 20,
                        height: 30,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <Text style={fontStyles.lato700_16}>{title}</Text>
                    <Space flex={1}/>
                    <View style={{
                        borderRadius: 20,
                        borderWidth: 1,
                        borderColor: colors.navyBlue,
                        width: 115,
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Text style={{...fontStyles.lato700_18, color: colors.navyBlue,}}>{currentValue}</Text>
                    </View>
                </View>
                <StyledEditText
                    style={{height: 30}}
                    placeholder={"Enter new value"}
                    onChangeText={(text) => {
                        onTextChange(text, cardIndex)
                    }}
                />
            </View>)
    }

    const styles = StyleSheet.create({
        cardRow: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        }
    })

    let valueIndex = -1
    return (
        <ScrollView style={{flex: 1}}>
            <View style={{alignItems: "flex-start"}}>
                <Table items={rows} columnsCount={3} itemGeneratorFunction={(item, index) => {
                    return (
                        <ValueCard
                            currentValue={index !== 0 && index !== 3 ? `${cardValues[index]}%` : cardValues[index]}
                            title={item} cardIndex={index}
                            onTextChange={(text, index) => {
                                onDefaultValueUpdated(text, index)
                            }}
                        />
                    )
                }}/>
                {/*{*/}
                {/*    rows.map((row, rowIndex) => {*/}
                {/*        return <View style={styles.cardRow}>*/}
                {/*            {*/}
                {/*                row.map((item, itemIndex) => {*/}
                {/*                    valueIndex++*/}
                {/*                    return ValueCard({*/}
                {/*                        cardIndex: valueIndex,*/}
                {/*                        title: item,*/}
                {/*                        currentValue: valueIndex !== 0 && valueIndex !== 3 ? `${cardValues[valueIndex]}%` : cardValues[valueIndex],*/}
                {/*                        onTextChange: (text, index) => {*/}
                {/*                            onDefaultValueUpdated(text, index)*/}
                {/*                        }*/}
                {/*                    })*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </View>*/}
                {/*    })*/}
                {/*}*/}
                {/*<SubmitDefaultsButton cardValues={cardValues} newValues={newDefaultValues}/>*/}
                <CustomButton style={{width: 280}} text={"Submit"} onPress={onSubmitDefaults}/>
                <Space height={20}/>
                <Text style={fontStyles.lato700_16}>Set number of days for each month of year</Text>
                <Space height={20}/>
                <View style={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                    <StyledDropDown
                        items={years} value={selectedYear} topLabel={true} placeHolder={"Year"} style={{width: 150}}
                        onChange={(year) => {
                            setSelectedYear(year)
                        }}/>
                    <Space width={10}/>
                    <Text style={fontStyles.lato400_14}>Total hours: </Text>
                    <Text
                        style={fontStyles.lato700_18}> {
                        monthlyHours
                            .reduce((previousValue, currentValue) => {
                                return previousValue + currentValue
                            }, 0)
                    }</Text>
                </View>
                <Space height={25}/>
                <Table items={months} columnsCount={4} itemGeneratorFunction={(item, index) => {
                    return (
                        <ValueCard
                            currentValue={monthlyHours[index]} title={item} cardIndex={index}
                            onTextChange={(text, index) => {
                                onMonthlyHourUpdated(text, index)
                            }}
                        />
                    )
                }}/>
                <CustomButton style={{width: 280}} text={"Submit"} onPress={() => {
                    onSubmitMonthlyHours(selectedYear)
                }}/>
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white", alignSelf: "stretch", flex: 1, paddingHorizontal: 20,
    }, headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignContent: "center",
        alignItems: "center",
        maxHeight: 55,
        marginBottom: 30
    },
})

export default DefaultValuesTab
