import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React, {useEffect, useRef, useState} from "react";
import ScreenHeader from "../ScreenHeader";
import TitleView from "../employee_screens/components/TitleView";
import MonthSelectors from "../MonthSelectors";
import DividerLineVertical from "../DividerLine";
import {
    addDays,
    addMonths,
    format,
    getDate,
    getDaysInMonth,
    getMonth,
    getYear, lastDayOfMonth,
    setDate,
    setHours,
    setMinutes, setMonth, setSeconds
} from "date-fns";
import Footer from "../Footer";
import Space from "../Space";
import {colors} from "../../colors";
import Table from "../Table";
import Selector from "../employee_screens/components/Selector";
import {fontStyles} from "../../fontStyles";
import HttpRequests from "../../http_requests/HttpRequests";
import Helpers from "../../misc/Helpers";
import SwedishHolidays from "../../misc/swedish-holidays";

function getMinutesOfTime(time) {
    return Helpers.getMinutesOfTime(time)
}

function getTimeOfMinutes(minutes) {
    return Helpers.getTimeOfMinutes(minutes)
}

export default function TimingReport({navigation}) {
    const today = new Date()
    const [startDate, setStartDate] = useState(new Date(getYear(today), getMonth(today), 1))
    const [endDate, setEndDate] = useState(lastDayOfMonth(today))
    const [selectedDay, setSelectedDay] = useState(new Date(format(today, "yyyy-MM-dd")))
    const [monthInfo, setMonthInfo] = useState([])
    const monthInfoTimes = useRef({});
    const workingStartTime = useRef("");
    const workingEndTime = useRef("");


    useEffect(() => {
        if (startDate < endDate) {
            const start = format(startDate, "yyyy-MM-dd")
            const end = format(endDate, "yyyy-MM-dd")
            HttpRequests.getTimeTableDataReport(start, end).then(result => {
                console.log(result)
                if (result.success) {
                    workingStartTime.current = result.startTime
                    workingEndTime.current = result.endTime
                    monthInfoTimes.current = {}
                    result.extras.forEach(item => {
                        if (!monthInfoTimes.current.hasOwnProperty(item.date)) monthInfoTimes.current[item.date] = []
                        monthInfoTimes.current[item.date] = [...monthInfoTimes.current[item.date], {
                            start: item.startTime,
                            end: item.endTime
                        }]
                        monthInfoTimes.current[item.date] = monthInfoTimes.current[item.date].sort((a, b) => getMinutesOfTime(a.start) - getMinutesOfTime(b.start))
                    })
                    console.log(monthInfoTimes.current)
                    setMonthInfo(result.extras)
                } else {
                    monthInfoTimes.current = {}
                    workingStartTime.current = ""
                    workingEndTime.current = ""
                    setMonthInfo([])
                    // handle error
                }
            })
        } else {
            monthInfoTimes.current = {}
            workingStartTime.current = ""
            workingEndTime.current = ""
            setMonthInfo([])
        }
    }, [startDate, endDate]);

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation}>
                <Text style={fontStyles.lato700_20}>{format(startDate, "MMMM yyyy")}</Text>
            </ScreenHeader>
            <TitleView titleText={"Timing Report"}/>
            <View style={{flex: 1, flexDirection: "row"}}>
                <MonthSelectors startDate={startDate} endDate={endDate} onSelectedDateChanged={(sDate, eDate) => {
                    setStartDate(sDate)
                    setEndDate(eDate)
                    setSelectedDay(setMonth(selectedDay, getMonth(sDate)))
                }}/>
                <DividerLineVertical/>
                <Space width={20}/>
                {/*day selectors*/}
                <DaySelectors
                    monthInfoTime={monthInfoTimes.current}
                    selectedDate={selectedDay}
                    startWorkingTime={workingStartTime.current}
                    endWorkingTime={workingEndTime.current}
                    onDaySelected={(day) => {
                        setSelectedDay(day)
                    }}/>
                {/*day details*/}
                <DailyReport
                    selectedDate={selectedDay} monthInfo={monthInfo} startWorkingTime={workingStartTime.current}
                    endWorkingTime={workingEndTime.current}/>
            </View>
            <Footer/>
        </View>
    )
}

function DaySelectors({selectedDate, startWorkingTime, endWorkingTime, monthInfoTime, onDaySelected}) {
    let holidays = SwedishHolidays.getHolidays(getYear(selectedDate))
    if (endWorkingTime.startsWith("00:00")) endWorkingTime = "23:59"

    function getDayTextColor(day) {
        let isHoliday = holidays.filter((value, index, array) => {
            let holidayDate = value.date
            const year = getYear(holidayDate);
            const month = getMonth(holidayDate);
            const date = getDate(holidayDate);
            const currentDate = getDate(day)
            const currentMonth = getMonth(day)
            const currentYear = getYear(day)

            return year === currentYear && month === currentMonth && date === currentDate
        }).length !== 0
        let isWeekend = format(day, "EEEE").includes("Sunday")
        return isHoliday || isWeekend ? colors.navyBlue : colors.darkGray
    }

    function getDayFillColor(day) {
        // return yellow if day does not exist in monthInfoTime
        if (!monthInfoTime.hasOwnProperty(day)) return "pink"
        // return yellow if start of working time is less than start of first month day info
        if (getMinutesOfTime(startWorkingTime) < getMinutesOfTime(monthInfoTime[day][0].start))
            return colors.yellow
        // return yellow if end of working time is more than end of last month day info
        if (getMinutesOfTime(endWorkingTime) > getMinutesOfTime(monthInfoTime[day][monthInfoTime[day].length - 1].end))
            return colors.yellow
        // after checking boundaries, check every element in the array to find gaps in time
        for (let i = 0; i < monthInfoTime[day].length - 1; i++) {
            if (getMinutesOfTime(monthInfoTime[day][i].end) < getMinutesOfTime(monthInfoTime[day][i + 1].start)) return colors.yellow
        }
        return "white"
    }

    let days = []
    for (let i = 0; i < getDaysInMonth(selectedDate); i++) {
        days = days.concat(addDays(setDate(selectedDate, 1), i))
    }

    return (
        <View style={{flex: 1}}>
            <Space height={20}/>
            <ScrollView style={{flex: 1}}>
                <View>
                    <Table
                        minColumnWidth={180}
                        items={days}
                        columnCountCalculationFunction={(windowWidth) => Math.floor(windowWidth / 2 / 180 - 1)}
                        itemGeneratorFunction={(day, id) => {
                            if (day == null) {
                                return <View style={{
                                    backgroundColor: "transparent",
                                    width: 140,
                                    height: 40,
                                    marginBottom: 15,
                                    marginHorizontal: 10
                                }}/>
                            }
                            return (
                                <Selector
                                    style={{marginBottom: 15, marginHorizontal: 10}}
                                    text={format(day, "dd - EEEE")}
                                    selectedBorderColor={colors.lightYellow}
                                    deselectedBorderColor={colors.lightYellow}
                                    selectedFillColor={colors.lightYellow}
                                    deselectedFillColor={getDayFillColor(format(day, "yyyy-MM-dd"))}
                                    selectedTextColor={getDayTextColor(day)}
                                    deselectedTextColor={getDayTextColor(day)}
                                    selectedId={selectedDate}
                                    id={day}
                                    onSelectedChange={(id) => {
                                        onDaySelected(id)
                                    }}
                                />
                            )
                        }}/>
                </View>
            </ScrollView>
            <Space height={20}/>
        </View>
    )
}

function DailyReport({selectedDate, monthInfo, startWorkingTime, endWorkingTime}) {
    const postsOfTheDay = monthInfo.filter(value => {
        return format(selectedDate, "yyyy-MM-dd") === value.date
    })

    function DayNotes({workingStart, workingEnd, postsOfTheDay}) {

        function voidFilledTimes(timeRanges, filledTime) {
            console.log(timeRanges)
            console.log(filledTime)
            let fillStart = getMinutesOfTime(filledTime.startTime)
            let fillEnd = getMinutesOfTime(filledTime.endTime)

            for (let i = 0; i < timeRanges.length; i++) {
                const timesItem = timeRanges[i]
                console.log(timesItem)
                const start = getMinutesOfTime(timesItem.startTime)
                const end = getMinutesOfTime(timesItem.endTime)
                if (fillEnd < start || fillStart > end) {
                    // if fill end is below timeRanges item start or fill start is above timeRanges item end:
                    //      skip
                    continue
                } else if (fillStart <= start && fillEnd >= end) {
                    // if fill start is below timeRanges item start and fill end is above timeRanges item end:
                    //      remove item
                    timeRanges.splice(i, 1)
                    i = 0
                } else if (fillStart <= start && fillEnd > start && fillEnd < end) {
                    // if fill start is below timeRanges item start and fill end is below timeRanges item end:
                    //      replace timeRanges item start with fill end
                    timeRanges[i].startTime = getTimeOfMinutes(fillEnd)
                } else if (fillStart > start && fillEnd < end) {
                    // if fill start is above timeRanges item start and fill end is below timeRanges item end:
                    //      replace timeRanges item end with fill start and insert new timeRanges item {start: fill end, end: item end}
                    timeRanges[i].endTime = getTimeOfMinutes(fillStart)
                    timeRanges = [...timeRanges.slice(0, i), {
                        startTime: getTimeOfMinutes(fillEnd),
                        endTime: getTimeOfMinutes(end)
                    }, ...timeRanges.slice(i)]
                    i = 0
                } else if (fillStart > start && fillStart < end && fillEnd >= end) {
                    //if fill start is above timeRanges item start and fill end is above timeRanges item end:
                    //      replace timeRanges item end with fill start
                    timeRanges[i].endTime = getTimeOfMinutes(fillStart)
                }
            }

            return timeRanges
        }

        let emptyTimeRanges = [{startTime: workingStart.slice(0, 5), endTime: workingEnd.slice(0, 5)}]
        for (let i = 0; i < postsOfTheDay.length; i++) {
            const post = postsOfTheDay[i]
            emptyTimeRanges = voidFilledTimes(emptyTimeRanges, post)
        }
        let noteText = ""
        if (emptyTimeRanges.length > 0) {
            noteText = emptyTimeRanges
                .sort((a, b) => getMinutesOfTime(a.startTime) - getMinutesOfTime(b.startTime))
                .map(value => `${value.startTime} to ${value.endTime}`)
                .reduce((previousValue, currentValue) => `${previousValue} - ${currentValue}`)
        }

        return (
            noteText !== "" && <View style={{
                flex: 1,
                marginHorizontal: 25,
                maxHeight: 100,
                backgroundColor: colors.lightGray,
                borderRadius: 20,
                borderColor: colors.navyBlue,
                borderWidth: 1,
                borderStyle: "dashed",
                justifyContent: "space-around"
            }}>
                <View>
                    <Text style={{
                        ...fontStyles.lato700_14,
                        flex: 1,
                        textAlign: "center",
                        textAlignVertical: "center",
                        color: colors.darkGray
                    }}>Attention!</Text>
                    <Text style={{
                        ...fontStyles.lato400_14,
                        flex: 1,
                        textAlign: "center",
                        textAlignVertical: "center",
                        color: colors.darkGray
                    }}>You have not set any staff for
                        working times:</Text>
                </View>
                <Text style={{
                    ...fontStyles.lato400_14,
                    flex: 1,
                    textAlign: "center",
                    textAlignVertical: "center",
                    color: colors.darkGray
                }}>{noteText}</Text>
            </View>
        )
    }

    function InfoRow({id, name, start, end, workingStatus}) {

        return (
            <View key={id}
                  style={{
                      flex: 1,
                      flexGrow: 1,
                      maxWidth: 500,
                      flexDirection: "row",
                      maxHeight: 30,
                      marginVertical: 5,
                  }}>
                <Text style={{
                    ...fontStyles.lato700_14,
                    flex: 1,
                    lineHeight: 30,
                    textAlign: "left",
                    textAlignVertical: "center",
                }}>{id} - {name}</Text>
                <Space width={10}/>
                <Text style={{
                    ...fontStyles.lato700_14,
                    flex: 1,
                    maxWidth: 150,
                    lineHeight: 30,
                    textAlign: "center",
                    backgroundColor: colors.lightGreen,
                    textAlignVertical: "center",
                    borderRadius: 20,
                    paddingHorizontal: 10
                }}>{workingStatus}</Text>
                <Space width={10}/>
                <Text style={{
                    ...fontStyles.lato700_14,
                    flex: 1,
                    maxWidth: 100,
                    lineHeight: 30,
                    textAlignVertical: "center",
                    textAlign: "center"
                }}>{start} to {end}</Text>
            </View>
        )
    }

    return (
        <View style={{
            flex: 1,
            maxWidth: 550,
            margin: 10,
            borderRadius: 20,
            borderColor: colors.navyBlue,
            borderWidth: 1,
            justifyContent: "space-around"
        }}>
            <Text style={{
                ...fontStyles.lato700_18,
                lineHeight: 22,
                height: 22,
                marginVertical: 15,
                textAlign: "center",
                alignSelf: "stretch"
            }}>{`${format(selectedDate, "dd - EEEE")} employee list`}</Text>
            {postsOfTheDay.length !== 0 &&
                <DayNotes workingStart={startWorkingTime} workingEnd={endWorkingTime}
                          postsOfTheDay={postsOfTheDay.map(item => {
                              return {startTime: item.startTime, endTime: item.endTime}
                          })}/>
            }
            <Space height={20}/>
            <ScrollView
                contentContainerStyle={{flex: 1, marginHorizontal: 15}}>
                {
                    postsOfTheDay.map((item, index, array) => {
                        return <InfoRow id={index + 1} workingStatus={item.workStatusTitle} end={item.endTime}
                                        start={item.startTime}
                                        name={item.name}/>
                    })
                }
            </ScrollView>
        </View>
    )
}


const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
})
