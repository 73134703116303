import {useEffect, useRef} from "react";
import {Animated, View} from "react-native";

function FadeView({visible, duration, useAnimation = true, onLayout, style, children, onAnimationFinish}) {
    const animatedValue = useRef(new Animated.Value(visible ? 0 : 1)).current

    useEffect(() => {
        Animated.timing(animatedValue, {
            toValue: visible ? 1 : 0,
            duration: duration,
            useNativeDriver: true
        }).start(() => {
            if (onAnimationFinish != null) onAnimationFinish()
        })
    })


    if (useAnimation) {
        return (
            <Animated.View
                onLayout={(event) => {
                    if (onLayout) {
                        onLayout(event)
                    }
                }}
                style={{
                    ...style,
                    opacity: animatedValue,
                    transform: [
                        {
                            scaleX: animatedValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: [.9, 1]
                            })
                        },
                        {
                            scaleY: animatedValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: [.9, 1]
                            })
                        },
                    ],
                }}>
                {children}
            </Animated.View>
        );
    } else {
        return (
            <View
                onLayout={(event) => {
                    if (onLayout) {
                        onLayout(event)
                    }
                }}
                style={{
                    ...style,
                }}>
                {children}
            </View>
        )
    }
}

export default FadeView
