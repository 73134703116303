import {Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colors} from "../colors";
import ProfileData from "../misc/ProfileData";
import {fontStyles} from "../fontStyles";

function ScreenHeader({navigation, children}) {
    return (
        <View style={styles.headerContainer}>
            <View style={{flex: 1, flexDirection: "row", alignItems: "center"}}>
                <TouchableOpacity style={{width: 55, height: 55}}
                                  onPress={() => {
                                      ProfileData.accountType === ProfileData.AccountTypes.Admin ?
                                          navigation.jumpTo("Employees") :
                                          navigation.jumpTo("Profile")
                                  }}>
                    <Image style={{height: 55, width: 55}} source={require("./../assets/logo512.png")}/>
                </TouchableOpacity>
                <View style={{width: 20}}/>
                <Text style={fontStyles.lato700_30}>Avvikelseanalys</Text>
            </View>
            {
                children != null && children
            }
            <View style={{flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "flex-end"}}>
                {/*<Image style={{width: 25, height: 25}} source={require("./../assets/sample_profile.png")}/>*/}
                <Text
                    style={{...fontStyles.lato700_16, color: colors.darkGray, marginStart: 5}}>{ProfileData.name}</Text>
            </View>
        </View>)
}

const styles = StyleSheet.create({
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignContent: "center",
        alignItems: "center",
        marginTop: 20,
        maxHeight: 55,
        marginBottom: 30
    },
})

export default ScreenHeader
