import {StyleSheet, View, Text, Image, ScrollView} from "react-native";
import ScreenHeader from "../../ScreenHeader";
import TitleView from "../../employee_screens/components/TitleView";
import {fontStyles} from "../../../fontStyles";
import StyledDropDown from "../../StyledDropDown";
import {useEffect, useState} from "react";
import DatePickerView from "../../DatePickerView";
import ModalDatePicker from "../../ModalDatePicker";
import Space from "../../Space";
import {colors} from "../../../colors";
import Footer from "../../Footer";
import Helpers from "../../../misc/Helpers";
import HttpRequests from "../../../http_requests/HttpRequests";
import LoadingMessage from "../../DefaultMessages";
import {addDays, getMonth, getYear} from "date-fns";
import CustomButton from "../../employee_screens/components/CustomButton";

export default function NewOverallReportsContents({navigation}) {

    const [numbersTableItems, setNumbersTableItems] = useState(emptyNumbersTableItems())

    const [staffStatusItems, setStaffStatusItems] = useState(emptyStaffStatusItems())

    const [tableItems, setTableItems] = useState(emptyTableItems())

    const yearItems = Array.from({length: 10}, (item, index) => {
        return {label: getYear(new Date()) - 5 + index}
    })

    const [selectedYear, setSelectedYear] = useState<number>(null)

    const timeIntervalItems = [{label: "Daily"}, {label: "Monthly"}, {label: "Yearly"}, {label: "Custom"}]
    const [timeInterval, setTimeInterval] = useState<string>(null)
    useEffect(() => {
        setStartDate(null)
        setEndDate(null)
        console.log(timeInterval)
    }, [timeInterval]);

    const [showStartPicker, setShowStartPicker] = useState(false)
    const [startDate, setStartDate] = useState<Date>(null)
    useEffect(() => {
        if (!startDate) {
            setNumbersTableItems(emptyNumbersTableItems())
            setStaffStatusItems(emptyStaffStatusItems())
            setTableItems(emptyTableItems())
        }
    }, [startDate]);

    const [showEndPicker, setShowEndPicker] = useState(false)
    const [endDate, setEndDate] = useState<Date>(null)
    useEffect(() => {
        if (!endDate) {
            setNumbersTableItems(emptyNumbersTableItems())
            setStaffStatusItems(emptyStaffStatusItems())
            setTableItems(emptyTableItems())
        }
    }, [endDate]);

    const [showLoadingMessage, setShowLoadingMessage] = useState(false)

    // functions
    function getDatePickerPlaceholder(): string {
        if (timeInterval) {
            switch (timeInterval["label"]) {
                case "Custom":
                    return "Start Date"
                case "Yearly":
                    return "Select Year"
                case "Monthly":
                    return "Select Month"
                case "Daily":
                    return "Select Date"
            }
        }
        return ""
    }

    function updateOverallReport(startDate: Date, endDate: Date) {
        console.log(startDate)
        console.log(endDate)
        setShowLoadingMessage(true)
        HttpRequests.getEmployerOverallReport(Helpers.formatDate(startDate), Helpers.formatDate(endDate)).then(result => {
            console.log(result)
            setShowLoadingMessage(false)
            if (result.success) {
                const extras = result.extras
                const normalWork = extras.find(value => value.workingStatusTitle === "Normal Work").totalHours
                const parentalLeave = extras.find(value => value.workingStatusTitle === "Parental leave").totalHours
                const holiday = extras.find(value => value.workingStatusTitle === "Holiday").totalHours
                const sick = extras.find(value => value.workingStatusTitle === "Sick").totalHours
                const normalLeave = extras.find(value => value.workingStatusTitle === "Leave from work").totalHours
                const takingCare = extras.find(value => value.workingStatusTitle === "Taking care of kid").totalHours
                const rest = extras.find(value => value.workingStatusTitle === "Rest").totalHours
                const permission = extras.find(value => value.workingStatusTitle === "Permission").totalHours

                const newNumbersTableItems = [
                    {
                        icon: "icons/report/total-employees-number.png",
                        title: "Total Employees Number",
                        value: result.totalEmployees
                    },
                    {icon: "icons/report/minimum-age.png", title: "Minimum Age", value: result.minAge},
                    {icon: "icons/report/maximum-age.png", title: "Maximum Age", value: result.maxAge},
                    {icon: "icons/report/average-age.png", title: "Average Age", value: result.avgAge},
                    {icon: "icons/report/normal-work.png", title: "Normal Work", value: normalWork},
                    {icon: "icons/report/parental-leave.png", title: "Parental Leave", value: parentalLeave},
                    {icon: "icons/report/holiday.png", title: "Holiday", value: holiday},
                    {icon: "icons/report/sick-leave.png", title: "Sick Leave", value: sick},
                    {icon: "icons/report/normal-leave.png", title: "Normal Leave", value: normalLeave},
                    {icon: "icons/report/taking-care-leave.png", title: "Taking Care of Kid", value: takingCare},
                    {icon: "icons/report/rest-leave.png", title: "Rest Leave", value: rest},
                    {icon: "icons/report/permission-leave.png", title: "Permission Leave", value: permission},
                ]
                setNumbersTableItems(newNumbersTableItems)
                setStaffStatusItems([
                        {title: "Normal Work", value: normalWork},
                        {title: "Parental Leave", value: parentalLeave},
                        {title: "Holiday", value: holiday},
                        {title: "Sick Leave", value: sick},
                        {title: "Normal Leave", value: normalLeave},
                        {title: "Taking Care of Kid", value: takingCare},
                        {title: "Rest Leave", value: rest},
                        {title: "Permission Leave", value: permission},
                    ]
                )
                setTableItems({
                    normalSalary: result.normalSalary,
                    holidays: result.holiday,
                    extraTime: result.overtime,
                    taxes: result.tax,
                    normalSalaryResult: result.normalSalaryResult,
                    holidaysResult: result.holidayResult,
                    extraTimeResult: result.overtimeResult,
                    taxesResult: result.taxResult,
                })
            } else {
                // handle error
                setNumbersTableItems(emptyNumbersTableItems())
                setStaffStatusItems(emptyStaffStatusItems())
                setTableItems(emptyTableItems())
            }
        })
    }

    function emptyNumbersTableItems() {
        return [
            {icon: "icons/report/total-employees-number.png", title: "Total Employees Number", value: 0},
            {icon: "icons/report/minimum-age.png", title: "Minimum Age", value: 0},
            {icon: "icons/report/maximum-age.png", title: "Maximum Age", value: 0},
            {icon: "icons/report/average-age.png", title: "Average Age", value: 0},
            {icon: "icons/report/normal-work.png", title: "Normal Work", value: 0},
            {icon: "icons/report/parental-leave.png", title: "Parental Leave", value: 0},
            {icon: "icons/report/holiday.png", title: "Holiday", value: 0},
            {icon: "icons/report/sick-leave.png", title: "Sick Leave", value: 0},
            {icon: "icons/report/normal-leave.png", title: "Normal Leave", value: 0},
            {icon: "icons/report/taking-care-leave.png", title: "Taking Care of Kid", value: 0},
            {icon: "icons/report/rest-leave.png", title: "Rest Leave", value: 0},
            {icon: "icons/report/permission-leave.png", title: "Permission Leave", value: 0},
        ];
    }

    function emptyStaffStatusItems() {
        return [
            {title: "Normal Work", value: 0},
            {title: "Parental Leave", value: 0},
            {title: "Holiday", value: 0},
            {title: "Sick Leave", value: 0},
            {title: "Normal Leave", value: 0},
            {title: "Taking Care of Kid", value: 0},
            {title: "Rest Leave", value: 0},
            {title: "Permission Leave", value: 0},
        ];
    }

    function emptyTableItems() {
        return {
            normalSalary: 0,
            extraTime: 0,
            taxes: 0,
            holidays: 0,
            normalSalaryResult: 0,
            extraTimeResult: 0,
            taxesResult: 0,
            holidaysResult: 0,
        };
    }

    // functional components
    function NumberRow(props: { value: number, title: string, icon: string, unit: string }) {
        return (
            <View>
                <View style={{
                    flexDirection: "row",
                    maxHeight: 40,
                    minHeight: 40,
                    flex: 1,
                    alignItems: "center",
                    borderWidth: 1,
                    borderRadius: 40,
                    borderColor: colors.navyBlue,
                    paddingHorizontal: 20,
                    paddingVertical: 2
                }}>
                    <Image source={require(`../../../assets/${props.icon}`)} style={{height: 20, width: 20}}/>
                    <Space width={5}/>
                    <Text style={{...fontStyles.lato700_14, color: colors.darkGray}}>{props.title}</Text>
                    <View style={{flex: 1}}/>
                    <Text style={{...fontStyles.lato700_22, color: colors.navyBlue}}>{props.value}</Text>
                    <Space width={5}/>
                    <Text style={{...fontStyles.lato700_12, color: colors.navyBlue}}>{props.unit}</Text>
                </View>
                <Space height={5}/>
            </View>
        )
    }

    function FinancialRow(props: { value: number, title: string, icon: string, color: string }) {
        return (
            <View>
                <View style={{
                    flexDirection: "row",
                    maxHeight: 40,
                    minHeight: 40,
                    flex: 1,
                    alignItems: "center",
                    borderWidth: 1,
                    borderRadius: 40,
                    borderColor: colors.navyBlue,
                    backgroundColor: props.color,
                    paddingHorizontal: 15,
                    paddingVertical: 2,

                }}>
                    <Image source={require(`../../../assets/${props.icon}`)} style={{height: 30, width: 30}}/>
                    <Space width={5}/>
                    <Text style={{...fontStyles.lato700_14, color: colors.darkGray}}>{props.title}</Text>
                    <View style={{flex: 1}}/>
                    <Text
                        style={{
                            ...fontStyles.lato700_22,
                            color: colors.navyBlue
                        }}>{Helpers.formatNumberWithSpace(props.value)}</Text>
                    {/*<Space width={5}/>*/}
                    {/*<Text style={{...fontStyles.lato700_12, color: colors.navyBlue}}>$</Text>*/}
                </View>
                <Space height={5}/>
            </View>
        )
    }

    function StaffStatusColumn(props: { value: number, title: string }) {
        return (
            <View style={{width: 40, height: 330}}>
                <View style={{
                    flexDirection: "row",
                    height: 40,
                    width: 330,
                    transform: [{translateY: (330 - 40) / 2}, {translateX: -(330 - 40) / 2}, {rotate: "-90deg"}]
                }}>
                    <View style={{flex: 1, maxHeight: 40}}/>
                    <Text style={{...fontStyles.lato700_14, alignSelf: "center"}}>{props.title}</Text>
                    <View style={{width: 15, height: 40}}/>
                    <View style={{
                        borderRadius: 40,
                        backgroundColor: colors.lightNavyBlue,
                        height: 40,
                        width: 180,
                        flexDirection: "row",
                        paddingHorizontal: 10
                    }}>
                        <Text style={{
                            ...fontStyles.lato700_14,
                            color: "white",
                            alignSelf: "center"
                        }}>{`${props.value} hours`}</Text>
                        <View style={{flex: 1, maxHeight: 40}}/>
                    </View>
                </View>
            </View>
        )
    }

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation} children={undefined}/>
            <TitleView titleText={"Reports"} style={{}}/>

            {/*duration selector row*/}
            <View style={{
                flexDirection: "row", flex: 1, alignItems: "center", maxHeight: 40
            }}>
                <Text style={fontStyles.lato700_14}>Duration</Text>
                <View style={{width: 180}}>
                    <StyledDropDown
                        style={undefined}
                        items={timeIntervalItems} placeHolder={"Time Interval"}
                        onChange={(item: string) => {
                            console.log(item)
                            setTimeInterval(item)
                        }}
                        value={timeInterval}
                    />
                </View>
                {
                    timeInterval !== null && timeInterval["label"] !== "Yearly" &&
                    <View style={{width: 180}}>
                        <DatePickerView
                            placeHolderText={getDatePickerPlaceholder()}
                            selectedDate={startDate}
                            onShowCalendarClicked={() => {
                                setShowStartPicker(true)
                            }}/>
                    </View>
                }
                <Space width={5}/>
                {
                    timeInterval && timeInterval["label"] === "Custom" &&
                    <View style={{width: 180}}>
                        <DatePickerView
                            placeHolderText={"End Date"} selectedDate={endDate}
                            onShowCalendarClicked={() => {
                                setShowEndPicker(true)
                            }}/>
                    </View>
                }
                {
                    timeInterval && timeInterval["label"] === "Yearly" &&
                    <View style={{width: 180}}>
                        <StyledDropDown
                            style={undefined} items={yearItems} placeHolder={"Select Year"} value={selectedYear}
                            onChange={(year) => {
                                let firstDate = Helpers.getDate(`${year.label}-01-01`)
                                let lastDate = Helpers.getDate(`${year.label + 1}-01-01`)
                                lastDate = addDays(lastDate, -1)
                                setSelectedYear(year.label)
                                updateOverallReport(firstDate, lastDate)
                            }}
                        />
                    </View>
                }
                <View style={{flex: 1}}/>
                <Text style={fontStyles.lato700_20}>{
                    timeInterval === null ? "Please select time interval" :
                        timeInterval["label"] === "Yearly" ? (selectedYear === null ? "Please select year" : `Year ${selectedYear["label"]} Report`) :
                            timeInterval["label"] === "Monthly" ? (startDate === null ? "Please select date" : `${Helpers.getMonthName(getMonth(startDate) + 1)} ${getYear(startDate)} Report`) :
                                timeInterval["label"] === "Daily" ? (startDate === null ? "Please select date" : `${Helpers.formatDate(startDate)} Report`) :
                                    timeInterval["label"] === "Custom" ? (startDate == null || endDate == null ? `Please select start and end date` : `${Helpers.formatDate(startDate)} to ${Helpers.formatDate(endDate)} Report`) :
                                        ""
                }</Text>
            </View>

            <Space height={25}/>

            <View style={{flex: 1, flexDirection: "row"}}>
                {/*numbers column*/}
                <ScrollView style={{flex: 1}}>
                    {
                        numbersTableItems.map((item, index) => {
                            return <NumberRow value={Helpers.formatNumberWithSpace(item.value)} title={item.title}
                                              icon={item.icon}
                                              unit={index === 0 ? "employees" : (index <= 3 ? "years" : "hours")}/>
                        })
                    }
                </ScrollView>

                <Space width={20}/>

                <View style={{flex: 3}}>
                    <ScrollView style={{flex: 3}}>
                        <View style={{flexDirection: "row"}}>
                            {/*budgets table*/}
                            <View style={{flex: 1}}>
                                <Text style={fontStyles.lato700_20}>Budgets</Text>
                                <Space height={30}/>
                                <FinancialRow value={tableItems.normalSalary} title={"Normal Salaries"}
                                              icon={"icons/salaries.png"} color={colors.tableRow}/>
                                <FinancialRow value={tableItems.extraTime} title={"Extra Times"}
                                              icon={"icons/extra_time.png"} color={colors.tableRow}/>
                                <FinancialRow value={tableItems.taxes} title={"Taxes"} icon={"icons/tax.png"}
                                              color={colors.tableRow}/>
                                <FinancialRow value={tableItems.holidays} title={"Holidays"} icon={"icons/holiday.png"}
                                              color={colors.tableRow}/>
                                <FinancialRow
                                    value={tableItems.normalSalary + tableItems.extraTime + tableItems.taxes + tableItems.holidays}
                                    title={"Total"} icon={"icons/holiday.png"} color={colors.lighterNavyBlue}/>
                            </View>

                            <Space width={20}/>

                            {/*results table*/}
                            <View style={{flex: 1}}>
                                <Text style={fontStyles.lato700_20}>Results</Text>
                                <Space height={30}/>
                                <FinancialRow value={tableItems.normalSalaryResult} title={"Normal Salaries"}
                                              icon={"icons/salaries.png"} color={colors.tableRow}/>
                                <FinancialRow value={tableItems.extraTimeResult} title={"Extra Times"}
                                              icon={"icons/extra_time.png"} color={colors.tableRow}/>
                                <FinancialRow value={tableItems.taxesResult} title={"Taxes"} icon={"icons/tax.png"}
                                              color={colors.tableRow}/>
                                <FinancialRow value={tableItems.holidaysResult} title={"Holidays"}
                                              icon={"icons/holiday.png"} color={colors.tableRow}/>
                                <FinancialRow
                                    value={tableItems.normalSalaryResult + tableItems.extraTimeResult + tableItems.taxesResult + tableItems.holidaysResult}
                                    title={"Total"} icon={"icons/holiday.png"} color={colors.lighterNavyBlue}/>
                            </View>

                            <Space width={20}/>

                            {/*diff table*/}
                            <View style={{flex: 1}}>
                                <Text style={fontStyles.lato700_20}>Diff</Text>
                                <Space height={30}/>
                                <FinancialRow value={tableItems.normalSalary - tableItems.normalSalaryResult}
                                              title={"Normal Salaries"} icon={"icons/salaries.png"}
                                              color={colors.tableRow}/>
                                <FinancialRow value={tableItems.extraTime - tableItems.extraTimeResult}
                                              title={"Extra Times"} icon={"icons/extra_time.png"}
                                              color={colors.tableRow}/>
                                <FinancialRow value={tableItems.taxes - tableItems.taxesResult} title={"Taxes"}
                                              icon={"icons/tax.png"} color={colors.tableRow}/>
                                <FinancialRow value={tableItems.holidays - tableItems.holidaysResult} title={"Holidays"}
                                              icon={"icons/holiday.png"} color={colors.tableRow}/>
                                <FinancialRow
                                    value={tableItems.normalSalary + tableItems.extraTime + tableItems.taxes + tableItems.holidays - (tableItems.normalSalaryResult + tableItems.extraTimeResult + tableItems.taxesResult + tableItems.holidaysResult)}
                                    title={"Total"} icon={"icons/holiday.png"} color={colors.lighterNavyBlue}/>
                            </View>
                        </View>
                        {/*<View style={{flex: 1, alignItems: "center"}}>*/}
                        {/*    <Text style={fontStyles.lato700_14}>Staff Time Status</Text>*/}
                        {/*    <View style={{*/}
                        {/*        borderWidth: 1,*/}
                        {/*        borderColor: colors.navyBlue,*/}
                        {/*        borderRadius: 20,*/}
                        {/*        height: 360,*/}
                        {/*        width: 450,*/}
                        {/*        paddingHorizontal: (450 - 40 * staffStatusItems.length - 10 * (staffStatusItems.length - 1)) / 2,*/}
                        {/*        paddingTop: 20,*/}
                        {/*        alignItems: "center",*/}
                        {/*        flexDirection: "row",*/}
                        {/*        gap: 10*/}
                        {/*    }}>*/}
                        {/*        {*/}
                        {/*            staffStatusItems.map((item, index) => {*/}
                        {/*                return <StaffStatusColumn value={item.value} title={item.title}/>*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*    </View>*/}
                        {/*</View>*/}
                    </ScrollView>
                    <Space height={20}/>

                    {/*export button*/}
                    <View style={{flex: 1, maxHeight: 40, flexDirection: "row-reverse",}}>
                        <CustomButton
                            flex={1} text={"Export PDF"} style={{maxWidth: 220}} fontSize={16}
                            color={colors.lightYellow}
                            onPress={
                                numbersTableItems.find(value => value.title === "Total Employees Number").value === 0 ? null :
                                    () => {
                                        let startDateStr: string
                                        let endDateStr: string
                                        if (timeInterval["label"] === "Yearly") {
                                            startDateStr = Helpers.formatDate(new Date(selectedYear, 0))
                                            endDateStr = Helpers.formatDate(new Date(selectedYear + 1, 0, 0))
                                        } else {
                                            startDateStr = Helpers.formatDate(startDate)
                                            endDateStr = Helpers.formatDate(endDate)
                                        }
                                        HttpRequests.getOverallReportPdf(startDateStr, endDateStr).then(value => {
                                            console.log(value)
                                        })
                                    }}/>
                    </View>
                </View>
            </View>

            <ModalDatePicker
                open={showStartPicker}
                currentDate={startDate}
                onCancel={() => {
                    setShowStartPicker(false)
                }}
                onConfirm={(date) => {
                    console.log(date)
                    setShowStartPicker(false)
                    switch (timeInterval["label"]) {
                        case "Daily":
                            setStartDate(date)
                            updateOverallReport(date, date)
                            break
                        case "Monthly":
                            const start = new Date(getYear(date), getMonth(date));
                            const end = new Date(getYear(date), getMonth(date) + 1, 0);
                            setStartDate(start)
                            setEndDate(end)
                            updateOverallReport(start, end)
                            break
                        case "Custom":
                            setStartDate(date)
                            if (endDate) {
                                updateOverallReport(date, endDate)
                            }
                            break
                    }
                }}
                mode={timeInterval && timeInterval["label"] === "Monthly" ? "monthYear" : "calendar"}
            />
            <ModalDatePicker
                open={showEndPicker}
                currentDate={endDate}
                onCancel={() => {
                    setShowEndPicker(false)
                }}
                onConfirm={(date) => {
                    setShowEndPicker(false)
                    setEndDate(date)
                    if (startDate) {
                        updateOverallReport(startDate, date)
                    }
                }}
                mode={timeInterval && timeInterval["label"] === "Monthly" ? "monthYear" : "calendar"}
            />
            <Footer/>
            <LoadingMessage showLoadingMessage={showLoadingMessage}/>
        </View>
    )

}


const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
    fillingRow: {
        flex: 1, flexDirection: "row"
    },
    fillingColumn: {
        flex: 1, flexDirection: "column"
    },
    centerMainAxis: {
        alignItems: "center"
    },
    centerCrossAxis: {
        justifyContent: "center"
    },
    center: {
        justifyContent: "center", alignItems: "center"
    }
})
