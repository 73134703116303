import PostingContents from "./PostingContents";
import FadeView from "../../FadeView";
import {useEffect, useRef, useState} from "react";
import EmployerTimeTableContents from "./EmployerTimeTableContents";
import AwesomeAlert from "react-native-awesome-alerts";
import LoadingMessage from "../../DefaultMessages";
import IntentMessenger from "../../../misc/IntentMessenger";
import {selectedEmployeeTag} from "../EmployeesTab/HomeContent";

export default function PostingTab({navigation}) {
    const animationDuration = 200
    const destinationNames = {
        posting: "posting",
        timeTable: "timeTable"
    }
    const [transition, setTransition] = useState(false)
    const [destination, setDestination] = useState(destinationNames.posting)
    const destinationRef = useRef(destination)
    useEffect(() => {
        destinationRef.current = destination
    }, [destination]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(-1)
    const selectedEmployeeIdRef = useRef(selectedEmployeeId);
    useEffect(() => {
        selectedEmployeeIdRef.current = selectedEmployeeId
    }, [selectedEmployeeId]);
    const [showMessage, setShowMessage] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const selectedPostInfo = useRef();
    const selectedMonthIndex = useRef();
    const dialogTitle = useRef("");
    const dialogMessage = useRef("");
    const dialogBody = useRef(null);
    const confirmAction = useRef(null);
    const cancelAction = useRef(null);

    useEffect(() => {
        navigation.addListener('focus', (e) => {
            console.log(e)

            if (destinationRef.current === destinationNames.timeTable) {
                goToDestination(destinationNames.posting)
            }
            const message = IntentMessenger.popMessage(selectedEmployeeTag)
            if (message === null) {
                const newNumber = selectedEmployeeIdRef.current < 0 ? selectedEmployeeIdRef.current - 1 : -1
                console.log(newNumber)
                setSelectedEmployeeId(newNumber)
            } else {
                setSelectedEmployeeId(+message)
            }
        })
    }, [navigation]);

    function onLoading(loading) {
        setShowLoading(loading)
        if (loading) setShowMessage(false)
    }

    function onMessage(title, message, onConfirm, onCancel, body) {
        dialogTitle.current = title
        dialogMessage.current = message ?? ""
        dialogBody.current = body ?? null

        confirmAction.current = onConfirm ? () => {
            onConfirm()
            setShowMessage(false)
        } : () => setShowMessage(false);
        if (onCancel) {
            cancelAction.current = () => {
                onCancel()
                setShowMessage(false)
            }
        } else {
            cancelAction.current = null
        }
        setShowMessage(true)
        setShowLoading(false)
    }

    function goToDestination(destination) {
        setTransition(true)
        setTimeout(() => {
            setDestination(destination)
            setTransition(false)
        }, animationDuration)
    }

    return (
        <FadeView style={{flex: 1}} duration={animationDuration} visible={!transition}>
            {destination === destinationNames.posting &&
                <PostingContents
                    navigation={navigation}
                    onMessage={onMessage}
                    onLoading={onLoading}
                    selectedEmployeeId={selectedEmployeeId}
                    onTimeTableCalled={(monthIndex) => {
                        goToDestination(destinationNames.timeTable);
                        selectedMonthIndex.current = monthIndex
                    }}
                    onPostSelected={(postInfo) => {
                        selectedPostInfo.current = postInfo
                        console.log(postInfo)
                    }}
                />}
            {destination === destinationNames.timeTable &&
                <EmployerTimeTableContents
                    navigation={navigation}
                    onMessage={onMessage}
                    onLoading={onLoading}
                    postInfo={selectedPostInfo.current}
                    monthIndex={selectedMonthIndex.current}
                    onBack={() => {
                        goToDestination(destinationNames.posting)
                    }}
                />
            }
            <AwesomeAlert
                show={showMessage}
                closeOnTouchOutside={false}
                title={dialogTitle.current}
                message={dialogMessage.current}
                customView={dialogBody.current}
                showConfirmButton={true}
                confirmText={"OK"}
                onConfirmPressed={() => {
                    confirmAction.current()
                }}
                showCancelButton={cancelAction.current != null}
                cancelText={"Cancel"}
                onCancelPressed={() => {cancelAction.current()}}
            />
            <LoadingMessage showLoadingMessage={showLoading}/>
        </FadeView>
    )
}
