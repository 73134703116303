import HttpRequests from "../../http_requests/HttpRequests";
import ProfileData from "../../misc/ProfileData";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useEffect, useRef, useState} from "react";
import FadeView from "../FadeView";
import StyledEditText from "./StyledEditText";
import {Image, StyleSheet, Text, TouchableHighlight, View} from "react-native";
import {Button} from "@rneui/themed";
import {colors} from "../../colors";

export default function LoginContent({visible, useAnimation, onForgotPass, animationDuration, tryLogin}) {

    const passwordFieldRef = useRef(null);
    // const passwordTextRef = useRef("adminAvvi!")
    const passwordTextRef = useRef("")
    const [passwordText, setPasswordText] = useState(passwordTextRef.current)
    useEffect(() => {
        passwordFieldRef.current?.focus()
        passwordTextRef.current = passwordText
    }, [passwordText]);

    const usernameFieldRef = useRef(null);
    // const usernameTextRef = useRef("");
    const [usernameText, setUsernameText] = useState("")
    useEffect(() => {
        usernameFieldRef.current?.focus()
        // usernameTextRef.current = usernameText
    }, [usernameText]);

    const [remember, setRemember] = useState(false)

    useEffect(() => {
        AsyncStorage.multiGet(["email", "password"], (errors, result) => {
            const email = result.find(value => value[0] === "email")[1]
            const password = result.find(value => value[0] === "password")[1]
            if (email != null && password != null) {
                setPasswordText(password)
                setUsernameText(email)
                setRemember(true)
            }
        })
    }, []);

    return (
        <FadeView
            style={{alignSelf: "stretch", zIndex: visible ? 1 : 0}}
            visible={visible}
            duration={animationDuration}
            useAnimation={useAnimation}
        >
            <StyledEditText
                ref={usernameFieldRef}
                value={usernameText}
                borderRadius={200}
                placeholder={"Email Address"}
                onChangeText={(text) => {
                    setUsernameText(text)
                }}
                onSubmitEditing={() => {
                    tryLogin(usernameText, passwordText, remember)
                }}
            />
            <StyledEditText
                ref={passwordFieldRef}
                value={passwordText}
                borderRadius={200}
                placeholder={"Password"}
                onChangeText={(text) => {
                    setPasswordText(text)
                }}
                onSubmitEditing={() => {
                    tryLogin(usernameText, passwordText, remember)
                }}
                isSecure={true}
            />
            <TouchableHighlight
                style={{marginBottom: 30}}
                underlayColor={"transparent"}
                onPress={(e) => {
                    setRemember(!remember)
                }}
            >
                <View style={styles.checkbox}>
                    <Image
                        style={{width: 18, height: 18}}
                        source={require(`./../../assets/icons/checkbox/${remember ? "checked.png" : "unchecked.png"}`)}/>
                    <Text style={[{
                        textAlign: "center",
                        textAlignVertical: "center",
                        margin: 5
                    }, styles.pinkText]}>Remember me</Text>
                </View>
            </TouchableHighlight>
            <Button
                title={"Login"}
                color={colors.lightYellow}
                buttonStyle={{borderRadius: 100, paddingVertical: 15}}
                containerStyle={{width: "100%"}}
                titleStyle={{color: colors.darkGray, fontWeight: "700", fontSize: 20}}
                onPress={() => {
                    tryLogin(usernameText, passwordText, remember)
                }}
            />
            <Text
                style={[styles.pinkText, styles.forgotPass]}
                onPress={() => {
                    onForgotPass(usernameText)
                }}>Forgot password?</Text>
        </FadeView>)
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        alignSelf: "stretch",
        backgroundColor: "white",
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: 20,
        maxWidth: 470,
    },
    footerImage: {
        alignSelf: "stretch",
        flex: 5,
    },
    pinkText: {
        alignSelf: "stretch",
        color: colors.navyBlue,
        fontSize: 16,
        fontWeight: "700",
        fontFamily: "Lato"
    },
    forgotPass: {
        marginTop: 15,
        textAlign: "right",
    },
    logo: {
        height: 130,
        width: 130,
    },
    title: {
        color: colors.darkGrey,
        fontFamily: "Lato",
        fontSize: 30,
        fontWeight: "700",
        marginBottom: 70,
    },
    centeredText: {
        alignSelf: "center",
    },
    checkbox: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignItems: "center",
    },
});
