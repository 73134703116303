import React, {useEffect, useRef, useState} from "react";
import {Image, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colors} from "../../../colors";
import Space from "../../Space";
import ScreenHeader from "../../ScreenHeader";
import {format, getYear} from "date-fns";
import Footer from "../../Footer";
import FadeView from "../../FadeView";
import StyledEditText from "../../employee_screens/components/StyledEditText";
import CustomButton from "../../employee_screens/components/CustomButton";
import TitleView from "../../employee_screens/components/TitleView";
import HttpRequests from "../../../http_requests/HttpRequests";
import ProfileData from "../../../misc/ProfileData";
import {fontStyles} from "../../../fontStyles";
import DatePickerView from "../../DatePickerView";
import ModalDatePicker from "../../ModalDatePicker";
import StyledDropDown from "../../StyledDropDown";
import LoadingMessage, {AlertMessage} from "../../DefaultMessages";
import NewUserContent from "./NewUserContent";
import HomeContent from "./HomeContent";
import AwesomeAlert from "react-native-awesome-alerts";
import Helpers from "../../../misc/Helpers";
import IntentMessenger from "../../../misc/IntentMessenger";
import {EmployerMainScreenRouteNames} from "../EmployerMainScreen";

export const employeesUpdatedTag = "employees updated";

function EmployeesTab({navigation}) {
    const [showLoadingMessage, setShowLoadingMessage] = useState(false)
    const [userToChangeStatus, setUserToChangeStatus] = useState(null)
    const [showContractDatePicker, setShowContractDatePicker] = useState(false)
    const [contractDate, setContractDate] = useState(new Date())
    const [showBirthDatePicker, setShowBirthDatePicker] = useState(false)
    const [birthDate, setBirthDate] = useState(new Date())
    const [employeesArray, setEmployeesArray] = useState([])
    const [alertMessageText, setAlertMessageText] = useState("")
    const [alertMessageTitle, setAlertMessageTitle] = useState("")
    const [showMessage, setShowMessage] = useState(false)

    function updateEmployeesArray() {
        setShowLoadingMessage(true)
        HttpRequests.getStaffListFull().then((result) => {
            console.log(result)
            if (result.success) {
                const newEmployeesArray = result.extras.data.map((item, index) => {
                    console.log(new Date(item.age))
                    return [
                        item.user_id,
                        item.name,
                        item.position,
                        item.age,
                        item.gender,
                        item.salary,
                        item.salary_type === ProfileData.SalaryTypes.Monthly ? "Monthly" : "Hourly",
                        item.status === 0 ? "Current" : "Past",
                        "",
                        "",
                        "",
                        item.job_desc,
                        item.total_monthly_hours,
                        item.email,
                        item.position_id,
                        item.contract_percent,
                        item.social_num,
                        item.cleaning_num,
                        item.account_num,
                        item.contact_num,
                        item.family_member_name,
                        item.family_member_contact
                    ]
                })
                console.log(newEmployeesArray)
                setTimeout(() => {
                    setShowLoadingMessage(false)
                }, 20)
                setEmployeesArray(newEmployeesArray)
            } else {
                setShowLoadingMessage(false)
                if (result.message !== "No Data Available!") {
                    setAlertMessageTitle("Error")
                    setAlertMessageText(result.message)
                    setShowMessage(true)
                }
            }
        })
    }

    useEffect(() => {
        updateEmployeesArray()

    }, []);

    // useEffect(() => {
    //     setFilteredEmployees(employeesArray)
    // }, [employeesArray]);


    const destinationNames = {
        home: "home", newUser: "new user"
    }

    const animationDuration = 200

    const [filteredEmployees, setFilteredEmployees] = useState(employeesArray)
    const [selectedType, setSelectedType] = useState(0)
    const [searchQuery, setSearchQuery] = useState("")
    const [userToEdit, setUserToEdit] = useState(null)

    useEffect(() => {
        console.log(employeesArray)
        let employeeTypeFilter = ""
        switch (selectedType) {
            case 0:
                employeeTypeFilter = ""
                break
            case 1:
                employeeTypeFilter = "Current"
                break
            default:
                employeeTypeFilter = "Past"
        }

        setFilteredEmployees(employeesArray.filter((value, index, array) => {
            const satisfyQuery =
                value[0].toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
                value[1].toString().toLowerCase().includes(searchQuery.toLowerCase())
            const satisfyType = value[7] === undefined || value[7].toString().includes(employeeTypeFilter)
            return satisfyType && satisfyQuery
        }))
    }, [selectedType, searchQuery, employeesArray]);


    const [transition, setTransition] = useState(false)
    const [destination, setDestination] = useState(destinationNames.home)

    const [showAnimation, setShowAnimation] = useState(false)

    function changeDestination(newDestination) {
        setTransition(true)
        setShowAnimation(true)
        setTimeout(() => {
            setDestination(newDestination)
            setTransition(false)
            setTimeout(() => {
                setShowAnimation(false)
            }, animationDuration)
        }, animationDuration)
    }

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation}>
                {/*<Text style={fontStyles.lato700_20}>{format(new Date(), 'MMMM yyyy')}</Text>*/}
            </ScreenHeader>
            {destination === destinationNames.home &&
                <HomeContent visible={!transition} useAnimation={showAnimation} animationDuration={animationDuration}
                             onSearchQueryChange={setSearchQuery} searchQuery={searchQuery}
                             onSelectedTypeChange={setSelectedType} selectedType={selectedType}
                             employees={filteredEmployees}
                             onChangeDestination={() => {
                                 setUserToEdit(null)
                                 setBirthDate(new Date())
                                 changeDestination(destinationNames.newUser)
                             }}
                             onUserReportClick={() => {
                                 navigation.jumpTo(EmployerMainScreenRouteNames.employeeReport)
                             }}
                             onUserTimeTableClick={() => {
                                 navigation.jumpTo("Posting")
                             }}
                             onChangeStatus={(id) => {
                                 setUserToChangeStatus(id)
                             }}
                             onEditClick={(id) => {
                                 setBirthDate(new Date(Date.parse(employeesArray.find(value => value[0] === id)[3])))
                                 // setBirthDate(new Date(Date.parse()))
                                 setUserToEdit(employeesArray.find(value => value[0] === id))
                                 changeDestination(destinationNames.newUser)
                             }}
                />
            }
            {destination === destinationNames.newUser &&
                <NewUserContent
                    visible={!transition} useAnimation={showAnimation} animationDuration={animationDuration}
                    contractDate={contractDate} birthDate={birthDate}
                    user={userToEdit}
                    onSubmit={(name, email, position, salary, salaryType, contractVariable, contractFile, jobDesc, id) => {
                        IntentMessenger.setMessage(employeesUpdatedTag, true)
                        setShowLoadingMessage(true)
                        if (id === null) {
                            HttpRequests.addUser(
                                name,
                                email,
                                position,
                                `${salary}`.replace(" ", ""),
                                salaryType,
                                contractVariable,
                                Helpers.formatDate(birthDate),
                                contractDate.getTime(),
                                contractFile,
                                jobDesc,
                                1).then(result => {
                                setTimeout(() => setShowLoadingMessage(false), 20)
                                console.log(result)
                                if (result.success) {
                                    updateEmployeesArray()
                                    changeDestination(destinationNames.home)
                                } else {
                                    setAlertMessageTitle("Error")
                                    setAlertMessageText(result.message)
                                    setShowMessage(true)
                                }
                            })
                        } else {
                            console.log(salary)
                            HttpRequests.editUser(id, name, email, position, `${salary}`.replace(" ", ""),
                                salaryType, contractVariable, Helpers.formatDate(birthDate), jobDesc, 1)
                                .then(result => {
                                    setTimeout(() => setShowLoadingMessage(false), 20)
                                    console.log(result)
                                    if (result.success) {
                                        updateEmployeesArray()
                                        changeDestination(destinationNames.home)
                                    } else {
                                        setAlertMessageTitle("Error")
                                        setAlertMessageText(result.message)
                                        setShowMessage(true)
                                    }
                                })
                        }
                    }}
                    onCancel={() => {
                        changeDestination(destinationNames.home)
                    }}
                    onShowCalendarClick={(isContract) => {
                        if (isContract) {
                            setShowContractDatePicker(true)
                        } else {
                            setShowBirthDatePicker(true)
                        }
                    }}
                />
            }
            <Footer/>
            <ModalDatePicker
                open={showContractDatePicker}
                currentDate={contractDate}
                onCancel={() => {
                    setShowContractDatePicker(false)
                }}
                onConfirm={(date) => {
                    setShowContractDatePicker(false)
                    setContractDate(date)
                }}
            />
            <ModalDatePicker
                open={showBirthDatePicker}
                currentDate={birthDate}
                onCancel={() => {
                    setShowBirthDatePicker(false)
                }}
                onConfirm={(date) => {
                    setShowBirthDatePicker(false)
                    setBirthDate(date)
                }}
                startYear={getYear(new Date()) - 100}
            />
            <LoadingMessage showLoadingMessage={showLoadingMessage}/>
            <AlertMessage message={alertMessageText} title={alertMessageTitle} showMessage={showMessage}
                          onConfirm={() => setShowMessage(false)}/>
            <AwesomeAlert
                show={userToChangeStatus != null}
                title={"Are You Sure"}
                message={`Are you sure you want to change this employee status to ${
                    employeesArray.find(value => value[0] === userToChangeStatus) !== undefined ?
                        employeesArray.find(value => value[0] === userToChangeStatus)[7] === "Current" ? "'Past'" : "'Current'"
                        : ""}?`}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                useNativeDriver={true}
                showConfirmButton={true}
                onConfirmPressed={() => {
                    const employeeIndex = employeesArray.findIndex(value => value[0] === userToChangeStatus)
                    console.log(employeesArray[employeeIndex])
                    setUserToChangeStatus(null)
                    setShowLoadingMessage(true)
                    if (employeeIndex !== -1) {
                        const employee = employeesArray[employeeIndex]
                        HttpRequests.changeStaffStatus(employee[0], employee[7] === "Current" ? 1 : 0)
                            .then(result => {
                                if (result.success) {
                                    employeesArray[employeeIndex][7] = employee[7] === "Current" ? "Past" : "Current"
                                }
                                setTimeout(() => setShowLoadingMessage(false), 20)
                                console.log(result)
                            })
                    }
                }}
                showCancelButton={true}
                onCancelPressed={() => {
                    setUserToChangeStatus(null)
                }}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
})

export default EmployeesTab
