import React, {useEffect, useRef, useState} from "react";
import {addDays, format, getDaysInMonth, getDaysInYear, getMonth, getYear, setDate} from "date-fns";
import {Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colors} from "../../../colors";
import Space from "../../Space";
import {Area, Chart, HorizontalAxis, Line, VerticalAxis} from "react-native-responsive-linechart";
import {fontStyles} from "../../../fontStyles";
import StyledDropDown from "../../StyledDropDown";
import CustomButton from "../../employee_screens/components/CustomButton";
import Helpers from "../../../misc/Helpers";
import DefaultValues from "../../../misc/DefaultValues";

export default function SingleEmployeeDailyReports(
    {
        startDate,
        endDate,
        reportValues,
        employees,
        selectedEmployee,
        onEmployeeChanged,
        onMonthlyClicked,
        onOverallClicked
    }) {

    function getTitle(startDate, endDate) {
        const rangeType = Helpers.getRangeType(startDate, endDate)
        if (rangeType === Helpers.rangeTypes.month) {
            return `${format(startDate, "MMMM yyyy")} daily report`
        } else if (rangeType === Helpers.rangeTypes.year) {
            return `${format(startDate, "yyyy")} daily report`
        } else {
            return "Custom daily report"
        }
    }

    function SearchBar({items, onChange, onBackPressed}) {
        return (
            <View style={{flex: 1, flexDirection: "row", maxHeight: 40}}>
                <StyledDropDown
                    search={true}
                    labelField={"name"} valueField={"id"}
                    items={items} placeHolder={"Search employee (ID or Name)"}
                    onChange={onChange}/>
                <Space width={50}/>
                <CustomButton text={"Overall Report"} style={{maxWidth: 150}} onPress={onBackPressed}/>
            </View>
        )
    }

    function EmployeeDetails({selectedEmployee}) {

        const [employeeId, setEmployeeId] = useState(-1)
        const [employeeName, setEmployeeName] = useState("Employee Name")
        const [employeePosition, setEmployeePosition] = useState("Employee Position")
        const [employeeEmail, setEmployeeEmail] = useState("Employee Email")

        useEffect(() => {
            console.log(selectedEmployee)
            if (selectedEmployee != null) {
                setEmployeeId(selectedEmployee.id)
                setEmployeeName(selectedEmployee.name)
                setEmployeePosition(selectedEmployee.position)
                setEmployeeEmail(selectedEmployee.email)
            }
        }, []);

        return (
            <View style={{
                width: 370,
                height: 170,
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 20,
                borderWidth: 1,
                borderColor: colors.navyBlue
            }}>
                <Space width={10}/>
                <View style={{flex: 1, alignItems: "center"}}>
                    <Image style={{
                        width: 100,
                        height: 100,
                        borderRadius: 50,
                        borderWidth: 1,
                        borderColor: colors.navyBlue,
                        marginHorizontal: 20
                    }}
                           source={require("./../../../assets/employee_image.png")}/>
                    <Space height={5}/>
                    <Text style={{
                        ...fontStyles.lato400_12,
                        padding: 5,
                        backgroundColor: colors.lightGreen,
                        color: "white",
                        borderRadius: 20,
                        lineHeight: 14
                    }}>Current employee</Text>
                </View>
                <Space width={30}/>
                <View style={{flex: 2}}>
                    <Text style={{
                        ...fontStyles.lato700_14,
                        lineHeight: 24
                    }}>{employeeName}</Text>
                    <Text style={{
                        ...fontStyles.lato700_14,
                        lineHeight: 24
                    }}>{employeePosition}</Text>
                    <View style={{flexDirection: "row"}}>
                        <Text style={{
                            ...fontStyles.lato700_16,
                            color: colors.mediumGray,
                            lineHeight: 19
                        }}>ID: </Text>
                        <Text style={{
                            ...fontStyles.lato700_16,
                            lineHeight: 19
                        }}>{employeeId}</Text>

                    </View>
                    <Text style={{
                        ...fontStyles.lato700_16,
                        lineHeight: 19
                    }}>{employeeEmail}</Text>

                </View>
                <Space width={10}/>
            </View>
        )
    }

    function EmployeeCosts({values}) {
        const [costs, setCosts] = useState([
            {title: "Normal Working:", value: 0},
            {title: "Overtime 1:", value: 0},
            {title: "Overtime 2:", value: 0},
            {title: "Overtime 3:", value: 0},
            {title: "Sick:", value: 0},
            {title: "Sick OB1:", value: 0},
            {title: "Sick OB2:", value: 0},
            {title: "Sick OB3:", value: 0},
        ])
        useEffect(() => {
            if (values.length > 0) {
                const normalSum = values.map(item => item.normal * item.salary).reduce((prev, current) => prev + current)
                const ob1Sum = values.map(item => item.ob1).reduce((prev, current) => prev + current)
                const ob2Sum = values.map(item => item.ob2).reduce((prev, current) => prev + current)
                const ob3Sum = values.map(item => item.ob3).reduce((prev, current) => prev + current)
                const sickSum = values.map(item => item.sick * item.salary).reduce((prev, current) => current + prev)
                const obs1Sum = values.map(item => item.obs1).reduce((prev, current) => prev + current)
                const obs2Sum = values.map(item => item.obs2).reduce((prev, current) => prev + current)
                const obs3Sum = values.map(item => item.obs3).reduce((prev, current) => prev + current)
                setCosts([
                    {title: "Normal Working:", value: normalSum},
                    {title: "Overtime 1:", value: ob1Sum},
                    {title: "Overtime 2:", value: ob2Sum},
                    {title: "Overtime 3:", value: ob3Sum},
                    {title: "Sick:", value: sickSum},
                    {title: "Sick OB1:", value: obs1Sum},
                    {title: "Sick OB2:", value: obs2Sum},
                    {title: "Sick OB3:", value: obs3Sum},
                ])
            }
        }, []);

        function CostItem({title, value}) {
            return <View style={{flexDirection: "row", margin: 4}}>
                <Text style={{
                    ...fontStyles.lato700_10,
                    color: "black"
                }}>{title}</Text>
                <Space flex={1}/>
                <Text style={{
                    ...fontStyles.lato700_10,
                    color: colors.navyBlue,
                }}>{value}</Text>
            </View>
        }

        return (
            <View style={{flex: 1, maxHeight: 370}}>
                <View style={{
                    flex: 1,
                    maxHeight: 25,
                    borderRadius: 15,
                    backgroundColor: colors.navyBlue,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row"
                }}>
                    <Text style={{
                        ...fontStyles.lato700_10,
                        color: "white"
                    }}>Total Cost: </Text>
                    <Text style={{
                        ...fontStyles.lato700_14,
                        color: "white"
                    }}>{`$${costs.map(item => item.value).reduce((previousValue, currentValue) => previousValue + currentValue)}`}</Text>
                </View>
                {costs.map((item, index) => {
                    return <CostItem title={item.title} value={item.value}/>
                })}
            </View>
        )
    }

    function Charts({values}) {
        if (values.length === 0) {
            return (
                <View style={{maxHeight: 230, flex: 2, alignItems: "center", justifyContent: "center"}}>
                    <Text style={fontStyles.lato700_14}>No working item added for this month</Text>
                </View>)
        }
        const monthDaysCount = getDaysInMonth(Date.parse(values[0].date))
        const [normalWorkValues, setNormalWorkValues] = useState([])
        const [ob1Values, setOb1Values] = useState([])
        const [ob2Values, setOb2Values] = useState([])
        const [ob3Values, setOb3Values] = useState([])
        const [highestValue, setHighestValue] = useState(0)
        useEffect(() => {
            let currentDate = setDate(Date.parse(values[0].date), 1)
            let tempNormals = []
            let tempOb1 = []
            let tempOb2 = []
            let tempOb3 = []
            for (let i = 1; i <= monthDaysCount; i++) {
                const dateItem = values.find(value => value.date === format(currentDate, "yyyy-MM-dd"))
                const normalStep = dateItem !== undefined ? dateItem.normal : 0
                const ob1Step = dateItem !== undefined ? dateItem.ob1 : 0
                const ob2Step = dateItem !== undefined ? dateItem.ob2 : 0
                const ob3Step = dateItem !== undefined ? dateItem.ob3 : 0
                tempNormals = ([...tempNormals,
                    {x: i, y: normalStep + (i === 1 ? 0 : tempNormals[tempNormals.length - 1].y)}])
                tempOb1 = ([...tempOb1, {x: i, y: ob1Step + (i === 1 ? 0 : tempOb1[tempOb1.length - 1].y)}])
                tempOb2 = ([...tempOb2, {x: i, y: ob2Step + (i === 1 ? 0 : tempOb2[tempOb2.length - 1].y)}])
                tempOb3 = ([...tempOb3, {x: i, y: ob3Step + (i === 1 ? 0 : tempOb3[tempOb3.length - 1].y)}])
                currentDate = addDays(currentDate, 1)
            }
            setNormalWorkValues(tempNormals)
            setOb1Values(tempOb1)
            setOb2Values(tempOb2)
            setOb3Values(tempOb3)
            setHighestValue(Math.max(
                ...tempNormals.map(item => item.y),
                ...tempOb1.map(item => item.y),
                ...tempOb2.map(item => item.y),
                ...tempOb3.map(item => item.y),
            ))
        }, []);

        function CustomLine({data, color}) {
            return <Line data={data}
                         theme={{
                             stroke: {color: color, width: 3},
                             // scatter: {default: {width: 4, height: 4, rx: 2}}
                         }}/>
        }

        function LegendItem({text, color}) {
            return (
                <View style={{flexDirection: "row"}}>
                    <Image style={{width: 15, height: 15, borderRadius: 15, backgroundColor: color}}/>
                    <Space width={10}/>
                    <Text style={fontStyles.lato700_14}>{text}</Text>
                </View>)
        }

        return (
            <View style={{maxHeight: 230, flex: 2}}>
                <Chart
                    style={{maxHeight: 230, flex: 1}}
                    padding={{left: 40, bottom: 20, right: 20, top: 20}}
                    xDomain={{min: 1, max: monthDaysCount}}
                    yDomain={{min: 0, max: Math.floor(highestValue / 10) * 10 + 10}}
                >
                    <VerticalAxis tickCount={11} theme={{labels: {formatter: (v) => v.toFixed(2)}}}/>
                    <HorizontalAxis tickCount={monthDaysCount}/>
                    <CustomLine data={normalWorkValues} color={'#ffa502'}/>
                    <CustomLine data={ob1Values} color={'#abff02'}/>
                    <CustomLine data={ob2Values} color={'#02ff78'}/>
                    <CustomLine data={ob3Values} color={'#02d1ff'}/>
                </Chart>
                <View style={{
                    flex: 1,
                    flexDirection: "row",
                    maxHeight: 40,
                    alignItems: "center",
                    justifyContent: "space-around"
                }}>
                    <LegendItem text={"Normal"} color={'#ffa502'}/>
                    <LegendItem text={"OB1"} color={'#abff02'}/>
                    <LegendItem text={"OB2"} color={'#02ff78'}/>
                    <LegendItem text={"OB3"} color={'#02d1ff'}/>
                </View>
            </View>)
    }

    function ReportTable({reportValues}) {

        function TableRow({rowValues, color, textStyle}) {
            function TableCell({value, textStyle}) {
                return (
                    <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                        <Text style={textStyle}>{value}</Text>
                    </View>)
            }

            return (
                <View style={{
                    maxHeight: 45,
                    minHeight: 45,
                    flex: 1,
                    backgroundColor: color,
                    flexDirection: "row",
                }}>
                    {rowValues.map(item => {
                        return <TableCell value={item} textStyle={textStyle}/>
                    })}
                </View>
            )

        }

        return (
            <View style={{flex: 1}}>
                <TableRow
                    textStyle={fontStyles.lato700_16}
                    color={colors.headerBlue}
                    rowValues={["Day", "Date", "Budget", "TIM", "OB1", "OB2", "OB3", "Sick", "S-OB1", "S-OB2", "S-OB3"]}
                />
                <ScrollView style={{flex: 1}}>
                    {reportValues.map((item, index) => {
                        const date = Date.parse(item.date)
                        const dayName = format(date, "EEEE")
                        const formattedDate = format(date, "dd MMM yyyy")

                        const values = [dayName, formattedDate, item.budget, item.normal, item.ob1, item.ob2, item.ob3, item.sick, item.obs1, item.obs2, item.obs3,]
                        return (
                            <TableRow
                                textStyle={fontStyles.lato400_11} rowValues={values}
                                color={index % 2 === 0 ? colors.tableRow : "white"}
                            />
                        )
                    })}
                </ScrollView>
            </View>
        )
    }

    return (
        <View style={{...styles.fillingColumn}}>
            {/*search bar*/}
            <SearchBar items={employees}
                       onChange={onEmployeeChanged}
                       onBackPressed={onOverallClicked}/>
            <Space height={25}/>
            <View style={{flex: 1, maxHeight: 250, flexDirection: "row"}}>
                {/*employee details*/}
                <EmployeeDetails selectedEmployee={selectedEmployee}/>
                <Space width={50}/>
                {/*employee costs*/}
                <EmployeeCosts values={reportValues}/>
                <Space width={50}/>
                {/*charts*/}
                <Charts values={reportValues}/>
            </View>
            <View style={{flex: 1}}>
                <View style={{flex: 1, flexDirection: "row", maxHeight: 40, alignItems: "center"}}>
                    <Text style={{...fontStyles.lato700_20, lineHeight: 24}}>{getTitle(startDate, endDate)}</Text>
                    <Space flex={1}/>
                    <CustomButton style={{maxWidth: 150}} text={"Monthly"} onPress={onMonthlyClicked}/>
                </View>
                <Space height={10}/>
                {/*daily report table*/}
                <ReportTable reportValues={reportValues}/>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingTop: 40,
        paddingHorizontal: 20,
    },
    fillingRow: {
        flex: 1, flexDirection: "row"
    },
    fillingColumn: {
        flex: 1, flexDirection: "column"
    },
    centerMainAxis: {
        alignItems: "center"
    },
    centerCrossAxis: {
        justifyContent: "center"
    },
    center: {
        justifyContent: "center", alignItems: "center"
    }
})
