import {Text, TouchableOpacity} from "react-native";
import {colors} from "../../../colors";


function CustomButton({onPress, flex, text, style, fontSize, color = undefined, disabledColor = undefined}) {
    let enabled = onPress !== null
    const buttonFillColor = enabled ?
        (color === undefined ? colors.lightYellow : color) :
        (disabledColor === undefined ? colors.mediumGray : disabledColor)
    return <TouchableOpacity
        disabled={!enabled}
        onPress={onPress}
        style={[{
            flex: flex ? flex : 10,
            paddingHorizontal: 15,
            height: 40,
            maxHeight: 40,
            minHeight: 40,
            borderRadius: 20,
            backgroundColor: buttonFillColor,
            alignContent: "center",
            justifyContent: "space-around"
        }, style]}>
        <Text style={{
            fontFamily: "LatoRegular",
            fontWeight: "700",
            fontSize: fontSize ? fontSize : 16,
            alignSelf: "center"
        }}>{text}</Text>
    </TouchableOpacity>;
}

export default CustomButton

// const colors = {
//     mediumGray: "#AAAAAA",
//     lightCyan: "#61DAFB"
// }
