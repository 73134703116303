import {Text, View} from "react-native";
import {colors} from "../../../colors";

function TitleView({titleText, style}) {
    return (
        <View style={{
            maxHeight: 40,
            minHeight: 40,
            flex: 1,
            alignItems: "center",
            alignContent: "center",
            justifyContent: "space-around",
            backgroundColor: colors.navyBlue,
            borderRadius: 20,
            marginBottom: 30,
            ...style
        }}>
            <Text style={{
                fontFamily: "Lato",
                fontWeight: "700",
                fontSize: 20,
                color: "white"
            }}>{titleText}</Text>
        </View>
    )
}

export default TitleView
