import {format, getYear} from "date-fns";
import Helpers from "./Helpers";

export default class SwedishHolidays {
    /*
    Måndag 1 januari - Nyårsdagen
    Lördag 6 januari - Trettondag jul
    Fredag 29 mars - Långfredagen
    Söndag 31 mars - Påskdagen
    Måndag 1 april – Annandag påsk
    Onsdag 1 maj - Arbetarrörelsens dag
    Torsdag 9 maj - Kristi Himmelsfärd
    Söndag 19 maj - Pingstdagen
    Torsdag 6 juni - Sveriges Nationaldag
    Lördag 22 juni – Midsommar
    Lördag – Alla helgons dag
    Tisdag 24 december - Julafton (inte en röd dag men vi kastar in den här ändå)
    Onsdag 25 december - Juldagen
    Torsdag 26 december - Annandag jul
    Tisdag 31 december - Nyårsafton (inte heller en röd dag, men ledig är du!)
    * */
    static getHolidays(year: number) {
        return [
            new Holiday(year, 1, 1, "Nyårsdagen"),
            new Holiday(year, 1, 6, "Trettondag jul"),
            new Holiday(year, 2, 29, "Långfredagen"),
            new Holiday(year, 2, 31, "Påskdagen"),
            new Holiday(year, 4, 1, "Annandag påsk"),
            new Holiday(year, 5, 1, "Arbetarrörelsens dag"),
            new Holiday(year, 5, 9, "Kristi Himmelsfärd"),
            new Holiday(year, 5, 19, "Pingstdagen"),
            new Holiday(year, 6, 6, "Sveriges Nationaldag"),
            new Holiday(year, 6, 22, "Midsommar"),
            new Holiday(year, 12, 24, "Julafton"),
            new Holiday(year, 12, 25, "Juldagen"),
            new Holiday(year, 12, 26, "Annandag jul"),
            new Holiday(year, 12, 31, "Nyårsafton"),
        ]
    }

    static isHoliday(date: Date) {
        return SwedishHolidays.getHolidays(getYear(date)).some(value => Helpers.formatDate(value.date) === Helpers.formatDate(date))
            || format(date, "EEEE").toLowerCase().includes("sun")
    }
}

class Holiday {
    date: Date
    name: string
    day: number
    month: number
    year: number

    constructor(year: number, month: number, day: number, name: string) {
        this.day = day
        this.month = month
        this.year = year
        this.name = name
        this.date = new Date(year, month - 1, day)
    }
}
