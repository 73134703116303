import ProfileData from "../misc/ProfileData";
import FileSaver from "file-saver";

export default class HttpRequests {
    static requestPrefix = "https://app.avvikelseanalys.com/api/"

    constructor() {

    }

    static async uploadContract(file) {
        let photo = {uri: file.uri, name: file.name, type: file.type}
        let formData = new FormData()
        formData.append("contract", photo)

        const response = await fetch(`${this.requestPrefix}upload_contract`, {
            method: 'POST',
            headers: {
                // "Accept": "*/*",
                'Content-Type': 'multipart/form-data',
                "Authorization": ProfileData.accessToken
            },
            body: formData,
        });
        const body = (response.body)
        const reader = body.getReader()

        return await this.analyseResponse(response, "contract")
    }

    static async login(email, password) {
        const response = await fetch(`${this.requestPrefix}login`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        return await this.analyseResponse(response, "login")
    }

    static async addUser(name, email, position, salary, salaryType, contractVariable, birthDate, contractDate, contractFile, jobDesc, accountType) {
        let body = {
            name: name,
            email: email,
            birthday: birthDate,
            position: position,
            salary: salary,
            salary_type: salaryType,
            job_desc: jobDesc,
            account_type: accountType
        }
        body[salaryType === ProfileData.SalaryTypes.Hourly ? "total_monthly_hours" : "contract_percent"] = contractVariable
        console.log(body)
        const response = await fetch(`${this.requestPrefix}add_user`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await this.analyseResponse(response, "add_user")
    }

    static async editUser(id, name, email, position, salary, salaryType, monthlyHours, birthDate, jobDesc, accountType) {
        let body = {
            user_id: id,
            name: name,
            email: email,
            birthday: birthDate,
            position: position,
            salary: salary,
            salary_type: salaryType,
            job_desc: jobDesc,
            account_type: accountType
        }
        body[salaryType === 1 ? "total_monthly_hours" : "contract_percent"] = monthlyHours
        console.log(body)
        const response = await fetch(`${this.requestPrefix}edit_user`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify(body),
        });

        return await this.analyseResponse(response, "edit_user")
    }

    static async verifyAccount(email, code) {
        const response = await fetch(`${this.requestPrefix}verify_account`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                code: code,
            }),
        });

        return await this.analyseResponse(response, "verify_account")
    }

    static async sendVerificationCode(email) {
        const response = await fetch(`${this.requestPrefix}send_verification_code`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
            }),
        });

        return await this.analyseResponse(response, "send_verification_code")
    }

    static async checkVerificationCode(email, code) {
        console.log(JSON.stringify({
            email: email,
            code: code
        }))
        const response = await fetch(`${this.requestPrefix}check_code`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                code: code
            }),
        });

        return await this.analyseResponse(response, "check_verification_code")
    }

    static async setNewPass(email, password) {
        console.log(
            JSON.stringify({
                email: email,
                password: password,
            }))
        const response = await fetch(`${this.requestPrefix}set_new_password`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        return await this.analyseResponse(response, "set_new_password")
    }

    static async editProfile(userId, socialNumber, cleaningNumber, age, accountNum, contractNumber, gender, familyMemberName, familyMemberContact) {
        const response = await fetch(`${this.requestPrefix}edit_staff_profile`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: userId, // (Required)
                social_num: socialNumber,
                cleaning_num: cleaningNumber,
                age: age, // birthdate
                account_num: accountNum,
                contact_num: contractNumber, // must be 12 digits
                gender: gender, // 0 for male and 1 for female
                family_member_name: familyMemberName,
                family_member_contact: familyMemberContact
            }),
        });

        return await this.analyseResponse(response, "edit_staff_profile")
    }

    static async newAccessToken(refreshToken) {
        const response = await fetch(`${this.requestPrefix}refresh_token`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                refreshToken: refreshToken
            }),
        });

        return await this.analyseResponse(response, "refresh_token")
    }

    static async logout(refreshToken) {
        const response = await fetch(`${this.requestPrefix}logout`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                refresh_token: refreshToken
            }),
        });

        return await this.analyseResponse(response, "logout")
    }

    static async getStaffListFull() {
        const response = await fetch(`${this.requestPrefix}list_of_staff`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
        });

        return await this.analyseResponse(response, "list_of_staff")
    }

    static async changeStaffStatus(userId, status) {
        const response = await fetch(`${this.requestPrefix}change_staff_status`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                status: status
            }),
        });

        return await this.analyseResponse(response, "change_staff_status")
    }

    static async getDefaultValues() {
        const response = await fetch(`${this.requestPrefix}get_taxing_percentages`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({}),
        });

        return await this.analyseResponse(response, "get_taxing_percentages")
    }

    static async setDefaultValues(defaults) {
        const response = await fetch(`${this.requestPrefix}set_taxing_percentages`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                admin_id: ProfileData.userId,
                start_working_time: defaults[0],
                tax1: defaults[1],
                ov1: defaults[2],
                end_working_time: defaults[3],
                tax2: defaults[4],
                ov2: defaults[5],
                holiday: defaults[6],
                tax3: defaults[7],
                ov3: defaults[8],
                total_monthly_hours: 0
            }),
        });

        return await this.analyseResponse(response, "set_taxing_percentages")
    }

    static async addSingleDayEmployeeSuggestionTime(userId = ProfileData.userId, date, startTime, endTime) {
        const response = await fetch(`${this.requestPrefix}add_single_suggest_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                date: date,
                start_time: startTime,
                end_time: endTime
            }),
        });

        return await this.analyseResponse(response, "add_single_suggest_time_table")
    }

    static async addIntervalEmployeeSuggestionTime(userId = ProfileData.userId, startDate, endDate, entries) {
        const response = await fetch(`${this.requestPrefix}add_suggest_time_table_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                start_date: startDate,
                end_date: endDate,
                entries: entries
            }),
        });

        return await this.analyseResponse(response, "add_suggest_time_table_data")
    }

    static async getSingleDayEmployeeSuggestionTime(userId = ProfileData.userId, date) {
        const response = await fetch(`${this.requestPrefix}get_single_day_suggest_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                date: date,
            }),
        });

        return await this.analyseResponse(response, "get_single_day_suggest_time_table")
    }

    static async getIntervalEmployeeSuggestionTime(userId = ProfileData.userId, startDate, endDate) {
        console.log(`${startDate} - ${endDate}`)
        const response = await fetch(`${this.requestPrefix}get_suggest_time_table_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                date_start: startDate,
                date_end: endDate,
            }),
        });

        return await this.analyseResponse(response, "get_suggest_time_table_data")
    }

    static async deleteEmployeeSuggestionTime(suggestionId) {
        const response = await fetch(`${this.requestPrefix}delete_single_suggest_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                id: suggestionId,
            }),
        });

        return await this.analyseResponse(response, "delete_single_suggest_time_table")
    }

    static async getStaffListDigest(status = null) {
        const response = await fetch(`${this.requestPrefix}get_staff_list_name`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                status: status
            }),
        });

        return await this.analyseResponse(response, "get_staff_list_name")
    }

    static async getPostingFilterTitles() {
        const response = await fetch(`${this.requestPrefix}posting_filter_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({}),
        });

        return await this.analyseResponse(response, "posting_filter_data")
    }

    static async addEmployeePost(employeeId, costCenterId, projectId, operationId, activityId, workingStatusId, adminId) {
        const response = await fetch(`${this.requestPrefix}add_post_emp`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                emp_id: employeeId,
                cc_id: costCenterId,
                project_id: projectId,
                op_id: operationId,
                activity_id: activityId,
                ws_id: workingStatusId,
                admin_id: adminId
            }),
        });

        return await this.analyseResponse(response, "add_post_emp")
    }

    static async getPostingEmployees() {
        const response = await fetch(`${this.requestPrefix}get_post_all_emp`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({}),
        });

        return await this.analyseResponse(response, "get_post_all_emp")
    }

    static async deletePostingEmployee(postEmployeeId) {
        const response = await fetch(`${this.requestPrefix}delete_post_emp`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                post_emp_id: postEmployeeId
            }),
        });

        return await this.analyseResponse(response, "delete_post_emp")
    }

    static async editPostingEmployee(postEmployeeId, costCenterId, projectId, operationId, activityId, workingStatusId) {
        console.log(JSON.stringify({
            post_emp_id: postEmployeeId,
            cc_id: costCenterId,
            project_id: projectId,
            op_id: operationId,
            activity_id: activityId,
            ws_id: workingStatusId,
            admin_id: +ProfileData.userId,
        }))
        const response = await fetch(`${this.requestPrefix}edit_post_emp`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                post_emp_id: postEmployeeId,
                cc_id: costCenterId,
                project_id: projectId,
                op_id: operationId,
                activity_id: activityId,
                ws_id: workingStatusId,
                admin_id: +ProfileData.userId,
            }),
        });

        return await this.analyseResponse(response, "edit_post_emp")
    }

    static async getSelectedPostingEmployeeInfo(userId, postEmployeeId, year) {
        const body = JSON.stringify({
            user_id: userId,
            post_emp_id: postEmployeeId,
            year: year
        });
        console.log(body)
        const response = await fetch(`${this.requestPrefix}get_post_emp_details`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: body,
        });

        return await this.analyseResponse(response, "get_post_emp_details")
    }

    static async addPostingPercentToSelectedEmployee(userId, percent, adminId, startDate, endDate, postEmployeeId) {
        const response = await fetch(`${this.requestPrefix}add_post_emp_months`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                post_emp_id: postEmployeeId,
                user_id: userId,
                post_percent: percent,
                admin_id: adminId,
                start_date: startDate,
                end_date: endDate
            }),
        });

        return await this.analyseResponse(response, "add_post_emp_months")
    }

    static async addSingleDayEmployerSuggestionTime(userId, adminId, postEmployeeId, postMonthId, date, startTime, endTime) {
        const response = await fetch(`${this.requestPrefix}add_suggest_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                admin_id: adminId,
                post_emp_id: postEmployeeId,
                post_month_id: postMonthId,
                date: date,
                start_time: startTime,
                end_time: endTime
            }),
        });

        return await this.analyseResponse(response, "add_suggest_time_table")
    }

    static async getIntervalEmployerSuggestionTime(userId, startDate, endDate) {
        const response = await fetch(`${this.requestPrefix}get_time_table_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                date_start: startDate,
                date_end: endDate
            }),
        });

        return await this.analyseResponse(response, "get_time_table_data")
    }

    static async addSingleEmployerSuggestionTime(userId, adminId, postEmployeeId, postMonthId, date, startTime, endTime, workingStatus) {
        console.log(`$
            ${userId},
            ${adminId},
            ${postEmployeeId},
            ${postMonthId},
            ${date},
            ${startTime},
            ${endTime},
            ${workingStatus},
        `)
        const response = await fetch(`${this.requestPrefix}add_single_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                admin_id: adminId,
                post_emp_id: postEmployeeId,
                post_month_id: postMonthId,
                date: date,
                start_time: startTime,
                end_time: endTime,
                work_status_id: workingStatus,
            }),
        });

        return await this.analyseResponse(response, "add_single_time_table")
    }

    static async addIntervalEmployerSuggestionTime(userId, adminId, postEmployeeId, postMonthId, startDate, endDate, times, workingStatus, daysToExclude) {
        const body = JSON.stringify({
            user_id: userId,
            admin_id: adminId,
            post_emp_id: postEmployeeId,
            post_month_id: postMonthId,
            start_date: startDate,
            end_date: endDate,
            entries: times,
            work_status_id: workingStatus,
            holidays: daysToExclude
        });
        console.log(body)
        const response = await fetch(`${this.requestPrefix}add_time_table_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: body,
        });

        return await this.analyseResponse(response, "add_time_table_data")
    }

    static async deleteIntervalEmployerSuggestionTime(userId, postEmployeeId, postMonthId, startDate, endDate) {
        const body = JSON.stringify({
            user_id: userId,
            post_emp_id: postEmployeeId,
            post_month_id: postMonthId,
            start_date: startDate,
            end_date: endDate,
        });
        console.log(body)
        const response = await fetch(`${this.requestPrefix}delete_time_table_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: body,
        });

        return await this.analyseResponse(response, "delete_time_table_data")
    }

    static async deleteEmployerSuggestionTime(id) {
        const response = await fetch(`${this.requestPrefix}delete_single_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                id: id
            }),
        });

        return await this.analyseResponse(response, "add_single_time_table")
    }

    static async getTimeTableDataReport(startDate, endDate) {
        const response = await fetch(`${this.requestPrefix}get_time_table_data_report`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                date_start: startDate,
                date_end: endDate
            }),
        });

        return await this.analyseResponse(response, "get_time_table_data_report")
    }

    static async getEmployeeTimeTableData(startDate, endDate, userId = null) {
        const body = JSON.stringify({
            user_id: userId ?? ProfileData.userId,
            date_start: startDate,
            date_end: endDate
        });
        console.log(body)
        const response = await fetch(`${this.requestPrefix}get_submitted_time_table_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: body,
        });

        return await this.analyseResponse(response, "get_submitted_time_table_data")
    }

    static async submitEmployeeTimeTableData(
        id, userId, date, startTime, endTime, postEmployeeId, postMonthId, workStatusId,) {
        console.log(`${JSON.stringify({
            id: id,
            user_id: userId,
            date: date,
            start_time: startTime,
            end_time: endTime,
            post_emp_id: postEmployeeId,
            post_month_id: postMonthId,
            work_status_id: workStatusId,
        })}`)
        const response = await fetch(`${this.requestPrefix}submit_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                id: id,
                user_id: userId,
                date: date,
                start_time: startTime,
                end_time: endTime,
                post_emp_id: postEmployeeId,
                post_month_id: postMonthId,
                work_status_id: workStatusId,
            }),
        });

        return await this.analyseResponse(response, "submit_time_table")
    }

    static async getEmployerOverallReport(startDate, endDate) {
        const response = await fetch(`${this.requestPrefix}get_report`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                date_start: startDate,
                date_end: endDate
            }),
        });

        return await this.analyseResponse(response, "get_report")
    }

    static async getYearlyBudgetReport(startDate) {
        console.log(startDate)
        const response = await fetch(`${this.requestPrefix}get_yearly_budget`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                date: startDate,
            }),
        });

        return await this.analyseResponse(response, "get_yearly_budget")
    }

    static async getYearlyEmployeeCountReport(startDate) {
        console.log(startDate)
        const response = await fetch(`${this.requestPrefix}get_yearly_emp_num`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                date: startDate,
            }),
        });

        return await this.analyseResponse(response, "get_yearly_emp_num")
    }

    static async getEmployerMonthlyReport(startDate, endDate, userId) {
        const response = await fetch(`${this.requestPrefix}get_user_report`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                date_start: startDate,
                date_end: endDate,
                user_id: userId
            }),
        });

        return await this.analyseResponse(response, "get_user_report")
    }

    static async getEmployerDailyReport(startDate, endDate, userId) {
        const response = await fetch(`${this.requestPrefix}get_user_daily_report`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                date_start: startDate,
                date_end: endDate
            }),
        });

        return await this.analyseResponse(response, "get_user_daily_report")
    }

    static async getEmployerDailyReportPageData(startDate, endDate, userId) {
        const response = await fetch(`${this.requestPrefix}get_user_daily_report_page_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                start_date: startDate,
                end_date: endDate
            }),
        });

        return await this.analyseResponse(response, "get_user_daily_report_page_data")
    }

    static async getPositions() {
        const response = await fetch(`${this.requestPrefix}get_positions`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({}),
        });

        return await this.analyseResponse(response, "get_positions")
    }

    static async addPosition(position) {
        const response = await fetch(`${this.requestPrefix}add_position`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                position: position
            }),
        });

        return await this.analyseResponse(response, "add_position")
    }

    static async updateMonthlyHours(data) {
        console.log(JSON.stringify(
            data
        ))
        const response = await fetch(`${this.requestPrefix}update_hours_of_months`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify(
                data
            ),
        });

        return await this.analyseResponse(response, "update_hours_of_months")
    }

    static async getMonthlyHours(year = null) {
        const response = await fetch(`${this.requestPrefix}get_hours_of_months`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                year: year
            }),
        });

        return await this.analyseResponse(response, "get_hours_of_months")
    }

    static async getEmployeeConfirmedTimeTableData(userId, startDate, endDate) {
        const response = await fetch(`${this.requestPrefix}get_confirmed_time_table_data`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: userId,
                date_start: startDate,
                date_end: endDate
            }),
        });

        return await this.analyseResponse(response, "get_confirmed_time_table_data")
    }

    static async confirmEmployeeTimeTable(id, userId, date, startTime, endTime, postEmpId, postMonthId, workStatusId) {
        const body = JSON.stringify([{
            id: id,
            user_id: userId,
            date: date,
            start_time: startTime,
            end_time: endTime,
            post_emp_id: postEmpId,
            post_month_id: postMonthId,
            work_status_id: workStatusId
        }])
        const response = await fetch(`${this.requestPrefix}confirm_emp_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: body,
        });

        return await this.analyseResponse(response, "confirm_emp_time_table")
    }

    static async confirmEmployeeTimeTableMulti(info) {
        const body = JSON.stringify(info)
        console.log(body)
        const response = await fetch(`${this.requestPrefix}confirm_emp_time_table`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: body,
        });

        return await this.analyseResponse(response, "confirm_emp_time_table")
    }

    static async getOverallReportPdf(startDate, endDate) {
        const response = await fetch(`${this.requestPrefix}generate-pdf`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                date_start: startDate,
                date_end: endDate
            }),
        });
        if (response.status === 200) {
            console.log(response.body)
            response.blob().then(blob => {
                FileSaver.saveAs(blob, `overall_report_${startDate}_${endDate}.pdf`);
            })
        } else {
            // handle error
        }
    }

    static async getSingleEmployeeReportPdf(startDate, endDate, employeeId, employeeName) {
        const response = await fetch(`${this.requestPrefix}generate-pdf-emp-report`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                user_id: employeeId,
                date_start: startDate,
                date_end: endDate
            }),
        });
        if (response.status === 200) {
            console.log(response.body)
            response.blob().then(blob => {
                FileSaver.saveAs(blob, `${employeeName}_report_${startDate}_${endDate}.pdf`);
            })
        } else {
            // handle error
        }
    }

    static async getTimingReportPdf(startDate, endDate, weekIndex, year) {
        const response = await fetch(`${this.requestPrefix}generate-pdf-timing-report`, {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": ProfileData.accessToken
            },
            body: JSON.stringify({
                year: year,
                week: weekIndex,
                date_start: startDate,
                date_end: endDate
            }),
        });
        if (response.status === 200) {
            console.log(response.body)
            response.blob().then(blob => {
                FileSaver.saveAs(blob, `timing_report_${startDate}_${endDate}.pdf`);
            })
        } else {
            // handle error
        }
    }


    static async analyseResponse(response, request) {
        const responseJson = await response.json()
        console.log(responseJson)

        switch (responseJson.status) {
            case 2:
                return {
                    success: false,
                    message: responseJson.error
                }
            case 3:
                let result = {
                    success: true,
                    extras: {}
                }
                if (responseJson.message !== undefined) {
                    result.message = responseJson.message.toString()
                }
                switch (request) {
                    case "contract":
                        result.extras = {
                            filename: responseJson.filename.toString()
                        }
                        break
                    case "add_user":
                        result.extras = {
                            id: responseJson.id.toString()
                        }
                        break
                    case "login":
                        result.extras = {
                            access_token: responseJson.access_token.toString(),
                            refresh_token: responseJson.refresh_token.toString(),
                            user_id: responseJson.user_id?.toString(),
                            acc_type: parseInt(responseJson.acc_type.toString()),
                            name: responseJson.name.toString(),
                            position: responseJson.position,
                            contract_date: responseJson.contract_date,
                            salary: responseJson.salary,
                            salary_type: responseJson.salary_type,
                            contract_file: responseJson.contract_file,
                            job_desc: responseJson.job_desc,
                            social_num: responseJson.social_num,
                            cleaning_num: responseJson.cleaning_num,
                            age: responseJson.age,
                            contact_num: responseJson.contact_num,
                            gender: responseJson.gender,
                            family_member_name: responseJson.family_member_name,
                            family_member_contact: responseJson.family_member_contact,
                            total_monthly_hours: responseJson.total_monthly_hours,
                            acc_num: responseJson.acc_num
                        }
                        break
                    case "refresh_token":
                        result.extras = {
                            access_token: responseJson.accessToken.toString(),
                        }
                        break
                    case "list_of_staff":
                        result.extras = {
                            data: responseJson.data,
                        }
                        break
                    case "get_taxing_percentages":
                        result.extras = responseJson.data[0]
                        break
                    case "get_suggest_time_table_data":
                        result.extras = responseJson.data
                        break
                    case "posting_filter_data":
                        let extras = {
                            workingStatus: [{title: null, id: null}],
                            costCenters: [{title: null, id: null}],
                            projects: [{title: null, id: null}],
                            operations: [{title: null, id: null}],
                            activities: [{title: null, id: null}]
                        }
                        const data = responseJson.data
                        data.working_status.forEach((item, index) => {
                            extras.workingStatus.push({id: item.ws_id, title: item.ws_title})
                        })
                        data.cost_centers.forEach((item, index) => {
                            extras.costCenters.push({id: item.cc_id, title: item.cc_title})
                        })
                        data.projects.forEach((item, index) => {
                            extras.projects.push({id: item.project_id, title: item.project_title})
                        })
                        data.operations.forEach((item, index) => {
                            extras.operations.push({id: item.op_id, title: item.op_title})
                        })
                        data.activities.forEach((item, index) => {
                            extras.activities.push({id: item.activity_id, title: item.activity_title})
                        })
                        result.extras = extras
                        break
                    case "get_staff_list_name":
                        result.extras = responseJson.data.map((item, index) => {
                            return {
                                id: item.user_id,
                                name: item.name,
                                position: item.position,
                                email: item.email,
                                salaryType: item.salary_type
                            }
                        })
                        break
                    case "get_post_all_emp":
                        result.extras = responseJson.data.map((item, index) => {
                            return {
                                postId: item.post_emp_id,
                                userId: item.user_id,
                                name: item.name,
                                position: item.position,
                                workingStatus: item.working_status_title,
                                costCenter: item.cost_center_title,
                                project: item.project_title,
                                operation: item.operation_title,
                                activity: item.activity_title,
                                userStatus: item.user_status === 0 ? "Current Employee" : "Past Employee",
                                salaryType: item.salary_type === 0 ? "Monthly" : "Hourly"
                            }
                        })
                        break
                    case "get_post_emp_details":
                        result.extras = responseJson
                        break
                    case "get_time_table_data":
                        result.extras = responseJson.data.map(item => {
                            return {
                                id: item.id,
                                date: item.date,
                                startTime: item.start_time,
                                endTime: item.end_time,
                                postEmployeeId: item.post_emp_id,
                                postMonthId: item.post_month_id,
                                workStatusId: item.work_status_id,
                                workStatusTitle: item.work_status_title
                            }
                        })
                        break
                    case "get_time_table_data_report":
                        result.startTime = responseJson.start_working_time
                        result.endTime = responseJson.end_working_time
                        result.extras = responseJson.data.map(item => {
                            return {
                                id: item.id,
                                userId: item.user_id,
                                name: item.name,
                                date: item.date,
                                startTime: item.start_time,
                                endTime: item.end_time,
                                postEmployeeId: item.post_emp_id,
                                postMonthId: item.post_month_id,
                                workStatusId: item.work_status_id,
                                workStatusTitle: item.work_status_title,
                                position: item.position_title
                            }
                        })
                        break
                    case "get_submitted_time_table_data":
                        result.extras = responseJson.data.map(item => {
                            return {
                                id: item.id,
                                date: item.date,
                                startTime: item.start_time,
                                endTime: item.end_time,
                                postEmployeeId: item.post_emp_id,
                                postMonthId: item.post_month_id,
                                workStatusId: item.work_status_id,
                                workStatusTitle: item.work_status_title,
                                overtime: item.overtime,
                            }
                        })
                        break
                    case "get_report":
                        result.totalEmployees = responseJson.data.total_emp
                        result.minAge = responseJson.data.min_age
                        result.maxAge = responseJson.data.max_age
                        result.avgAge = responseJson.data.avg_age
                        result.normalSalary = responseJson.data.normal_salary
                        result.holiday = responseJson.data.holiday
                        result.overtime = responseJson.data.overtime
                        result.tax = responseJson.data.tax
                        result.normalSalaryResult = responseJson.data.normal_salary_result
                        result.holidayResult = responseJson.data.final_holiday_result
                        result.overtimeResult = responseJson.data.overtime_result
                        result.taxResult = responseJson.data.tax_result
                        result.extras = responseJson.data.working_status.map(item => {
                            return {
                                workingStatusId: item.ws_id,
                                workingStatusTitle: item.title,
                                totalHours: item.total_hours,
                            }
                        })
                        break
                    case "get_user_report":
                        result.salaryType = responseJson.data.salary_type
                        result.salary = responseJson.data.salary
                        result.ob1 = responseJson.data.ob1
                        result.ob2 = responseJson.data.ob2
                        result.ob3 = responseJson.data.ob3
                        result.obs1 = responseJson.data.obs1
                        result.obs2 = responseJson.data.obs2
                        result.obs3 = responseJson.data.obs3
                        result.extras = responseJson.data.data.map(item => {
                            return {
                                workingStatusId: item.ws_id,
                                workingStatusTitle: item.ws_title,
                                recordCount: item.record_count,
                                totalHours: item.total_hours,
                            }
                        })
                        break
                    case "get_yearly_budget":
                        result.extras = responseJson.data.map(item => {
                            return {
                                month: item.month,
                                totalBudget: item.total_budget,
                            }
                        })
                        break
                    case "get_yearly_emp_num":
                        result.extras = responseJson.date.map(item => {
                            return {
                                month: item.month,
                                totalEmployees: item.total_emp,
                            }
                        })
                        break
                    case "get_user_daily_report":
                        result.extras = responseJson.data.map(item => {
                            return {
                                salary: item.salary,
                                date: item.date,
                                normal: item.normal,
                                ob1: item.ob1,
                                ob1Cost: item.ob1_cost,
                                ob2: item.ob2,
                                ob2Cost: item.ob2_cost,
                                ob3: item.ob3,
                                ob3Cost: item.ob3_cost,
                                sick: item.sick,
                                obs1: item.obs1,
                                obs1Cost: item.obs1_cost,
                                obs2: item.obs2,
                                obs2Cost: item.obs2_cost,
                                obs3: item.obs3,
                                obs3Cost: item.obs3_cost,
                                budget: item.budget,
                            }
                        })
                        break
                    case "get_positions":
                        result.extras = responseJson.data.map(item => {
                            return {id: item.id, label: item.position}
                        })
                        break
                    case "get_hours_of_months":
                        result.extras = responseJson.data.map(item => {
                            return {
                                id: item.hom_id,
                                year: item.year,
                                month: item.month,
                                workingHours: item.working_hours,
                            }
                        })
                        break
                    case "get_user_daily_report_page_data":
                        let tableData = responseJson.budgets_data.map(item => {
                            let date = item["date"]
                            let resultsData = responseJson.results_data
                            let resultData = undefined
                            if (resultsData.length > 0) {
                                resultData = resultsData.find((value, index) => value["date"] === date)
                            }
                            if (resultData === undefined) {
                                resultData = {
                                    "salary": null,
                                    "date": null,
                                    "normal": null,
                                    "ob1": null,
                                    "ob1_cost": null,
                                    "ob2": null,
                                    "ob2_cost": null,
                                    "ob3": null,
                                    "ob3_cost": null,
                                    "sick": null,
                                    "obs1": null,
                                    "obs1_cost": null,
                                    "obs2": null,
                                    "obs2_cost": null,
                                    "obs3": null,
                                    "obs3_cost": null,
                                    "result": null,
                                }
                            }
                            return {
                                budgetData: {
                                    salary: item["salary"],
                                    date: item["date"],
                                    normal: item["normal"],
                                    ob1: item["ob1"],
                                    ob1Cost: item["ob1_cost"],
                                    ob2: item["ob2"],
                                    ob2Cost: item["ob2_cost"],
                                    ob3: item["ob3"],
                                    ob3Cost: item["ob3_cost"],
                                    budget: item["budget"],
                                },
                                resultsData: {
                                    salary: resultData["salary"],
                                    date: resultData["date"],
                                    normal: resultData["normal"],
                                    ob1: resultData["ob1"],
                                    ob1Cost: resultData["ob1_cost"],
                                    ob2: resultData["ob2"],
                                    ob2Cost: resultData["ob2_cost"],
                                    ob3: resultData["ob3"],
                                    ob3Cost: resultData["ob3_cost"],
                                    sick: resultData["sick"],
                                    obs1: resultData["obs1"],
                                    obs1Cost: resultData["obs1_cost"],
                                    obs2: resultData["obs2"],
                                    obs2Cost: resultData["obs2_cost"],
                                    obs3: resultData["obs3"],
                                    obs3Cost: resultData["obs3_cost"],
                                    result: resultData["result"]
                                }
                            }
                        })
                        let staticsData = responseJson.leaves["data"].map(item => {
                            return {
                                wsId: item["ws_id"],
                                wsTitle: item["ws_title"],
                                recordCount: item["record_count"],
                                totalHours: item["total_hours"],
                            }
                        })

                        result.extras = {
                            tableData: tableData,
                            staticsData: staticsData,
                        }
                        break
                    case "get_confirmed_time_table_data":
                        result.extras = responseJson.data.map(item => {
                            return {
                                id: item.id,
                                date: item.date,
                                startTime: item.start_time,
                                endTime: item.end_time,
                                postEmployeeId: item.post_emp_id,
                                postMonthId: item.post_month_id,
                                workStatusId: item.work_status_id,
                                workStatusTitle: item.work_status_title,
                                overtime: item.overtime,
                            }
                        })
                        break
                }
                return result
            default:
                return {
                    success: false,
                    message: responseJson.message,
                }
        }
    }
}
