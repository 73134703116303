export default class YearlyHours {
    private static yearlyHours = new Array<{year: number, values: MonthHours}>()

    static isUpdated(year: number): boolean {
        return this.yearlyHours.filter(value => value.year === year).length !== 0
    }

    static updateValues(year: number, monthHours: MonthHours) {
        const previousDataIndex = this.yearlyHours.findIndex(value => value.year === year)
        if (previousDataIndex !== -1) {
            this.yearlyHours[previousDataIndex].values = monthHours
        } else {
            this.yearlyHours.push({year: year, values: monthHours})
        }
        console.log(this.yearlyHours)
    }

    static getMonthlyValues(year: number) {
        const yearItems = this.yearlyHours.filter(value => value.year === year);

        return yearItems.length > 0 ? yearItems[0].values : null
    }
}

export interface MonthHours {
    jan: number
    feb: number
    mar: number
    apr: number
    may: number
    jun: number
    jul: number
    aug: number
    sep: number
    oct: number
    nov: number
    dec: number
}
