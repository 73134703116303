import {View, StyleSheet} from "react-native";
import React, {useEffect, useRef, useState} from "react";
import {
    format,
    setDate,
    addMonths, lastDayOfMonth
} from "date-fns";
import ScreenHeader from "../../ScreenHeader";
import Footer from "../../Footer";
import TitleView from "../../employee_screens/components/TitleView";
import Space from "../../Space";
import ModalDatePicker from "../../ModalDatePicker";
import FadeView from "../../FadeView";
import OverallReportsContents from "./OverallReportsContents";
import SingleEmployeeMonthlyReports from "./SingleEmployeeMonthlyReports";
import SingleEmployeeDailyReports from "./SingleEmployeeDailyReports";
import MonthSelectors from "../../MonthSelectors";
import DividerLineVertical from "../../DividerLine";
import HttpRequests from "../../../http_requests/HttpRequests";
import LoadingMessage from "../../DefaultMessages";
import Helpers from "../../../misc/Helpers";
import DefaultValues from "../../../misc/DefaultValues";
import IntentMessenger from "../../../misc/IntentMessenger";
import HomeContent, {selectedEmployeeTag} from "../EmployeesTab/HomeContent";
import NewOverallReportsContents from "./NewOverallReportsContents";


function ReportsTab({navigation}) {
    const animationDuration = 200
    const destinationNames = {
        overall: "overall",
        singleEmployeeMonthly: "single employee",
        singleEmployeeDaily: "single employee daily"
    }

    const [showLoadingMessage, setShowLoadingMessage] = useState(false)

    const [overallReportTitles, setOverallReportTitles] = useState([])
    const [overallReportValues, setOverallReportValues] = useState([])
    const [yearlyBudgetValues, setYearlyBudgetValues] = useState([])
    const [yearlyEmployeeValues, setYearlyEmployeeValues] = useState([])
    const [monthlyReportTitles, setMonthlyReportTitles] = useState([])
    const [monthlyReportValues, setMonthlyReportValues] = useState([])
    const [dailyReportValues, setDailyReportValues] = useState([])
    // const selectedEmployee = useRef(null);
    // const selectedEmployeeId = useRef(null);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)
    const [selectedEmployee, setSelectedEmployee] = useState(null)

    useEffect(() => {
        navigation.addListener('state', (e) => {
            // const receiver = Messenger.getMessageReceiver()
            // if (receiver === Messenger.receivers.ReportsTab) {
            //     setSelectedEmployeeId(Messenger.getMessage(Messenger.receivers.ReportsTab))
            //     setDestination(destinationNames.singleEmployeeMonthly)
            // }

            const message = IntentMessenger.popMessage(selectedEmployeeTag);
            if (message !== null) {
                setSelectedEmployeeId(message)
                setDestination(destinationNames.singleEmployeeMonthly)
            }
        })
    }, []);

    useEffect(() => {
        if (allEmployeesList.length > 0) {
            const employee = allEmployeesList.find(value => value.id === selectedEmployeeId)
            console.log(employee)
            setSelectedEmployee(employee)
        }
    }, [selectedEmployeeId]);

    useEffect(() => {
        if (destination === destinationNames.singleEmployeeMonthly) {
            updateMonthlyReport(startDate, endDate)
        } else if (destination === destinationNames.singleEmployeeDaily) {
            updateDailyReport(startDate)
        }
    }, [selectedEmployee]);

    function updateOverallReport(startDate, endDate) {
        setShowLoadingMessage(true)
        if (Helpers.getRangeType(startDate, endDate) === Helpers.rangeTypes.year) {
            HttpRequests.getYearlyBudgetReport(Helpers.formatDate(startDate)).then(budgetResult => {
                console.log(budgetResult)
                if (budgetResult.success) {
                    HttpRequests.getYearlyEmployeeCountReport(Helpers.formatDate(startDate)).then(employeeResult => {
                        setShowLoadingMessage(false)
                        console.log(employeeResult)
                        if (employeeResult.success) {
                            setYearlyEmployeeValues(employeeResult.extras)
                            setYearlyBudgetValues(budgetResult.extras)
                        } else {
                            // handle error
                        }
                    })
                } else {
                    setShowLoadingMessage(false)
                    // handle error
                }
            })
        } else {
            HttpRequests.getEmployerOverallReport(startDate, endDate).then(result => {
                setShowLoadingMessage(false)
                if (result.success) {
                    const extras = result.extras
                    setOverallReportTitles(["Employees", "Min age", "Max age", "Medium age", ...extras.map((item) => item.workingStatusTitle), "Normal Salaries", "Tax", "OB", "Holiday"])
                    setOverallReportValues([
                        result.totalEmployees,
                        result.minAge,
                        result.maxAge,
                        result.avgAge,
                        ...extras.map((item) => item.totalHours),
                        result.normalSalary,
                        result.tax,
                        result.overtime,
                        result.holiday,
                    ])
                } else {
                    setOverallReportValues(new Array(overallReportValues.length).fill(null))
                    // handle error
                }
            })
        }
    }

    function updateMonthlyReport(startDate, endDate) {
        if (selectedEmployee !== null) {
            setShowLoadingMessage(true)
            HttpRequests.getEmployerMonthlyReport(startDate, endDate, selectedEmployee.id).then(result => {
                setShowLoadingMessage(false)
                if (result.success) {
                    setMonthlyReportTitles([
                        result.extras[0].workingStatusTitle,
                        "OB1",
                        "OB2",
                        "OB3",
                        result.extras[3].workingStatusTitle,
                        "OBS1",
                        "OBS2",
                        "OBS3",
                        result.extras[1].workingStatusTitle,
                        result.extras[2].workingStatusTitle,
                        ...result.extras.slice(4).map(item => item.workingStatusTitle)
                    ])
                    setMonthlyReportValues([
                        result.extras[0].totalHours,
                        result.ob1,
                        result.ob2,
                        result.ob3,
                        result.extras[3].totalHours,
                        result.obs1,
                        result.obs2,
                        result.obs3,
                        result.extras[1].totalHours,
                        result.extras[2].totalHours,
                        ...result.extras.slice(4).map(item => item.totalHours)
                    ])
                } else {
                    setMonthlyReportValues(new Array(14).fill(0))
                    // handle error
                }
            })
        }
    }

    function updateDailyReport(startDate) {
        if (selectedEmployee !== null) {
            setShowLoadingMessage(true)
            HttpRequests.getEmployerDailyReport(Helpers.formatDate(setDate(startDate, 1)), selectedEmployee.id).then(dailyReportResult => {
                if (dailyReportResult.success) {
                    if (DefaultValues.updated) {
                        setShowLoadingMessage(false)
                        setDailyReportValues(dailyReportResult.extras)
                    } else {
                        HttpRequests.getDefaultValues().then(defaultValuesResult => {
                            setShowLoadingMessage(false)
                            if (defaultValuesResult.success) {
                                DefaultValues.updateValues(defaultValuesResult.extras)
                                setDailyReportValues(dailyReportResult.extras)
                            } else {
                                setDailyReportValues([])
                                // handle error
                            }
                        })
                    }
                } else {
                    setDailyReportValues([])
                    setShowLoadingMessage(false)
                    // handle error
                }
            })
        } else {
            setDailyReportValues([])
            // handle error
        }
    }

    const [startDate, setStartDate] = useState(setDate(Date.parse(format(new Date(), "yyyy-MM-dd")), 1))
    const [endDate, setEndDate] = useState(setDate(addMonths(startDate, 1), 0))

    // digest list of employees
    const [allEmployeesList, setAllEmployeesList] = useState([])

    const [showStartPicker, setShowStartPicker] = useState(false)
    const [showEndPicker, setShowEndPicker] = useState(false)

    const [transition, setTransition] = useState(false)
    const [destination, setDestination] = useState(destinationNames.overall)
    useEffect(() => {
        if (destination !== destinationNames.overall) {
            setStartDate(setDate(startDate, 1))
            setEndDate(setDate(addMonths(startDate, 1), 0))
            if (allEmployeesList.length === 0) {
                setShowLoadingMessage(true)
                HttpRequests.getStaffListDigest().then(result => {
                    console.log(result)
                    setShowLoadingMessage(false)
                    if (result.success) {
                        setAllEmployeesList(result.extras)
                        if (selectedEmployeeId !== null) {
                            console.log(result.extras)
                            setSelectedEmployee(result.extras.find(value => value.id === selectedEmployeeId))
                        }
                    } else {
                        // handle error
                    }
                })
            } else {
                if (selectedEmployeeId !== null) {
                    setSelectedEmployee(allEmployeesList.find(value => value.id === selectedEmployeeId))
                }
            }
        }
        if (destination === destinationNames.overall) {
            updateOverallReport(startDate, endDate)
        } else if (destination === destinationNames.singleEmployeeMonthly) {
            updateMonthlyReport(startDate, endDate)
        } else if (destination === destinationNames.singleEmployeeDaily) {
            updateDailyReport(startDate)
        }
    }, [destination]);

    const [chartType, setChartType] = useState("pie")

    //
    // function MonthSelectors({startDate, endDate, onSelectedDateChanged}) {
    //     const rangeType = getRangeType(startDate, endDate)
    //     let selectedId = -1
    //     if (rangeType === "month") {
    //         selectedId = getMonth(startDate)
    //     } else if (rangeType === "year") {
    //         selectedId = 12
    //     }
    //
    //     let yearSelectors = []
    //     for (let i = 0; i < 10; i++) yearSelectors.push({
    //         label: getYear(new Date()) - i,
    //         value: getYear(new Date()) - i
    //     })
    //
    //     let selectorData = [
    //         "Yearly",
    //         "January",
    //         "February",
    //         "March",
    //         "April",
    //         "May",
    //         "June",
    //         "July",
    //         "August",
    //         "September",
    //         "October",
    //         "November",
    //         "December",
    //     ]
    //     return (
    //         <ScrollView style={{flexGrow: 0}}>
    //             <View style={{maxWidth: 190, maxHeight: 40, alignItems: "center", marginRight: 20}}>
    //                 <Dropdown
    //                     style={{
    //                         height: 40,
    //                         width: 140,
    //                         borderWidth: 1,
    //                         borderColor: colors.lightCyan,
    //                         borderRadius: 20,
    //                         marginBottom: 15
    //                     }}
    //                     data={yearSelectors}
    //                     itemTextStyle={{
    //                         fontFamily: "LatoRegular",
    //                         fontSize: 14,
    //                         fontWeight: "700",
    //                     }}
    //                     searchField={null}
    //                     selectedTextStyle={{
    //                         fontFamily: "LatoRegular",
    //                         fontSize: 14,
    //                         fontWeight: "700",
    //                         marginLeft: 10
    //                     }}
    //                     labelField={"label"}
    //                     valueField={"value"}
    //                     value={rangeType != null ? getYear(startDate) : null}
    //                     onChange={(item) => {
    //                         if (rangeType != null) {
    //                             const sDate = setYear(startDate, item.value)
    //                             const eDate = new Date(item.value, getMonth(endDate), getDaysInMonth(setYear(endDate, item.value)))
    //                             onSelectedDateChanged(sDate, eDate)
    //                         } else {
    //                             const sDate = new Date(item.value, getMonth(startDate), 1)
    //                             const eDate = new Date(item.value, getMonth(startDate), getDaysInMonth(setYear(startDate, item.value)))
    //                             onSelectedDateChanged(sDate, eDate)
    //                         }
    //                     }}
    //                     placeholder={"Select Year"}
    //                     placeholderStyle={{
    //                         color: colors.mediumGray,
    //                         fontFamily: "LatoRegular",
    //                         marginLeft: 10,
    //                         fontWeight: "700",
    //                         fontSize: 14
    //                     }}
    //                 />
    //                 <Table columnsCount={1} items={selectorData} itemGeneratorFunction={(month, id) => {
    //                     return (
    //                         <Selector
    //                             style={{marginBottom: 15}}
    //                             text={month}
    //                             selectedBorderColor={colors.lightCyan}
    //                             deselectedBorderColor={colors.pink}
    //                             selectedFillColor={colors.lightCyan}
    //                             deselectedFillColor={"white"}
    //                             selectedTextColor={colors.darkGray}
    //                             deselectedTextColor={colors.pink}
    //                             selectedId={selectedId}
    //                             id={(id + 12) % 13}
    //                             onSelectedChange={(id) => {
    //                                 const y = getYear(startDate)
    //                                 if (id < 12) {
    //                                     const sDate = new Date(y, id, 1)
    //                                     const eDate = setDate(sDate, getDaysInMonth(sDate))
    //                                     onSelectedDateChanged(sDate, eDate)
    //                                 } else {
    //                                     const sDate = new Date(y, 0, 1)
    //                                     const eDate = new Date(y + 1, 0, 0)
    //                                     onSelectedDateChanged(sDate, eDate)
    //                                 }
    //                             }}
    //                         />)
    //                 }}/>
    //             </View>
    //         </ScrollView>
    //     )
    // }

    function getTitleText(startDate, endDate) {
        const type = Helpers.getRangeType(startDate, endDate)
        if (destination === destinationNames.overall) {
            if (type === Helpers.rangeTypes.none) {
                return "Custom report"
            } else if (type === Helpers.rangeTypes.month) {
                return `${format(startDate, "MMMM yyyy")} report`
            } else {
                return `${format(startDate, "yyyy")} report`
            }
        } else if (destination === destinationNames.singleEmployeeMonthly || destination === destinationNames.singleEmployeeDaily) {
            return "Employee Report"
        }
    }

    return (
        <NewOverallReportsContents navigation={navigation}/>
        // <View style={styles.mainContainer}>
        //     <ScreenHeader navigation={navigation}/>
        //     <TitleView titleText={getTitleText(startDate, endDate)}/>
        //     <View style={styles.fillingRow}>
        //         <MonthSelectors
        //             startDate={startDate} endDate={endDate} yearSelectorVisible={true}
        //             onSelectedDateChanged={(sDate, eDate) => {
        //                 setStartDate(sDate)
        //                 setEndDate(eDate)
        //                 if (destination === destinationNames.overall) {
        //                     updateOverallReport(sDate, eDate)
        //                 } else if (destination === destinationNames.singleEmployeeMonthly) {
        //                     updateMonthlyReport(sDate, eDate)
        //                 } else if (destination === destinationNames.singleEmployeeDaily) {
        //                     updateDailyReport(sDate)
        //                 }
        //                 setChartType(Helpers.getRangeType(sDate, eDate) === Helpers.rangeTypes.year ? "linear" : "pie")
        //             }}/>
        //         <DividerLineVertical/>
        //         <Space width={20}/>
        //         <FadeView style={{flex: 1}} duration={animationDuration} visible={!transition}>
        //             {destination === destinationNames.overall &&
        //                 <OverallReportsContents
        //                     startDate={startDate}
        //                     endDate={endDate}
        //                     chartType={chartType}
        //                     reportTitles={overallReportTitles}
        //                     reportValues={overallReportValues}
        //                     yearlyBudgetValues={yearlyBudgetValues}
        //                     yearlyEmployeeValues={yearlyEmployeeValues}
        //                     onStartCalendarOpen={() => {
        //                         setShowStartPicker(true)
        //                     }}
        //                     onEndCalendarOpen={() => {
        //                         setShowEndPicker(true)
        //                     }}
        //                     onSingleEmployeeClicked={() => {
        //                         setTransition(true)
        //                         setTimeout(() => {
        //                             setTransition(false)
        //                             setDestination(destinationNames.singleEmployeeMonthly)
        //                         }, animationDuration)
        //                     }}
        //                 />}
        //             {destination === destinationNames.singleEmployeeMonthly &&
        //                 <SingleEmployeeMonthlyReports
        //                     startDate={startDate} endDate={endDate} employees={allEmployeesList}
        //                     selectedEmployee={selectedEmployee} reportTitles={monthlyReportTitles}
        //                     reportValues={monthlyReportValues}
        //                     onDailyClicked={() => {
        //                         setTransition(true)
        //                         setTimeout(() => {
        //                             setTransition(false)
        //                             setDestination(destinationNames.singleEmployeeDaily)
        //                         }, animationDuration)
        //                     }}
        //                     onEmployeeChanged={(employeeItem) => {
        //                         setSelectedEmployee(employeeItem)
        //                         // updateMonthlyReport(startDate, endDate);
        //                     }}
        //                     onOverallClicked={() => {
        //                         setTransition(true)
        //                         setTimeout(() => {
        //                             setTransition(false)
        //                             setDestination(destinationNames.overall)
        //                         }, animationDuration)
        //                     }}
        //                 />
        //             }
        //             {destination === destinationNames.singleEmployeeDaily &&
        //                 <SingleEmployeeDailyReports
        //                     employees={allEmployeesList} selectedEmployee={selectedEmployee}
        //                     reportValues={dailyReportValues}
        //                     startDate={startDate} endDate={endDate}
        //                     onMonthlyClicked={() => {
        //                         setTransition(true)
        //                         setTimeout(() => {
        //                             setTransition(false)
        //                             setDestination(destinationNames.singleEmployeeMonthly)
        //                         }, animationDuration)
        //                     }}
        //                     onEmployeeChanged={(employeeItem) => {
        //                         setSelectedEmployee(employeeItem)
        //                         // updateDailyReport(startDate);
        //                     }}
        //                     onOverallClicked={() => {
        //                         setTransition(true)
        //                         setTimeout(() => {
        //                             setTransition(false)
        //                             setDestination(destinationNames.overall)
        //                         }, animationDuration)
        //                     }}
        //                 />
        //             }
        //         </FadeView>
        //     </View>
        //     <Footer/>
        //     <ModalDatePicker
        //         open={showStartPicker}
        //         currentDate={startDate}
        //         onCancel={() => {
        //             setShowStartPicker(false)
        //         }}
        //         onConfirm={(date) => {
        //             setShowStartPicker(false)
        //             setStartDate(date)
        //             if (destination === destinationNames.overall) {
        //                 updateOverallReport(date, endDate)
        //             } else if (destination === destinationNames.singleEmployeeMonthly) {
        //                 updateMonthlyReport(date, endDate)
        //             } else if (destination === destinationNames.singleEmployeeDaily) {
        //                 updateDailyReport(date)
        //             }
        //         }}
        //     />
        //     <ModalDatePicker
        //         open={showEndPicker}
        //         currentDate={endDate}
        //         onCancel={() => {
        //             setShowEndPicker(false)
        //         }}
        //         onConfirm={(date) => {
        //             setShowEndPicker(false)
        //             setEndDate(date)
        //             if (destination === destinationNames.overall) {
        //                 updateOverallReport(startDate, date)
        //             } else if (destination === destinationNames.singleEmployeeMonthly) {
        //                 updateMonthlyReport(startDate, date)
        //             } else if (destination === destinationNames.singleEmployeeDaily) {
        //                 updateDailyReport(startDate)
        //             }
        //         }}
        //     />
        //     <LoadingMessage showLoadingMessage={showLoadingMessage}/>
        // </View>
    )
}

export default ReportsTab

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
    fillingRow: {
        flex: 1, flexDirection: "row"
    },
    fillingColumn: {
        flex: 1, flexDirection: "column"
    },
    centerMainAxis: {
        alignItems: "center"
    },
    centerCrossAxis: {
        justifyContent: "center"
    },
    center: {
        justifyContent: "center", alignItems: "center"
    }
})
