import {useEffect, useRef, useState} from "react";
import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {addMonths, format, getMonth, getYear, lastDayOfMonth, setDate} from "date-fns";
import Space from "../Space";
import Footer from "../Footer";
import AwesomeAlert from "react-native-awesome-alerts";
import {colors} from "../../colors";
import ScreenHeader from "../ScreenHeader";
import HttpRequests from "../../http_requests/HttpRequests";
import ProfileData from "../../misc/ProfileData";
import {fontStyles} from "../../fontStyles";
import Helpers from "../../misc/Helpers";
import StyledDropDown from "../StyledDropDown";
import LoadingMessage from "../DefaultMessages";
import TitleView from "../employee_screens/components/TitleView";
import CustomButton from "../employee_screens/components/CustomButton";
import DatePickerView from "../DatePickerView";
import ModalDatePicker from "../ModalDatePicker";
import IntentMessenger from "../../misc/IntentMessenger";
import {selectedEmployeeTag} from "./EmployeesTab/HomeContent";
import MiniButton from "../employee_screens/components/MiniButton";

export default function ConfirmTimingTab({navigation}) {

    const [showEditDialog, setShowEditDialog] = useState(false)
    const [assignedTimes, setAssignedTimes] = useState([])
    const [acceptedTimes, setAcceptedTimes] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const workingStates = useRef([]);
    const dialogMessage = useRef("");
    const dialogTitle = useRef("");
    useEffect(() => {
        updateEmployeesArray()
        // updateTimes()
    }, [selectedDate]);
    const assignedTimesByDate = useRef([]);
    const acceptedTimesByDate = useRef([]);
    const dialogInfo = useRef(new Date());

    const [employees, setEmployees] = useState([])
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)
    useEffect(() => {
        newUpdateTimes()
    }, [selectedEmployeeId]);

    const yearItems = Array.from({length: 10}, (item, index) => {
        return {label: getYear(new Date()) - 5 + index}
    })

    const timeIntervalItems = [{label: "Monthly"}, {label: "Yearly"}]
    const [timeInterval, setTimeInterval] = useState(null)
    useEffect(() => {
        console.log(timeInterval)
        setStartDate(null)
    }, [timeInterval]);

    const [selectedYear, setSelectedYear] = useState(null)

    const [showStartPicker, setShowStartPicker] = useState(false)
    const [startDate, setStartDate] = useState(null)
    useEffect(() => {
        newUpdateTimes()
    }, [startDate]);

    // functions
    function newUpdateTimes() {
        let endDate = null
        if (timeInterval === "Monthly") {
            endDate = new Date(getYear(startDate), getMonth(startDate) + 1, 0);
        } else if (timeInterval === "Yearly") {
            endDate = new Date(getYear(startDate) + 1, 0, 0);
        }
        if (selectedEmployeeId && startDate && endDate) {
            const startDateStr = Helpers.formatDate(startDate);
            const endDateStr = Helpers.formatDate(endDate);
            console.log(`Lets get submitted times from ${startDateStr} to ${endDateStr} for employee id of ${selectedEmployeeId}`)
            setShowLoading(true)
            acceptedTimesByDate.current = []
            assignedTimesByDate.current = []

            HttpRequests.getEmployeeTimeTableData(startDateStr, endDateStr, selectedEmployeeId).then(employeeTimeTableResponse => {
                console.log(employeeTimeTableResponse)
                if (employeeTimeTableResponse.success) {
                    HttpRequests.getEmployeeConfirmedTimeTableData(selectedEmployeeId, startDateStr, endDateStr).then(confirmedDataResponse => {
                        console.log(confirmedDataResponse)
                        if (confirmedDataResponse.success || confirmedDataResponse.message.includes("No Data")) {
                            employeeTimeTableResponse.extras.forEach(item => {
                                if (!assignedTimesByDate.current.hasOwnProperty(item.date)) {
                                    assignedTimesByDate.current[item.date] = []
                                    acceptedTimesByDate.current[item.date] = []
                                }
                                assignedTimesByDate.current[item.date] = [...assignedTimesByDate.current[item.date], item]
                                const foundAccepted = confirmedDataResponse.extras === undefined ? undefined : confirmedDataResponse.extras.filter(res => {
                                    return res.id === item.id
                                })
                                if (foundAccepted === undefined || foundAccepted === null || foundAccepted.length === 0) {
                                    acceptedTimesByDate.current[item.date] = [...acceptedTimesByDate.current[item.date], {date: item.date}]
                                } else {
                                    console.log(foundAccepted)
                                    acceptedTimesByDate.current[item.date] = [...acceptedTimesByDate.current[item.date], foundAccepted[0]]
                                }
                            })
                            console.log(assignedTimesByDate)
                            console.log(acceptedTimesByDate)
                            setAssignedTimes(confirmedDataResponse.extras)
                            if (workingStates.current.length !== 0) {
                                setShowLoading(false)
                            } else {
                                updateWorkStatusItems()
                            }
                        } else {
                            setAcceptedTimes([])
                            setAssignedTimes([])
                            setShowLoading(false)
                            dialogMessage.current = employeeTimeTableResponse.message
                            dialogTitle.current = "Error"
                            setShowMessage(true)
                        }
                    })
                } else {
                    setAcceptedTimes([])
                    setAssignedTimes([])
                    setShowLoading(false)
                    dialogMessage.current = employeeTimeTableResponse.message
                    dialogTitle.current = "Error"
                    setShowMessage(true)

                }
            })
        }
    }

    function updateWorkStatusItems() {
        HttpRequests.getPostingFilterTitles().then(result => {
            setShowLoading(false)
            if (result.success) {
                workingStates.current = result.extras.workingStatus
            } else {
                dialogMessage.current = result.message
                dialogTitle.current = "Error"
                setShowMessage(true)
            }
        })
    }

    function updateEmployeesArray() {
        setShowLoading(true)
        HttpRequests.getStaffListFull().then((result) => {
            console.log(result)
            if (result.success) {
                const newEmployeesArray = result.extras.data.map((item, index) => {
                    console.log(new Date(item.age))
                    return {name: item.name, id: item.user_id, position: item.position, email: item.email}
                })
                console.log(newEmployeesArray)
                setEmployees(newEmployeesArray)
                setTimeout(() => {
                    const message = IntentMessenger.popMessage(selectedEmployeeTag)
                    if (message) {
                        setSelectedEmployeeId(newEmployeesArray.find((value) => value.id === message))
                    }
                    setShowLoading(false)
                }, 20)
            } else {
                setShowLoading(false)
                setTimeout(args => {
                    if (result.message !== "No Data Available!") {
                        dialogMessage.current = "Error"
                        dialogTitle.current = result.message
                        setShowMessage(true)
                    }
                }, 20)
            }
        })
    }

    function updateTimes() {
        setShowLoading(true)
        HttpRequests.getEmployeeTimeTableData(
            Helpers.formatDate(setDate(selectedDate, 1)),
            Helpers.formatDate(lastDayOfMonth(selectedDate)))
            .then(acceptedTimesResult => {
                console.log(acceptedTimesResult)
                if (acceptedTimesResult.success || acceptedTimesResult.message.includes("No Data")) {
                    HttpRequests.getIntervalEmployerSuggestionTime(
                        ProfileData.userId,
                        Helpers.formatDate(setDate(selectedDate, 1)),
                        Helpers.formatDate(lastDayOfMonth(selectedDate)))
                        .then(assignedTimesResult => {
                            console.log(assignedTimesResult)
                            acceptedTimesByDate.current = {}
                            assignedTimesByDate.current = {}
                            if (assignedTimesResult.success) {
                                assignedTimesResult.extras.forEach(item => {
                                    if (!assignedTimesByDate.current.hasOwnProperty(item.date)) {
                                        assignedTimesByDate.current[item.date] = []
                                        acceptedTimesByDate.current[item.date] = []
                                    }
                                    assignedTimesByDate.current[item.date] = [...assignedTimesByDate.current[item.date], item]
                                    const foundAccepted = acceptedTimesResult.extras === undefined ? undefined : acceptedTimesResult.extras.filter(res => {
                                        return res.id === item.id
                                    })
                                    if (foundAccepted === undefined || foundAccepted === null || foundAccepted.length === 0) {
                                        acceptedTimesByDate.current[item.date] = [...acceptedTimesByDate.current[item.date], {date: item.date}]
                                    } else {
                                        console.log(foundAccepted)
                                        acceptedTimesByDate.current[item.date] = [...acceptedTimesByDate.current[item.date], foundAccepted[0]]
                                    }
                                })
                                console.log(assignedTimesByDate)
                                console.log(acceptedTimesByDate)
                                setAssignedTimes(assignedTimesResult.extras)
                                if (workingStates.current.length !== 0) {
                                    setShowLoading(false)
                                } else {
                                    updateWorkStatusItems()
                                }
                            } else {
                                setShowLoading(false)
                                setAssignedTimes([])
                                dialogMessage.current = assignedTimesResult.message
                                dialogTitle.current = "Error"
                                setShowMessage(true)
                            }
                        })
                    // acceptedTimesResult.extras.forEach(item => {
                    //     if (!acceptedTimesByDate.current.hasOwnProperty(item.date)) acceptedTimesByDate.current[item.date] = []
                    //     acceptedTimesByDate.current[item.date] = [...acceptedTimesByDate.current[item.date], item]
                    // })
                    // setAcceptedTimes(acceptedTimesResult.extras)
                } else {
                    setAcceptedTimes([])
                    setShowLoading(false)
                    dialogMessage.current = acceptedTimesResult.message
                    dialogTitle.current = "Error"
                    setShowMessage(true)
                }
            })
    }

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation}/>
            <TitleView titleText={"Confirm Employees Timing Report"}/>
            <View style={{flexDirection: "row", height: 40}}>
                <Text style={{...fontStyles.lato700_14, alignSelf: "center"}}>Select Employee</Text>
                <View style={{width: 200}}>
                    <StyledDropDown
                        placeHolder={"Search for Name or ID"} items={employees} value={selectedEmployeeId}
                        labelField={"name"} valueField={"id"} topLabel={false}
                        onChange={(item) => {
                            setSelectedEmployeeId(item.id)
                        }}
                    />
                </View>
                <Space width={25}/>
                <Text style={{...fontStyles.lato700_14, alignSelf: "center"}}>Duration</Text>
                <View style={{width: 200}}>
                    <StyledDropDown
                        placeHolder={"-"} items={timeIntervalItems} value={timeInterval}
                        topLabel={false}
                        onChange={(item) => {
                            console.log(item)
                            setTimeInterval(item.label)
                        }}
                    />
                </View>
                {
                    timeInterval !== null && timeInterval !== "Yearly" &&
                    <View style={{width: 180}}>
                        <DatePickerView
                            placeHolderText={"Select Month"}
                            selectedDate={startDate}
                            onShowCalendarClicked={() => {
                                setShowStartPicker(true)
                            }}/>
                    </View>
                }
                {
                    timeInterval !== null && timeInterval === "Yearly" &&
                    <View style={{width: 180}}>
                        <StyledDropDown
                            style={undefined} items={yearItems} placeHolder={"Select Year"} value={selectedYear}
                            topLabel={false}
                            onChange={(year) => {
                                setSelectedYear(year)
                                setStartDate(Helpers.getDate(`${year.label}-01-01`))
                            }}
                        />
                    </View>
                }
                <Space flex={1}/>
                <CustomButton style={{maxWidth: 200}} text={"Submit All"} onPress={() => {
                    const assignedIds =
                        Object.values(assignedTimesByDate.current)
                            .flat()
                            .reduce((previousValue, currentValue) => {
                                return [...previousValue, currentValue.id];
                            }, [])
                    const acceptedIds =
                        Object.values(acceptedTimesByDate.current)
                            .flat()
                            .reduce((previousValue, currentValue) => {
                                return [...previousValue, currentValue.id];
                            }, [])
                    const unacceptedIds = assignedIds.filter(id => {
                        return !acceptedIds.includes(id)
                    })

                    const assignedButNotAccepted = Object.values(assignedTimesByDate.current)
                        .flat()
                        .filter(value => unacceptedIds.includes(value.id))
                        .map(value => {
                            return {
                                id: value.id,
                                user_id: selectedEmployeeId,
                                date: value.date,
                                start_time: value.startTime,
                                end_time: value.endTime,
                                post_emp_id: value.postEmployeeId,
                                post_month_id: value.postMonthId,
                                work_status_id: value.workStatusId
                            }
                        })
                    console.log(assignedButNotAccepted)

                    if (assignedButNotAccepted.length > 0) {
                        HttpRequests.confirmEmployeeTimeTableMulti(assignedButNotAccepted).then(response => {
                            console.log(response)
                            newUpdateTimes()
                        })
                    } else {
                        // nothing to confirm dialog
                    }
                }}/>
            </View>
            <ScrollView style={{flex: 1}}>
                <View style={{flex: 1, flexDirection: "row"}}>
                    <View style={{flex: 1}}>
                        {
                            Object.keys(assignedTimesByDate.current).map(key => {
                                return (
                                    <AssignmentCard
                                        assigner={"employer"}
                                        onEditPressed={(info) => {
                                            dialogInfo.current = info
                                            setShowEditDialog(true)
                                        }}
                                        onSuccess={() => {
                                            newUpdateTimes()
                                        }}
                                        onFailure={(info) => {

                                        }}
                                        cardInfo={assignedTimesByDate.current[key]}
                                        userId={selectedEmployeeId}
                                    />
                                )
                            })
                        }
                    </View>
                    <Space width={20}/>
                    <View style={{flex: 1}}>
                        {
                            Object.keys(assignedTimesByDate.current).map(key => {
                                return (
                                    <AssignmentCard
                                        assigner={"employee"}
                                        onEditPressed={(info) => {
                                            dialogInfo.current = info
                                            setShowEditDialog(true)
                                        }}
                                        onSuccess={() => {
                                            newUpdateTimes()
                                        }}
                                        onFailure={(info) => {

                                        }}
                                        cardInfo={acceptedTimesByDate.current[key]}
                                        userId={selectedEmployeeId}
                                    />
                                )
                            })
                        }
                    </View>
                </View>
            </ScrollView>
            <Footer/>
            <AwesomeAlert
                contentContainerStyle={{
                    borderRadius: 20,
                }}
                closeOnTouchOutside={false}
                title={"Submit new time by yourself"}
                titleStyle={styles.alertTitle}
                customView={
                    <AlertMessageBody
                        workingItems={workingStates.current}
                        selectedPostInfo={dialogInfo.current}
                        onClose={() => {
                            setShowEditDialog(false)
                        }}
                        onSubmit={(startTime, endTime, workingStatus) => {
                            setShowLoading(true)
                            console.log(startTime)
                            console.log(endTime)
                            HttpRequests.confirmEmployeeTimeTable(
                                dialogInfo.current.id,
                                selectedEmployeeId,
                                dialogInfo.current.date,
                                startTime,
                                endTime,
                                dialogInfo.current.postEmployeeId,
                                dialogInfo.current.postMonthId,
                                workingStatus).then(result => {
                                    setShowLoading(false)
                                    if (!result.success) {
                                        dialogMessage.current = result.message
                                        dialogTitle.current = "Error"
                                        setShowMessage(true)
                                    } else {
                                        newUpdateTimes()
                                    }
                                }
                            )
                            setShowEditDialog(false)
                        }}
                    />}
                show={showEditDialog}
            />
            <AwesomeAlert
                show={showMessage}
                closeOnTouchOutside={false}
                title={dialogTitle.current}
                message={dialogMessage.current}
                showConfirmButton={true}
                confirmText={"OK"}
                onConfirmPressed={() => {
                    setShowMessage(false)
                }}
            />
            <LoadingMessage showLoadingMessage={showLoading}/>
            <ModalDatePicker
                open={showStartPicker}
                currentDate={startDate}
                onCancel={() => {
                    setShowStartPicker(false)
                }}
                onConfirm={(date) => {
                    console.log(date)
                    setShowStartPicker(false)
                    const start = new Date(getYear(date), getMonth(date));
                    setStartDate(start)
                }}
                mode={"monthYear"}
            />
        </View>
    )
}

function YearPicker({onDateChange, selectedDate}) {
    const styles = StyleSheet.create({
        dateHandle: {color: colors.lightYellow, marginHorizontal: 20, fontSize: 30}
    })
    const now = new Date()
    return (
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <TouchableOpacity onPress={() => {
                onDateChange(addMonths(selectedDate, -1))
            }}>
                <Text style={styles.dateHandle}>{"<"}</Text>
            </TouchableOpacity>
            <Text style={{...fontStyles.lato700_20}}>{format(selectedDate, "MMMM yyyy")}</Text>
            <TouchableOpacity onPress={() => {
                const newDate = addMonths(selectedDate, 1)
                if (newDate.getTime() <= now.getTime()) {
                    onDateChange(newDate)
                }
            }}>
                <Text style={styles.dateHandle}>{">"}</Text>
            </TouchableOpacity>
        </View>
    )
}

function AssignmentCard({assigner, cardInfo, onEditPressed, onSuccess, onFailure, userId}) {
    const styles = StyleSheet.create({
        bounds: {
            borderRadius: 20,
            borderWidth: 1,
            borderColor: colors.navyBlue,
            borderStyle: "dashed",
            height: Math.max(cardInfo.length * 40 + 80, 170),
            marginVertical: 5
        },
        cardHeaderContainer: {
            backgroundColor: colors.lightGray,
            height: 40,
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center"
        },
        cardHeader: {...fontStyles.lato700_14},
        columnHeader: {
            ...fontStyles.lato700_14,
            width: 300,
            textAlign: "center",
        },
        columnValue: {
            ...fontStyles.lato700_14,
            width: 60,
            textAlign: "center",
        },
        columnButtons: {
            width: 60,
            justifyContent: "space-around",
            flexDirection: "row"
        }, contentsContainer: {
            flexDirection: "row",
            flex: 1,
            marginHorizontal: 10,
            justifyContent: "space-between",
        }, dateContainer: {
            flex: 1,
            marginVertical: 10,
            alignItems: "center",
            justifyContent: "space-around",
            maxWidth: 90,
            minHeight: 90,
            borderRadius: 20,
            backgroundColor: colors.navyBlue
        },
        dayText: {
            ...fontStyles.lato700_30,
            color: "white"
        },
        dayNameText: {
            ...fontStyles.lato700_14,
            color: "white"
        },
        dividerContainer: {
            flexDirection: "row",
            borderStyle: "dashed",
            borderColor: colors.navyBlue,
            borderWidth: 1
        }
    })

    let employee = assigner === "employee"

    return (
        <View style={{...styles.bounds}}>
            <View style={styles.cardHeaderContainer}>
                <Text style={styles.columnHeader}>{employee ? "Confirmed by you" : "Submitted by employee"}</Text>
            </View>
            <View style={styles.contentsContainer}>
                <View style={styles.dateContainer}>
                    <Text style={styles.dayNameText}>{format(new Date(cardInfo[0].date), "MMM")}</Text>
                    <Text style={styles.dayText}>{format(new Date(cardInfo[0].date), "dd")}</Text>
                    <Text style={styles.dayNameText}>{format(new Date(cardInfo[0].date), "EEEE")}</Text>
                </View>
                <Space width={10}/>
                <View style={{flex: 1, marginVertical: 10, justifyContent: "space-between"}}>
                    <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                        <Text style={styles.columnHeader}>Start</Text>
                        <Text style={styles.columnHeader}>End</Text>
                        <Text style={styles.columnHeader}>Time</Text>
                        <Text style={styles.columnHeader}>Decimal</Text>
                        <Text style={styles.columnHeader}>Status</Text>
                        <Text style={styles.columnHeader}>Total OB</Text>
                        <Text style={styles.columnHeader}></Text>
                    </View>
                    {
                        cardInfo.map((info, index) => {
                            if (!info.hasOwnProperty("endTime")) {
                                return (
                                    <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
                                        <Text>Item not assigned yet</Text>
                                    </View>
                                )
                            }
                            const minutesDiff = Helpers.getMinutesOfTime(info.endTime) - Helpers.getMinutesOfTime(info.startTime)
                            return (
                                <>
                                    <View key={index} style={{
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <Text style={styles.columnValue}>{info.startTime}</Text>
                                        <Text style={styles.columnValue}>{info.endTime}</Text>
                                        <Text style={styles.columnValue}>{Helpers.getTimeOfMinutes(minutesDiff)}</Text>
                                        <Text style={styles.columnValue}>{Math.round(minutesDiff * 10 / 6) / 100}</Text>
                                        <Text style={styles.columnValue}>{info.workStatusTitle}</Text>
                                        <Text style={styles.columnValue}>{info.overtime}</Text>
                                        <View style={styles.columnButtons}>
                                            <MiniButton source={require("../../assets/icons/mini_button/edit.png")}
                                                        onPress={() => {
                                                            onEditPressed(info)
                                                        }}/>
                                            {!employee &&
                                                <MiniButton
                                                    source={require("../../assets/icons/mini_button/check_mark.png")}
                                                    onPress={() => {
                                                        HttpRequests.confirmEmployeeTimeTable(
                                                            info.id,
                                                            userId,
                                                            info.date,
                                                            info.startTime,
                                                            info.endTime,
                                                            info.postEmployeeId,
                                                            info.postMonthId,
                                                            info.workStatusId).then(result => {
                                                            if (result.success) {
                                                                onSuccess()
                                                            } else {
                                                                onFailure()
                                                            }
                                                        })
                                                    }}/>
                                            }
                                        </View>
                                    </View>
                                    {index !== cardInfo.length - 1 &&
                                        <View key={-index - 1} style={styles.dividerContainer}/>}
                                </>
                            )
                        })
                    }
                </View>
            </View>
        </View>
    )
}

function AlertMessageBody({onClose, onSubmit, selectedPostInfo, workingItems}) {
    const start = useRef(selectedPostInfo.startTime);
    const end = useRef(selectedPostInfo.endTime);
    const workingStatus = useRef(selectedPostInfo.workStatusId);
    const [errorMessage, setErrorMessage] = useState("")
    let dropDownItems = [{label: null}]
    for (let i = 0; i < 24; i++) {
        dropDownItems = [...dropDownItems,
            {label: `${i.toString().padStart(2, "0")}:00`},
            {label: `${i.toString().padStart(2, "0")}:15`},
            {label: `${i.toString().padStart(2, "0")}:30`},
            {label: `${i.toString().padStart(2, "0")}:45`},
        ]
    }
    console.log(workingItems.find(data => data.id === workingStatus.current))

    function updateMessage() {
        if (start.current === null) setErrorMessage("Please select start time.")
        else if (end.current === null) setErrorMessage("Please select end time.")
        else if (workingStatus.current === null || workingItems.find(data => data.id === workingStatus.current) === undefined) setErrorMessage("Please select status.")
        else if (Helpers.getMinutesOfTime(start.current) >= Helpers.getMinutesOfTime(end.current)) {
            setErrorMessage("Start time should be less than end time.")
        } else {
            setErrorMessage("")
        }
    }

    useEffect(() => {
        updateMessage()
    }, []);

    return (
        <View style={{width: 650, height: 230, alignItems: "center", justifyContent: "space-around"}}>
            <TouchableOpacity style={{width: 50, height: 50, marginBottom: 100}} onPress={onClose}>
                <Image style={{width: 50, height: 50}} source={require("../../assets/icons/exit.png")}/>
            </TouchableOpacity>
            <View
                style={{height: 200, marginBottom: 100, alignItems: "center", justifyContent: "space-around"}}>
                <Text
                    style={fontStyles.lato700_20}>{format(new Date(selectedPostInfo.date), "dd MMMM yyyy - EEEE")}</Text>
                <View style={{flexDirection: "row"}}>
                    <StyledDropDown
                        style={{marginHorizontal: 10, width: 150}} items={dropDownItems}
                        placeHolder={"Start time"} value={start.current}
                        onChange={(item) => {
                            start.current = item.label
                            updateMessage()
                        }}
                    />
                    <StyledDropDown
                        style={{marginHorizontal: 10, width: 150}} items={dropDownItems}
                        placeHolder={"End time"} value={end.current}
                        onChange={(item) => {
                            end.current = item.label
                            updateMessage()
                        }}
                    />
                    <StyledDropDown
                        style={{marginHorizontal: 10, width: 150}} items={workingItems}
                        valueField={"id"}
                        labelField={"title"}
                        placeHolder={"Working Status"}
                        value={workingStatus.current}
                        onChange={(item) => {
                            workingStatus.current = item.id
                            updateMessage()
                        }}
                    />
                </View>
                <View style={{flex: 1, maxHeight: 30, alignItems: "center", justifyContent: "center"}}>
                    <Text style={fontStyles.lato400_14}>{errorMessage}</Text>
                </View>
                <TouchableOpacity
                    style={{
                        width: 300,
                        height: 40,
                        borderRadius: 20,
                        backgroundColor: errorMessage === "" ? colors.lightYellow : colors.lightGray,
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onPress={() => {
                        onSubmit(start.current, end.current, workingStatus.current)
                    }}
                    disabled={errorMessage !== ""}
                >
                    <Text style={fontStyles.lato700_14}>Submit</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    alertTitle: {
        ...fontStyles.lato700_20,
        color: colors.navyBlue
    },
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignContent: "center",
        alignItems: "center",
        maxHeight: 55,
        marginBottom: 30
    },
    userProfile: {
        ...fontStyles.lato700_16,
        color: colors.darkGray,
        marginStart: 5
    }
})
