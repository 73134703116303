import {Text, View} from "react-native";
import {fontStyles} from "../fontStyles";
import {colors} from "../colors";


function Footer() {
    return(
        <View style={{height:30, marginTop: 10}}>
            <View style={{flex: 1, maxHeight: 1, backgroundColor: colors.navyBlue}}/>
            <Text style={{
                ...fontStyles.lato700_12,
                color: colors.navyBlue,
                alignSelf: "center"
            }}>Copyright © 2023 Avvikelseanalys. All rights reserved.</Text>
        </View>
    )
}

export default Footer
