export const colors = {
    navyBlue: "#0F3D66",
    lightNavyBlue: "#155291",
    lighterNavyBlue: "#62AADF",
    lighterGray: "#EEEEEE",
    lightGray: "#D9D9D9",
    mediumGray: "#AAAAAA",
    darkGray: "#202020",
    lightGreen: "#299327",
    lighterGreen: "#58b257",
    headerBlue: "#51779E",
    tableRow: "#E0D4B8",
    lighterOrange: "#FDC6C2",
    lightOrange: "#FFBFB1",
    normalOrange: "#FF9D2A",
    lighterYellow: "#FFE588",
    lightYellow: "#FCD46A",
    yellow: "#FBC04F",
    lightRed: "#EC6258",
}
