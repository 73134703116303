import {ScrollView, StyleSheet, View, Text} from "react-native";
import Space from "../../Space";
import DatePickerView from "../../DatePickerView";
import MiniButton from "../../employee_screens/components/MiniButton";
import CustomButton from "../../employee_screens/components/CustomButton";
import Table from "../../Table";
import ReportItem from "../../ReportItem";
import TitleView from "../../employee_screens/components/TitleView";
import FadeView from "../../FadeView";
import {Area, Chart, HorizontalAxis, Line, VerticalAxis} from "react-native-responsive-linechart";
import {PieChart} from "react-minimal-pie-chart";
import {useEffect, useRef, useState} from "react";
import {fontStyles} from "../../../fontStyles";


export default function OverallReportsContents(
    {
        startDate,
        endDate,
        chartType,
        reportTitles,
        reportValues,
        yearlyBudgetValues,
        yearlyEmployeeValues,
        onStartCalendarOpen,
        onEndCalendarOpen,
        onSingleEmployeeClicked
    }) {
    const chartColors = [
        '#a8d08d',
        '#9cc3e5',
        '#ffd965',
        '#c9c9c9',
        '#f4b183',
        '#8eaadb',
        '#8496b0',
        '#757070']
    const animationDuration = 200

    const [transition, setTransition] = useState(false)

    const chartTypeRef = useRef(chartType);
    useEffect(() => {
        if (chartType !== chartTypeRef.current) {
            setTransition(true)
            setTimeout(() => {
                chartTypeRef.current = chartType
                setTransition(false)
            }, animationDuration)
        }
    });

    function ReportIntervalPicker({startDate, endDate, onStartCalendarOpen, onEndCalendarOpen}) {
        return (
            <View style={{
                flexDirection: "row", justifyContent: "space-between", alignItems: "center",
            }}>
                <Text style={fontStyles.lato700_14}>Get report by time interval:</Text>
                <Space width={10}/>
                <DatePickerView
                    placeHolderText={"Start Date"}
                    selectedDate={startDate}
                    onShowCalendarClicked={() => {
                        if (onStartCalendarOpen) onStartCalendarOpen()
                    }}/>
                <Space width={10}/>
                <DatePickerView
                    placeHolderText={"End Date"}
                    selectedDate={endDate}
                    onShowCalendarClicked={() => {
                        if (onEndCalendarOpen) onEndCalendarOpen()
                    }}/>
                <Space width={10}/>
                {/*<MiniButton source={require("./../../../assets/icons/enter.png")} size={40}/>*/}
            </View>
        )
    }

    return <View style={[styles.fillingColumn]}>
        {/*top controls*/}
        <View style={{...styles.fillingRow, maxHeight: 60, alignItems: "center"}}>
            <ReportIntervalPicker
                startDate={startDate} endDate={endDate}
                onEndCalendarOpen={() => {
                    if (onEndCalendarOpen) onEndCalendarOpen()
                }}
                onStartCalendarOpen={() => {
                    if (onStartCalendarOpen) onStartCalendarOpen()
                }}
            />
            <Space flex={1}/>
            <CustomButton
                flex={1}
                text={"Unique Employee Report"}
                onPress={() => {
                    if (onSingleEmployeeClicked) onSingleEmployeeClicked()
                }}
            />
        </View>

        {/*report elements*/}
        <View style={[styles.fillingRow]}>
            {/*report items*/}
            <View style={{flex: 1}}>
                <ScrollView
                    style={{flex: 1}}>
                    <Table
                        items={reportTitles}
                        columnCountCalculationFunction={(windowWidth) => {
                            let count = Math.floor((windowWidth - 300) / 2 / 160);
                            if (count < 1) count = 1
                            return count
                        }}
                        itemGeneratorFunction={(item, index) => {
                            if (item == null) return <View style={{width: 135}}/>
                            return <ReportItem value={Math.round(reportValues[index] * 100) / 100} title={item}/>
                        }}
                    />

                </ScrollView>
                <Space height={40}/>
                {/*// total cost label*/}
                <View style={{flexDirection: "row"}}>
                    <TitleView
                        titleText={
                            `Total Cost: ${reportValues.length > 0 && !reportValues.includes(null, 12) ?
                                reportValues.slice(12).reduce((previousValue, currentValue) => previousValue + currentValue) :
                                0
                            }`
                        }
                    />
                </View>
            </View>

            {/*chart*/}
            <View style={{flex: 1}}>
                <FadeView
                    style={{flex: 1, margin: 20}}
                    visible={!transition}
                    duration={animationDuration}
                >
                    {/*pie chart*/}
                    {chartTypeRef.current === "pie" &&
                        <PieChart
                            style={{margin: 50}}
                            data={reportTitles.slice(4, 12).map((item, index) => {
                                return {
                                    title: item,
                                    value: reportValues.slice(4, 12)[index],
                                    color: chartColors[index]
                                }
                            })}
                            startAngle={0}
                            animate={true}
                            label={({dataEntry}) => {
                                if (dataEntry.value !== 0) {
                                    const percent = Math.round(dataEntry.percentage * 100) / 100
                                    return `${dataEntry.title}: ${percent}%`
                                }
                                return ""
                            }}
                            labelStyle={{fontSize: "4px", fontFamily: "LatoRegular", fontWeight: "200"}}
                            labelPosition={80}
                        />}
                    {/*linear chart*/}
                    {chartTypeRef.current === "linear" && yearlyEmployeeValues.length > 0 &&
                        <View style={{flex: 1}}>
                            {/*employees count report*/}
                            {yearlyEmployeeValues.length === 0 ||
                            yearlyEmployeeValues.findIndex(value => value.totalEmployees == null) !== -1 ||
                            yearlyEmployeeValues.map(item => item.totalEmployees).reduce((previousValue, currentValue) => currentValue + previousValue) === 0 ?
                                <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
                                    <Text style={fontStyles.lato700_14}>No budget info found for this year.</Text>
                                </View> :
                                <View style={{flex: 1, }}>
                                    <Text style={{...fontStyles.lato700_16, alignSelf: "center"}}>Total Monthly Employees Count</Text>
                                    <Chart
                                        style={{flex: 1}}
                                        data={yearlyEmployeeValues.map((item, index) => {
                                            return {x: index, y: item.totalEmployees}
                                        })}
                                        padding={{left: 40, bottom: 20, right: 20, top: 20}}
                                        xDomain={{min: 0, max: yearlyEmployeeValues.length - 1}}
                                        yDomain={{
                                            min: 0,
                                            max: yearlyEmployeeValues.map(item => item.totalEmployees).reduce((previousValue, currentValue) => {
                                                return Math.max(previousValue, currentValue);
                                            })
                                        }}
                                    >
                                        <VerticalAxis tickCount={11} theme={{labels: {formatter: (v) => v.toFixed(2)}}}/>
                                        {/*<HorizontalAxis tickCount={5}/>*/}
                                        <Area theme={{
                                            gradient: {
                                                from: {color: '#ffa502'},
                                                to: {color: '#ffa502', opacity: 0.4}
                                            }
                                        }}/>
                                        <Line theme={{
                                            stroke: {color: '#ffa502', width: 5},
                                            scatter: {default: {width: 4, height: 4, rx: 2}}
                                        }}/>
                                    </Chart>
                                </View>
                            }
                            {/*total budget report*/}
                            {yearlyBudgetValues.length === 0 ||
                            yearlyBudgetValues.findIndex(value => value.totalBudget == null) !== -1 ||
                            yearlyBudgetValues.map(item => item.totalBudget).reduce((previousValue, currentValue) => currentValue + previousValue) === 0 ?
                                <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
                                    <Text style={fontStyles.lato700_14}>No budget info found for this year.</Text>
                                </View> :
                                <View style={{flex: 1, }}>
                                    <Text style={{...fontStyles.lato700_16, alignSelf: "center"}}>Total Monthly Budget</Text>
                                    <Chart
                                        style={{flex: 1}}
                                        data={yearlyBudgetValues.map((item, index) => {
                                            return {x: index, y: item.totalBudget}
                                        })}
                                        padding={{left: 40, bottom: 20, right: 20, top: 20}}
                                        xDomain={{min: 0, max: yearlyBudgetValues.length - 1}}
                                        yDomain={{
                                            min: 0,
                                            max: yearlyBudgetValues.map(item => item.totalBudget).reduce((previousValue, currentValue) => {
                                                return Math.max(previousValue, currentValue);
                                            })
                                        }}
                                    >
                                        <VerticalAxis tickCount={11} theme={{labels: {formatter: (v) => v.toFixed(2)}}}/>
                                        {/*<HorizontalAxis tickCount={5}/>*/}
                                        <Area theme={{
                                            gradient: {
                                                from: {color: '#ffa502'},
                                                to: {color: '#ffa502', opacity: 0.4}
                                            }
                                        }}/>
                                        <Line theme={{
                                            stroke: {color: '#ffa502', width: 5},
                                            scatter: {default: {width: 4, height: 4, rx: 2}}
                                        }}/>
                                    </Chart>
                                </View>
                            }
                        </View>
                    }
                </FadeView>
            </View>
        </View>
    </View>;
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingTop: 40,
        paddingHorizontal: 20,
    },
    fillingRow: {
        flex: 1, flexDirection: "row"
    },
    fillingColumn: {
        flex: 1, flexDirection: "column"
    },
    centerMainAxis: {
        alignItems: "center"
    },
    centerCrossAxis: {
        justifyContent: "center"
    },
    center: {
        justifyContent: "center", alignItems: "center"
    }
})
