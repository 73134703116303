import {useEffect, useRef, useState} from "react";
import {
    addDays,
    addMonths, compareAsc,
    format,
    getDate,
    getDaysInMonth,
    getMonth,
    getYear, isLastDayOfMonth, lastDayOfMonth,
    lastDayOfWeek, setDate,
} from "date-fns";
import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colors} from "../../../colors";
import Table from "../../Table";
import Selector from "../../employee_screens/components/Selector";
import Space from "../../Space";
import CustomButton from "../../employee_screens/components/CustomButton";
import ScreenHeader from "../../ScreenHeader";
import TitleView from "../../employee_screens/components/TitleView";
import Footer from "../../Footer";
import {fontStyles} from "../../../fontStyles";
import HttpRequests from "../../../http_requests/HttpRequests";
import ProfileData from "../../../misc/ProfileData";
import StyledDropDown from "../../StyledDropDown";
import Helpers from "../../../misc/Helpers";
import SwedishHolidays from "../../../misc/swedish-holidays";
import {te} from "date-fns/locale";
import IntentMessenger from "../../../misc/IntentMessenger";

function EmployerTimeTableContents({postInfo, monthIndex, onMessage, onLoading, onBack, navigation}) {
    console.log(postInfo)
    const employeeInfo = postInfo.employeeInfo
    const isMonthly = employeeInfo.salaryType === "Monthly"
    const [selectedMonthInfo, setSelectedMonthInfo] = useState({})
    useEffect(() => {
        console.log(selectedMonthInfo)
    }, [selectedMonthInfo]);
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(monthIndex)
    useEffect(() => {
        setSelectedMonthInfo(postInfo.monthInfo[selectedMonthIndex])
        setSelectedDate(new Date(getYear(new Date()), selectedMonthIndex, 1))
    }, [selectedMonthIndex]);

    const [selectedDate, setSelectedDate] = useState(new Date(getYear(new Date()), selectedMonthIndex, 1))

    const [intervalStartDate, setIntervalStartDate] = useState({label: getDate(selectedDate)})
    const [intervalEndDate, setIntervalEndDate] = useState({label: getDate(selectedDate)})
    useEffect(() => {
        console.log(intervalEndDate)
    }, [intervalEndDate]);
    useEffect(() => {
        console.log(intervalStartDate)
    }, [intervalStartDate]);

    useEffect(() => {
        const filteredTimes = allocatedDates.filter(value => {
            return value.date === format(selectedDate, "yyyy-MM-dd")
        })
        setAllocatedTimes(filteredTimes)
        updateAllocatedDates()
    }, [selectedDate]);
    const [employeeTimeTableData, setEmployeeTimeTableData] = useState([])
    const [allocatedDates, setAllocatedDates] = useState([])
    const [allocatedTimes, setAllocatedTimes] = useState([])
    const [totalAllocatedTime, setTotalAllocatedTime] = useState(0)
    useEffect(() => {
        let totalTime = 0
        allocatedDates.forEach(value => {
            const startMinutes = value.startTime.split(":")[0] * 60 + value.startTime.split(":")[1] * 1
            const endMinutes = value.endTime.split(":")[0] * 60 + value.endTime.split(":")[1] * 1
            const totalMinutes = endMinutes - startMinutes
            totalTime += totalMinutes
        })
        console.log(totalTime)
        const filteredTimes = allocatedDates.filter(value => {
            return value.date === format(selectedDate, "yyyy-MM-dd")
        })
        console.log(filteredTimes)
        setAllocatedTimes(filteredTimes)
        setTotalAllocatedTime(totalTime / 60)
    }, [allocatedDates]);

    useEffect(() => {
        updateAllocatedDates()
    }, []);

    function updateAllocatedDates() {
        const startDate = new Date(getYear(new Date()), selectedMonthIndex, 1)
        const formattedStartDate = Helpers.formatDate(startDate)
        const formattedEndDate = Helpers.formatDate(addMonths(startDate, 1))
        onLoading(true)
        HttpRequests.getIntervalEmployerSuggestionTime(employeeInfo.userId, formattedStartDate, formattedEndDate).then(response => {
            console.log(response)
            if (response.success) {
                setAllocatedDates(response.extras)
            } else {
                setAllocatedDates([])
                if (response.message.toLowerCase() !== "No Data Available!".toLowerCase()) {
                    onMessage("Error", response.message)
                }
            }
            if (!isMonthly) {
                HttpRequests.getIntervalEmployeeSuggestionTime(employeeInfo.userId, formattedStartDate, formattedEndDate).then(response => {
                    onLoading(false)
                    console.log(response)
                    if (response.success) {
                        setEmployeeTimeTableData(response.extras)
                    } else {
                        setEmployeeTimeTableData([])
                        if (response.message.toLowerCase() !== "No Data Available!".toLowerCase()) {
                            onMessage("Error", response.message)
                        }
                    }
                })
            } else {
                onLoading(false)
            }
        })
    }

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation}/>
            <TitleView titleText={"Set Employee Time Table"}/>
            <View style={{flex: 1, flexDirection: "row"}}>
                <MonthSelectors
                    selectedDate={selectedDate}
                    onMonthChanged={(monthIndex) => {
                        setSelectedMonthIndex(monthIndex)
                    }}
                    monthsInfo={postInfo.monthInfo}
                />
                <View style={{backgroundColor: colors.darkGray, width: 1,}}/>
                <View style={{flex: 1}}>
                    <EmployeeInformation post={employeeInfo} monthInfo={selectedMonthInfo}
                                         allocatedTime={totalAllocatedTime}/>
                    <View style={{flex: 1, flexDirection: "row"}}>
                        <Space width={20}/>
                        <DaySelectors
                            isMonthly={isMonthly}
                            employeeTimeTableData={employeeTimeTableData}
                            selectedDate={selectedDate}
                            onBack={onBack}
                            onDaySelected={onDaySelected}
                            onClearAll={onClearAllClicked}
                            onClearInterval={onClearIntervalClicked}
                        />
                        <Space width={20}/>
                        <TimeSelectors
                            onMessage={onMessage}
                            allocatedTimes={allocatedTimes}
                            timeTableOfSelectedDate={employeeTimeTableData.filter(value => value.date === Helpers.formatDate(selectedDate))}
                            onDeleteClicked={onDeleteClicked}
                            onAddClicked={onAddClicked}/>
                    </View>
                </View>
            </View>
            <Footer/>
        </View>
    )

    function onDaySelected(day) {
        const newDate = new Date(getYear(selectedDate), getMonth(selectedDate), day)
        setSelectedDate(newDate)
    }

    function isRangeOk(time, date) {
        const startMinutes = Helpers.getMinutesOfTime(time.start_time)
        const endMinutes = Helpers.getMinutesOfTime(time.end_time)
        return employeeTimeTableData.some(value => {
            const timeTableStartMinutes = Helpers.getMinutesOfTime(value.start_time)
            const timeTableEndMinutes = Helpers.getMinutesOfTime(value.end_time)
            return Helpers.formatDate(date) === value.date &&
                startMinutes >= timeTableStartMinutes && startMinutes < timeTableEndMinutes &&
                endMinutes > timeTableStartMinutes && endMinutes <= timeTableEndMinutes
        })
    }

    function clearInterval(start, end) {
        const formattedStartDate = Helpers.formatDate(setDate(selectedDate, start));
        const formattedEndDate = Helpers.formatDate(setDate(selectedDate, end));
        console.log(formattedStartDate)
        console.log(formattedEndDate)
        onLoading(true)
        HttpRequests.deleteIntervalEmployerSuggestionTime(
            employeeInfo.userId,
            employeeInfo.postId,
            selectedMonthInfo.post_month_id,
            formattedStartDate,
            formattedEndDate
        ).then(response => {
            onLoading(false)
            console.log(response)
            if (response.success) {
                updateAllocatedDates()
            } else {
                // handle error
            }
        })

        setIntervalEndDate({label: getDate(selectedDate)})
        setIntervalStartDate({label: getDate(selectedDate)})
    }

    function onClearIntervalClicked() {
        const items = Array.from({length: getDaysInMonth(selectedDate)}, (v, k) => {
            return {label: k + 1}
        })
        onMessage("Select Interval", null,
            () => {
                const start = IntentMessenger.popMessage("interval start") ?? getDate(selectedDate)
                const end = IntentMessenger.popMessage("interval end") ?? getDate(selectedDate)
                if (start > end) {
                    setTimeout(() => {
                        onMessage("Wrong Interval", "End date should be greater than or equal to start date.", () => {
                            setIntervalEndDate({label: getDate(selectedDate)})
                            setIntervalStartDate({label: getDate(selectedDate)})
                        })
                    }, 200)
                } else {
                    clearInterval(start, end);
                }
            },
            () => {
            },
            <View style={{flexDirection: "row", alignItems: "center"}}>
                <Text style={{...fontStyles.lato700_14, color: colors.mediumGray}}>This action will clear all post
                    data between </Text>
                <StyledDropDown
                    style={{minWidth: 100}} items={items} value={intervalStartDate.label}
                    topLabel={false}
                    onChange={(item) => {
                        setIntervalStartDate(item)
                        IntentMessenger.popMessage("interval start")
                        IntentMessenger.setMessage("interval start", item.label)
                    }}
                />
                <Text style={{...fontStyles.lato700_14, color: colors.mediumGray}}> and </Text>
                <StyledDropDown
                    style={{minWidth: 100}} items={items} value={intervalEndDate.label} topLabel={false}
                    onChange={(item) => {
                        setIntervalEndDate(item)
                        IntentMessenger.popMessage("interval end")
                        IntentMessenger.setMessage("interval end", item.label)
                    }}
                />
                <Text style={{
                    ...fontStyles.lato700_14,
                    color: colors.mediumGray
                }}> of {format(selectedDate, "MMM yyyy")}. Click ok if you want to continue.</Text>
            </View>
        )
    }

    function onClearAllClicked() {
        const message = `This action will clear all post data of this month. Do you want to continue?`
        onMessage("Warning", message,
            () => {
                const end = getDate(setDate(addMonths(selectedDate, 1), 0))
                clearInterval(1, end)
            }, () => {
            }
        )
    }

    function onAddClicked(times, rangeType) {
        // todo: handle errors in another function which returns false in case of error and appropriate error message, otherwise true
        // todo: after separation of error handling from code logic, mix month and week because only dates are different
        console.log(ProfileData.userId)
        // todo: exclusion dates should be subtracted from timeToAdd
        let timeToAdd = 0
        for (let i = 0; i < times.length; i++) {
            const startMinutes = Helpers.getMinutesOfTime(times[i].start_time)
            const endMinutes = Helpers.getMinutesOfTime(times[i].end_time)
            timeToAdd += (endMinutes - startMinutes) / 60
        }
        console.log(timeToAdd)
        const timeToFillPercent = selectedMonthInfo.posted_percent
        const timeToFillHours = Math.round(selectedMonthInfo.total_monthly_hours * timeToFillPercent / 10) / 10
        const newTotalTime = totalAllocatedTime + timeToAdd
        if (rangeType == null) {
            if (newTotalTime > timeToFillHours) {
                onMessage("Error", "Total time of posts should not be more than this post hours.")
            } else if (!isMonthly && !isRangeOk(times[0], selectedDate)) {
                onMessage("Error", "Added time range should fall between one of the availability ranges.")
            } else {
                onLoading(true)
                HttpRequests.addSingleEmployerSuggestionTime(
                    employeeInfo.userId,
                    ProfileData.userId,
                    employeeInfo.postId,
                    selectedMonthInfo.post_month_id,
                    Helpers.formatDate(selectedDate),
                    times[0].start_time,
                    times[0].end_time,
                    employeeInfo.workingStatusId
                ).then(response => {
                    onLoading(false)
                    console.log(response)
                    if (response.success) {
                        updateAllocatedDates()
                    } else {
                        onMessage("Error", response.message)
                    }
                })
            }
        } else if (rangeType === "week") {
            // selected date should not be last day of month
            if (isLastDayOfMonth(selectedDate)) {
                onMessage("Error", "You are currently selecting last day of month, you cannot proceed to next month.")
                return
            }
            // selected date should not be last day of week
            let lastDay = addDays(lastDayOfWeek(selectedDate), isMonthly ? -1 : 1)
            const startDate = format(selectedDate, "yyyy-MM-dd")
            let endDate = format(lastDay, "yyyy-MM-dd")
            if (endDate === startDate) {
                onMessage("Error", "Selected day is last day of week.")
                return;
            }

            //
            let message = `Are you sure you want to add this time to the rest of this week? ${
                isMonthly ? "(holidays are excluded)" : "(days without availability are excluded)"
            }`
            if (getMonth(lastDay) !== getMonth(selectedDate)) {
                message = "Last day of this week exceeds last day of this month, do you want to fill to the end of this month instead?"
                lastDay = lastDayOfMonth(selectedDate)
                endDate = format(lastDay, "yyyy-MM-dd")
            }

            let daysToExclude = []
            let exclusionDate = selectedDate
            let rangeOk = true
            while (compareAsc(exclusionDate, lastDay) === -1) {
                if (isMonthly) {
                    const dayName = format(exclusionDate, "EEEE").toLowerCase()
                    const isWeekend = dayName.startsWith("sun") || dayName.startsWith("sat")
                    const isHoliday =
                        SwedishHolidays.getHolidays(getYear(selectedDate)).some(value => {
                            return value.date.getTime() === exclusionDate.getTime()
                        })
                    if (isHoliday || isWeekend) {
                        daysToExclude.push(Helpers.formatDate(exclusionDate))
                    }
                } else {
                    const foundData = employeeTimeTableData.filter(value => value.date === Helpers.formatDate(exclusionDate))
                    if (foundData.length === 0) {
                        daysToExclude.push(Helpers.formatDate(exclusionDate))
                    } else {
                        for (let i = 0; i < times.length; i++) {
                            rangeOk = rangeOk && isRangeOk(times[i], exclusionDate)
                        }
                    }
                }
                exclusionDate = addDays(exclusionDate, 1)
            }

            const dayDifference = (lastDay.getTime() - selectedDate.getTime()) / 24 / 3600 / 1000 - daysToExclude.length
            console.log(dayDifference)
            const newTotalTime = totalAllocatedTime + timeToAdd * dayDifference
            if (newTotalTime > timeToFillHours) {
                onMessage("Error", "Total time of posts should not be more than this post hours.")
            } else if (!rangeOk) {
                onMessage("Error", "Added time range should fall between one of the availability ranges for all days of this week.")
            } else {
                onMessage("Add new time", message,
                    () => {
                        onLoading(true)
                        HttpRequests.addIntervalEmployerSuggestionTime(
                            employeeInfo.userId,
                            +ProfileData.userId,
                            employeeInfo.postId,
                            selectedMonthInfo.post_month_id,
                            Helpers.formatDate(addDays(selectedDate, 1)),
                            endDate,
                            times,
                            employeeInfo.workingStatusId,
                            daysToExclude
                        ).then(result => {
                            onLoading(false)
                            if (result.success) {
                                updateAllocatedDates()
                            } else {
                                onMessage("Error", result.message)
                            }
                        })
                    }, () => {
                    })
            }
        } else if (rangeType === "month") {
            if (!isLastDayOfMonth(selectedDate)) {
                const startDate = addDays(selectedDate, 1)
                const endDate = lastDayOfMonth(selectedDate)
                let message = `Are you sure you want to add this time to the rest of this month? ${
                    isMonthly ? "(holidays are excluded)" : "(days without availability are excluded)"
                }`

                let daysToExclude = []
                let exclusionDate = selectedDate
                let rangeOk = true
                while (compareAsc(exclusionDate, endDate) === -1) {
                    if (isMonthly) {
                        const dayName = format(exclusionDate, "EEEE").toLowerCase()
                        const isWeekend = dayName.startsWith("sun") || dayName.startsWith("sat")
                        const isHoliday =
                            SwedishHolidays.getHolidays(getYear(selectedDate)).some(value => {
                                return value.date.getTime() === exclusionDate.getTime()
                            })
                        if (isHoliday || isWeekend) {
                            daysToExclude.push(Helpers.formatDate(exclusionDate))
                        }
                    } else {
                        const foundData = employeeTimeTableData.filter(value => value.date === Helpers.formatDate(exclusionDate))
                        if (foundData.length === 0) {
                            daysToExclude.push(Helpers.formatDate(exclusionDate))
                        } else {
                            for (let i = 0; i < times.length; i++) {
                                rangeOk = rangeOk && isRangeOk(times[i], exclusionDate)
                            }
                        }
                    }
                    exclusionDate = addDays(exclusionDate, 1)
                }
                console.log(daysToExclude)

                const dayDifference = (endDate.getTime() - selectedDate.getTime()) / 24 / 3600 / 1000 - daysToExclude.length
                console.log(dayDifference)
                const newTotalTime = totalAllocatedTime + timeToAdd * dayDifference
                if (newTotalTime > timeToFillHours) {
                    onMessage("Error", "Total time of posts should not be more than this post hours.")
                } else if (!rangeOk) {
                    onMessage("Error", "Added time range should fall between one of the availability ranges for all days of this month.")
                } else {
                    onMessage("Add new time", message,
                        () => {
                            onLoading(true)
                            HttpRequests.addIntervalEmployerSuggestionTime(
                                employeeInfo.userId,
                                +ProfileData.userId,
                                employeeInfo.postId,
                                selectedMonthInfo.post_month_id,
                                Helpers.formatDate(startDate),
                                Helpers.formatDate(endDate),
                                times,
                                employeeInfo.workingStatusId,
                                daysToExclude
                            ).then(result => {
                                onLoading(false)
                                console.log(result)
                                if (result.success) {
                                    updateAllocatedDates()
                                } else {
                                    onMessage("Error", result.message)
                                }
                            })
                        }, () => {
                        })
                }
            } else {
                onMessage("Error", "Selected day is last day of month.")
            }
        }
    }

    function onDeleteClicked(index) {
        onMessage("Delete", "Are you sure you want to delete this time?",
            () => {
                onLoading(true)
                HttpRequests.deleteEmployerSuggestionTime(allocatedTimes[index].id).then((result) => {
                    onLoading(false)
                    console.log(result)
                    if (result.success) {
                        updateAllocatedDates()
                    } else {
                        onMessage("Error", result.message)
                    }
                })
            },
            () => {
            }
        )
    }
}

function MonthSelectors({selectedDate, onMonthChanged, monthsInfo}) {
    let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    return (
        <ScrollView style={{flexGrow: 0}}>
            <View style={{maxWidth: 190, alignItems: "center", marginRight: 20}}>
                <Text style={styles.monthSelectorTextStyle}>{getYear(selectedDate)}</Text>
                <Table columnsCount={1} items={months} itemGeneratorFunction={(month, id) => {
                    const isSelectable =
                        monthsInfo[id].availability !== 0 || monthsInfo[id].posted_percent !== 0
                    return (
                        <Selector
                            style={{marginBottom: 15}}
                            text={month}
                            selectedBorderColor={colors.lightYellow}
                            deselectedBorderColor={colors.navyBlue}
                            selectedFillColor={colors.lightYellow}
                            deselectedFillColor={isSelectable ? "white" : colors.lighterGray}
                            selectedTextColor={colors.darkGray}
                            deselectedTextColor={colors.navyBlue}
                            selectedId={getMonth(selectedDate)}
                            id={id}
                            onSelectedChange={isSelectable ? (id) => {
                                onMonthChanged(id)
                                // setSelectedDate(setMonth(selectedDate, id))
                                // console.log(selectedDate)
                                // setSelectedId(id)

                            } : null}
                        />)
                }}/>
            </View>
        </ScrollView>
    )
}

function DaySelectors({
                          selectedDate,
                          employeeTimeTableData,
                          isMonthly,
                          onDaySelected,
                          onBack,
                          onClearAll,
                          onClearInterval
                      }) {
    let holidays = SwedishHolidays.getHolidays(getYear(new Date()))

    function getWeekDayName(day) {
        const date = new Date(getYear(selectedDate), getMonth(selectedDate), day)
        return format(date, "dd - EEEE")
    }

    function isHoliday(day) {
        const isHoliday = holidays.filter(value => {
            let holidayDate = value.date
            return getYear(holidayDate) === getYear(selectedDate) && getMonth(holidayDate) === getMonth(selectedDate) && getDate(holidayDate) === day
        }).length !== 0
        const isWeekend = getWeekDayName(day).includes("Sunday") || getWeekDayName(day).includes("Saturday")
        return isHoliday || isWeekend
    }

    function getDayTextColor(day) {
        return isHoliday(day) ? colors.navyBlue : colors.darkGray
    }

    function getDayFillColor(day) {
        if (!isMonthly) return "white"
        return !isHoliday(day) ? "white" : colors.lighterGray
    }

    let days = []
    for (let i = 1; i <= getDaysInMonth(selectedDate); i++) {
        days = days.concat(i)
    }

    return (
        <View style={{flex: 1}}>
            <Space height={20}/>
            <ScrollView style={{flex: 1}}>
                <View>
                    <Table
                        minColumnWidth={180}
                        items={days}
                        columnCountCalculationFunction={(windowWidth) => Math.floor(windowWidth / 2 / 180 - 1)}
                        itemGeneratorFunction={(day, id) => {
                            const itemDate = new Date(getYear(selectedDate), getMonth(selectedDate), day)
                            // hourly employees should have their timetable set for this day to be clickable.
                            // monthly employees are not available in holidays and are not clickable
                            const isClickable =
                                (!isMonthly && employeeTimeTableData.some(value => value.date === Helpers.formatDate(itemDate))) ||
                                (isMonthly && !isHoliday(day))
                            if (day == null) {
                                return <View style={{
                                    backgroundColor: "transparent",
                                    width: 140,
                                    height: 40,
                                    marginBottom: 15,
                                    marginHorizontal: 10
                                }}/>
                            }
                            return (
                                <Selector
                                    style={{marginBottom: 15, marginHorizontal: 10}}
                                    text={getWeekDayName(day)}
                                    selectedBorderColor={colors.lightYellow}
                                    deselectedBorderColor={colors.lightYellow}
                                    selectedFillColor={colors.lightYellow}
                                    deselectedFillColor={isClickable ? "white" : colors.lighterGray}
                                    selectedTextColor={getDayTextColor(id + 1)}
                                    deselectedTextColor={getDayTextColor(id + 1)}
                                    selectedId={getDate(selectedDate)}
                                    id={id + 1}
                                    onSelectedChange={(id) => {
                                        if (isClickable) {
                                            onDaySelected(id)
                                        }
                                    }}
                                />
                            )
                        }}/>
                </View>
            </ScrollView>
            <Space height={20}/>
            <View style={{flexDirection: "row", gap: 10}}>
                <CustomButton text={"Done"} fontSize={14} onPress={() => {
                    onBack()
                }}/>
                <CustomButton text={"Clear All"} fontSize={14} onPress={() => {
                    onClearAll()
                }}/>
                <CustomButton text={"Clear Interval"} fontSize={14} onPress={() => {
                    onClearInterval()
                }}/>
            </View>
        </View>
    )
}

function TimeSelectors({allocatedTimes, onAddClicked, timeTableOfSelectedDate, onDeleteClicked, onMessage}) {
    console.log(timeTableOfSelectedDate)
    const hours = []
    for (let i = 0; i < 24; i++) {
        hours.push({
            label: String(i).padStart(2, "0") + ":00",
            value: String(i).padStart(2, "0") + ":00",
        })
        hours.push({
            label: String(i).padStart(2, "0") + ":30",
            value: String(i).padStart(2, "0") + ":30",
        })
    }

    const startTime = useRef("08:00");
    const endTime = useRef("17:00");

    function getMinutes(time) {
        let parts = time.split(":")
        return parseInt(parts[0]) * 60 + parseInt(parts[1])
    }

    function TimeCard({start, end, index, onDeleteClicked}) {
        return (
            <View
                style={{
                    borderRadius: 20,
                    backgroundColor: colors.navyBlue,
                    height: 40,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                }}
            >
                <View style={{
                    height: 40,
                    width: 40,
                    borderRadius: 20,
                    borderWidth: 1,
                    borderColor: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: colors.navyBlue,
                }}>
                    <Text
                        style={{
                            color: "white"
                        }}
                    >{index + 1}</Text>
                </View>
                <Text style={{
                    color: "white",
                    flex: 1,
                    alignContent: "center",
                    marginHorizontal: 10,
                }}>{`${start} to ${end}`}</Text>

                {onDeleteClicked &&
                    <TouchableOpacity
                        style={{
                            height: 40,
                            width: 40,
                            borderRadius: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: colors.navyBlue,
                        }}
                        onPress={() => {
                            onDeleteClicked(index)
                        }}
                    >
                        <Image style={{width: 20, height: 20, marginHorizontal: 10}}
                               source={require("../../../assets/icons/delete.png")}/>
                    </TouchableOpacity>
                }
            </View>
        )
    }

    return (
        <View style={{flex: 1}}>
            <Space height={20}/>
            <ScrollView style={{flex: 1, borderWidth: 1, borderColor: colors.navyBlue, borderRadius: 20, padding: 25}}>
                <StyledDropDown
                    style={{height: 40}}
                    placeHolder={"Start Time"}
                    items={hours}
                    value={startTime.current}
                    onChange={(hour) => {
                        startTime.current = hour.value
                    }}
                    topLabel={true}
                />
                <Space height={15}/>
                <StyledDropDown
                    style={{height: 40}}
                    placeHolder={"End Time"}
                    items={hours}
                    value={endTime.current}
                    onChange={(hour) => {
                        endTime.current = hour.value
                    }}
                    topLabel={true}
                />
                <Space height={15}/>
                <CustomButton
                    style={{flex: 1}} fontSize={14} text={"Add"}
                    onPress={() => {
                        console.log(startTime.current)
                        console.log(endTime.current)
                        if (startTime.current === "" || endTime.current === "") {
                            onMessage("Error", "Please set start time and end time.")
                        } else if (getMinutes(startTime.current) >= getMinutes(endTime.current)) {
                            onMessage("Error", "Start time should be smaller than end time.")
                        } else {
                            onAddClicked([{start_time: startTime.current, end_time: endTime.current}])
                            startTime.current = "08:00"
                            endTime.current = "17:00"
                        }
                    }}/>
                <Space height={20}/>
                <View style={{flexDirection: "row"}}>
                    {timeTableOfSelectedDate.length > 0 &&
                        <>
                            <Text style={{...fontStyles.lato700_18, marginBottom: 20, width: "50%"}}>Availability</Text>
                            <Space width={20}/>
                        </>
                    }
                    <Text style={{
                        ...fontStyles.lato700_18,
                        marginBottom: 20,
                        width: timeTableOfSelectedDate.length > 0 ? "50%" : "100%"
                    }}>This day times</Text>
                </View>
                <View style={{flexDirection: "row"}}>
                    {timeTableOfSelectedDate.length > 0 &&
                        <>
                            <View style={{width: "50%"}}>
                                {
                                    timeTableOfSelectedDate.map((value, index, array) => {
                                        return <TimeCard start={value.start_time} end={value.end_time} index={index}/>
                                    })
                                }
                            </View>
                            <Space width={20}/>
                        </>
                    }
                    <View style={{width: timeTableOfSelectedDate.length > 0 ? "50%" : "100%"}}>
                        {
                            allocatedTimes.map((value, index, array) => {
                                return <TimeCard start={value.startTime} end={value.endTime} index={index}
                                                 onDeleteClicked={onDeleteClicked}/>
                            })
                        }
                    </View>
                </View>
                <Space height={15}/>
                <View style={{flexDirection: "row", marginTop: 30}}>
                    <CustomButton
                        style={{flex: 1}} fontSize={14} text={"Set this times to all the Month"}
                        onPress={(allocatedTimes.length > 0) ? () => {
                            const times = allocatedTimes.map((value) => {
                                return {start_time: value.startTime, end_time: value.endTime}
                            })
                            onAddClicked(times, "month")
                        } : null}/>
                    <Space width={15}/>
                    <CustomButton
                        style={{flex: 1}} fontSize={14} text={"Set this times to all the Week"}
                        onPress={allocatedTimes.length > 0 ? () => {
                            const times = allocatedTimes.map((value) => {
                                return {start_time: value.startTime, end_time: value.endTime}
                            })
                            onAddClicked(times, "week")
                        } : null}/>
                </View>
            </ScrollView>
        </View>
    )
}

function EmployeeInformation({post, monthInfo, allocatedTime}) {
    function LeftCard({post}) {
        return (
            <View style={{...styles.leftInfoCardContainer}}>
                {/*employee name*/}
                <Text style={{...fontStyles.lato700_20}}>{post.name}</Text>
                {/*position*/}
                <Text style={{...fontStyles.lato700_20}}>{post.position}</Text>
                {/*id*/}
                <View style={{flexDirection: "row", alignItems: "center"}}>
                    <Text style={{...fontStyles.lato400_16, color: colors.mediumGray}}>ID: </Text>
                    <Text style={{...fontStyles.lato700_16}}>{post.userId}</Text>
                </View>
                {/*salary type*/}
                <Text style={{...fontStyles.lato700_16}}>{post.salaryType} staff</Text>
                {/*employee status*/}
                <Text
                    style={{
                        ...fontStyles.lato400_12,
                        width: 120,
                        backgroundColor: post.userStatus.includes("Current") ? colors.lightGreen : colors.lightRed,
                        lineHeight: 20,
                        borderRadius: 10,
                        textAlign: "center",
                        textAlignVertical: "center",
                        color: "white"
                    }}
                >
                    {post.userStatus}
                </Text>
            </View>
        )
    }

    function CenterCard({post, monthInfo}) {
        return (
            <View style={{alignItems: "start", justifyContent: "space-between", paddingVertical: 30}}>
                <View style={{maxHeight: 30, flexDirection: "row"}}>
                    <Text style={{...fontStyles.lato700_14}}>Total must working time: </Text>
                    <Text style={{...fontStyles.lato400_14}}>100% | {monthInfo.total_monthly_hours} Hours</Text>
                </View>
                <View style={{maxHeight: 30, flexDirection: "row"}}>
                    <Text style={{...fontStyles.lato700_14}}>Cost Center: </Text>
                    <Text style={{...fontStyles.lato400_14}}>{post.costCenter}</Text>
                </View>
                <View style={{maxHeight: 30, flexDirection: "row"}}>
                    <Text style={{...fontStyles.lato700_14}}>Project: </Text>
                    <Text style={{...fontStyles.lato400_14}}>{post.project}</Text>
                </View>
                <View style={{maxHeight: 30, flexDirection: "row"}}>
                    <Text style={{...fontStyles.lato700_14}}>Operation: </Text>
                    <Text style={{...fontStyles.lato400_14}}>{post.operation}</Text>
                </View>
                <View style={{maxHeight: 30, flexDirection: "row"}}>
                    <Text style={{...fontStyles.lato700_14}}>Activity: </Text>
                    <Text style={{...fontStyles.lato400_14}}>{post.activity}</Text>
                </View>
            </View>
        )
    }

    function RightCard({monthInfo, allocatedTime}) {
        const timeToFillPercent = monthInfo.posted_percent
        const timeToFillHours = Math.round(monthInfo.total_monthly_hours * timeToFillPercent / 10) / 10
        const remainingTimeToFill = timeToFillHours - allocatedTime
        return (
            <View style={styles.rightInfoCardContainer}>
                <Text style={fontStyles.lato700_18}>This post (%): {timeToFillPercent}%</Text>
                <Text style={fontStyles.lato700_18}>This post (hours): {timeToFillHours} hours</Text>
                <View style={{flexDirection: "row", height: 64, justifyContent: "space-between"}}>
                    <View style={{alignItems: "center", justifyContent: "space-between"}}>
                        <Text style={fontStyles.lato700_18}>Set</Text>
                        <Text style={{
                            ...fontStyles.lato400_14,
                            lineHeight: 32,
                            backgroundColor: colors.lightRed,
                            borderRadius: 16,
                            width: 140,
                            textAlign: "center"
                        }}>{`${Math.round(allocatedTime / timeToFillHours * 1000) / 10} % | ${allocatedTime} hours`}</Text>
                    </View>
                    <Space width={40}/>
                    <View style={{alignItems: "center", justifyContent: "space-between"}}>
                        <Text style={fontStyles.lato700_18}>Remaining </Text>
                        <Text style={{
                            ...fontStyles.lato400_14,
                            lineHeight: 32,
                            backgroundColor: colors.lightRed,
                            borderRadius: 16,
                            width: 140,
                            textAlign: "center"
                        }}>{`${Math.round(remainingTimeToFill / timeToFillHours * 1000) / 10} % | ${remainingTimeToFill} hours`}</Text>
                    </View>
                </View>
            </View>
        )
    }

    return (
        <View style={{flexDirection: "row"}}>
            <Space width={20}/>
            <View style={{flex: 1, justifyContent: "space-between", flexDirection: "row", maxHeight: 175, gap: 10}}>
                <LeftCard post={post}/>
                <CenterCard post={post} monthInfo={monthInfo}/>
                <RightCard monthInfo={monthInfo} allocatedTime={allocatedTime}/>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    monthSelectorTextStyle: {
        ...fontStyles.lato700_14,
        lineHeight: 40,
        textAlignVertical: "center",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: 40,
        width: 140,
        borderWidth: 1,
        borderColor: colors.lightYellow,
        borderRadius: 20,
        marginBottom: 15,
    },
    drawerStyles: {
        drawerType: "permanent",
        headerShown: false,
        drawerActiveBackgroundColor: colors.navyBlue,
        drawerActiveTintColor: "white",
        drawerStyle:
            {
                backgroundColor: colors.lightGray,
                width: 90,
            }
    },
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignContent: "center",
        alignItems: "center",
        maxHeight: 55,
        marginBottom: 30
    },
    // info card styles
    leftInfoCardContainer: {
        flex: 1,
        maxWidth: 500,
        minWidth: 375,
        borderWidth: 1,
        borderColor: colors.navyBlue,
        borderRadius: 20,
        padding: 15,
        justifyContent: "space-between"
    },
    rightInfoCardContainer: {
        flex: 1,
        maxWidth: 500,
        minWidth: 375,
        borderWidth: 1,
        borderColor: colors.navyBlue,
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "space-around"
    }
})

export default EmployerTimeTableContents
