import {StatusBar} from 'expo-status-bar';
import {StyleSheet, Text, View, Button, TextInput} from 'react-native';
import {useFonts} from "expo-font";
import EmployeeMainScreen from "./components/employee_screens/EmployeeMainScreen";
import LoginScreen from "./components/login_screens/LoginScreen";
import {NavigationContainer} from "@react-navigation/native";
import {createStackNavigator} from "@react-navigation/stack";
import EmployerMainScreen from "./components/employer_screens/EmployerMainScreen";

export default function App() {
    // fonts
    const [fontsLoaded] = useFonts({
        'LatoRegular': require('./assets/fonts/Lato-Regular.ttf'),
        'Lato': require('./assets/fonts/Lato-Light.ttf'),
    });

    if (!fontsLoaded) {
        return (<View/>)
    }

    const Stack = createStackNavigator();

    return (
        <NavigationContainer>
            <Stack.Navigator screenOptions={{headerShown: false}}>
                <Stack.Screen name={"LoginScreen"} component={LoginScreen}/>
                <Stack.Screen name={"EmployeeScreens"} component={EmployeeMainScreen}/>
                <Stack.Screen name={"EmployerScreens"} component={EmployerMainScreen}/>
            </Stack.Navigator>
        </NavigationContainer>
    )
}
