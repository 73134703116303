import {Image, TouchableHighlight} from "react-native";
import {colors} from "../../../colors";

function MiniButton({onPress, source, size = 22}) {
    let localColors = {
        lightCyanTransparent: "#61DAFB88",
    }
    return (
        <TouchableHighlight
            style={{
                width: size,
                height: size,
                borderRadius: size / 2,
                backgroundColor: colors.lightYellow,
                alignItems: "center",
                justifyContent: "space-around",

            }}
            onPress={onPress}
            underlayColor={localColors.lightCyanTransparent}
        >
            <Image style={{flex: 1, aspectRatio: 1, resizeMode: "contain", margin: 7}} source={source}/>
        </TouchableHighlight>
    )
}

export default MiniButton
