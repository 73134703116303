import {Text, TextInput, View} from "react-native";
import {fontStyles} from "../../../fontStyles";
import {forwardRef, useEffect, useState} from "react";
import {colors} from "../../../colors";

const StyledEditText =
    forwardRef(({
                    style,
                    value,
                    isSecure,
                    placeholder,
                    multiline,
                    onChangeText,
                    topLabel,
                    enabled = true
                }, ref) => {
        const [showTitle, setShowTitle] = useState(false)
        useEffect(() => {
            setShowTitle(value !== null && value !== undefined && value !== "")
        }, [value]);
        let height = style && style.height ? style.height : 40
        return (
            <View style={[style, {
                borderColor: colors.lightYellow,
                borderRadius: 20,
                borderWidth: 1,
                height: height,
            }]}>
                <TextInput
                    ref={ref}
                    style={[style, {
                        ...fontStyles.lato700_14,
                        flex: 1,
                        height: multiline ? height - 30 : height,
                        minHeight: multiline ? height - 30 : height,
                        maxHeight: multiline ? height - 30 : height,
                        lineHeight: 17,
                        marginVertical: multiline ? 15 : 0,
                        marginHorizontal: 15,
                        outlineStyle: 'none',
                    }]}
                    editable={enabled}
                    focusable={enabled}
                    value={value}
                    secureTextEntry={isSecure === true}
                    onChangeText={text => {
                        console.log(value)
                        if (value === undefined) {
                            setShowTitle(text !== "")
                        }
                        onChangeText(text)
                    }}
                    placeholder={placeholder}
                    placeholderTextColor={colors.mediumGray}
                    multiline={multiline}
                />
                {
                    showTitle && <Text style={{
                        ...fontStyles.lato400_12,
                        marginLeft: 15,
                        paddingHorizontal: 3,
                        alignSelf: "flex-start",
                        color: colors.mediumGray,
                        backgroundColor: "white",
                        zIndex: 1,
                        lineHeight: 15,
                        marginTop: -height - 15 / 1.5
                    }}>{topLabel === true ? placeholder : topLabel}</Text>
                }
            </View>
        )
    });

export default StyledEditText
