import React, {useEffect, useRef, useState} from "react";
import {format, getDaysInMonth, getDaysInYear, getMonth, getYear} from "date-fns";
import {Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {colors} from "../../../colors";
import Space from "../../Space";
import CustomButton from "../../employee_screens/components/CustomButton";
import Table from "../../Table";
import ReportItem from "../../ReportItem";
import TitleView from "../../employee_screens/components/TitleView";
import {PieChart} from "react-minimal-pie-chart";
import StyledDropDown from "../../StyledDropDown";
import Helpers from "../../../misc/Helpers";
import {fontStyles} from "../../../fontStyles";

export default function SingleEmployeeMonthlyReports(
    {
        startDate,
        endDate,
        employees,
        selectedEmployee,
        reportTitles,
        reportValues,
        onDailyClicked,
        onOverallClicked,
        onEmployeeChanged,
    }) {

    const chartColors = [
        '#a8d08d',
        '#9cc3e5',
        '#ffd965',
        '#c9c9c9',
        '#f4b183',
        '#8eaadb',
        '#8496b0',
        '#757070']

    const [employeeId, setEmployeeId] = useState(-1)
    const [employeeName, setEmployeeName] = useState("Employee Name")
    const [employeePosition, setEmployeePosition] = useState("Employee Position")
    const [employeeEmail, setEmployeeEmail] = useState("Employee Email")

    useEffect(() => {
        if (selectedEmployee != null) {
            setEmployeeId(selectedEmployee.id)
            setEmployeeName(selectedEmployee.name)
            setEmployeePosition(selectedEmployee.position)
            setEmployeeEmail(selectedEmployee.email)
        }
    }, [selectedEmployee]);

    function getTitle() {
        const rangeType = Helpers.getRangeType(startDate, endDate)
        if (rangeType === Helpers.rangeTypes.month) {
            return `${format(startDate, "MMMM yyyy")} report`
        } else if (rangeType === Helpers.rangeTypes.year) {
            return `${format(startDate, "yyyy")} report`
        } else {
            return "Custom report"
        }
    }

    return (
        <View style={{...styles.fillingColumn}}>
            {/*search bar*/}
            <SearchBar items={employees}
                       onChange={(item) => {
                           setEmployeeId(item.id)
                           setEmployeeName(item.name)
                           setEmployeePosition(item.position)
                           setEmployeeEmail(item.email)
                           onEmployeeChanged(item)
                       }}
                       onBackPressed={onOverallClicked}
            />
            <Space height={25}/>
            {/*employee details*/}
            <EmployeeDetails name={employeeName} id={employeeId} position={employeePosition} email={employeeEmail}/>
            {/*divider*/}
            <Space height={20}/>
            <View style={{flex: 1, flexDirection: "row", maxHeight: 40, alignItems: "center"}}>
                <Text style={{...fontStyles.lato700_20, lineHeight: 24}}>{getTitle()}</Text>
                <Space flex={1}/>
                <CustomButton style={{maxWidth: 150}} text={"Daily"} onPress={() => {
                    if (onDailyClicked) onDailyClicked()
                }}/>
            </View>
            <View style={styles.fillingRow}>
                {/*report items*/}
                <View style={{flex: 1}}>
                    <ScrollView
                        style={{flex: 1}}>
                        <Table
                            items={reportTitles}
                            columnCountCalculationFunction={(windowWidth) => {
                                let count = Math.floor((windowWidth - 300) / 2 / 160);
                                if (count < 1) count = 1
                                return count
                            }}
                            itemGeneratorFunction={(item, index) => {
                                if (item == null) return <View style={{width: 135}}/>
                                return <ReportItem value={reportValues[index]} title={item}/>
                            }}
                        />
                    </ScrollView>
                    <Space height={40}/>

                    {/*// total cost label*/}
                    <View style={{flexDirection: "row"}}>
                        <TitleView titleText={"Total Salary: 539.35 "}/>
                    </View>
                </View>
                {/*charts*/}
                <View style={{flex: 1}}>
                    {/*pie chart*/}
                    <PieChart
                        style={{margin: 50}}
                        data={[
                            {title: reportTitles[0], value: reportValues[0], color: chartColors[0]},
                            {title: reportTitles[4], value: reportValues[4], color: chartColors[1]},
                            ...reportTitles.slice(8).map((item, index) => {
                                return {title: item, value: reportValues.slice(8)[index], color: chartColors[index + 2]}
                            }),
                        ]}
                        startAngle={0}
                        animate={true}
                        label={({dataEntry}) => {
                            if (dataEntry.value !== 0 && reportValues.length > 0) {
                                const percent = Math.round(dataEntry.percentage * 100) / 100
                                return `${dataEntry.title}: ${percent}%`
                            }
                            return ""
                        }}
                        labelStyle={{fontSize: "4px", fontFamily: "LatoRegular", fontWeight: "200"}}
                        labelPosition={80}
                    />
                </View>

            </View>
        </View>
    )
}


function EmployeeDetails({name, id, position, email}) {
    return (
        <View style={{
            flex: 1,
            maxHeight: 120,
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 20,
            borderWidth: 1,
            borderColor: colors.navyBlue
        }}>
            <Image style={{
                width: 100,
                height: 100,
                borderRadius: 50,
                borderWidth: 1,
                borderColor: colors.navyBlue,
                marginHorizontal: 20
            }}
                   source={require("./../../../assets/employee_image.png")}/>
            <View>
                <Text style={{
                    fontFamily: "LatoRegular",
                    fontSize: 20,
                    fontWeight: "700",
                    lineHeight: 24
                }}>{name}</Text>
                <Text style={{
                    fontFamily: "LatoRegular",
                    fontSize: 20,
                    fontWeight: "700",
                    lineHeight: 24
                }}>{position}</Text>
            </View>
            <Space width={20}/>
            <Text style={{
                padding: 5,
                backgroundColor: colors.lightGreen,
                color: "white",
                borderRadius: 20,
                fontFamily: "LatoRegular",
                fontSize: 12,
                fontWeight: "400",
                lineHeight: 14
            }}>Current employee</Text>
            <Space flex={1}/>
            <View style={{alignItems: "flex-end", marginHorizontal: 20}}>
                <View style={{flexDirection: "row"}}>
                    <Text style={{
                        color: colors.mediumGray,
                        fontFamily: "Lato",
                        fontSize: 16,
                        fontWeight: "400",
                        lineHeight: 19
                    }}>ID: </Text>
                    <Text style={{
                        fontFamily: "LatoRegular",
                        fontSize: 16,
                        fontWeight: 700,
                        lineHeight: 19
                    }}>{id}</Text>

                </View>
                <Space height={10}/>
                <Text style={{
                    fontFamily: "LatoRegular",
                    fontSize: 16,
                    fontWeight: 700,
                    lineHeight: 19
                }}>{email}</Text>
            </View>
        </View>
    )
}

function SearchBar({items, onChange, onBackPressed}) {
    return (
        <View style={{flex: 1, flexDirection: "row", maxHeight: 40}}>
            <StyledDropDown
                search={true}
                labelField={"name"} valueField={"id"}
                items={items} placeHolder={"Search employee (ID or Name)"}
                onChange={onChange}/>
            <Space width={50}/>
            <CustomButton text={"Overall Report"} style={{maxWidth: 150}} onPress={onBackPressed}/>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingTop: 40,
        paddingHorizontal: 20,
    },
    fillingRow: {
        flex: 1, flexDirection: "row"
    },
    fillingColumn: {
        flex: 1, flexDirection: "column"
    },
    centerMainAxis: {
        alignItems: "center"
    },
    centerCrossAxis: {
        justifyContent: "center"
    },
    center: {
        justifyContent: "center", alignItems: "center"
    }
})
