import {StyleSheet, View, Text, ScrollView, TextInput, TouchableOpacity, Image} from "react-native";
import ScreenHeader from "../../ScreenHeader";
import TitleView from "../../employee_screens/components/TitleView";
import React, {useEffect, useRef, useState} from "react";
import {colors} from "../../../colors";
import CustomButton from "../../employee_screens/components/CustomButton";
import Space from "../../Space";
import Footer from "../../Footer";
import AwesomeAlert from "react-native-awesome-alerts";
import StyledDropDown from "../../StyledDropDown";
import {fontStyles} from "../../../fontStyles";
import HttpRequests from "../../../http_requests/HttpRequests";
import {DividerLineHorizontal} from "../../DividerLine";
import ProfileData from "../../../misc/ProfileData";
import {getMonth, getYear} from "date-fns";
import {Dropdown} from "react-native-element-dropdown";
import Helpers from "../../../misc/Helpers";

function PostingContents({navigation, selectedEmployeeId, onTimeTableCalled, onPostSelected, onMessage, onLoading}) {
    const [showPickEmployeeDialog, setShowPickEmployeeDialog] = useState(false)

    // filter dropdown values
    const [dropDownValues, setDropDownValues] = useState(
        {
            workingStatus: [{title: null, id: null}],
            costCenters: [{title: null, id: null}],
            projects: [{title: null, id: null}],
            operations: [{title: null, id: null}],
            activities: [{title: null, id: null}],
            salaryType: [{id: null, title: null}],
        })

    // digest list of employees, used in add employee dialog
    const [allEmployeesList, setAllEmployeesList] = useState([])

    // posting employees, used in list of employees
    const allPostingEmployees = useRef([])

    // filtered posting employees
    const [postingEmployees, setPostingEmployees] = useState([])
    useEffect(() => {
        selectEmployeeOfId(selectedEmployeeId)
        console.log(postingEmployees)
    }, [postingEmployees]);

    // used to know which months require update
    const selectedEmployeeInfoBackup = useRef({})
    const [selectedEmployeeInfo, setSelectedEmployeeInfo] = useState({})
    useEffect(() => {
        console.log(selectedEmployeeInfo)
        if (selectedEmployeeInfo && selectedEmployeeInfo.hasOwnProperty("data")) {
            const taxPercent = selectedEmployeeInfo.tax / 100
            const holidayPercent = selectedEmployeeInfo.holiday / 100
            const totalSalary = selectedEmployeeInfo.data.reduce((previousValue, currentValue) => {
                let newValue = currentValue.posted_percent / 100 * currentValue.salary
                if (currentValue.salary_type === 1) newValue *= currentValue.total_monthly_hours
                return newValue + previousValue
            }, 0)
            const totalTax = Math.round(taxPercent * totalSalary)
            const totalHoliday = Math.round(holidayPercent * totalSalary)
            const sum = Math.round(totalSalary + totalTax + totalHoliday)
            setEmployeeTotals({
                totalTax: totalTax,
                totalHoliday: totalHoliday,
                totalSalary: Math.round(totalSalary),
                sum: sum
            })
        } else {
            setEmployeeTotals({totalTax: 0, totalHoliday: 0, totalSalary: 0, sum: 0})
        }
    }, [selectedEmployeeInfo]);

    // index of selected employee in list
    const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(-1)
    useEffect(() => {
        console.log(selectedEmployeeIndex)
        if (selectedEmployeeIndex >= 0) {
            getSelectedEmployeeInfo()
        } else {
            setSelectedEmployeeInfo({})
        }
    }, [selectedEmployeeIndex])

    // employee totals
    const [employeeTotals, setEmployeeTotals] = useState({totalTax: 0, totalSalary: 0, totalHoliday: 0, sum: 0})
    useEffect(() => {
        console.log(selectedEmployeeId)
        selectEmployeeOfId(selectedEmployeeId)
    }, [selectedEmployeeId]);

    function selectEmployeeOfId(id) {
        console.log(id)
        if (postingEmployees.length > 0) {
            if (id !== -1) {
                setSelectedEmployeeIndex(postingEmployees.findIndex(value => value.userId === id))
            } else {
                setSelectedEmployeeIndex(-1)
            }
        }
    }

    const [selectedMonthIndex, setSelectedMonthIndex] = useState(-1)
    useEffect(() => {
        if (selectedMonthIndex >= 0 && selectedEmployeeIndex >= 0) {
            console.log(selectedEmployeeInfo.data[selectedMonthIndex])
        }
    }, [selectedMonthIndex]);

    function getSelectedEmployeeInfo() {
        onLoading(true)
        const selectedEmployee = postingEmployees[selectedEmployeeIndex]
        console.log(selectedEmployeeIndex)
        console.log(postingEmployees)
        console.log(allPostingEmployees)
        HttpRequests.getSelectedPostingEmployeeInfo(selectedEmployee.userId, selectedEmployee.postId, getYear(new Date())).then((result) => {
            if (result.success) {
                setSelectedEmployeeInfo(result.extras)
                selectedEmployeeInfoBackup.current = result.extras
            } else {
                setSelectedEmployeeInfo({})
            }
            onPostSelected({employeeInfo: selectedEmployee, monthInfo: result.extras.data})
            console.log(selectedEmployee)
            setTimeout(() => onLoading(false), 20)
        })
    }

    async function getFilters() {
        const postingFiltersResult = await HttpRequests.getPostingFilterTitles()

        if (postingFiltersResult.success) {
            let dropDowns = postingFiltersResult.extras
            dropDowns["salaryType"] = [
                {id: null, title: null},
                {id: 0, title: "Monthly"},
                {id: 1, title: "Hourly"}
            ]
            setDropDownValues(dropDowns)
            return dropDowns
        } else {
            onMessage("Error", postingFiltersResult.message)
        }
        return null
    }

    function readPostingEmployeesResult(postingEmployeesResult, dropDowns) {
        if (postingEmployeesResult.success) {
            allPostingEmployees.current = postingEmployeesResult.extras.map(employeeData => {
                const workingStatusTitle = employeeData.workingStatus
                const workingStatusId = dropDowns.workingStatus.find((value) => {
                    return value.title === workingStatusTitle
                }).id
                return {...employeeData, workingStatusId: workingStatusId}
            })
            console.log(allPostingEmployees.current)
            setPostingEmployees(allPostingEmployees.current)
            setTimeout(() => {
                selectEmployeeOfId(selectedEmployeeId)
            }, 100)
        }
    }

    async function readDataFromServer() {
        allPostingEmployees.current = []
        setPostingEmployees([])
        setSelectedEmployeeIndex(-1)
        setTimeout(() => onLoading(true), 200)
        return await HttpRequests.getPostingEmployees().then((postingEmployeesResult) => {
            console.log(postingEmployeesResult)
            if (postingEmployeesResult.success || postingEmployeesResult.message.includes("No Data")) {
                if (dropDownValues.workingStatus.length === 1) {
                    getFilters().then(dropDowns => {
                        if (dropDowns !== null) {
                            console.log(postingEmployeesResult)
                            readPostingEmployeesResult(postingEmployeesResult, dropDowns);
                            setTimeout(() => onLoading(false), 20)
                        }
                    })
                } else {
                    readPostingEmployeesResult(postingEmployeesResult, dropDownValues);
                    setTimeout(() => onLoading(false), 20)
                }
            } else {
                onLoading(false)
                onMessage("Error", postingEmployeesResult.message)
            }
        })
    }


    // get required info at start
    useEffect(() => {
        navigation.addListener('focus', (e) => {
            readDataFromServer()
        })
    }, [navigation]);

    // months that require percent update
    const [monthsToUpdate, setMonthsToUpdate] = useState([])
    useEffect(() => {
        console.log(monthsToUpdate)
        // if there's any months to update, update and remove it from the array. it will continue till there's no items left in the array.
        if (monthsToUpdate && monthsToUpdate.length > 0) {
            const userId = postingEmployees[selectedEmployeeIndex].userId
            const adminId = ProfileData.userId
            const percent = monthsToUpdate[0].posted_percent
            const startDate = `${getYear(new Date())}-${monthsToUpdate[0].month}-01`
            let endDate = startDate
            for (let i = 1; i < monthsToUpdate.length; i++) {
                if (monthsToUpdate[i].month === monthsToUpdate[0].month) {
                    endDate = `${getYear(new Date())}-${monthsToUpdate[i].month}-01`
                } else {
                    break
                }
            }
            const postId = postingEmployees[selectedEmployeeIndex].postId
            console.log(postingEmployees)
            console.log(`${userId}, ${percent}, ${adminId}, ${startDate}, ${endDate}, ${postId}`)
            HttpRequests.addPostingPercentToSelectedEmployee(userId, percent, adminId, startDate, endDate, postId).then((result) => {
                if (result.success) {
                    const startMonth = getMonth(new Date(startDate)) + 1
                    const endMonth = getMonth(new Date(endDate)) + 1
                    const updatedMonths = monthsToUpdate.filter((value, index, array) => {
                        return value.month < startMonth || value.month > endMonth
                    })
                    setMonthsToUpdate(updatedMonths)
                } else {
                    onMessage("Error", result.message)
                }
            })
        } else {
            selectedEmployeeInfoBackup.current = selectedEmployeeInfo
            readDataFromServer()
        }
    }, [monthsToUpdate]);

    // Caution: changing order of items in these arrays requires update in other components
    const tableFlexes = [1, 3, 2, 2, 2, 2, 3, 2, 2, 1]
    const tableHeaderItems = [
        "ID",
        "Name",
        "Position",
        "Salary Type",
        "Status",
        "Cost Center",
        "Project",
        "Operation",
        "Activity",
        ""
    ]

    function getTotalMonthlyHours() {
        if (selectedMonthIndex >= 0 && selectedEmployeeIndex >= 0 && selectedEmployeeInfo.hasOwnProperty("data")) {
            return selectedEmployeeInfo.data[selectedMonthIndex].total_monthly_hours
        } else {
            return "_"
        }
    }

    function getAvailabilityHours() {
        if (selectedMonthIndex >= 0 && selectedEmployeeIndex >= 0 && selectedEmployeeInfo.hasOwnProperty("data")) {
            return selectedEmployeeInfo.data[selectedMonthIndex].availability_hours
        } else {
            return "_"
        }
    }

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation}>
                <Text style={{...fontStyles.lato700_20, color: colors.darkGray}}>{getYear(new Date())}</Text>
            </ScreenHeader>
            <TitleView titleText={"Posting"}/>
            <View style={{flexDirection: "row", flex: 1}}>
                {/*table*/}
                <View style={{flex: 1}}>
                    {/*add employee button*/}
                    <CustomButton style={{width: 150, alignSelf: "flex-end"}} text={"Add Employee"} onPress={() => {
                        onLoading(true)
                        HttpRequests.getStaffListDigest(0).then((result) => {
                            onLoading(false)
                            if (result.success) {
                                setAllEmployeesList(result.extras)
                                setShowPickEmployeeDialog(true)
                            } else {
                                onMessage("Error", result.message)
                            }
                        })
                    }}/>
                    {/*filters*/}
                    <Filters dropDownValues={dropDownValues} onFilterChange={(filters) => {
                        console.log(filters)
                        setPostingEmployees(allPostingEmployees.current.filter(employee => {
                            console.log(employee)
                            const salaryTypeSatisfied = filters.salaryType === null || employee.salaryType === filters.salaryType
                            const statusSatisfied = filters.status === null || employee.workingStatus === filters.status
                            const costCenterSatisfied = filters.costCenter === null || employee.costCenter === filters.costCenter
                            const projectSatisfied = filters.project === null || employee.project === filters.project
                            const operationSatisfied = filters.operation === null || employee.operation === filters.operation
                            const activitySatisfied = filters.activity === null || employee.activity === filters.activity
                            return salaryTypeSatisfied && statusSatisfied && costCenterSatisfied && projectSatisfied && operationSatisfied && activitySatisfied
                        }))
                    }}/>
                    <Space height={15}/>
                    {/*table header*/}
                    <TableRow rowIndex={-1} items={tableHeaderItems} flexes={tableFlexes} color={colors.headerBlue}
                              textStyle={fontStyles.lato700_16}/>
                    {/*table*/}
                    <ScrollView>
                        <View style={{flex: 1}}>
                            {
                                postingEmployees.map((value, index) => {
                                    return (
                                        <View key={index}>
                                            <TableRow
                                                rowIndex={index}
                                                items={[value.userId, value.name, value.position, value.salaryType, value.workingStatus, value.costCenter, value.project, value.operation, value.activity, ""]}
                                                flexes={tableFlexes}
                                                color={selectedEmployeeIndex === index ? colors.lighterYellow : (index % 2 === 0 ? colors.tableRow : "white")}
                                                textStyle={fontStyles.lato400_11}
                                                dropdownValues={dropDownValues}
                                                onEdit={(rowIndex, dropdownKey, newValue) => {
                                                    onLoading(true)
                                                    console.log(dropDownValues)
                                                    console.log(dropdownKey)
                                                    let ws = dropDownValues.workingStatus.find(item => item.title === value.workingStatus).id
                                                    let cc = dropDownValues.costCenters.find(item => item.title === value.costCenter).id
                                                    let project = dropDownValues.projects.find(item => item.title === value.project).id
                                                    let operation = dropDownValues.operations.find(item => item.title === value.operation).id
                                                    let activity = dropDownValues.activities.find(item => item.title === value.activity).id
                                                    switch (dropdownKey) {
                                                        case "workingStatus":
                                                            ws = newValue.id
                                                            break
                                                        case "costCenters":
                                                            cc = newValue.id
                                                            break
                                                        case "projects":
                                                            project = newValue.id
                                                            break
                                                        case "operations":
                                                            operation = newValue.id
                                                            break
                                                        case "activities":
                                                            activity = newValue.id
                                                            break
                                                    }
                                                    HttpRequests.editPostingEmployee(value.postId, cc, project, operation, activity, ws).then(response => {
                                                        if (response.success) {
                                                            allPostingEmployees.current = allPostingEmployees.current.map(employee => {
                                                                if (employee.postId === value.postId && employee.userId === value.userId) {
                                                                    switch (dropdownKey) {
                                                                        case "workingStatus":
                                                                            employee.workingStatus = newValue.title
                                                                            break
                                                                        case "costCenters":
                                                                            employee.costCenter = newValue.title
                                                                            break
                                                                        case "projects":
                                                                            employee.project = newValue.title
                                                                            break
                                                                        case "operations":
                                                                            employee.operation = newValue.title
                                                                            break
                                                                        case "activities":
                                                                            employee.activity = newValue.title
                                                                            break
                                                                    }
                                                                    return employee
                                                                }
                                                                return employee
                                                            })
                                                            onLoading(false)
                                                        } else {
                                                            onLoading(false)
                                                            // handle error
                                                        }
                                                    })
                                                }}
                                                onClick={() => {
                                                    setSelectedEmployeeIndex(index)
                                                }}
                                                onDeleteClick={() => {
                                                    HttpRequests.deletePostingEmployee(postingEmployees[index].postId).then(result => {
                                                        console.log(result)
                                                        setPostingEmployees(postingEmployees.filter((value1, index1) => {
                                                            return index1 !== index
                                                        }))
                                                        allPostingEmployees.current = allPostingEmployees.current.filter((value1, index1) => {
                                                            return index1 !== index
                                                        })
                                                    })
                                                }}
                                            />
                                            <DividerLineHorizontal/>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </ScrollView>
                </View>

                <View style={{backgroundColor: colors.darkGray, width: 1, marginHorizontal: 10}}/>

                {/*posting items*/}
                <View style={{width: 470}}>
                    {/*selected employee info*/}
                    <View style={{
                        borderRadius: 20,
                        height: 80,
                        // height: 105,
                        borderColor: colors.navyBlue,
                        borderWidth: 1,
                        paddingHorizontal: 20,
                        justifyContent: "space-evenly"
                    }}>
                        <Text style={{...fontStyles.lato700_14, lineHeight: 20}}>{
                            selectedEmployeeIndex === -1 ? "Employee Name" : postingEmployees[selectedEmployeeIndex].name}
                        </Text>
                        <View style={{height: 20, flexDirection: "row", justifyContent: "space-between"}}>
                            <Text style={fontStyles.lato400_11}>{
                                selectedEmployeeIndex === -1 ? "Salary Type" : postingEmployees[selectedEmployeeIndex].salaryType}
                            </Text>
                            <View style={{flexDirection: "row", width: 150, justifyContent: "space-between"}}>
                                <Text style={fontStyles.lato400_11}>Contract Hours:</Text>
                                <Text
                                    style={fontStyles.lato700_11}>{getTotalMonthlyHours()}</Text>
                            </View>
                        </View>
                        <View style={{height: 20, flexDirection: "row", justifyContent: "space-between"}}>
                            <Text
                                style={fontStyles.lato400_11}>{selectedEmployeeIndex === -1 ? "Position" : postingEmployees[selectedEmployeeIndex].position}</Text>
                            <View style={{flexDirection: "row", width: 150, justifyContent: "space-between"}}>
                                <Text style={fontStyles.lato400_11}>Total Hours Available:</Text>
                                <Text
                                    style={fontStyles.lato700_11}>{getAvailabilityHours()}</Text>
                            </View>
                        </View>
                    </View>
                    <Space height={10}/>
                    {/*posting items table*/}
                    <InfoContents
                        monthsInfo={selectedEmployeeInfo}
                        originalMonthInfo={selectedEmployeeInfoBackup.current.data}
                        onMessage={onMessage}
                        onMonthChange={(monthIndex) => {
                            setSelectedMonthIndex(monthIndex)
                        }}
                        monthPercentChanged={(month, percent) => {
                            if (selectedEmployeeInfo && selectedEmployeeInfo.hasOwnProperty("data")) {
                                const foundMonthInfo = selectedEmployeeInfo.data.find((item) => item.month === month + 1)
                                if (foundMonthInfo && foundMonthInfo.hasOwnProperty("posted_percent")) {
                                    const monthPercent = foundMonthInfo.posted_percent
                                    if (monthPercent !== percent) {
                                        const newEmployeeInfo = selectedEmployeeInfo.data.map((value, index, array) => {
                                            if (value.month === month + 1) {
                                                return {...value, posted_percent: percent}
                                            } else {
                                                return value
                                            }
                                        })
                                        setSelectedEmployeeInfo({...selectedEmployeeInfo, data: newEmployeeInfo})
                                    }
                                }
                            }
                        }}
                        onSetTimeTableClicked={(monthItems) => {
                            const changed = selectedEmployeeInfoBackup.current !== selectedEmployeeInfo
                            if (changed) {
                                onMessage("Warning", "Changed percents should be submitted before continue, press submit button then try again?")
                            } else {
                                console.log(monthItems)
                                if (+monthItems[2] === 0) {
                                    onMessage("Error", "Please set percentage above 0 before continue.")
                                } else {
                                    onTimeTableCalled(selectedMonthIndex)
                                }
                            }
                        }}
                        onFillClicked={(month) => {
                            if (selectedEmployeeInfo !== undefined && selectedEmployeeInfo.hasOwnProperty("data")) {
                                const foundMonthInfo = selectedEmployeeInfo.data.find((item) => item.month === month + 1)
                                if (foundMonthInfo !== undefined && foundMonthInfo.hasOwnProperty("posted_percent")) {
                                    const monthPercent = foundMonthInfo.posted_percent
                                    const newEmployeeInfo = selectedEmployeeInfo.data.map((value, index, array) => {
                                        if (value.month > month + 1 && value.availability !== 0) {
                                            return {...value, posted_percent: monthPercent}
                                        } else {
                                            return value
                                        }
                                    })
                                    setSelectedEmployeeInfo({...selectedEmployeeInfo, data: newEmployeeInfo})
                                }
                            }
                        }}
                    />
                    <View>
                        <Space height={3}/>
                        <DividerLineHorizontal color={colors.mediumGray}/>
                        <View style={{
                            flex: 1,
                            flexDirection: "row",
                            maxHeight: 30,
                            marginVertical: 7,
                        }}>
                            {[40, 50, 50, 90, 90, 90].map((width, index) => {
                                switch (index) {
                                    case 0:
                                        return (
                                            <View key={index} style={{
                                                height: 30,
                                                width: width,
                                                marginHorizontal: 4,
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                                <Text style={fontStyles.lato700_14}>
                                                    Totals:
                                                </Text>
                                            </View>
                                        )
                                    case 3:
                                        return (
                                            <View key={index} style={{
                                                height: 30,
                                                width: width,
                                                marginHorizontal: 4,
                                                backgroundColor: colors.lightGray,
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: 20,
                                                borderWidth: 1,
                                                borderColor: colors.navyBlue
                                            }}>
                                                <Text style={fontStyles.lato700_14}>
                                                    {Helpers.formatNumberWithSpace(employeeTotals.totalSalary)}
                                                </Text>
                                            </View>
                                        )
                                    case 4:
                                        return (
                                            <View key={index} style={{
                                                height: 30,
                                                width: width,
                                                marginHorizontal: 4,
                                                backgroundColor: colors.lightGray,
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: 20,
                                                borderWidth: 1,
                                                borderColor: colors.navyBlue
                                            }}>
                                                <Text style={fontStyles.lato700_14}>
                                                    {Helpers.formatNumberWithSpace(employeeTotals.totalTax)}
                                                </Text>
                                            </View>
                                        )
                                    case 5:
                                        return (
                                            <View key={index} style={{
                                                height: 30,
                                                width: width,
                                                marginHorizontal: 4,
                                                backgroundColor: colors.lightGray,
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: 20,
                                                borderWidth: 1,
                                                borderColor: colors.navyBlue
                                            }}>
                                                <Text style={fontStyles.lato700_14}>
                                                    {Helpers.formatNumberWithSpace(employeeTotals.totalHoliday)}
                                                </Text>
                                            </View>
                                        )
                                    default:
                                        return <View key={index} style={{
                                            height: 30,
                                            width: width,
                                            marginHorizontal: 4,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}/>
                                }
                            })}
                        </View>
                        <DividerLineHorizontal color={colors.mediumGray}/>
                        <View style={{
                            flex: 1,
                            flexDirection: "row",
                            maxHeight: 30,
                            marginVertical: 7,
                        }}>
                            {[40, 50, 50, 286].map((width, index) => {
                                switch (index) {
                                    case 3:
                                        return (
                                            <View key={index} style={{
                                                height: 30,
                                                width: width,
                                                marginHorizontal: 4,
                                                backgroundColor: colors.lightGray,
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: 20,
                                                borderWidth: 1,
                                                borderColor: colors.navyBlue
                                            }}>
                                                <Text style={fontStyles.lato700_14}>
                                                    {Helpers.formatNumberWithSpace(employeeTotals.sum)}
                                                </Text>
                                            </View>
                                        )
                                    case 0:
                                        return (
                                            <View key={index} style={{
                                                height: 30,
                                                width: width,
                                                marginHorizontal: 4,
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                                <Text style={fontStyles.lato700_14}>
                                                    Sum:
                                                </Text>
                                            </View>
                                        )
                                    default:
                                        return <View key={index} style={{
                                            height: 30,
                                            width: width,
                                            marginHorizontal: 4,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}/>
                                }
                            })}
                        </View>
                        <CustomButton text={"Submit"} onPress={() => {
                            if (selectedEmployeeInfo !== undefined && selectedEmployeeInfo.hasOwnProperty("data")) {
                                const editedEmployeeData = selectedEmployeeInfo.data.filter((value, index, array) => {
                                    return value.posted_percent !== selectedEmployeeInfoBackup.current.data[index].posted_percent
                                })
                                if (editedEmployeeData.length > 0) {
                                    console.log(editedEmployeeData)
                                    onLoading(true)
                                    setMonthsToUpdate(editedEmployeeData)
                                }
                            }
                        }}/>
                    </View>
                </View>
            </View>
            <Footer/>
            <AwesomeAlert
                contentContainerStyle={{
                    borderRadius: 20,
                }}
                closeOnTouchOutside={false}
                customView={
                    <AddEmployeeDialogBody
                        employees={allEmployeesList}
                        dropDownValues={dropDownValues}
                        onConfirm={(employeeId, costCenterId, projectId, operationId, activityId, workingStatus) => {
                            setShowPickEmployeeDialog(false)
                            onLoading(true)
                            HttpRequests.addEmployeePost(employeeId, costCenterId, projectId, operationId, activityId, workingStatus, ProfileData.userId).then(response => {
                                console.log(response)
                                if (response.success) {
                                    HttpRequests.getPostingEmployees().then((result) => {
                                        if (result.success) {
                                            allPostingEmployees.current = result.extras
                                            setPostingEmployees(result.extras)
                                        } else {
                                            onMessage("Error", result.message)
                                        }
                                        setTimeout(() => onLoading(false), 20)
                                    })
                                } else {
                                    onMessage("Error", response.message)
                                }
                            })
                        }}
                        onClose={() => {
                            setShowPickEmployeeDialog(false)
                        }}/>
                }
                show={showPickEmployeeDialog}
            />
        </View>
    )
}

function InfoContents({
                          originalMonthInfo,
                          monthsInfo,
                          monthPercentChanged,
                          onSetTimeTableClicked,
                          onFillClicked,
                          onMonthChange,
                          onMessage
                      }) {
    const [currentRowIndex, setCurrentRowIndex] = useState(null)

    // monthName, availability, postedPercent, salary, tax, holiday, monthHours, postMonthId
    const rowItems = [
        ["Jan", "__", "", "__", "__", "__"],
        ["Feb", "__", "", "__", "__", "__"],
        ["Mar", "__", "", "__", "__", "__"],
        ["Apr", "__", "", "__", "__", "__"],
        ["May", "__", "", "__", "__", "__"],
        ["June", "__", "", "__", "__", "__"],
        ["July", "__", "", "__", "__", "__"],
        ["Aug", "__", "", "__", "__", "__"],
        ["Sep", "__", "", "__", "__", "__"],
        ["Oct", "__", "", "__", "__", "__"],
        ["Nov", "__", "", "__", "__", "__"],
        ["Dec", "__", "", "__", "__", "__"],
    ]
    console.log(monthsInfo)
    if (monthsInfo.hasOwnProperty("data")) {
        monthsInfo.data.forEach((value, index) => {
            const item = rowItems[value.month - 1]
            const postedPercent = value.posted_percent
            const salary = value.salary
            let monthSalary = "__"
            let tax = "__"
            let holiday = "__"
            if (postedPercent !== "") {
                monthSalary = salary * postedPercent * (+value.salary_type === 0 ? 1 : value.total_monthly_hours)
                tax = Math.round(monthSalary * monthsInfo.tax / 100) / 100
                holiday = Math.round(monthSalary * monthsInfo.holiday / 100) / 100
                monthSalary = Math.round(monthSalary / 100)
            }
            rowItems[value.month - 1] = [item[0], value.availability, postedPercent, monthSalary, tax, holiday, value.total_monthly_hours, value.post_month_id]
        })
    }

    function Row({
                     index,
                     currentRowIndex,
                     items,
                     originalMonthInfo,
                     onRowSelected,
                     selectable,
                     onPercentChange,
                     onFillClicked,
                     itemsType = "content"
                 }) {

        function RowContents({
                                 currentRowIndex,
                                 index,
                                 items,
                                 selectable,
                                 itemsType,
                                 onPercentChange,
                                 onFillAllClicked,
                                 isEditable = false
                             }) {
            const widths = [40, 50, 50, 90, 90, 90]

            function Cell({rowIndex, text, width, color, onPercentChange, isRowSelected, selectable}) {
                const [percentValue, setPercentValue] = useState(text)
                // useEffect(() => {
                //     if (onPercentChange != null) {
                //         onPercentChange(percentValue)
                //     }
                // }, [percentValue]);
                const fieldRef = useRef(null);

                useEffect(() => {
                    if (isRowSelected && selectable) {
                        fieldRef.current?.focus()
                    }
                }, [isRowSelected]);

                return (
                    <View style={{
                        height: 30,
                        width: width,
                        marginHorizontal: 4,
                        backgroundColor: color,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 20,
                        borderWidth: 1,
                        borderColor: colors.navyBlue
                    }}>
                        {onPercentChange ?
                            <TextInput
                                ref={fieldRef}
                                editable={selectable}
                                // selectTextOnFocus={selectable}
                                style={{width: width, textAlign: "center", outlineStyle: "none"}}
                                maxLength={3}
                                onFocus={e => {
                                    console.log("selected")
                                    console.log(rowIndex)
                                    if (selectable) {
                                        onRowSelected(rowIndex)
                                    }
                                }}
                                value={percentValue}
                                onChangeText={(t) => {
                                    setPercentValue(t)
                                    onPercentChange(t)
                                }}
                            /> :
                            <Text
                                style={fontStyles.lato700_14}>{isNaN(+text) ? text : Helpers.formatNumberWithSpace(text)}</Text>
                        }

                    </View>
                )
            }

            return <>
                <View style={{
                    flex: 1,
                    flexDirection: "row",
                    maxHeight: 30,
                    marginVertical: 7
                }}>
                    {
                        widths.map((width, i) => {
                            if (itemsType === "content") {
                                let text = items[i]
                                if (i === 3) {
                                    text = Helpers.formatNumberWithSpace(items[i])
                                }
                                return (
                                    <Cell
                                        key={i} rowIndex={index} text={text} width={width} selectable={selectable}
                                        isRowSelected={currentRowIndex != null && index != null && currentRowIndex === index}
                                        onPercentChange={i !== 2 || !isEditable ? null : onPercentChange}
                                        color={"white"}/>
                                )
                            } else if (itemsType === "header") {
                                return (
                                    <Cell
                                        key={i} rowIndex={index} text={items[i]} width={width}
                                        color={colors.lightGray}
                                    />
                                )
                            }
                        })
                    }
                </View>
                {currentRowIndex != null && index != null && currentRowIndex === index &&
                    <View style={{flex: 1, flexDirection: "row", maxHeight: 40}}>
                        <CustomButton
                            fontSize={12} flex={1} text={"Fill same the remaining months"}
                            onPress={items[2] === "" || isNaN(+items[2]) ? null : () => {
                                console.log(items)
                                onFillAllClicked(index)
                            }}/>
                        <Space width={10}/>
                        <CustomButton
                            fontSize={12} flex={1} text={"Set time table"} color={colors.lighterGreen}
                            onPress={items[2] === "" || isNaN(+items[2]) ? null : () => {
                                onSetTimeTableClicked(items)
                            }}/>
                    </View>
                }
            </>;
        }

        return (
            selectable && currentRowIndex !== index ?
                <TouchableOpacity onPress={() => onRowSelected(index)}>
                    <RowContents currentRowIndex={currentRowIndex} index={index} items={items} selectable={selectable}
                                 onPercentChange={onPercentChange} itemsType={itemsType}
                                 onFillAllClicked={onFillClicked}
                                 isEditable={originalMonthInfo !== undefined && (originalMonthInfo[index].posted_percent !== 0 || originalMonthInfo[index].availability !== 0)}/>
                </TouchableOpacity> :
                <View>
                    <RowContents currentRowIndex={currentRowIndex} index={index} items={items} selectable={selectable}
                                 onPercentChange={onPercentChange} itemsType={itemsType}
                                 onFillAllClicked={onFillClicked}
                                 isEditable={originalMonthInfo !== undefined && (originalMonthInfo[index].posted_percent !== 0 || originalMonthInfo[index].availability !== 0)}/>
                </View>
        )
    }

    function rowSelected(rowIndex) {
        if (originalMonthInfo[rowIndex].availability === 0 && originalMonthInfo[rowIndex].posted_percent === 0) {
            onMessage("Error", "Employees should set their availability before you set posting for them.")
        } else {
            setCurrentRowIndex(rowIndex)
            onMonthChange(rowIndex)
        }
    }

    return (
        <ScrollView>
            <Row selectable={false} items={["M", "Ava%", "%", "Salary", "Tax", "Holiday"]} itemsType={"header"}/>
            {
                rowItems.map((items, index) => {
                    return (
                        <Row key={index} onRowSelected={rowSelected} currentRowIndex={currentRowIndex} index={index}
                             items={items} selectable={monthsInfo.hasOwnProperty("data")} onFillClicked={onFillClicked}
                             originalMonthInfo={originalMonthInfo}
                             onPercentChange={(percent) => {
                                 monthPercentChanged(index, percent)
                             }}
                        />
                    )
                })
            }
        </ScrollView>
    );
}

function TableRow({
                      rowIndex,
                      items,
                      flexes,
                      textStyle,
                      color,
                      selectedRowKey,
                      onClick,
                      onDeleteClick,
                      dropdownValues,
                      onEdit
                  }) {

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            maxHeight: 40,
            minHeight: 40,
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: color
        }
    })

    function Row({items, flexes, textStyle, rowIndex, dropdownValues, onDeleteClick, onEdit}) {
        const dropdownKeys = ["", "", "", "", "workingStatus", "costCenters", "projects", "operations", "activities", ""]
        return items.map((item, index) => {
            return (
                <View key={index} style={{flex: flexes[index], alignItems: "center", justifyContent: "center"}}>
                    {
                        onDeleteClick && item === "" ?
                            <TouchableOpacity onPress={() => {
                                onDeleteClick()
                            }}>
                                <Image style={{width: 20, height: 20}}
                                       source={require("./../../../assets/icons/trash.png")}/>
                            </TouchableOpacity> :
                            dropdownValues && (index >= 4 && index <= 8) ?
                                <Dropdown
                                    data={dropdownValues[dropdownKeys[index]].filter(value => value.id !== null)}
                                    labelField={"title"} valueField={"id"} selectedTextStyle={textStyle}
                                    itemTextStyle={textStyle}
                                    value={dropdownValues[dropdownKeys[index]].findIndex(value => value.title === item)}
                                    onChange={(value) => {
                                        onEdit(rowIndex, dropdownKeys[index], value)
                                    }}
                                /> :
                                <Text style={textStyle}>{item}</Text>
                    }
                </View>
            )
        })
    }

    return onClick ?
        <TouchableOpacity style={styles.container} onPress={onClick}>
            <Row items={items} rowIndex={rowIndex} flexes={flexes} textStyle={textStyle} onDeleteClick={onDeleteClick}
                 dropdownValues={dropdownValues} onEdit={onEdit}/>
        </TouchableOpacity> :
        <View style={styles.container}>
            <Row items={items} flexes={flexes} textStyle={textStyle}/>
        </View>
}

function Filters({onFilterChange, dropDownValues}) {
    const [filters, setFilters] = useState({
        salaryType: null,
        status: null,
        costCenter: null,
        project: null,
        operation: null,
        activity: null
    })
    useEffect(() => {
        onFilterChange(filters)
    }, [filters]);

    return <View>
        <Text style={fontStyles.lato700_14}>Filter by:</Text>
        <Space height={15}/>
        <View style={{
            flex: 1,
            maxHeight: 45,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <StyledDropDown
                labelField={"title"} valueField={"id"} items={dropDownValues.salaryType}
                placeHolder={"Salary Type"}
                onChange={(item) => {
                    setFilters({...filters, salaryType: item.id == null ? null : item.title})
                }}
            />
            <StyledDropDown
                labelField={"title"} valueField={"id"} items={dropDownValues.workingStatus}
                placeHolder={"Status"}
                onChange={(item) => {
                    setFilters({...filters, status: item.id == null ? null : item.title})
                }}
            />
            <StyledDropDown
                labelField={"title"} valueField={"id"} items={dropDownValues.costCenters}
                placeHolder={"Cost Center"}
                onChange={(item) => {
                    setFilters({...filters, costCenter: item.id == null ? null : item.title})
                }}
            />
            <StyledDropDown
                labelField={"title"} valueField={"id"} items={dropDownValues.projects}
                placeHolder={"Project"}
                onChange={(item) => {
                    setFilters({...filters, project: item.id == null ? null : item.title})
                }}
            />
            <StyledDropDown
                labelField={"title"} valueField={"id"} items={dropDownValues.operations}
                placeHolder={"Operation"}
                onChange={(item) => {
                    setFilters({...filters, operation: item.id == null ? null : item.title})
                }}
            />
            <StyledDropDown
                labelField={"title"} valueField={"id"} items={dropDownValues.activities}
                placeHolder={"Activity"}
                onChange={(item) => {
                    setFilters({...filters, activity: item.id == null ? null : item.title})
                }}
            />
        </View>
    </View>;
}

function AddEmployeeDialogBody({employees, dropDownValues, onConfirm, onClose}) {
    const dialogHeight = 580
    const dialogWidth = 410
    const [employeeName, setEmployeeName] = useState("Employee Name")
    const [employeePosition, setEmployeePosition] = useState("Employee Position")
    const [employeeSalaryType, setEmployeeSalaryType] = useState("Salary Type")
    const [employeeId, setEmployeeId] = useState(-1)
    const [costCenterId, setCostCenterId] = useState(-1)
    const [projectId, setProjectId] = useState(-1)
    const [operationId, setOperationId] = useState(-1)
    const [activityId, setActivityId] = useState(-1)
    const [workingStatus, setWorkingStatus] = useState(1)

    const [canConfirm, setCanConfirm] = useState(false)
    useEffect(() => {
        setCanConfirm(costCenterId !== -1 && projectId !== -1 && operationId !== -1 && activityId !== -1 && workingStatus !== -1 && employeeId !== -1)
    }, [employeeId, costCenterId, projectId, operationId, activityId, workingStatus]);

    function FilterByName() {
        const inputFieldRef = useRef(null);
        setTimeout(() => {
            inputFieldRef.current?.focus()
        }, 10)

        return (
            <StyledDropDown
                style={{width: dialogWidth - 100, maxHeight: 40}} labelField={"name"} valueField={"id"}
                items={employees} placeHolder={"Search employee (ID or Name)"} search={true}
                onChange={(item) => {
                    console.log(item)
                    setEmployeeId(item.id)
                    setEmployeeName(item.name)
                    setEmployeePosition(item.position)
                    setEmployeeSalaryType(item.salaryType === 0 ? "Monthly" : "Hourly")
                }}/>
        )
    }

    return (
        <View style={{
            width: dialogWidth,
            height: dialogHeight,
            alignItems: "center",
            justifyContent: "space-around",
        }}>
            <TouchableOpacity
                style={{width: 50, height: 50, marginTop: -100}}
                onPress={() => {
                    onClose()
                }}
            >
                <Image style={{width: 50, height: 50}} source={require("../../../assets/icons/exit.png")}/>
            </TouchableOpacity>
            <Space height={50}/>
            <View
                style={{
                    height: dialogHeight,
                    width: dialogWidth,
                    alignItems: "center",
                    justifyContent: "space-around",
                }}
            >
                <Text style={fontStyles.lato700_20}>Set Employee Post</Text>
                <Space height={30}/>
                <FilterByName/>
                <Space height={20}/>
                <Text style={fontStyles.lato700_30}>{employeeName}</Text>
                <Text style={fontStyles.lato400_16}>{employeeSalaryType}</Text>
                <Text style={fontStyles.lato400_20}>{employeePosition}</Text>
                <Space height={10}/>
                <StyledDropDown
                    labelField={"title"}
                    valueField={"id"}
                    style={{width: dialogWidth - 100, maxHeight: 40}}
                    items={dropDownValues.costCenters}
                    placeHolder={"Cost Center"}
                    onChange={(item) => {
                        setCostCenterId(item.id ?? -1)
                    }}/>
                <StyledDropDown
                    labelField={"title"} valueField={"id"}
                    style={{width: dialogWidth - 100, maxHeight: 40}} items={dropDownValues.projects}
                    placeHolder={"Project"} onChange={(item) => {
                    setProjectId(item.id ?? -1)
                }}/>
                <StyledDropDown
                    labelField={"title"} valueField={"id"}
                    style={{width: dialogWidth - 100, maxHeight: 40}} items={dropDownValues.operations}
                    placeHolder={"Operation"} onChange={(item) => {
                    setOperationId(item.id ?? -1)
                }}/>
                <StyledDropDown
                    labelField={"title"} valueField={"id"}
                    style={{width: dialogWidth - 100, maxHeight: 40}} items={dropDownValues.activities}
                    placeHolder={"Activity"} onChange={(item) => {
                    setActivityId(item.id ?? -1)
                }}/>
                {/*<StyledDropDown*/}
                {/*    labelField={"title"} valueField={"id"}*/}
                {/*    style={{width: dialogWidth - 100, maxHeight: 40}} items={dropDownValues.workingStatus}*/}
                {/*    placeHolder={"Status"}*/}
                {/*    onChange={(item) => {*/}
                {/*        console.log(item.id)*/}
                {/*        setWorkingStatus(item.id ?? -1)*/}
                {/*    }}/>*/}
                <Space height={20}/>
                <CustomButton style={{width: dialogWidth - 100}} fontSize={14} text={"Submit"} onPress={
                    !canConfirm ? null : () => {
                        onConfirm(employeeId, costCenterId, projectId, operationId, activityId, workingStatus)
                    }}/>
                <Space height={50}/>
            </View>
        </View>
    )
}

export default PostingContents

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
})
