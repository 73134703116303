import {useEffect, useRef, useState} from "react";
import FadeView from "../FadeView";
import StyledEditText from "./StyledEditText";
import {Image, StyleSheet, Text, TouchableHighlight, View} from "react-native";
import {Button} from "@rneui/themed";
import Timer from "../Timer";
import AwesomeAlert from "react-native-awesome-alerts";
import HttpRequests from "../../http_requests/HttpRequests";
import ProfileData from "../../misc/ProfileData";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LoginContent from "./LoginContent";
import {colors} from "../../colors";
import LoadingMessage from "../DefaultMessages";

export default function LoginScreen({navigation}) {
    //constants
    const LoginDestinationName = "login"
    const ChangePassDestinationName = "changePass"
    const VerificationDestinationName = "verification"
    const animationDuration = 200
    const resendCodeTime = 180

    // states

    const verificationCodeFieldRef = useRef(null);
    const verificationCodeTextRef = useRef("")
    const [verificationCodeText, setVerificationCodeText] = useState(verificationCodeTextRef.current)
    useEffect(() => {
        verificationCodeFieldRef.current?.focus()
        verificationCodeTextRef.current = verificationCodeText
    }, [verificationCodeText]);

    const changePassFieldRef = useRef(null);
    const changePassTextRef = useRef("")
    const [changePassText, setChangePassText] = useState(changePassTextRef.current)
    useEffect(() => {
        changePassFieldRef.current?.focus()
        changePassTextRef.current = changePassText
    }, [changePassText]);

    const changePassRepeatFieldRef = useRef(null);
    const changePassRepeatTextRef = useRef("")
    const [changePassRepeatText, setChangePassRepeatText] = useState(changePassRepeatTextRef.current)
    useEffect(() => {
        changePassRepeatFieldRef.current?.focus()
        changePassRepeatTextRef.current = changePassRepeatText
    }, [changePassRepeatText]);

    // verification code timer
    const [timeIsUp, setTimeIsUp] = useState(false)

    // indicator of transition between contents
    const transitionRef = useRef(false);
    const [transition, setTransition] = useState(transitionRef.current)
    useEffect(() => {
        transitionRef.current = transition
    }, [transition]);

    // destination of transition
    const destinationRef = useRef(LoginDestinationName);
    const [destination, setDestination] = useState(destinationRef.current)
    useEffect(() => {
        destinationRef.current = destination
    }, [destination]);

    // indicator of animation
    const showAnimationRef = useRef(false);
    const [showAnimation, setShowAnimation] = useState(showAnimationRef.current)
    useEffect(() => {
        showAnimationRef.current = showAnimation
    }, [showAnimation]);

    // username
    const usernameTextRef = useRef("");

    // error states
    const errorMessage = useRef("");
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [showFillFormError, setShowFillFormError] = useState(false)
    const [showFillUsernameError, setShowFillUsernameError] = useState(false)
    const [showLoadingMessage, setShowLoadingMessage] = useState(false)

    useEffect(() => {
        AsyncStorage.multiGet([
            "name",
            "accountType",
            "userId",
            "refreshToken",
            "position",
            "salary",
            "age",
            "gender",
            "contractFile",
            "contractDate",
            "salaryType",
            "familyMemberContact",
            "familyMemberName",
            "contactNumber",
            "socialNumber",
            "jobDescription",
            "cleaningNumber",
            "email",
            "password",
            "monthlyHours"], (errors, result) => {
            const name = result.find(value => value[0] === "name")[1]
            const accountType = result.find(value => value[0] === "accountType")[1]
            const userId = result.find(value => value[0] === "userId")[1]
            const refreshToken = result.find(value => value[0] === "refreshToken")[1]
            const position = result.find(value => value[0] === "position")[1]
            const salary = result.find(value => value[0] === "salary")[1]
            const age = result.find(value => value[0] === "age")[1]
            const gender = result.find(value => value[0] === "gender")[1]
            const contractFile = result.find(value => value[0] === "contractFile")[1]
            const contractDate = result.find(value => value[0] === "contractDate")[1]
            const salaryType = result.find(value => value[0] === "salaryType")[1]
            const familyMemberContact = result.find(value => value[0] === "familyMemberContact")[1]
            const familyMemberName = result.find(value => value[0] === "familyMemberName")[1]
            const contactNumber = result.find(value => value[0] === "contactNumber")[1]
            const socialNumber = result.find(value => value[0] === "socialNumber")[1]
            const jobDescription = result.find(value => value[0] === "jobDescription")[1]
            const cleaningNumber = result.find(value => value[0] === "cleaningNumber")[1]
            const email = result.find(value => value[0] === "email")[1]
            const password = result.find(value => value[0] === "password")[1]
            const monthlyHours = result.find(value => value[0] === "monthlyHours")[1]

            if (refreshToken != null && false) {
                setShowLoadingMessage(true)
                HttpRequests.newAccessToken(refreshToken).then(value => {
                    setShowLoadingMessage(false)
                    console.log(refreshToken)
                    console.log(value)
                    if (value.success) {
                        const accessToken = value.extras.access_token
                        ProfileData.setData({
                            name: name,
                            accountType: accountType,
                            userId: userId,
                            refreshToken: refreshToken,
                            accessToken: accessToken,
                            position: position,
                            salary: salary,
                            age: age,
                            gender: gender,
                            contractFile: contractFile,
                            contractDate: contractDate,
                            salaryType: salaryType,
                            familyMemberContact: familyMemberContact,
                            familyMemberName: familyMemberName,
                            contactNumber: contactNumber,
                            socialNumber: socialNumber,
                            jobDescription: jobDescription,
                            cleaningNumber: cleaningNumber,
                            email: email,
                            password: password,
                            monthlyHours: monthlyHours,
                        })
                        if (+ProfileData.accountType === ProfileData.AccountTypes.Admin) {
                            navigation.navigate("EmployerScreens")
                        } else {
                            navigation.navigate("EmployeeScreens")
                        }
                    } else {
                        errorMessage.current = "Token expired, please login again."
                        AsyncStorage.clear()
                        setShowErrorDialog(true)
                    }
                })
            } else {

            }
        })
    }, []);

    // containers
    const VerificationContent = ({visible, useAnimation, usernameText}) => {
        return (
            <FadeView
                style={{
                    alignSelf: "stretch",
                    zIndex: visible ? 1 : 0,
                }}
                visible={visible}
                duration={animationDuration}
                useAnimation={useAnimation}
            >
                <StyledEditText
                    ref={verificationCodeFieldRef}
                    value={verificationCodeText}
                    borderRadius={200}
                    placeholder={"Enter Verification Code"}
                    onChangeText={(text) => {
                        setVerificationCodeText(text)
                    }}
                />
                <Timer totalTime={timeIsUp ? 0 : resendCodeTime} onTimeUp={() => {
                    setTimeIsUp(true)
                }}/>
                <Text
                    style={[styles.pinkText, styles.centeredText, {
                        color: timeIsUp ? colors.navyBlue : colors.darkGrey,
                        marginTop: 5
                    }]}
                    onPress={!timeIsUp ? null : () => {
                        setTimeIsUp(false)
                    }}
                >Didn't receive the code? Resend</Text>
                <Button title={"Submit"}
                        style={{marginTop: 20}}
                        color={colors.lightYellow}
                        buttonStyle={{borderRadius: 100, paddingVertical: 15}}
                        containerStyle={{width: "100%"}}
                        titleStyle={{color: colors.darkGrey, fontWeight: "700", fontSize: 20}}
                        onPress={() => {
                            setShowLoadingMessage(true)
                            HttpRequests.checkVerificationCode(usernameText, verificationCodeText).then((result) => {
                                setShowLoadingMessage(false)
                                if (result.success) {
                                    changeDestination(ChangePassDestinationName)
                                } else {
                                    errorMessage.current = result.message
                                    setShowErrorDialog(true)
                                }
                            })
                        }}
                />
                <Text style={[styles.pinkText, styles.centeredText, {marginTop: 15}]}
                      onPress={() => {
                          changeDestination(LoginDestinationName)
                      }}>Cancel</Text>
            </FadeView>)
    }

    const ChangePassContent = ({visible, useAnimation}) => {
        return (
            <FadeView
                style={{
                    alignSelf: "stretch",
                    zIndex: visible ? 1 : 0,
                }}
                visible={visible}
                duration={animationDuration}
                useAnimation={useAnimation}
            >
                <StyledEditText
                    ref={changePassFieldRef}
                    value={changePassText}
                    onChangeText={(text) => setChangePassText(text)}
                    borderRadius={200} placeholder={"New password"} isSecure={true}/>
                <StyledEditText
                    ref={changePassRepeatFieldRef}
                    value={changePassRepeatText}
                    onChangeText={(text) => setChangePassRepeatText(text)}
                    borderRadius={200} placeholder={"Re-type new password"} isSecure={true}/>
                <Button title={"Submit"}
                        style={{marginTop: 60}}
                        color={colors.lightYellow}
                        buttonStyle={{borderRadius: 100, paddingVertical: 15}}
                        containerStyle={{width: "100%"}}
                        titleStyle={{color: colors.darkGray, fontWeight: "700", fontSize: 20}}
                        onPress={() => {
                            if (changePassRepeatText !== changePassText) {
                            } else {
                                setShowLoadingMessage(true)
                                HttpRequests.setNewPass(usernameTextRef.current, changePassText).then((result) => {
                                    setShowLoadingMessage(false)
                                    if (result.success) {
                                        changeDestination(LoginDestinationName)
                                    } else {
                                        errorMessage.current = result.message
                                        setShowErrorDialog(true)
                                    }
                                })
                            }
                        }}
                />
                <Text style={[styles.pinkText, styles.centeredText, {marginTop: 15}]}
                      onPress={() => {
                          changeDestination(LoginDestinationName)
                      }}>Cancel</Text>
            </FadeView>)
    }

    function changeDestination(newDestination) {
        setTransition(true)
        setShowAnimation(true)
        setTimeout(() => {
            setDestination(newDestination)
            setTransition(false)
            setTimeout(() => {
                setShowAnimation(false)
            }, animationDuration)
        }, animationDuration)
    }


    return (
        <View style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            backgroundColor: "white"
        }}>
            <View style={{flex: 1}}/>
            <View style={styles.container}>
                <View style={{flex: 2}}/>
                <Image style={styles.logo} source={require('./../../assets/logo512.png')}/>
                <Text style={styles.title}>Avvikelseanalys</Text>
                {destination === LoginDestinationName &&
                    <LoginContent
                        visible={!transition} useAnimation={showAnimation} animationDuration={animationDuration}
                        onForgotPass={(usernameText) => {
                            if (usernameText.length === 0) {
                                setShowFillUsernameError(true)
                            } else {
                                setShowLoadingMessage(true)
                                HttpRequests.sendVerificationCode(usernameText).then((result) => {
                                    setShowLoadingMessage(false)
                                    if (result.success) {
                                        usernameTextRef.current = usernameText
                                        changeDestination(VerificationDestinationName)
                                    } else {
                                        errorMessage.current = result.message
                                        setShowErrorDialog(true)
                                    }
                                })
                            }
                        }}
                        tryLogin={(usernameText, passwordText, remember) => {
                            // console.log(`Mory: ${usernameTextRef.current}, ${passwordTextRef.current}`)

                            if (usernameText.length === 0 || passwordText.length === 0) {
                                setShowFillFormError(true)
                            } else {
                                setShowLoadingMessage(true)
                                HttpRequests.login(usernameText, passwordText).then(async (res) => {
                                    setShowLoadingMessage(false)
                                    console.log(res)
                                    if (res.success) {
                                        const extras = res.extras;
                                        ProfileData.setData({
                                            name: extras.name,
                                            accountType: extras.acc_type,
                                            userId: extras.user_id,
                                            refreshToken: extras.refresh_token,
                                            accessToken: extras.access_token,
                                            position: extras.position,
                                            salary: extras.salary,
                                            age: extras.age,
                                            gender: extras.gender,
                                            contractFile: extras.contract_file,
                                            contractDate: extras.contract_date,
                                            salaryType: extras.salary_type,
                                            familyMemberContact: extras.family_member_contact,
                                            familyMemberName: extras.family_member_name,
                                            contactNumber: extras.contact_num,
                                            socialNumber: extras.social_num,
                                            jobDescription: extras.job_desc,
                                            cleaningNumber: extras.cleaning_num,
                                            email: usernameText,
                                            password: passwordText,
                                            monthlyHours: extras.total_monthly_hours,
                                            accountNum: extras.acc_num
                                        })
                                        if (remember) {
                                            await AsyncStorage.multiSet([
                                                ["name", extras.name?.toString()],
                                                ["accountType", extras.acc_type?.toString()],
                                                ["userId", extras.user_id?.toString()],
                                                ["refreshToken", extras.refresh_token?.toString()],
                                                ["position", extras.position?.toString()],
                                                ["salary", extras.salary?.toString()],
                                                ["age", extras.age?.toString()],
                                                ["gender", extras.gender?.toString()],
                                                ["contractFile", extras.contract_file?.toString()],
                                                ["contractDate", extras.contract_date?.toString()],
                                                ["salaryType", extras.salary_type?.toString()],
                                                ["familyMemberContact", extras.family_member_contact?.toString()],
                                                ["familyMemberName", extras.family_member_name?.toString()],
                                                ["contactNumber", extras.contact_num?.toString()],
                                                ["socialNumber", extras.social_num?.toString()],
                                                ["jobDescription", extras.job_desc?.toString()],
                                                ["cleaningNumber", extras.cleaning_num?.toString()],
                                                ["email", usernameText?.toString()],
                                                ["password", passwordText?.toString()],
                                                ["monthlyHours", extras.total_monthly_hours?.toString()],
                                                ["accountNum", extras.acc_num?.toString()]
                                            ], errors => {
                                                if (ProfileData.accountType === ProfileData.AccountTypes.Admin) {
                                                    navigation.navigate("EmployerScreens")
                                                } else {
                                                    navigation.navigate("EmployeeScreens")
                                                }
                                            })
                                        } else {
                                            await AsyncStorage.clear(error => {
                                                if (ProfileData.accountType === ProfileData.AccountTypes.Admin) {
                                                    navigation.navigate("EmployerScreens")
                                                } else {
                                                    navigation.navigate("EmployeeScreens")
                                                }
                                            })
                                        }
                                    } else {
                                        errorMessage.current = res.message
                                        setShowErrorDialog(true)
                                    }
                                })
                            }
                        }}/>}
                {destination === VerificationDestinationName &&
                    <VerificationContent visible={!transition} useAnimation={showAnimation} usernameText={usernameTextRef.current}/>}
                {destination === ChangePassDestinationName &&
                    <ChangePassContent visible={!transition} useAnimation={showAnimation}/>}
                <Image style={styles.footerImage} source={require('./../../assets/login_footer_image.png')}/>
            </View>
            <View style={{flex: 1}}/>
            <AwesomeAlert
                show={showFillFormError}
                title={"Login"}
                message={"Please fill username and password fields"}
                showConfirmButton={true}
                confirmText={"OK"}
                confirmButtonColor={colors.lightYellow}
                onConfirmPressed={() => {
                    setShowFillFormError(false)
                }}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                useNativeDriver={true}
            />
            <AwesomeAlert
                show={showFillUsernameError}
                title={"Login"}
                message={"Please fill username field to continue"}
                showConfirmButton={true}
                confirmText={"OK"}
                confirmButtonColor={colors.lightYellow}
                onConfirmPressed={() => {
                    setShowFillUsernameError(false)
                }}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                useNativeDriver={true}
            />
            <AwesomeAlert
                show={showErrorDialog}
                title={"Error"}
                message={errorMessage.current}
                showConfirmButton={true}
                confirmText={"OK"}
                confirmButtonColor={colors.lightYellow}
                onConfirmPressed={() => {
                    setShowErrorDialog(false)
                }}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                useNativeDriver={true}
            />
            <LoadingMessage showLoadingMessage={showLoadingMessage}/>
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        alignSelf: "stretch",
        backgroundColor: "white",
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: 20,
        maxWidth: 470,
    },
    footerImage: {
        alignSelf: "stretch",
        flex: 5,
    },
    pinkText: {
        alignSelf: "stretch",
        color: colors.navyBlue,
        fontSize: 16,
        fontWeight: "700",
        fontFamily: "Lato"
    },
    forgotPass: {
        marginTop: 15,
        textAlign: "right",
    },
    logo: {
        height: 130,
        width: 130,
    },
    title: {
        color: colors.darkGrey,
        fontFamily: "Lato",
        fontSize: 30,
        fontWeight: "700",
        marginBottom: 70,
    },
    centeredText: {
        alignSelf: "center",
    },
    checkbox: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignItems: "center",
    },
});
