import {StyleSheet} from "react-native";
import {createDrawerNavigator, DrawerContentScrollView} from "@react-navigation/drawer";
import {colors} from "../../colors";
import DefaultValuesTab from "./DefaultValuesTab";
import EmployeesTab from "./EmployeesTab/EmployeesTab";
import CustomDrawerContent from "../CustomDrawerContnet";
import EmployerTimeTableContents from "./PostingTab/EmployerTimeTableContents";
import ReportsTab from "./ReportsTab/ReportsTab";
import TimingReport from "./TimingReport";
import PostingTab from "./PostingTab/PostingTab";
import NewSingleEmployeeReport from "./ReportsTab/NewSingleEmployeeReport";
import NewTimingReport from "./ReportsTab/NewTimingReport";
import TimeTableTabView from "../employee_screens/TimeTableTabView";
import ConfirmTimingTab from "./ConfirmTimingTab";

export class EmployerMainScreenRouteNames {
    static employees = "Employees"
    static defaultValues = "Central Parameters"
    static posting = "Posting"
    static timing = "Confirm Timing"
    static reports = "Overall"
    static employeeReport = "Employee"
    static timingReport = "Timing"
}

export default function EmployerMainScreen() {

    const Drawer = createDrawerNavigator();

    const DrawerScreens = [
        {
            route: EmployerMainScreenRouteNames.employees,
            label: EmployerMainScreenRouteNames.employees,
            component: EmployeesTab,
            deselectedSrc: require("../../assets/icons/employees_pink.png"),
            selectedSrc: require("../../assets/icons/employees_white.png"),
        },
        {
            route: EmployerMainScreenRouteNames.defaultValues,
            label: EmployerMainScreenRouteNames.defaultValues,
            component: DefaultValuesTab,
            deselectedSrc: require("../../assets/icons/taxing_pink.png"),
            selectedSrc: require("../../assets/icons/taxing_white.png"),
        },
        {
            route: EmployerMainScreenRouteNames.posting,
            label: EmployerMainScreenRouteNames.posting,
            component: PostingTab,
            deselectedSrc: require("../../assets/icons/posting_pink.png"),
            selectedSrc: require("../../assets/icons/posting_white.png"),
        },
        {
            route: EmployerMainScreenRouteNames.timing,
            label: EmployerMainScreenRouteNames.timing,
            component: ConfirmTimingTab,
            deselectedSrc: require("../../assets/icons/posting_pink.png"),
            selectedSrc: require("../../assets/icons/posting_white.png"),
        },
        {
            route: EmployerMainScreenRouteNames.reports,
            label: EmployerMainScreenRouteNames.reports,
            component: ReportsTab,
            deselectedSrc: require("../../assets/icons/reports_pink.png"),
            selectedSrc: require("../../assets/icons/reports_white.png"),
        },
        {
            route: EmployerMainScreenRouteNames.employeeReport,
            label: EmployerMainScreenRouteNames.employeeReport,
            component: NewSingleEmployeeReport,
            deselectedSrc: require("../../assets/icons/employee_report_pink.png"),
            selectedSrc: require("../../assets/icons/employee_report_white.png"),
        },
        {
            route: EmployerMainScreenRouteNames.timingReport,
            label: EmployerMainScreenRouteNames.timingReport,
            component: NewTimingReport,
            deselectedSrc: require("../../assets/icons/timing_report_pink.png"),
            selectedSrc: require("../../assets/icons/timing_report_white.png"),
        },
    ]

    return (
        // <NavigationContainer>
        <Drawer.Navigator
            initialRouteName={EmployerMainScreenRouteNames.employees}
            screenOptions={styles.drawerStyles}
            drawerContent={CustomDrawerContent}>
            {DrawerScreens.map((item, i) => (
                <Drawer.Screen key={i} name={item.route} component={item.component} options={{item: item}}/>
            ))}
        </Drawer.Navigator>

        // </NavigationContainer>
    )
}


const styles = StyleSheet.create({
    drawerStyles: {
        drawerType: "permanent",
        headerShown: false,
        drawerActiveBackgroundColor: colors.navyBlue,
        drawerActiveTintColor: "white",
        drawerStyle:
            {
                backgroundColor: colors.lightGray,
                width:
                    90,
            }
    },
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingTop: 40,
        paddingHorizontal: 20,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignContent: "center",
        alignItems: "center",
        maxHeight: 55,
        marginBottom: 30
    }
    ,
})
