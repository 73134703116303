import {
    View,
    StyleSheet,
    Text,
    SafeAreaView,
    ScrollView,
    TouchableOpacity,
    Image
} from "react-native";
import {NavigationContainer} from "@react-navigation/native";
import {createDrawerNavigator, DrawerContentScrollView} from "@react-navigation/drawer";
import AvailabilityTab from "./AvailabilityTab";
import TimeTableTabView from "./TimeTableTabView";
import ProfileTabView from "./ProfileTabView";
import {colors} from "../../colors";
import CustomDrawerContent from "../CustomDrawerContnet";
import ProfileData from "../../misc/ProfileData";

export default function EmployeeMainScreen() {

    const Drawer = createDrawerNavigator();

    const DrawerScreens = [
        {
            route: "Profile",
            label: "Profile",
            component: ProfileTabView,
            deselectedSrc: require("../../assets/icons/employee_pink.png"),
            selectedSrc: require("../../assets/icons/employee_white.png"),
        },
        {
            route: "Time Table",
            label: "Time Table",
            component: TimeTableTabView,
            deselectedSrc: require("../../assets/icons/calendar_pink.png"),
            selectedSrc: require("../../assets/icons/calendar_white.png"),
        },
        ProfileData.salaryType !== ProfileData.SalaryTypes.Monthly ? {
            route: "Availability",
            label: "Availability",
            component: AvailabilityTab,
            deselectedSrc: require("../../assets/icons/calendar_pink.png"),
            selectedSrc: require("../../assets/icons/calendar_white.png"),
        } : null
    ]
    console.log(DrawerScreens)

    return (
        // <NavigationContainer>
        <Drawer.Navigator
            // initialRouteName={"Edit Time Table"}
            screenOptions={styles.drawerStyles}
            drawerContent={CustomDrawerContent}>
            {DrawerScreens.map((item, i) => {
                    return item != null && <Drawer.Screen key={i} name={item.route} component={item.component} options={{item: item}}/>
                }
            )}
        </Drawer.Navigator>

        // </NavigationContainer>
    )

}


const styles = StyleSheet.create({
    alertTitle: {
        fontFamily: "LatoRegular",
        fontSize: 20,
        fontWeight: "700",
        color: colors.navyBlue
    },
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingTop: 40,
        paddingHorizontal: 20,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignContent: "center",
        alignItems: "center",
        maxHeight: 55,
        marginBottom: 30
    },
    sideBarStyles: {
        flex: 1,
        alignSelf: "stretch",
        backgroundColor: colors.lightGray,
        maxWidth: 90,
    },
    drawerStyles: {
        drawerType: "permanent",
        headerShown: false,
        drawerActiveBackgroundColor: colors.navyBlue,
        drawerActiveTintColor: "white",
        drawerStyle: {
            backgroundColor: colors.lightGray,
            width: 90,
        }
    },
    userProfile: {
        fontFamily: "Lato",
        fontWeight: "700",
        fontSize: 16,
        color: colors.darkGray,
        marginStart: 5
    }
})
