import {Component} from "react";
import PropTypes, {number} from "prop-types";
import {styleProps as ViewPropTypes} from "react-native-web/src/modules/forwardedProps";
import {View} from "react-native";

export default class Table extends Component {
    static propTypes = {
        style: ViewPropTypes.style,
        columnsCount: PropTypes.number,
        items: PropTypes.array,
        itemGeneratorFunction: PropTypes.func,
        columnCountCalculationFunction: PropTypes.func,
    }

    state = { width: window.innerWidth, height: window.innerHeight };

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        function generateTable(props, windowWidth) {
            let rows = []
            let items = props.items
            let columnsCount = 0
            let rowsCount = 1
            if (props.columnsCount) {
                columnsCount = props.columnsCount;
            } else {
                if (windowWidth === 0) return null
                if (props.columnCountCalculationFunction) {
                    columnsCount = props.columnCountCalculationFunction(windowWidth)
                }
            }

            rowsCount = items.length / columnsCount
            if (!isFinite(rowsCount)) {
                rowsCount = 1
            }

            for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
                let rowItems = items.slice(rowIndex * columnsCount)
                if (rowItems.length > columnsCount) {
                    rowItems = rowItems.slice(0, columnsCount)
                } else if (rowItems.length < columnsCount) {
                    while (rowItems.length !== columnsCount) {
                        rowItems.push(null)
                    }
                }
                let newRow = (
                    <View style={{flexDirection: "row", justifyContent: "space-around"}}>
                        {
                            rowItems.map((item, index) => {
                                // if (item === null) return <View/>
                                return props.itemGeneratorFunction(item, rowIndex * columnsCount + index)
                            })
                        }
                    </View>
                )
                rows = rows.concat(newRow)
            }

            return (
                <View>
                    {
                        rows.map((row, index)=>{
                            return row
                        })
                    }
                </View>
            )
        }

        return (
            <View style={this.props.style}>
                {
                    generateTable(this.props, this.state.width)
                }
            </View>
        )
    }
}
