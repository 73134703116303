import {Image, ScrollView, StyleSheet, Text, View} from "react-native";
import StyledEditText from "./components/StyledEditText";
import Space from "../Space";
import CustomButton from "./components/CustomButton";
import {useEffect, useState} from "react";
import TitleView from "./components/TitleView";
import Footer from "../Footer";
import {colors} from "../../colors";
import ScreenHeader from "../ScreenHeader";
import ProfileData from "../../misc/ProfileData";
import {format, getDate, getMonth, getYear} from "date-fns";
import HttpRequests from "../../http_requests/HttpRequests";
import {te} from "date-fns/locale";
import {fontStyles} from "../../fontStyles";
import StyledDropDown from "../StyledDropDown";
import LoadingMessage, {AlertMessage} from "../DefaultMessages";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";


export default function ProfileTabView({navigation}) {
    const [showLoading, setShowLoading] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertTitle, setAlertTitle] = useState("")

    return (
        <View style={styles.mainContainer}>
            <ScrollView style={{flex: 1}}>
                <ScreenHeader navigation={navigation}/>
                <TitleView titleText={"Employee Profile"}/>
                <View style={{flex: 1}}>
                    <ConstantData/>
                    <Space height={40}/>
                    <EditableData onSubmitClicked={(data) => {
                        setShowLoading(true)
                        HttpRequests.editProfile(
                            ProfileData.userId,
                            data.ssn,
                            data.cleaningNum,
                            data.age,
                            data.accountNum,
                            data.contactNum,
                            data.gender,
                            data.familyMemberName,
                            data.familyMemberNum
                        ).then((result) => {
                            console.log(result)
                            setShowLoading(false)
                            if (result.success) {
                                setAlertMessage("Profile successfully updated.")
                                setAlertTitle("Profile Update")
                                setShowMessage(true)
                            } else {
                                // handle error
                            }
                        })
                    }}/>
                    <Space height={40}/>
                    <ChangePassword
                        onMessage={(title, message) => {
                            setAlertTitle(title)
                            setAlertMessage(message)
                            setShowMessage(true)
                        }}
                        onLoading={(loading) => {
                            setShowLoading(loading)
                        }}/>
                    <Space flex={1}/>
                </View>
            </ScrollView>
            <Footer/>
            <LoadingMessage showLoadingMessage={showLoading}/>
            <AlertMessage message={alertMessage} title={alertTitle} showMessage={showMessage}
                          onConfirm={() => setShowMessage(false)}/>
        </View>
    )
}

function ConstantData() {
    return (
        <View>
            <View style={{flex: 1, flexDirection: "row"}}>
                <View style={{flex: 1, flexDirection: "column"}}>
                    <View style={{flex: 1, flexDirection: "row", maxHeight: 40,}}>
                        <StyledEditText
                            placeholder={"Full Name"}
                            style={{flex: 20, paddingEnd: 45}}
                            value={ProfileData.name}
                            enabled={false}
                            topLabel={true}
                        />
                        <Space flex={1}/>
                        <StyledEditText
                            placeholder={"Position"}
                            style={{flex: 20}}
                            value={ProfileData.position}
                            enabled={false}
                            topLabel={true}
                        />
                        <Space flex={1}/>
                        <StyledEditText
                            placeholder={"Age"}
                            style={{flex: 10}}
                            value={format(new Date(
                                getYear(new Date()) - getYear(Date.parse(ProfileData.age)),
                                getMonth(new Date()) - getMonth(Date.parse(ProfileData.age)),
                                getDate(new Date()) - getDate(Date.parse(ProfileData.age))), "yy")}
                            topLabel={true}
                        />
                        {/*<Space flex={1}/>*/}
                        {/*<StyledEditText*/}
                        {/*    placeholder={"Contract Date"}*/}
                        {/*    style={{flex: 10}}*/}
                        {/*    value={*/}
                        {/*        isNaN(+ProfileData.contractDate) || getYear(new Date(Date(+ProfileData.contractDate))) < 1990 ?*/}
                        {/*            "" :*/}
                        {/*            format(new Date(Date(+ProfileData.contractDate)), "yyyy/MM/dd")*/}
                        {/*    }*/}
                        {/*    enabled={false}*/}
                        {/*    topLabel={true}*/}
                        {/*/>*/}
                    </View>
                    <Space height={15}/>
                    <View style={{flex: 1, flexDirection: "row", maxHeight: 40,}}>
                        <StyledEditText
                            style={{flex: 9.5}}
                            placeholder={"Salary"}
                            value={ProfileData.salary}
                            enabled={false}
                            topLabel={true}
                        />
                        <Space flex={1}/>
                        <StyledEditText
                            style={{flex: 9.5}}
                            placeholder={"Salary Type"}
                            value={ProfileData.salaryType === ProfileData.SalaryTypes.Monthly ? "Monthly" : "Hourly"}
                            enabled={false}
                            topLabel={true}
                        />
                        <Space flex={1}/>
                        <StyledEditText
                            style={{flex: 20}}
                            placeholder={"Email"}
                            value={ProfileData.email}
                            enabled={false}
                            topLabel={true}
                        />
                        {/*<Space flex={1}/>*/}
                        {/*<CustomButton text={"Upload Contract"} flex={10} onPress={null}/>*/}
                    </View>
                </View>
                {/*<Image style={{width: 85, height: 85, marginHorizontal: 25}}*/}
                {/*       source={require("../../assets/contract.png")}/>*/}
            </View>
            <Space height={15}/>
            <StyledEditText
                style={{height: 110,}}
                placeholder={"Job Description"}
                value={ProfileData.jobDesc}
                enabled={false}
                multiline={true}
                topLabel={true}
            />
        </View>
    );
}

function EditableData({onSubmitClicked}) {
    const genderItems = [
        {label: "Male", value: ProfileData.Genders.Male},
        {label: "Female", value: ProfileData.Genders.Female},
        {label: "Other", value: ProfileData.Genders.Other}
    ]

    const [ssn, setSsn] = useState(ProfileData.socialNumber)
    const [cleaningNum, setCleaningNum] = useState(ProfileData.cleaningNumber)
    const [age, setAge] = useState(ProfileData.age)
    const [contactNum, setContactNum] = useState(ProfileData.contactNumber)
    const [familyMemberName, setFamilyMemberName] = useState(ProfileData.familyMemberName)
    const [familyMemberNum, setFamilyMemberNum] = useState(ProfileData.familyMemberContact)
    const [accountNum, setAccountNum] = useState(ProfileData.accountNum)
    const [gender, setGender] =
        useState(genderItems.find(value => value.value === +ProfileData.gender))

    return (
        <View style={{}}>
            <Text style={fontStyles.lato700_14}>Enter or Edit your data</Text>
            <Space height={15}/>
            <View style={{flexDirection: "row", maxHeight: 40, flex: 1}}>
                <StyledEditText
                    placeholder={"Social Security #"}
                    style={{flex: 10}}
                    value={ssn}
                    onChangeText={(text) => setSsn(text)}
                    topLabel={true}
                />
                <Space width={20}/>
                <StyledEditText
                    placeholder={"Clearing #"}
                    style={{flex: 10}}
                    value={cleaningNum}
                    onChangeText={(text) => setCleaningNum(text)}
                    topLabel={true}
                />
                <Space width={20}/>
                <StyledEditText
                    placeholder={"Account #"}
                    style={{flex: 10}}
                    value={accountNum}
                    onChangeText={(text) => setAccountNum(text)}
                    topLabel={true}
                />
            </View>
            <Space height={15}/>
            <View style={{flexDirection: "row", maxHeight: 40, flex: 1}}>
                <StyledDropDown topLabel={true} items={genderItems} value={gender.label} style={{flex: 10}}
                                placeHolder={"Gender"}
                                onChange={(item) => {
                                    console.log(item)
                                    setGender(item)
                                }}/>
                <Space width={20}/>
                <StyledEditText
                    placeholder={"Contact #"}
                    style={{flex: 10}}
                    value={contactNum}
                    onChangeText={(text) => setContactNum(text)}
                    topLabel={true}
                />
                <Space width={20}/>
                <StyledEditText
                    placeholder={"Name to closest family"}
                    style={{flex: 10}}
                    value={familyMemberName}
                    onChangeText={(text) => setFamilyMemberName(text)}
                    topLabel={true}
                />
                <Space width={20}/>
                <StyledEditText
                    placeholder={"Contact to closest family"}
                    style={{flex: 10}}
                    value={familyMemberNum}
                    onChangeText={(text) => setFamilyMemberNum(text)}
                    topLabel={true}
                />
            </View>
            <Space height={15}/>
            <View style={{flex: 1, flexDirection: "row", maxHeight: 40}}>
                <Space flex={5}/>
                <CustomButton text={"Submit"} flex={10} onPress={() => {
                    const data = {
                        ssn: ssn,
                        cleaningNum: cleaningNum,
                        age: age,
                        contactNum: contactNum,
                        familyMemberName: familyMemberName,
                        familyMemberNum: familyMemberNum,
                        gender: gender.value,
                        accountNum: accountNum
                    }
                    onSubmitClicked(data)
                }}/>
                <Space flex={5}/>
            </View>
        </View>
    )
}

function ChangePassword({onMessage, onLoading}) {
    const [oldPass, setOldPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [repeatPass, setRepeatPass] = useState("")

    function passwordIsOk() {
        const theyMatch = newPass === repeatPass
        let isSecure = newPass.match("([A-Za-z0-9!@#$%^&*()]){8,}") != null
        isSecure = isSecure && newPass.match("[A-Z]") != null
        isSecure = isSecure && newPass.match("[a-z]") != null
        isSecure = isSecure && newPass.match("[0-9]") != null
        isSecure = isSecure && newPass.match("[!@#$%^&*()]") != null
        return theyMatch && isSecure
    }

    function onPress() {
        if (oldPass !== ProfileData.password) {
            onMessage("Error", "Old password value is not correct.")
        } else if (!passwordIsOk()) {
            onMessage("Error", "Password length should be more than 8 characters and contain at least one uppercase letter, one lower case letter, one number and a special character.")
        } else {
            onLoading(true)
            HttpRequests.setNewPass(ProfileData.email, newPass).then((result) => {
                onLoading(false)
                console.log(result)
                if (result.success) {
                    setOldPass("")
                    setNewPass("")
                    setRepeatPass("")
                    onMessage("Success", "Password successfully changed.")
                } else {
                    onMessage("Error", "Something went wrong while trying to change password. Please try again later.")
                }
            })
        }
    }

    return (
        <View style={{flexDirection: "row"}}>
            <View style={{flex: 1}}>
                <Text style={fontStyles.lato700_14}>Change Password</Text>
                <Space height={15}/>
                <StyledEditText
                    placeholder={"Old password"}
                    isSecure={true}
                    value={oldPass}
                    onChangeText={(text) => {
                        setOldPass(text)
                    }}
                    topLabel={true}
                />
                <Space height={15}/>
                <StyledEditText
                    placeholder={"New password"}
                    isSecure={true}
                    value={newPass}
                    onChangeText={(text) => {
                        setNewPass(text)
                    }}
                    topLabel={true}
                />
                <Space height={15}/>
                <StyledEditText
                    placeholder={"Re-type new password"}
                    isSecure={true}
                    value={repeatPass}
                    onChangeText={(text) => {
                        setRepeatPass(text)
                    }}
                    topLabel={true}
                />
                <Space height={15}/>
                <CustomButton text={"Change Password"} flex={1}
                              onPress={(newPass !== "" && repeatPass !== "" && newPass === repeatPass) ? onPress : null}/>
            </View>
            <Space flex={2}/>
        </View>
    )
}


const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignContent: "center",
        alignItems: "center",
        maxHeight: 55,
        marginBottom: 30
    },
    userProfile: {
        fontFamily: "Lato",
        fontWeight: "700",
        fontSize: 16,
        color: colors.darkGray,
        marginStart: 5
    }
})
