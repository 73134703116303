import React, {useEffect, useRef, useState} from "react";
import {Image, Platform, Text, TouchableOpacity, View} from "react-native";
import Space from "../../Space";
import CustomButton from "../../employee_screens/components/CustomButton";
import FadeView from "../../FadeView";
import TitleView from "../../employee_screens/components/TitleView";
import StyledEditText from "../../employee_screens/components/StyledEditText";
import DatePickerView from "../../DatePickerView";
import StyledDropDown from "../../StyledDropDown";
import DefaultValues from "../../../misc/DefaultValues";
import HttpRequests from "../../../http_requests/HttpRequests";
import LoadingMessage from "../../DefaultMessages";
import AwesomeAlert from "react-native-awesome-alerts";
import {Dropdown} from "react-native-element-dropdown";
import {fontStyles} from "../../../fontStyles";
import {colors} from "../../../colors";
import ProfileData from "../../../misc/ProfileData";
import Helpers from "../../../misc/Helpers";

export default function NewUserContent({
                                           visible,
                                           useAnimation,
                                           animationDuration,
                                           contractDate,
                                           birthDate,
                                           onShowCalendarClick,
                                           onSubmit,
                                           onCancel,
                                           user
                                       }) {
    const [selectedData, setSelectedData] = useState({})
    const newPositionName = useRef("");
    const [showLoading, setShowLoading] = useState(false)
    const [showNewPositionDialog, setShowNewPositionDialog] = useState(false)
    const [showLoadingError, setShowLoadingError] = useState(false)
    const [showFillDefaultsError, setShowFillDefaultsError] = useState(false)
    const [defaultMonthlyHours, setDefaultMonthlyHours] = useState(0)
    const [positions, setPositions] = useState([])

    function updatePositions() {
        HttpRequests.getPositions().then(result => {
            setShowLoading(false)
            console.log(result)
            if (result.success) {
                setPositions(result.extras)
            } else {
                setPositions([])
            }
        })
    }

    useEffect(() => {
        setShowLoading(true)
        updatePositions()
        // if (!DefaultValues.updated) {
        //     setShowLoading(true)
        // HttpRequests.getDefaultValues().then(result => {
        //     setShowLoading(false)
        //     if (result.success) {
        //         DefaultValues.updateValues(result.extras)
        //         setDefaultMonthlyHours(DefaultValues.total_monthly_hours)
        //     } else if (result.message === "No Data Available!") {
        //         setShowFillDefaultsError(true)
        //     } else {
        //         setShowLoadingError(true)
        //     }
        // })
        // } else {
        //     setDefaultMonthlyHours(DefaultValues.total_monthly_hours)
        // }
    }, []);


    function NewPositionDialogBody({onTextChange}) {
        return (
            <View style={{marginVertical: 10}}>
                <Text style={fontStyles.lato700_14}>Please enter new position name</Text>
                <Space height={10}/>
                <StyledEditText placeholder={"Position name"} topLabel={true} onChangeText={onTextChange}/>
            </View>
        )
    }

    return (
        <FadeView style={{flex: 1}} visible={visible} duration={animationDuration} useAnimation={useAnimation}>
            <TitleView titleText={"Employee Profile"}/>
            <View style={{flex: 1}}>
                <RegistrationInfo
                    positions={positions}
                    user={user}
                    contractDate={contractDate}
                    birthDate={birthDate}
                    onShowCalendarClick={onShowCalendarClick}
                    defaultMonthlyHours={defaultMonthlyHours}
                    onDataUpdated={(newData) => {
                        setSelectedData(newData)
                    }}
                    onAddClick={() => {
                        setShowNewPositionDialog(true)
                    }}

                />
                <Space height={40}/>
                <Buttons user={user} selectedData={selectedData} onSubmit={onSubmit} onCancel={onCancel}/>
                <Space flex={1}/>
            </View>
            <LoadingMessage showLoadingMessage={showLoading}/>
            <AwesomeAlert title={"Error"}
                          message={"Something went wrong while trying to connect to server. Please try again later"}
                          closeOnTouchOutside={false}
                          show={showLoadingError}
                          showConfirmButton={true}
                          onConfirmPressed={() => {
                              onCancel()
                          }}
            />
            <AwesomeAlert title={"Error"}
                          message={"There is no default values for working times, please fill defaults and then try again."}
                          closeOnTouchOutside={false}
                          show={showFillDefaultsError}
                          showConfirmButton={true}
                          onConfirmPressed={() => {
                              onCancel()
                          }}
            />
            <AwesomeAlert
                titleStyle={fontStyles.lato700_20}
                title={"New Position"}
                customView={<NewPositionDialogBody onTextChange={(text) => {
                    newPositionName.current = text
                }}/>}
                closeOnTouchOutside={false}
                show={showNewPositionDialog}
                showConfirmButton={true}
                onConfirmPressed={() => {
                    setShowNewPositionDialog(false)
                    setTimeout(() => {
                        setShowLoading(true)
                        HttpRequests.addPosition(newPositionName.current).then(result => {
                            setShowLoading(false)
                            console.log(result)
                            newPositionName.current = ""
                            if (result.success) {
                                updatePositions()
                            } else {
                                // handle error
                            }
                        })
                    }, 200)
                }}
                showCancelButton={true}
                onCancelPressed={() => {
                    setShowNewPositionDialog(false)
                }}
            />
        </FadeView>
    )
}

function Buttons({user, selectedData, onSubmit, onCancel}) {
    return (
        <View style={{flex: 1, flexDirection: "row", maxHeight: 40}}>
            <Space flex={5}/>
            <CustomButton text={"Cancel"} flex={7} onPress={() => {
                onCancel()
            }}/>
            <Space flex={1}/>
            <CustomButton text={"Submit"} flex={7} onPress={() => {
                console.log(selectedData)
                if (selectedData.hasOwnProperty("name")) {
                    const {
                        name,
                        position,
                        salaryType,
                        salary,
                        monthlyHours,
                        contractPercent,
                        email,
                        contract,
                        jobDesc
                    } = selectedData
                    if (name === "" || email === "" || position === "" || salary === "") {
                        // handle error
                    } else {
                        onSubmit(name, email, position, salary.replace(" ", ""), salaryType,
                            salaryType === ProfileData.SalaryTypes.Monthly ? contractPercent : monthlyHours,
                            contract, jobDesc, user === null ? null : user[0])
                    }
                } else {
                    // handle error
                }
            }}/>
            <Space flex={5}/>
        </View>
    )
}

function RegistrationInfo({
                              positions,
                              user,
                              onDataUpdated,
                              onShowCalendarClick,
                              birthDate,
                              contractDate,
                              defaultMonthlyHours,
                              onAddClick
                          }) {
    const salaryTypes = [{value: 0, title: "Monthly"}, {value: 1, title: "Hourly"}]
    const isEditing = useRef(user !== null);
    const [name, setName] = useState(isEditing.current ? user[1] : "")
    const [position, setPosition] = useState(isEditing.current ? user[14] : null)
    const [salaryType, setSalaryType] = useState(isEditing.current ? salaryTypes.find(value => value.title === user[6]).value : 0)
    const [salary, setSalary] = useState(isEditing.current ? Helpers.formatNumberWithSpace(user[5]) : "")
    const [monthlyHours, setMonthlyHours] = useState(isEditing.current ?
        (user[12] == null ? "" : user[12]) : "")
    const [contractPercent, setContractPercent] = useState(isEditing.current ?
        (user[15] == null ? "" : user[15]) : "")
    const [contractVariableText, setContractVariableText] = useState("")
    const [email, setEmail] = useState(isEditing.current ? user[13] : "")
    const [contract, setContract] = useState("")
    const [jobDesc, setJobDesc] = useState(isEditing.current ? user[11] : "")
    const [contractVariableLabel, setContractVariableLabel] = useState("")
    useEffect(() => {
        onDataUpdated(getSelectedData())
    }, [name, position, salary, monthlyHours, email, contract, jobDesc, contractPercent]);

    useEffect(() => {
        const isMonthly = salaryType === ProfileData.SalaryTypes.Monthly
        setContractVariableText(isMonthly ? contractPercent : monthlyHours)
        setContractVariableLabel(isMonthly ? "Contract Percent" : "Monthly Hours")
        onDataUpdated(getSelectedData())
    }, [salaryType]);

    useEffect(() => {
        const isMonthly = salaryType === ProfileData.SalaryTypes.Monthly
        if (isMonthly) {
            setContractPercent(contractVariableText)
        } else {
            setMonthlyHours(contractVariableText)
        }

    }, [contractVariableText]);

    function getSelectedData() {
        return {
            name: name,
            position: position,
            birthDate: birthDate,
            contractDate: contractDate,
            salaryType: salaryType,
            salary: salary,
            monthlyHours: monthlyHours,
            contractPercent: contractPercent,
            email: email,
            contract: contract,
            jobDesc: jobDesc
        }
    }

    return (
        <View>
            <View style={{flex: 1, flexDirection: "row"}}>
                <View style={{flex: 1, flexDirection: "column"}}>
                    <View style={{flex: 1, flexDirection: "row", maxHeight: 40,}}>
                        <StyledEditText
                            style={{flex: 20, paddingEnd: 45}} placeholder={"Full Name"} topLabel={true}
                            value={name}
                            onChangeText={setName}
                        />
                        <Space flex={1}/>
                        <PositionDropDown positions={positions} onAddClick={onAddClick} selectedItem={position}
                                          onChange={(item => {
                                              setPosition(item.id)
                                          })}/>
                        <Space flex={1}/>
                        <StyledEditText style={{flex: 20}} value={email} placeholder={"Email"} onChangeText={setEmail}
                                        topLabel={true}/>
                        <Space flex={1}/>
                        <DatePickerView selectedDate={birthDate} placeHolderText={"Birth Date"}
                                        onShowCalendarClicked={() => {
                                            onShowCalendarClick(false)
                                        }}/>
                    </View>
                    <Space height={15}/>
                    <View style={{flex: 1, flexDirection: "row", maxHeight: 40,}}>
                        {isEditing.current ?
                            <StyledEditText
                                style={{flex: 9.5}} placeHolder={"Salary Type"} items={salaryTypes}
                                value={salaryTypes.find(item => item.value === salaryType).title}
                                enabled={false}
                            /> :
                            <StyledDropDown
                                style={{flex: 9.5}} placeHolder={"Salary Type"} items={salaryTypes} value={salaryType}
                                valueField={"value"} labelField={"title"}
                                onChange={(item) => {
                                    console.log(item)
                                    setSalaryType(item.value)
                                    // if (item.value === 0) setMonthlyHours(defaultMonthlyHours)
                                }}
                            />
                        }
                        <Space flex={1}/>
                        <StyledEditText
                            style={{flex: 9.5}} value={salary} placeholder={"Salary"} topLabel={true}
                            onChangeText={(text) => {
                                const formattedNumber = Helpers.formatNumberWithSpace(text)
                                if (formattedNumber != null) {
                                    setSalary(formattedNumber)
                                }
                            }}/>
                        <Space flex={1}/>
                        <StyledEditText
                            style={{flex: 9.5}} value={contractVariableText} placeholder={contractVariableLabel}
                            topLabel={true}
                            onChangeText={(text) => {
                                if (text === "") {
                                    setContractVariableText(text)
                                } else if (!isNaN(+text)) {
                                    setContractVariableText(+text)
                                }
                            }}
                        />
                        {/*<Space flex={1}/>*/}
                        {/*<DatePickerView selectedDate={contractDate} placeHolderText={"Contract Date"}*/}
                        {/*                onShowCalendarClicked={() => {*/}
                        {/*                    onShowCalendarClick(true)*/}
                        {/*                }}/>*/}
                        {/*<Space flex={1}/>*/}
                        {/*<CustomButton text={"Upload Contract"} flex={10} onPress={() => {*/}
                        {/*    DocumentPicker.getDocumentAsync({type: ["image/*"], multiple: false})*/}
                        {/*        .then(value => {*/}
                        {/*            console.log(value)*/}
                        {/*            HttpRequests.uploadContract(value.assets[0]).then(response => {*/}
                        {/*                console.log(response)*/}
                        {/*            })*/}
                        {/*        })*/}
                        {/*}}/>*/}
                    </View>
                </View>
                {/*<Image style={{width: 85, height: 85, marginHorizontal: 25}}*/}
                {/*       source={require("./../../../assets/contract.png")}/>*/}
            </View>
            <Space height={15}/>
            <StyledEditText style={{height: 110,}} value={jobDesc} placeholder={"Job Description"} topLabel={true}
                            onChangeText={setJobDesc}
                            multiline={true}/>
        </View>
    );
}

function PositionDropDown({positions, onAddClick, onChange, selectedItem}) {
    // const showLabel = useRef(selectedItem !== null);
    const [showLabel, setShowLabel] = useState(selectedItem !== null)
    useEffect(() => {
        setShowLabel(selectedItem !== null)
        console.log(selectedItem)
    }, [selectedItem]);
    return (
        <View style={{
            flex: 10,
            maxHeight: 40,
            borderColor: colors.lightYellow,
            borderWidth: 1,
            borderRadius: 20,
        }}>
            <View style={{
                flex: 1,
                maxHeight: 40,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Dropdown style={{flex: 1, marginLeft: 10,}} data={positions} labelField={"label"} valueField={"id"}
                          placeholder={"Position"} value={+selectedItem} selectedTextStyle={fontStyles.lato700_14}
                          placeholderStyle={{color: colors.mediumGray, ...fontStyles.lato700_14}}
                          onChange={onChange}/>
                <TouchableOpacity style={{width: 30, height: 30, marginHorizontal: 5}} onPress={onAddClick}>
                    <Text style={{
                        color: colors.lightYellow,
                        lineHeight: 30,
                        textAlign: "center", ...fontStyles.lato700_30
                    }}>+</Text>
                </TouchableOpacity>
            </View>
            {
                showLabel && <Text style={{
                    ...fontStyles.lato400_12,
                    backgroundColor: "white",
                    marginLeft: 15,
                    paddingHorizontal: 3,
                    alignSelf: "flex-start",
                    color: colors.mediumGray,
                    zIndex: 1,
                    lineHeight: 15,
                    marginTop: -40 - 15 / 1.5
                }}>Position</Text>
            }
        </View>
    )
}
