import {TextInput} from "react-native";
import {forwardRef} from "react";

const StyledEditText =
    forwardRef(({
                    style,
                    borderRadius,
                    borderColor,
                    placeholder,
                    isSecure,
                    onChangeText,
                    onSubmitEditing,
                    value
                }, ref) => {
        // const ref = useRef(null);

        let styles = {
            inputText:
                [style, {
                    alignSelf: "stretch",
                    borderColor: borderColor ? borderColor : "#202020",
                    borderRadius: borderRadius,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    fontFamily: "Lato",
                    fontSize: 20,
                    fontWeight: "400",
                    marginVertical: 6,
                    paddingHorizontal: 25,
                    paddingVertical: 15,
                }],
        }

        return <TextInput
            ref={ref}
            style={styles.inputText}
            secureTextEntry={isSecure === true}
            placeholder={placeholder}
            value={value}
            onSubmitEditing={e => {
                if (onSubmitEditing) {
                    onSubmitEditing()
                }
            }}
            onChangeText={(text) => {
                if (onChangeText) {
                    onChangeText(text)
                }
            }}/>
    })

export default StyledEditText
