import {Image, TextInput, Text, TouchableOpacity, View} from "react-native";
import {colors} from "../colors";
import {format} from "date-fns";
import {fontStyles} from "../fontStyles";


function DatePickerView({placeHolderText, onShowCalendarClicked, selectedDate, width = undefined}) {
    return (
        <View style={{
            height: 40,
            width: width ?? 180,
            borderColor: colors.lightYellow,
            borderWidth: 1,
            borderRadius: 20,
        }}>
            <View style={{
                height: 40,
                flexDirection: "row",
                width: width ?? 180,
                justifyContent: "space-around",
                alignItems: "center"
            }}>
                <TextInput
                    style={{
                        ...fontStyles.lato700_14,
                        lineHeight: 17,
                        width: (width ?? 180) - 80,
                        outlineStyle: "none",
                    }}
                    editable={false}
                    value={selectedDate ? format(selectedDate, "yyyy/MM/dd") : placeHolderText ?? ""}
                    // placeholder={placeHolderText}
                    // placeholderTextColor={colors.mediumGray}
                />
                <TouchableOpacity
                    style={{width: 20, height: 20}}
                    onPress={() => {
                        if (onShowCalendarClicked) onShowCalendarClicked()
                    }}
                >
                    <Image
                        style={{width: 20, height: 20}}
                        source={require("./../assets/icons/calendar_pink.png")}
                    />
                </TouchableOpacity>

            </View>
            {selectedDate && <Text style={{
                ...fontStyles.lato400_12,
                marginLeft: 15,
                alignSelf: "flex-start",
                color: colors.mediumGray,
                backgroundColor: "white",
                zIndex: 1,
                lineHeight: 15,
                marginTop: -50
            }}>{placeHolderText}</Text>}
        </View>
    )
}

export default DatePickerView
