import {View} from "react-native";
import {colors} from "../colors";

export default function DividerLineVertical({color}) {
    return <View key={Math.random()} style={{backgroundColor: color ? color : colors.darkGray, width: 1,}}/>
}

export function DividerLineHorizontal({color}) {
    return <View key={Math.random()} style={{backgroundColor: color ? color : colors.darkGray, height: 1,}}/>
}
