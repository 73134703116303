import AwesomeAlert from "react-native-awesome-alerts";


export default function LoadingMessage({showLoadingMessage}) {
    return <AwesomeAlert
        show={showLoadingMessage}
        // alertContainerStyle={{backgroundColor: "green"}}
        overlayStyle={{backgroundColor: "white"}}
        // contentContainerStyle={{backgroundColor: "red"}}
        // contentStyle={{backgroundColor: "yellow"}}
        // messageStyle={{backgroundColor: "magenta"}}
        // actionContainerStyle={{backgroundColor: "black"}}
        title={"Loading"}
        message={"Please wait..."}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        useNativeDriver={true}
    />;
}

export function AlertMessage({showMessage, title, message, onConfirm}) {
    return (
        <AwesomeAlert
            show={showMessage}
            title={title}
            message={message}
            closeOnTouchOutside={false}
            showConfirmButton={true}
            confirmText={"OK"}
            onConfirmPressed={onConfirm}
        />
    )
}
