class IntentMessenger {
    private static messages: Array<Message> = []

    static getMessage(tag: string): any {
        const result = this.messages.find(message => message.tag === tag);
        console.log(result)
        if (result) {
            return result.content
        }
        return null
    }

    static popMessage(tag: string) {
        const result = this.getMessage(tag)
        console.log(result)
        if (result) {
            this.messages = this.messages.filter(message => message.tag !== tag)
        }
        return result
    }

    static setMessage(tag: string, contents: any) {
        this.messages = [...this.messages, new Message(tag, contents)]
        console.log(this.messages)
    }
}

class Message {
    content: any
    tag: string

    constructor(tag: string, content: any) {
        this.content = content
        this.tag = tag
    }
}

export default IntentMessenger
