import {View} from "react-native";
import {Component} from "react";
import PropTypes from "prop-types";


export default class Space extends Component {
    static propTypes = {
        height: PropTypes.number,
        width: PropTypes.number,
        flex: PropTypes.number
    }

    render() {
        if (this.props.height) return <View style={{height: this.props.height, flexDirection: "column"}}/>
        else if (this.props.width) return <View style={{width: this.props.width, flexDirection: "row"}}/>
        else if (this.props.flex) return <View style={{flex: this.props.flex}}/>
    }
}
