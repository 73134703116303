import {Text, TouchableHighlight} from "react-native";
import PropTypes from "prop-types";
import {styleProps as ViewPropTypes} from "react-native-web/src/modules/forwardedProps";

Selector.propTypes = {
    text: PropTypes.string,
    deselectedBorderColor: PropTypes.string,
    selectedBorderColor: PropTypes.string,
    deselectedFillColor: PropTypes.string,
    selectedFillColor: PropTypes.string,
    selectedTextColor: PropTypes.string,
    deselectedTextColor: PropTypes.string,
    id: PropTypes.number,
    selectedId: PropTypes.number,
    onSelectedChange: PropTypes.func,
    style: ViewPropTypes.style
}

export default function Selector(props) {

    let selected = props.id.toString() === props.selectedId.toString()

    let backgroundColor = selected ? props.selectedFillColor : props.deselectedFillColor
    let borderColor = selected ? props.selectedBorderColor : props.deselectedBorderColor
    let textColor = selected ? props.selectedTextColor : props.deselectedTextColor

    return (
        <TouchableHighlight
            style={
                [{
                    ...props.style,
                    width: 140,
                    height: 40,
                    alignItems: "center",
                    justifyContent: "space-around",
                    borderRadius: 20,
                    borderWidth: 1,
                    borderColor: borderColor,
                    backgroundColor: backgroundColor,
                }]
            }
            onPress={() => {
                // this.setSelected(true)
                if (props.onSelectedChange) {
                    props.onSelectedChange(props.id)
                }
            }}
            underlayColor={"transparent"}
        >
            <Text style={{
                color: textColor,
                fontFamily: "LatoRegular",
                fontSize: 14,
                fontWeight: "700",
            }}>{props.text}</Text>
        </TouchableHighlight>
    );
}
