import {Image, ScrollView, StyleSheet, Text, View} from "react-native";
import ScreenHeader from "../../ScreenHeader";
import TitleView from "../../employee_screens/components/TitleView";
import Footer from "../../Footer";
import {fontStyles} from "../../../fontStyles";
import StyledDropDown from "../../StyledDropDown";
import React, {useEffect, useState} from "react";
import {colors} from "../../../colors";
import Space from "../../Space";
import NormalTable from "../../NormalTable";
import HttpRequests from "../../../http_requests/HttpRequests";
import LoadingMessage, {AlertMessage} from "../../DefaultMessages";
import DatePickerView from "../../DatePickerView";
import {addDays, addMonths, differenceInDays, format, getYear, parse} from "date-fns";
import ModalDatePicker from "../../ModalDatePicker";
import Helpers from "../../../misc/Helpers";
import {Chart, HorizontalAxis, Line, Tooltip, VerticalAxis} from 'react-native-responsive-linechart'
import IntentMessenger from "../../../misc/IntentMessenger";
import {selectedEmployeeTag} from "../EmployeesTab/HomeContent";
import CustomButton from "../../employee_screens/components/CustomButton";


export default function NewSingleEmployeeReport({navigation}) {
    const [staticsRowItems, setStaticsRowItems] = useState(emptyStaticsRowItems())

    const dateTableHeaders = ["Day", "Date"]
    const [dateTableValues, setDateTableValues] = useState([["N/A", "N/A"]])
    const budgetTableHeaders = ["Budget", "N", "OB1", "OB2", "OB3"]
    const [budgetTableValues, setBudgetTableValues] = useState([["0", "0", "0", "0", "0"]])
    const resultTableHeaders = ["Result", "N", "OB1", "OB2", "OB3", "Sick", "S-OB1", "S-OB2", "S-OB3"]
    const [resultTableValues, setResultTableValues] = useState([["0", "0", "0", "0", "0", "0", "0", "0", "0"]])
    const diffTableHeaders = ["Diff"]
    const [diffTableValues, setDiffTableValues] = useState([["0"]])

    const tableHeaders = ["Day", "Date", "Budget", "N", "OB1", "OB2", "OB3", "Sick", "S-OB1", "S-OB2", "S-OB3"]
    const [tableValues, setTableValues] = useState([
        ["N/A", "N/A", "0", "0", "0", "0", "0", "0", "0", "0", "0"]
    ])

    const [startDate, setStartDate] = useState<Date>(null)
    const [endDate, setEndDate] = useState<Date>(null)
    const [showStartPicker, setShowStartPicker] = useState(false)
    const [showEndPicker, setShowEndPicker] = useState(false)
    const timeIntervalItems = [{label: "Daily"}, {label: "Monthly"}, {label: "Yearly"}, {label: "Custom"}]
    const [timeInterval, setTimeInterval] =
        useState<{ label: "Daily" } | { label: "Monthly" } | { label: "Yearly" } | { label: "Custom" }>(null)
    useEffect(() => {
        setStartDate(null)
        setEndDate(null)
        setSelectedYear(null)
        setStaticsRowItems(emptyStaticsRowItems())
        setDateTableValues([["N/A", "N/A"]])
        setBudgetTableValues([["0", "0", "0", "0", "0"]])
        setResultTableValues([["0", "0", "0", "0", "0", "0", "0", "0", "0"]])
        setDiffTableValues([["0"]])

        setTableValues([
            ["N/A", "N/A", "0", "0", "0", "0", "0", "0", "0", "0", "0"]
        ])
    }, [timeInterval]);

    const yearItems = Array.from({length: 10}, (item, index) => {
        return {label: getYear(new Date()) - 5 + index}
    })
    const [selectedYear, setSelectedYear] = useState<{ label: number }>(null)
    useEffect(() => {
        if (selectedYear) {
            setStartDate(new Date(selectedYear.label, 0, 1))
            setEndDate(new Date(selectedYear.label + 1, 0, 0))
        }
    }, [selectedYear]);

    const [employees, setEmployees]
        = useState(Array<{ name: string, id: number, position: string, email: string }>())
    const [selectedEmployee, setSelectedEmployee] =
        useState<{ name: string, id: number, position: string, email: string }>(null)

    const [showLoadingMessage, setShowLoadingMessage] = useState(true)
    const [alertMessageText, setAlertMessageText] = useState("")
    const [alertMessageTitle, setAlertMessageTitle] = useState("")
    const [showMessage, setShowMessage] = useState(false)

    useEffect(() => {
        console.log(startDate)
        console.log(endDate)
        console.log(selectedEmployee)
        if (startDate !== null && endDate !== null && selectedEmployee !== null) {
            setTimeout(_ => {
                setShowLoadingMessage(true)
                HttpRequests.getEmployerDailyReportPageData(Helpers.formatDate(startDate), Helpers.formatDate(endDate), selectedEmployee.id).then(response => {
                    console.log(response)
                    if (response.success) {
                        let newStaticsRowItems = response.extras["staticsData"].map(item => {
                            return {title: item.wsTitle, value: item.totalHours}
                        })
                        newStaticsRowItems = newStaticsRowItems.filter(item => item.title.toLowerCase() !== "sick")
                        setStaticsRowItems(newStaticsRowItems)

                        setDateTableValues(response.extras["tableData"].map(item => {
                            const date = Helpers.getDate(item.budgetData.date)
                            return [
                                format(date, "EEEE"),
                                format(date, "dd LLL yyyy"),
                            ]
                        }))
                        setBudgetTableValues(response.extras["tableData"].map(item => {
                            return [
                                item.budgetData.budget,
                                item.budgetData.normal,
                                item.budgetData.ob1,
                                item.budgetData.ob2,
                                item.budgetData.ob3,
                            ]
                        }))
                        setResultTableValues(response.extras["tableData"].map(item => {
                            return [
                                item.resultsData.result,
                                item.resultsData.normal,
                                item.resultsData.ob1,
                                item.resultsData.ob2,
                                item.resultsData.ob3,
                                item.resultsData.sick,
                                item.resultsData.obs1,
                                item.resultsData.obs2,
                                item.resultsData.obs3,
                            ]
                        }))
                        setDiffTableValues(response.extras["tableData"].map(item => {
                            const expectedTime = item.budgetData.budget
                            const realTime = item.resultsData.result ?? 0
                            const result = expectedTime - realTime
                            return [
                                result
                            ]
                        }))

                        setTableValues(response.extras["tableData"].map(item => {
                            const date = Helpers.getDate(item.budgetData.date)
                            return [
                                format(date, "EEEE"),
                                format(date, "dd LLL yyyy"),
                                `$${item.budgetData.salary}`,
                                item.budgetData.normal,
                                item.budgetData.ob1,
                                item.budgetData.ob2,
                                item.budgetData.ob3,
                                item.resultsData.sick,
                                item.resultsData.obs1,
                                item.resultsData.obs2,
                                item.resultsData.obs3,
                            ]
                        }))
                    } else {
                        setDateTableValues([["N/A", "N/A"]])
                        setBudgetTableValues([["0", "0", "0", "0", "0"]])
                        setResultTableValues([["0", "0", "0", "0", "0", "0", "0", "0", "0"]])
                        setDiffTableValues([["0"]])
                    }

                    setShowLoadingMessage(false)
                })
            }, 500)
        }
    }, [selectedEmployee, startDate, endDate]);

    // functions
    function updateEmployeesArray() {
        setShowLoadingMessage(true)
        HttpRequests.getStaffListFull().then((result) => {
            console.log(result)
            if (result.success) {
                const newEmployeesArray = result.extras.data.map((item, index) => {
                    console.log(new Date(item.age))
                    return {name: item.name, id: item.user_id, position: item.position, email: item.email}
                })
                console.log(newEmployeesArray)
                setEmployees(newEmployeesArray)
                setTimeout(() => {
                    const message = IntentMessenger.popMessage(selectedEmployeeTag)
                    if (message) {
                        setSelectedEmployee(newEmployeesArray.find((value: {
                            name: string,
                            id: number,
                            position: string,
                            email: string
                        }) => value.id === message as number))
                    }
                    setShowLoadingMessage(false)
                }, 20)
            } else {
                setShowLoadingMessage(false)
                setTimeout(args => {
                    if (result.message !== "No Data Available!") {
                        setAlertMessageTitle("Error")
                        setAlertMessageText(result.message)
                        setShowMessage(true)
                    }
                }, 20)
            }
        })
    }

    function emptyStaticsRowItems() {
        return [
            {title: "Parental Leave", value: 0},
            {title: "Holiday", value: 0},
            {title: "Normal Leave", value: 0},
            {title: "Taking Care of Kid", value: 0},
            {title: "Rest Leave", value: 0},
            {title: "Permission Leave", value: 0},
        ]
    }

    function getDatepickerLabel() {
        switch (timeInterval.label) {
            case "Daily":
                return "Select Date"
            case "Monthly":
                return "Select Month"
            case "Custom":
                return "Start Date"
            default:
                return ""
        }
    }

    function getChartData(columnIndex: number) {
        if (!timeInterval || !startDate || !endDate) return []
        console.log("calculating chart date")
        return Array.from({length: differenceInDays(endDate, startDate)}, (_, index) => {
            const date = addDays(startDate, index)
            const foundInTable = tableValues.find(item => {
                return Helpers.formatDate(parse(item[1], "dd LLL yyyy", new Date())) === Helpers.formatDate(date)
            })
            const y = foundInTable ? +foundInTable[columnIndex] : 0
            return {x: index, y: y}
        })
    }

    function getTableHeader(): string {
        if (timeInterval && startDate) {
            switch (timeInterval.label) {
                case "Daily":
                    return format(startDate, "MMMM dd, yyyy")
                case "Monthly":
                    return format(startDate, "MMMM yyyy")
                case "Yearly":
                    return `Year ${format(startDate, "yyyy")}`
                case "Custom":
                    if (endDate) {
                        return `${format(startDate, "MMMM dd, yyyy")} to ${format(endDate, "MMMM dd, yyyy")}`
                    } else {
                        return ""
                    }
            }
        }
        return ""
    }

    function sumArray(array: string[][]): number[] {
        console.log(array)
        const numberArray = array.map(value => {
            return value.map(value1 => {
                if (typeof value1 === "string") {
                    return +value1.replace("$", "");
                } else {
                    return value1
                }
            });
        })
        return numberArray.reduce((previousValue, currentValue) => {
            return previousValue.map((value, index) => value + currentValue[index]);
        }, Array(array[0].length).fill(0))
    }

    useEffect(() => {
        updateEmployeesArray()
    }, []);

    // functional components
    function ReportRow(props: { icon: string, title: string, budget: number, time: number }) {
        return (
            <View>
                <View style={{
                    flexDirection: "row",
                    maxHeight: 40,
                    minHeight: 40,
                    flex: 1,
                    alignItems: "center",
                    borderWidth: 1,
                    borderRadius: 40,
                    borderColor: colors.navyBlue,
                    paddingHorizontal: 20,
                    paddingVertical: 2
                }}>

                    <Image source={require(`../../../assets/${props.icon}`)} style={{height: 20, width: 20}}/>
                    <Space width={10}/>
                    <Text style={{...fontStyles.lato700_14, color: colors.darkGray}}>{props.title}</Text>
                    <View style={{flex: 1}}/>
                    <Text style={{
                        ...fontStyles.lato700_15,
                        color: colors.navyBlue
                    }}>{`$${props.budget} | ${props.time}`}</Text>
                    <Space width={5}/>
                    <Text style={{...fontStyles.lato700_12, color: colors.navyBlue}}>hours</Text>
                </View>
            </View>
        )
    }

    function StaticsRow(props: { title: String, value: number }) {
        return <View style={{flex: 1, flexDirection: "row", maxHeight: 20}}>
            <Text style={{...fontStyles.lato700_12, lineHeight: 20}}>{props.title}</Text>
            <Space flex={1}/>
            <Text style={{
                ...fontStyles.lato700_12,
                color: colors.lightNavyBlue,
                lineHeight: 20
            }}>{`${props.value} `}</Text>
            <Text style={{...fontStyles.lato700_10, color: colors.lightNavyBlue, lineHeight: 20}}>hours</Text>
        </View>;
    }

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation} children={undefined}/>
            <TitleView titleText={"Employee Report"} style={undefined}/>

            <View style={{flex: 1}}>
                {/*top*/}
                <View style={{flexDirection: "row", flex: 1, maxHeight: 220, gap: 25}}>
                    {/*filters*/}
                    <View style={{width: 330, gap: 10}}>
                        <View style={{flexDirection: "row", flex: 1, maxHeight: 40, minHeight: 40}}>
                            <Text style={{...fontStyles.lato700_14, alignSelf: "center"}}>Select Employee</Text>
                            <View style={{flex: 1, maxHeight: 1}}/>
                            <View style={{width: 220}}>
                                <StyledDropDown
                                    labelField={"name"} valueField={"id"} search={true} value={selectedEmployee}
                                    style={undefined} items={employees} placeHolder={"Search for Name or ID"}
                                    topLabel={false}
                                    onChange={(item: { name: string, id: number, email: string, position: string }) => {
                                        console.log(item)
                                        setSelectedEmployee(item)
                                    }}
                                />
                            </View>
                        </View>
                        <View style={{flexDirection: "row", flex: 1, maxHeight: 40, minHeight: 40}}>
                            <Text style={{...fontStyles.lato700_14, alignSelf: "center"}}>Duration</Text>
                            <View style={{flex: 1, maxHeight: 1}}/>
                            <View style={{width: 220}}>
                                <StyledDropDown
                                    value={timeInterval} style={undefined} items={timeIntervalItems}
                                    placeHolder={"Time Interval"}
                                    onChange={(item) => {
                                        setTimeInterval(item)
                                    }}
                                />
                            </View>
                        </View>
                        {
                            timeInterval && timeInterval.label !== "Yearly" &&
                            <View style={{flexDirection: "row", flex: 1, maxHeight: 40, minHeight: 40, paddingEnd: 5}}>
                                <Text style={{
                                    ...fontStyles.lato700_14,
                                    alignSelf: "center"
                                }}>{getDatepickerLabel()}</Text>
                                <View style={{flex: 1, maxHeight: 1}}/>
                                <DatePickerView
                                    placeHolderText={undefined} onShowCalendarClicked={() => setShowStartPicker(true)}
                                    selectedDate={startDate} width={210}/>
                            </View>
                        }
                        {
                            timeInterval && timeInterval.label === "Custom" &&
                            <View style={{flexDirection: "row", flex: 1, maxHeight: 40, minHeight: 40, paddingEnd: 5}}>
                                <Text style={{...fontStyles.lato700_14, alignSelf: "center"}}>End Date</Text>
                                <View style={{flex: 1, maxHeight: 1}}/>
                                <DatePickerView
                                    placeHolderText={undefined} onShowCalendarClicked={() => setShowEndPicker(true)}
                                    selectedDate={endDate} width={210}/>
                            </View>
                        }
                        {
                            timeInterval && timeInterval.label === "Yearly" &&
                            <View style={{flexDirection: "row", flex: 1, maxHeight: 40, minHeight: 40, paddingEnd: 5}}>
                                <Text style={{...fontStyles.lato700_14, alignSelf: "center"}}>Select Year</Text>
                                <View style={{flex: 1, maxHeight: 1}}/>
                                <StyledDropDown
                                    style={{minWidth: 210, marginHorizontal: 0}} items={yearItems} placeHolder={"Year"}
                                    value={selectedYear}
                                    onChange={(item) => {
                                        setSelectedYear(item)
                                    }}
                                />
                            </View>
                        }
                    </View>
                    {/*<View style={{flexDirection: "row"}}>*/}
                    {/*    <Chart*/}
                    {/*        style={{height: 180, width: 330, backgroundColor: '#eee'}}*/}
                    {/*        xDomain={{min: -7, max: differenceInDays(endDate, startDate)}}*/}
                    {/*        yDomain={{*/}
                    {/*            min: 0, max: Math.max(*/}
                    {/*                ...tableValues.map(value => +value[3]),*/}
                    {/*                ...tableValues.map(value => +value[4]),*/}
                    {/*                ...tableValues.map(value => +value[5]),*/}
                    {/*                ...tableValues.map(value => +value[6]),*/}
                    {/*                1*/}
                    {/*            )*/}
                    {/*        }}*/}
                    {/*        padding={{left: 20, top: 30, bottom: 20, right: 10}}*/}
                    {/*        viewport={{*/}
                    {/*            size: {width: Math.min(30, differenceInDays(endDate, startDate))},*/}
                    {/*            initialOrigin: {x: -3}*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <VerticalAxis tickCount={5}/>*/}
                    {/*        <HorizontalAxis*/}
                    {/*            tickValues={[*/}
                    {/*                ...Array.from({length: Math.round(differenceInDays(endDate, startDate) / 7)}, (v, k) => k * 7),*/}
                    {/*                differenceInDays(endDate, startDate)]}*/}
                    {/*            // tickCount={Math.max(5, Math.round(differenceInDays(endDate, startDate) / 7))}*/}
                    {/*            theme={{*/}
                    {/*                labels: {*/}
                    {/*                    formatter: value => {*/}
                    {/*                        if (value < 0) return ""*/}

                    {/*                        const date = addDays(startDate, value)*/}
                    {/*                        return Helpers.formatDate(date)*/}
                    {/*                    }*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*        {*/}
                    {/*            tableValues[0][1] !== "N/A" &&*/}
                    {/*            <Line*/}
                    {/*                data={getChartData(3)}*/}
                    {/*                smoothing="none" theme={{stroke: {color: 'red', width: 1}}}*/}
                    {/*                tooltipComponent={<Tooltip/>}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        {*/}
                    {/*            tableValues[0][1] !== "N/A" &&*/}
                    {/*            <Line*/}
                    {/*                data={getChartData(4)}*/}
                    {/*                smoothing="none" theme={{stroke: {color: 'blue', width: 1}}}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        {*/}
                    {/*            tableValues[0][1] !== "N/A" &&*/}
                    {/*            <Line*/}
                    {/*                data={getChartData(5)}*/}
                    {/*                smoothing="none" theme={{stroke: {color: 'green', width: 1}}}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        {*/}
                    {/*            tableValues[0][1] !== "N/A" &&*/}
                    {/*            <Line*/}
                    {/*                data={getChartData(6)}*/}
                    {/*                smoothing="none" theme={{stroke: {color: 'yellow', width: 1}}}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*    </Chart>*/}
                    {/*    /!*chart legend*!/*/}
                    {/*    <View style={{width: 100, height: 180, backgroundColor: colors.lighterGray}}>*/}
                    {/*        <View style={{height: 20, flexDirection: "row", gap: 5, alignItems: "center"}}>*/}
                    {/*            <View style={{backgroundColor: 'red', width: 10, height: 10, borderRadius: 10}}/>*/}
                    {/*            <Text style={fontStyles.lato700_12}>Normal Work</Text>*/}
                    {/*        </View>*/}
                    {/*        <View style={{height: 20, flexDirection: "row", gap: 5, alignItems: "center"}}>*/}
                    {/*            <View style={{backgroundColor: 'blue', width: 10, height: 10, borderRadius: 10}}/>*/}
                    {/*            <Text style={fontStyles.lato700_12}>Overtime 1</Text>*/}
                    {/*        </View>*/}
                    {/*        <View style={{height: 20, flexDirection: "row", gap: 5, alignItems: "center"}}>*/}
                    {/*            <View style={{backgroundColor: 'green', width: 10, height: 10, borderRadius: 10}}/>*/}
                    {/*            <Text style={fontStyles.lato700_12}>Overtime 2</Text>*/}
                    {/*        </View>*/}
                    {/*        <View style={{height: 20, flexDirection: "row", gap: 5, alignItems: "center"}}>*/}
                    {/*            <View style={{backgroundColor: 'yellow', width: 10, height: 10, borderRadius: 10}}/>*/}
                    {/*            <Text style={fontStyles.lato700_12}>Overtime 3</Text>*/}
                    {/*        </View>*/}
                    {/*    </View>*/}
                    {/*</View>*/}
                    {/*employee info*/}
                    <View style={{
                        maxHeight: 180,
                        borderColor: colors.navyBlue,
                        borderWidth: 2,
                        borderRadius: 20,
                        padding: 10,
                        gap: 12,
                        flex: 1
                    }}>
                        <Text
                            style={fontStyles.lato700_20}>{selectedEmployee === null ? "Name" : selectedEmployee.name}</Text>
                        <Text
                            style={fontStyles.lato700_20}>{selectedEmployee === null ? "Position" : selectedEmployee.position}</Text>
                        <View style={{flexDirection: "row"}}>
                            <Text style={{...fontStyles.lato700_14, color: colors.lightGray}}>ID: </Text>
                            <Text
                                style={{...fontStyles.lato700_14}}>{selectedEmployee === null ? "ID" : selectedEmployee.id}</Text>
                        </View>
                        <Text
                            style={fontStyles.lato700_16}>{selectedEmployee === null ? "Email" : selectedEmployee.email}</Text>
                        <View style={{
                            flex: 1,
                            maxHeight: 20,
                            backgroundColor: colors.lightGreen,
                            borderRadius: 20,
                            paddingHorizontal: 5
                        }}>
                            <Text style={{
                                ...fontStyles.lato400_12,
                                color: "white",
                                alignSelf: "center",
                                lineHeight: 20
                            }}>Current Employee</Text>
                        </View>
                    </View>
                    {/*leave statics*/}
                    <View style={{flex: 1}}>
                        <View style={{flex: 1, maxHeight: 40, backgroundColor: colors.navyBlue, borderRadius: 40}}>
                            <Text style={{
                                ...fontStyles.lato700_14,
                                lineHeight: 40,
                                color: "white",
                                alignSelf: "center"
                            }}>Leave Statics</Text>
                        </View>
                        {
                            staticsRowItems.map((item, index) => {
                                return <StaticsRow title={item.title} value={item.value}/>
                            })
                        }
                    </View>
                </View>

                {/*bottom table*/}
                <View style={{flex: 1}}>
                    <Text style={fontStyles.lato700_20}>{getTableHeader()}</Text>
                    <ScrollView>
                        <View style={{flexDirection: "row", gap: 15}}>
                            <NormalTable
                                values={dateTableValues} headers={dateTableHeaders}
                                containerStyle={{width: 160}} columnWidthValues={[1, 1]}
                                headerColor={colors.headerBlue} headerHeight={45}
                                headerTextStyle={fontStyles.lato700_16} rowHeight={45} columnType={"flex"}
                                rowTextStyle={fontStyles.lato400_11} rowColors={[colors.tableRow, "white"]}
                                dividerColor={colors.tableRow}/>
                            <NormalTable
                                values={budgetTableValues} headers={budgetTableHeaders}
                                containerStyle={{flex: 1}} columnWidthValues={[2, 1, 1, 1, 1]}
                                headerColor={colors.headerBlue} headerHeight={45}
                                headerTextStyle={fontStyles.lato700_16} rowHeight={45} columnType={"flex"}
                                rowTextStyle={fontStyles.lato400_11} rowColors={[colors.tableRow, "white"]}
                                dividerColor={colors.tableRow}/>
                            <NormalTable
                                values={resultTableValues} headers={resultTableHeaders}
                                containerStyle={{flex: 2}} columnWidthValues={[2, 1, 1, 1, 1, 1, 1, 1, 1]}
                                headerColor={colors.headerBlue} headerHeight={45}
                                headerTextStyle={fontStyles.lato700_16} rowHeight={45} columnType={"flex"}
                                rowTextStyle={fontStyles.lato400_11} rowColors={[colors.tableRow, "white"]}
                                dividerColor={colors.tableRow}/>
                            <NormalTable
                                values={diffTableValues} headers={diffTableHeaders}
                                containerStyle={{width: 100}} columnWidthValues={[1]}
                                headerColor={colors.headerBlue} headerHeight={45}
                                headerTextStyle={fontStyles.lato700_16} rowHeight={45} columnType={"flex"}
                                rowTextStyle={fontStyles.lato400_11} rowColors={[colors.tableRow, "white"]}
                                dividerColor={colors.tableRow}/>
                        </View>
                        <View style={{flexDirection: "row", gap: 15}}>
                            <NormalTable
                                values={[["Total:"]]} headers={[]}
                                containerStyle={{width: 160}} columnWidthValues={[1]}
                                headerColor={colors.navyBlue} headerHeight={0}
                                headerTextStyle={{...fontStyles.lato700_16, color: "white"}} rowHeight={45}
                                columnType={"flex"}
                                rowTextStyle={{...fontStyles.lato700_16, color: "white"}} rowColors={[colors.navyBlue]}
                                dividerColor={colors.tableRow}/>
                            <NormalTable
                                values={[sumArray(budgetTableValues)]}
                                headers={[]}
                                containerStyle={{flex: 1}} columnWidthValues={[2, 1, 1, 1, 1]}
                                headerColor={colors.navyBlue} headerHeight={0}
                                headerTextStyle={{...fontStyles.lato700_16, color: "white"}} rowHeight={45}
                                columnType={"flex"}
                                rowTextStyle={{...fontStyles.lato700_16, color: "white"}} rowColors={[colors.navyBlue]}
                                dividerColor={colors.tableRow}/>
                            <NormalTable
                                values={[sumArray(resultTableValues)]}
                                headers={[]}
                                containerStyle={{flex: 2}} columnWidthValues={[2, 1, 1, 1, 1, 1, 1, 1, 1]}
                                headerColor={colors.navyBlue} headerHeight={0}
                                headerTextStyle={{...fontStyles.lato700_16, color: "white"}} rowHeight={45}
                                columnType={"flex"}
                                rowTextStyle={{...fontStyles.lato700_16, color: "white"}} rowColors={[colors.navyBlue]}
                                dividerColor={colors.tableRow}/>
                            <NormalTable
                                values={[sumArray(diffTableValues)]} headers={[]}
                                containerStyle={{width: 100}} columnWidthValues={[1]}
                                headerColor={colors.navyBlue} headerHeight={0}
                                headerTextStyle={{...fontStyles.lato700_16, color: "white"}} rowHeight={45}
                                columnType={"flex"}
                                rowTextStyle={{...fontStyles.lato700_16, color: "white"}} rowColors={[colors.navyBlue]}
                                dividerColor={colors.tableRow}/>
                            {/*<NormalTable*/}
                            {/*    columnType={"flex"} values={tableValues}*/}
                            {/*    columnWidthValues={[1.5, 1.5, 1.5, 1, 1, 1, 1, 1, 1, 1, 1]} containerStyle={{flex: 1}}*/}
                            {/*    headers={tableHeaders} headerColor={colors.headerBlue} headerHeight={45}*/}
                            {/*    headerTextStyle={fontStyles.lato700_16}*/}
                            {/*    rowHeight={45} rowTextStyle={fontStyles.lato400_11}*/}
                            {/*    rowColors={[colors.tableRow, "white"]}*/}
                            {/*    dividerColor={colors.tableRow}/>*/}
                        </View>
                    </ScrollView>
                </View>
            </View>
            <Space height={20}/>

            {/*export button*/}
            <View style={{flex: 1, maxHeight: 40, flexDirection: "row-reverse",}}>
                <CustomButton
                    flex={1} text={"Export PDF"} style={{maxWidth: 220}} fontSize={16} color={colors.lightYellow}
                    onPress={() => {
                        const startDateStr = Helpers.formatDate(startDate)
                        const endDateStr = Helpers.formatDate(endDate)
                        HttpRequests.getSingleEmployeeReportPdf(startDateStr, endDateStr, selectedEmployee.id, selectedEmployee.name).then(value => {
                            console.log(value)
                        })
                    }}/>
            </View>

            <Footer/>
            <LoadingMessage showLoadingMessage={showLoadingMessage}/>
            <AlertMessage message={alertMessageText} title={alertMessageTitle} showMessage={showMessage}
                          onConfirm={() => setShowMessage(false)}/>

            <ModalDatePicker
                open={showStartPicker}
                currentDate={startDate}
                onCancel={() => {
                    setShowStartPicker(false)
                }}
                onConfirm={(date) => {
                    setStartDate(date)
                    if (timeInterval) {
                        switch (timeInterval.label) {
                            case "Daily":
                                setEndDate(date)
                                break;
                            case "Monthly":
                                setEndDate(addDays(addMonths(date, 1), -1))
                                break;
                        }
                    }
                    setShowStartPicker(false)
                }}
                mode={timeInterval && timeInterval.label === "Monthly" ? "monthYear" : "calendar"}
            />
            <ModalDatePicker
                open={showEndPicker}
                currentDate={endDate}
                onCancel={() => {
                    setShowEndPicker(false)
                }}
                onConfirm={(date) => {
                    setShowEndPicker(false)
                    setEndDate(date)
                }}
                mode={timeInterval && timeInterval.label === "Monthly" ? "monthYear" : "calendar"}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
    fillingRow: {
        flex: 1, flexDirection: "row"
    },
    fillingColumn: {
        flex: 1, flexDirection: "column"
    },
    centerMainAxis: {
        alignItems: "center"
    },
    centerCrossAxis: {
        justifyContent: "center"
    },
    center: {
        justifyContent: "center", alignItems: "center"
    }
})
