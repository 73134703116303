import {useEffect, useRef, useState} from "react";
import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {
    addDays,
    addMonths,
    format,
    getDate,
    getDaysInMonth,
    getMonth,
    getYear, isLastDayOfMonth, lastDayOfMonth,
    lastDayOfWeek,
    setDate,
    setMonth,
    setYear
} from "date-fns";
import {Dropdown} from "react-native-element-dropdown";
import Table from "../Table";
import Selector from "./components/Selector";
import Space from "../Space";
import CustomButton from "./components/CustomButton";
import TitleView from "./components/TitleView";
import Footer from "../Footer";
import {colors} from "../../colors";
import ScreenHeader from "../ScreenHeader";
import {fontStyles} from "../../fontStyles";
import ProfileData from "../../misc/ProfileData";
import HttpRequests from "../../http_requests/HttpRequests";
import AwesomeAlert from "react-native-awesome-alerts";
import SwedishHolidays from "../../misc/swedish-holidays";

export default function AvailabilityTab({navigation}) {
    // const totalSubmittedTime = useRef(0);
    const [totalSubmittedTime, setTotalSubmittedTime] = useState(0)
    const [submittedTime, setSubmittedTime] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date())
    const selectedYear = useRef(getYear(new Date()));
    let holidays = SwedishHolidays.getHolidays(getYear(new Date()))
    useEffect(() => {
        if (selectedYear.current !== getYear(selectedDate)) {
            selectedYear.current = getYear(selectedDate)
            holidays = SwedishHolidays.getHolidays(selectedYear.current)
            console.log(holidays)
        }
    }, [selectedDate]);

    const [loadingDone, setLoadingDone] = useState(false)
    if (!loadingDone) {
        console.log(`loading for date ${selectedDate} not finished yet`)
        HttpRequests.getIntervalEmployeeSuggestionTime(
            ProfileData.userId, format(setDate(selectedDate, 1), "yyyy-MM-dd"),
            format(setDate(addMonths(selectedDate, 1), 0), "yyyy-MM-dd"))
            .then((result) => {
                if (result.success) {
                    setSubmittedTime(result.extras)
                } else {
                    setSubmittedTime([])
                }
                setLoadingDone(true)
                console.log("loading finished")
            })
    }
    useEffect(() => {
        let t = 0
        submittedTime.forEach((value, index) => {
            t += calculateTime(value.start_time, value.end_time)
        })
        setTotalSubmittedTime(t)
    }, [submittedTime]);

    const [showDialog, setShowDialog] = useState(false)
    const dialogTitle = useRef("");
    const dialogMessage = useRef("");

    function calculateTime(startTime, endTime) {
        const startHour = +startTime.split(":")[0]
        const startMinute = +startTime.split(":")[1]
        const endHour = +endTime.split(":")[0]
        const endMinute = +endTime.split(":")[1]
        return endHour - startHour + (endMinute - startMinute) / 60
    }

    return (
        <View style={styles.mainContainer}>
            <ScreenHeader navigation={navigation}/>
            <TitleView titleText={"Set your availability"}/>
            <View style={{flex: 1, flexDirection: "row"}}>
                <MonthSelectors selectedDate={selectedDate} onSelectedDateChanged={(date => {
                    setSelectedDate(date)
                    setLoadingDone(false)
                })}/>
                <View style={{backgroundColor: colors.darkGray, width: 1,}}/>
                <Space width={20}/>
                <View style={{flex: 1}}>
                    <RequiredTimeNote totalSubmittedTime={totalSubmittedTime}/>
                    <View style={{flex: 1, flexDirection: "row"}}>
                        <DaySelectors selectedDate={selectedDate} holidays={holidays}
                                      onDaySelected={(date) => setSelectedDate(date)}/>
                        <Space width={20}/>
                        <TimeSelectors
                            selectedDate={selectedDate}
                            submittedTime={submittedTime}
                            onDataChanged={() => {
                                setLoadingDone(false)
                            }}
                            onMessage={(title, message) => {
                                dialogMessage.current = message
                                dialogTitle.current = title
                                setShowDialog(true)
                            }}
                        />
                    </View>
                </View>
            </View>
            <Footer/>
            <AwesomeAlert
                show={showDialog}
                message={dialogMessage.current}
                title={dialogTitle.current}
                showConfirmButton={true}
                onConfirmPressed={() => setShowDialog(false)}
                closeOnHardwareBackPress={false}
                closeOnTouchOutside={false}
                confirmButtonColor={colors.lightYellow}
                confirmButtonTextStyle={{color: "black"}}
            />
        </View>
    )
}

function RequiredTimeNote({totalSubmittedTime}) {
    return (
        <View style={{
            flex: 1,
            flexDirection: "row",
            maxHeight: 40,
            backgroundColor: colors.lightOrange,
            borderRadius: 20,
            alignItems: "center",
            marginBottom: 15,
        }}>
            <Text style={{
                ...fontStyles.lato700_18,
                marginStart: 20,
                textAlignVertical: "bottom"
            }}>{`You must set at least ${ProfileData.monthlyHours} hours`}</Text>
            <Space flex={1}/>
            <Text style={{...fontStyles.lato700_18, marginEnd: 20, textAlignVertical: "center"}}>You
                set {totalSubmittedTime} hours
                | {ProfileData.monthlyHours - totalSubmittedTime} hours
                left</Text>
        </View>
    )
}

function DaySelectors({selectedDate, holidays, onDaySelected}) {
    const [selectedId, setSelectedId] = useState(getDate(selectedDate))

    function getWeekDayName(day) {
        let date = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day)
        return `${String(day).padStart(2, "0")} - ${date.toLocaleDateString("en-US", {weekday: 'long'})}`
    }

    function getDayColor(day) {
        let isHoliday = holidays.filter((value, index, array) => {
            let holidayDate = value.date
            return getYear(holidayDate) === getYear(selectedDate) && getMonth(holidayDate) === getMonth(selectedDate) && getDate(holidayDate) === day
        }).length !== 0
        let isWeekend = getWeekDayName(day).includes("Sunday") || getWeekDayName(day).includes("Saturday")
        return isHoliday || isWeekend ? colors.navyBlue : colors.darkGray
    }

    let days = []
    for (let i = 1; i <= getDaysInMonth(selectedDate); i++) {
        days = days.concat(i)
    }

    return (
        <View style={{flex: 1}}>
            <Text style={{
                fontFamily: "LatoRegular",
                fontSize: 14,
                fontWeight: "700",
            }}>Select the days which you are available:</Text>
            <Space height={20}/>
            <ScrollView style={{flex: 1}}>
                <View>
                    <Table
                        minColumnWidth={180}
                        items={days}
                        columnCountCalculationFunction={(windowWidth) => Math.floor(windowWidth / 2 / 180 - 1)}
                        itemGeneratorFunction={(day, id) => {
                            if (day == null) {
                                return <View style={{
                                    backgroundColor: "transparent",
                                    width: 140,
                                    height: 40,
                                    marginBottom: 15,
                                    marginHorizontal: 10
                                }}/>
                            }
                            return (
                                <Selector
                                    style={{marginBottom: 15, marginHorizontal: 10}}
                                    text={getWeekDayName(day)}
                                    selectedBorderColor={colors.lightYellow}
                                    deselectedBorderColor={colors.lightYellow}
                                    selectedFillColor={colors.lightYellow}
                                    deselectedFillColor={"white"}
                                    selectedTextColor={getDayColor(id + 1)}
                                    deselectedTextColor={getDayColor(id + 1)}
                                    selectedId={selectedId}
                                    id={id + 1}
                                    onSelectedChange={(id) => {
                                        setSelectedId(id)
                                        onDaySelected(setDate(selectedDate, id))
                                    }}
                                />
                            )
                        }}/>
                </View>
            </ScrollView>
            <Space height={20}/>
            {/*<CustomButton text={"Submit"} fontSize={14} onPress={() => {*/}
            {/*}}/>*/}
        </View>
    )
}

function TimeSelectors({selectedDate, submittedTime, onDataChanged, onMessage}) {
    const selectedTimes = submittedTime.filter((time) => {
        return time.date === format(selectedDate, "yyyy-MM-dd")
    })

    const hours = []
    for (let i = 0; i < 24; i++) {
        hours.push({
            label: String(i).padStart(2, "0") + ":00",
            value: String(i).padStart(2, "0") + ":00",
        })
        hours.push({
            label: String(i).padStart(2, "0") + ":15",
            value: String(i).padStart(2, "0") + ":15",
        })
        hours.push({
            label: String(i).padStart(2, "0") + ":30",
            value: String(i).padStart(2, "0") + ":30",
        })
        hours.push({
            label: String(i).padStart(2, "0") + ":45",
            value: String(i).padStart(2, "0") + ":45",
        })
    }

    let startTime = ""
    let endTime = ""

    function getMinutes(time) {
        let parts = time.split(":")
        return parseInt(parts[0]) * 60 + parseInt(parts[1])
    }

    function TimeCard(value, index) {
        return (
            <View
                style={{
                    borderRadius: 20,
                    backgroundColor: colors.navyBlue,
                    width: 300,
                    height: 40,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                }}
            >
                <View style={{
                    height: 40,
                    width: 40,
                    borderRadius: 20,
                    borderWidth: 1,
                    borderColor: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: colors.navyBlue,
                }}>
                    <Text
                        style={{
                            color: "white"
                        }}
                    >{index + 1}</Text>
                </View>
                <Text style={{
                    color: "white",
                    flex: 1,
                    alignContent: "center",
                    marginHorizontal: 10,
                }}>{`${value.start_time} to ${value.end_time}`}</Text>

                <TouchableOpacity
                    style={{
                        height: 40,
                        width: 40,
                        borderRadius: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: colors.navyBlue,
                    }}
                    onPress={() => {
                        HttpRequests.deleteEmployeeSuggestionTime(value.id).then(result => {
                            if (result.success) {
                                onDataChanged()
                            }
                        })
                    }}
                >
                    <Image style={{width: 20, height: 20, marginHorizontal: 10}}
                           source={require("../../assets/icons/delete.png")}/>
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <View style={{flex: 1}}>
            <Text style={fontStyles.lato700_14}>Edit your day's time:</Text>
            <Space height={20}/>
            <View style={{flex: 1, borderWidth: 1, borderColor: colors.navyBlue, borderRadius: 20, padding: 25}}>
                <Dropdown
                    style={{
                        borderRadius: 20,
                        height: 40,
                        borderColor: colors.lightYellow,
                        borderWidth: 1
                    }}
                    placeholder={"Start Time"}
                    placeholderStyle={{...fontStyles.lato700_14, marginLeft: 20,}}
                    data={hours}
                    labelField={"label"}
                    valueField={"value"}
                    selectedTextStyle={{...fontStyles.lato700_14, marginLeft: 10,}}
                    itemTextStyle={{...fontStyles.lato700_14,}}
                    onChange={(hour) => {
                        if (endTime !== "" && getMinutes(hour.value) >= getMinutes(endTime)) {
                            console.log("error in setting start time")
                        } else {
                            startTime = hour.value
                        }
                    }}
                />
                <Space height={15}/>
                <Dropdown
                    style={{
                        borderRadius: 20,
                        height: 40,
                        borderColor: colors.lightYellow,
                        borderWidth: 1
                    }}
                    placeholder={"End Time"}
                    placeholderStyle={{...fontStyles.lato700_14, marginLeft: 20,}}
                    data={hours}
                    labelField={"label"}
                    valueField={"value"}
                    selectedTextStyle={{...fontStyles.lato700_14, marginLeft: 10,}}
                    itemTextStyle={{...fontStyles.lato700_14,}}
                    onChange={(hour) => {
                        if (startTime !== "" && getMinutes(hour.value) <= getMinutes(startTime)) {
                            console.log(getMinutes(hour.value))
                            console.log(`${startTime} contains ${getMinutes(startTime)} minutes`)
                            console.log(`${hour.value} contains ${getMinutes(hour.value)} minutes`)
                            console.log("error in setting end time")
                        } else {
                            endTime = hour.value
                        }
                    }}
                />
                <Space height={15}/>
                <CustomButton style={{flex: 1}} fontSize={14} text={"Add"} onPress={() => {
                    console.log(startTime)
                    console.log(endTime)
                    if (startTime === "" || endTime === "") {
                        onMessage("Error", "Please select start time and end time.")
                    } else if (getMinutes(startTime) >= getMinutes(endTime)) {
                        onMessage("Error", "Start time should be lower than end time")
                    } else {
                        console.log(selectedDate)
                        HttpRequests.addSingleDayEmployeeSuggestionTime(ProfileData.userId, format(selectedDate, "yyyy-MM-dd"), startTime, endTime).then((result) => {
                            if (result.success) {
                                onDataChanged()
                            } else {
                                onMessage("Error", result.message)
                            }
                        })
                    }
                }}/>
                <Space height={20}/>
                <Text
                    style={{
                        fontFamily: "LatoRegular",
                        fontSize: 18,
                        fontWeight: "700",
                        marginBottom: 20,
                    }}>This day times</Text>
                <ScrollView style={{flex: 1}}>
                    {
                        selectedTimes.map((value, index, array) => {
                            return TimeCard(value, index)
                        })
                    }
                </ScrollView>
                <Space height={15}/>
                <View style={{flexDirection: "row", marginTop: 30}}>
                    <CustomButton
                        style={{flex: 1}} fontSize={14} text={"Set this times to all the Month"}
                        onPress={() => {
                            if (selectedTimes.length === 0) {
                                onMessage("Error", "There's no item for this day, please add at least one time for this day and try again.")
                            } else {
                                if (isLastDayOfMonth(selectedDate)) {
                                    onMessage("Error", "This is the las day of month.")
                                } else {
                                    const endDate = format(lastDayOfMonth(selectedDate), "yyyy-MM-dd")
                                    const startDate = format(addDays(selectedDate, 1), "yyyy-MM-dd")
                                    console.log(endDate)
                                    const newItems = selectedTimes.map((item, index) => {
                                        return {start_time: item.start_time, end_time: item.end_time}
                                    })
                                    HttpRequests.addIntervalEmployeeSuggestionTime(ProfileData.userId, startDate, endDate, newItems).then((result) => {
                                        if (result.success) {
                                            onDataChanged()
                                        }
                                    })
                                }
                            }
                        }}/>
                    <Space width={15}/>
                    <CustomButton
                        style={{flex: 1}} fontSize={14} text={"Set this times to all the Week"}
                        onPress={() => {
                            if (selectedTimes.length === 0) {
                                onMessage("Error", "Theres no item for this day, please add at least one time for this day and try again.")
                            } else {
                                const endDate = format(lastDayOfWeek(selectedDate, {weekStartsOn: 1}), "yyyy-MM-dd")
                                console.log(endDate)
                                if (endDate === format(selectedDate, "yyyy-MM-dd")) {
                                    onMessage("Error", "This is the last day of week.")
                                } else {
                                    const startDate = format(addDays(selectedDate, 1), "yyyy-MM-dd")
                                    const newItems = selectedTimes.map((item, index) => {
                                        return {start_time: item.start_time, end_time: item.end_time}
                                    })
                                    HttpRequests.addIntervalEmployeeSuggestionTime(ProfileData.userId, startDate, endDate, newItems).then((result) => {
                                        if (result.success) {
                                            onDataChanged()
                                        }
                                    })
                                }
                            }
                        }}/>
                </View>
            </View>
        </View>
    )
}

function MonthSelectors({selectedDate, onSelectedDateChanged}) {
    const [selectedId, setSelectedId] = useState(getMonth(selectedDate))

    let yearSelectors = []
    for (let i = 0; i < 10; i++) yearSelectors.push({
        label: getYear(new Date()) + i,
        value: getYear(new Date()) + i
    })

    let selectorData = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    return (
        <ScrollView style={{flexGrow: 0}}>
            <View style={{maxWidth: 190, maxHeight: 40, alignItems: "center", marginRight: 20}}>
                <Dropdown
                    style={{
                        height: 40,
                        width: 140,
                        borderWidth: 1,
                        borderColor: colors.lightYellow,
                        borderRadius: 20,
                        marginBottom: 15
                    }}
                    data={yearSelectors}
                    itemTextStyle={{
                        fontFamily: "LatoRegular",
                        fontSize: 14,
                        fontWeight: "700",
                    }}
                    searchField={null}
                    selectedTextStyle={{
                        fontFamily: "LatoRegular",
                        fontSize: 14,
                        fontWeight: "700",
                        marginLeft: 10
                    }}
                    labelField={"label"}
                    valueField={"value"}
                    value={getYear(selectedDate)}
                    onChange={(item) => {
                        console.log(item)
                        onSelectedDateChanged(setYear(selectedDate, item.value))
                    }}
                />
                <Table columnsCount={1} items={selectorData} itemGeneratorFunction={(month, id) => {
                    return (
                        <Selector
                            style={{marginBottom: 15}}
                            text={month}
                            selectedBorderColor={colors.lightYellow}
                            deselectedBorderColor={colors.navyBlue}
                            selectedFillColor={colors.lightYellow}
                            deselectedFillColor={"white"}
                            selectedTextColor={colors.darkGray}
                            deselectedTextColor={colors.navyBlue}
                            selectedId={selectedId}
                            id={id}
                            onSelectedChange={(id) => {
                                onSelectedDateChanged(setMonth(selectedDate, id))
                                setSelectedId(id)
                            }}
                        />)
                }}/>
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "white",
        alignSelf: "stretch",
        flex: 1,
        paddingHorizontal: 20,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "stretch",
        alignContent: "center",
        alignItems: "center",
        maxHeight: 55,
        marginBottom: 30
    },
    userProfile: {
        ...fontStyles.lato700_16,
        color: colors.darkGray,
        marginStart: 5
    }
})
